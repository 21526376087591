import React, { useState, useEffect, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { logger } from '../../../logging';
import MicOffIcon from '../../../assets/icons/MicOff';
import MicIcon from '../../../assets/icons/Mic';
import VideocamIcon from '../../../assets/icons/Videocam';
import VideocamOffIcon from '../../../assets/icons/VideocamOff';
import { useDeviceSelectors } from 'react-device-detect';
import {
    useCaazamVideoContext,
    useLocalAudioToggle,
    useLocalVideoToggle,
} from '@caazam/caazam-video-room';
import { waitingRoomMessages } from './messages';
import { VideoScreenSpinner } from './components/video-screen-preview-loader/video-screen-spinner';
import './video-screen-preview.scss';
import {useLocation} from "react-router-dom";
import {VIDEO_CALL_PAGES} from "../../VideoCall";
import {PAGES} from "../../../constants/routes";

export const VideoScreenPreview = ({ videoTrackError, smallPreview, setButtonDisabled, isBoutiqVideoSupported }) => {

    const Intl = useIntl();
    const location = useLocation();
    const videoPreviewRef = useRef(null);
    const [isVideoAttached, setIsVideoAttached] = useState(false);
    const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle(true);
    const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle(true);
    const { localTracks, isAcquiringLocalTracks } = useCaazamVideoContext();
    const [isControlsVisible, setIsControlsVisible] = useState(false);
    const [videoAttaching, setVideoAttaching] = useState(false);
    const videoElementWrapperRef = useRef(null);
    const controlsWrapperRef = useRef(null);
    const [deviceSelectors]  = useDeviceSelectors();
    const { isIOS, isAndroid, browserName } = deviceSelectors

    const videoTrack = localTracks.find(track => track.kind === 'video') || null;
    const audioTrack = localTracks.find(track => track.kind === 'audio') || null;

    useEffect(() => {
        const isVideoPreviewDisabled = localTracks.length === 0 || isAcquiringLocalTracks;
        setButtonDisabled(isVideoPreviewDisabled);
        setIsControlsVisible(isBoutiqVideoSupported && !isAcquiringLocalTracks);
    }, [isAcquiringLocalTracks, localTracks]);

    useEffect(()=>{
        if (!isBoutiqVideoSupported) {
            logger.warn(`browser ${browserName} is not supported ${isIOS ? 'iOS device' : ''}${isAndroid ? 'Android device' : '' }`);
        }
    }, [isBoutiqVideoSupported, isIOS, isAndroid]);

    const renderVideoPreviewMessage = () => {
        if (!isBoutiqVideoSupported) {
            if (isIOS) {
                return (
                    <span>
                        <FormattedMessage {...waitingRoomMessages.browser_media_not_supported} values={{ browserName }} />{'. '}
                        <strong><FormattedMessage {...waitingRoomMessages.browser_media_not_supported_ios} /></strong>
                    </span>)
            } else if (isAndroid) {
                return (
                    <span>
                        <FormattedMessage {...waitingRoomMessages.browser_media_not_supported} values={{ browserName }} />{'. '}
                        <strong><FormattedMessage {...waitingRoomMessages.browser_media_not_supported_android} /></strong>
                    </span>)
            } else {
                return <FormattedMessage {...waitingRoomMessages.browser_media_not_supported}
                    values={{ browserName: Intl.formatMessage({ ...waitingRoomMessages.browser_this }) }}
                />
            }
        } else if (!!videoTrackError) {
            if (videoTrackError.name === 'AbortError' || videoTrackError.name === 'NotReadableError') {
                return Intl.formatMessage({ ...waitingRoomMessages.browser_media_abort });
            } else if (videoTrackError.name === 'NotAllowedError') {
                return Intl.formatMessage({ ...waitingRoomMessages.browser_media_not_allowed }, { device: 'both'});
            } else if (videoTrackError.name === 'VideoNotAllowedError') {
                return Intl.formatMessage({ ...waitingRoomMessages.browser_media_not_allowed }, { device: 'video'});
            } else if (videoTrackError.name === 'NotFoundError') {
                return Intl.formatMessage({ ...waitingRoomMessages.browser_media_not_found }, { device: 'both'});
            } else if (videoTrackError.name === 'VideoNotFoundError') {
                return Intl.formatMessage({ ...waitingRoomMessages.browser_media_not_found }, { device: 'video'});
            } else {
                return Intl.formatMessage({ ...waitingRoomMessages.browser_media_error }, { errorMsg: videoTrackError.message });
            }
        } else if (isAcquiringLocalTracks) {
            return <FormattedMessage {...waitingRoomMessages.acquire_media} />
        }
    }

    useEffect(() => {
        if (isVideoEnabled) {
            setVideoAttaching(true);
            if (isVideoAttached) {
                setVideoAttaching(false);
            }
        }
    }, [isVideoEnabled, isVideoAttached]);

    useEffect(() => {
        if (smallPreview) {
            if (videoElementWrapperRef.current) {
                const SMPreviewWidth = videoElementWrapperRef.current.clientWidth / 4;
                const controlsScale = SMPreviewWidth / 144;
                controlsWrapperRef.current.style.transform = `scale(${controlsScale})`;
                controlsWrapperRef.current.style.left = `35px`;
            }
        }

    }, [videoElementWrapperRef.current, smallPreview])

    useEffect(() => {
        if (videoPreviewRef?.current && location?.pathname === `${PAGES.VIDEO_CALL}/${VIDEO_CALL_PAGES.WAITING_ROOM}`) {
            controlsWrapperRef.current.style.marginTop = `${videoPreviewRef?.current.clientHeight / 2 + 50}px`;
        }
    }, [videoPreviewRef?.current, smallPreview])

    return (
        <div className={`video-container-wrapper${smallPreview ? ' small-preview' : ''}`}>
            <div className='video-container'>
                <div ref={videoElementWrapperRef} className='video-element-wrapper'>
                    {isBoutiqVideoSupported && !videoTrackError && <VideoScreenSpinner isLoading={videoAttaching} />}
                    {videoTrack && <VideoElement track={videoTrack} setIsVideoAttached={setIsVideoAttached} videoPreviewRef={videoPreviewRef} />}
                </div>
                {(localTracks.length === 0 || !!videoTrackError) && !smallPreview &&
                    <span className='av-alert-message'>
                        {renderVideoPreviewMessage()}
                    </span>
                }
                <div ref={controlsWrapperRef} className={`preview-controls${isControlsVisible ? ' visible' : ''} ${location?.pathname === `${PAGES.VIDEO_CALL}/${VIDEO_CALL_PAGES.WAITING_ROOM}` && 'waiting-room_control-position'}`}>
                    {!videoTrackError && <button onClick={toggleVideoEnabled}>
                        <div className={`video-button-loader${videoAttaching ? ' animated' : ''}`}></div>
                        {isVideoEnabled ? (
                            <VideocamIcon size={42}/>
                        ) : (
                            <VideocamOffIcon size={42}/>
                        )}
                    </button>}
                    {audioTrack && <button onClick={toggleAudioEnabled}>
                        {isAudioEnabled ? (
                            <MicIcon size={42}/>
                        ) : (
                            <MicOffIcon size={42}/>
                        )}
                    </button>}
                </div>
            </div>
        </div>
    );
}


const VideoElement = ({ track, setIsVideoAttached, videoPreviewRef }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        const el = videoRef.current;
        videoPreviewRef.current = el;
        el.muted = true;
        track.attach(el);
        setIsVideoAttached(true);
        return () => {
            setIsVideoAttached(false);
            track.detach(el);
        };
    }, [track]);

    const isFrontFacing =
        track.mediaStreamTrack.getSettings().facingMode !== 'environment';

    return (
        <video
            ref={videoRef}
            style={isFrontFacing ? { transform: 'scaleX(-1)' } : null}
        />
    );
};

