import React from 'react';
import PlayArrowIcon from '../../../assets/icons/PlayArrow';
import './index.scss'

export default function UserPlayInteraction({onUserInteraction}) {

    return (
        <div className='play-interaction-container'>
            <button onClick={onUserInteraction} className='play-interaction-button'>
                <PlayArrowIcon size={40} />
            </button>
        </div>
    )
}
