import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FilledButton, OutlinedButton } from '../../../../../components';
import { cartMessages } from '../../../intl/cart-messages/messages';
import './CheckoutComplete.scss';

export const CheckoutComplete = ({ clearCompletedCheckout, clearSessionAndCheckout }) => {
    return (
        <div className='mobile-showroom-overlay'>
            <h1 className='mobile-overlay-checkout-complete-header'>
                <FormattedMessage {...cartMessages.showroom_remove} />
            </h1>
            <div className='mobile-overlay-active-buttons-container'>
                <OutlinedButton
                    onClick={clearCompletedCheckout} className='mobile-keep'>
                    <FormattedMessage {...cartMessages.cta_keep} />
                </OutlinedButton>
                <FilledButton
                    className='mobile-remove'
                    onClick={clearSessionAndCheckout}>
                    <FormattedMessage {...cartMessages.cta_remove} />
                </FilledButton>
            </div>
        </div>
    )
}
