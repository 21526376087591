import { defineMessages } from 'react-intl';

export const boutiqFormMessages = defineMessages({
    required: {
        id: 'adverb_required'
    },
    name_error: {
        id: 'messages_name_error'
    },
    email_error: {
        id: 'messages.email_error'
    },
    email_invalid: {
        id: 'messages.email_invalid'
    },
    empty_field_error: {
        id: 'messages_empty_field_error'
    },
    select_option: {
        id: 'button_actions_select_option'
    },
    scheduling_form_desc: {
        id: 'messages_scheduling_form_desc'
    },
    scheduling_form_text_placeholder: {
        id: 'messages_scheduling_form_text_placeholder'
    },
    textarea_placeholder: {
        id: 'messages_scheduling_form_textarea_placeholder'
    },
    input_phone_error: {
        id: 'input_phone_error'
    },
    enter_phone: {
        id: 'messages_enter_phone'
    },
});
