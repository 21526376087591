

const trackerId = process.env.REACT_APP_GA_TRACKER_ID;

exports.loadGA = () => {
    if (window.boutiqGtag) return;
    
    var script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackerId}`
    document.head.appendChild(script);
    window.dataLayer = window.dataLayer || [];
    window.boutiqGtag = function() { window.dataLayer.push(arguments); }
    window.boutiqGtag('js', new Date());

    const initOptions = {
        'page_path': '/',
        'custom_map': { 'dimension1': 'shop_id', 'dimension2': 'context_id', 'dimension3' : 'shop_open' },
        'send_page_view': false,
    }

    if (window.self !== window.top) {
        initOptions['linker'] = {
            'accept_incoming': true
        };
        initOptions['client_storage'] = 'none';
        //initOptions['client_id'] = iframeSrc.searchParams.get('caazam_ga_client_id');        
    }

    window.boutiqGtag('config',trackerId, initOptions);
    window.boutiqGtag('set', { 'app_version': process.env.REACT_APP_VERSION, 'app_name': 'Boutiq Shopify Client',  });
}

exports.setShop = (shopId, isShopOpen) => {
    window.boutiqGtag && window.boutiqGtag('set', { 'shop_id': shopId, 'shop_open': !!isShopOpen ? 'open' : 'close' });
} 

exports.setContext = (contextId) => {
    window.boutiqGtag && window.boutiqGtag('set', { 'context_id': contextId, });
} 

exports.sendAppView = () => {
    window.boutiqGtag && window.boutiqGtag('event', 'page_view', {
        page_title: 'Boutiq Client App',
        page_location: process.env.PUBLIC_URL, // Include the full URL
      });
}

exports.sendScreeView = (screenName) => {
    window.boutiqGtag && window.boutiqGtag('event', 'screen_view', {
        'screen_name' : screenName,
      });
}

exports.sendCallEvent = (eventAction, label, value = null) => {
    window.boutiqGtag && window.boutiqGtag('event', eventAction, {        
        'event_category': 'call',
        'event_label': `${label}`,
        'value': value || null,
    });
}

exports.sendEcommEvent = (eventAction, eventParams) => {
    window.boutiqGtag && window.boutiqGtag('event', eventAction, eventParams);
}

exports.sendChatEvent = (eventAction, label = '') => {
    window.boutiqGtag && window.boutiqGtag('event', eventAction, {
        'event_category': 'chat',
        'event_label': `${label}` 
    });
}

exports.sendStoryEvent = (eventAction, label = '') => {
    window.boutiqGtag && window.boutiqGtag('event', eventAction, {
        'event_category': 'story',
        'event_label': `${label}` 
    });
}
