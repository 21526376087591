import React from 'react';
import { MainGreen, Opacity_16 } from '../ulits/colors';

import './styles.scss';

export default function AudioLevelIndicator({ volume }) {
  const baseHeight = window.screen.width < 600 ? 8 : 12;
  return (
    <div className='audio-level__container'>
      <span
        style={{ height: `${baseHeight}px`, backgroundColor: `${volume > 0 ? MainGreen : Opacity_16}` }}
        className={`audio-level__indicator`}
      />
      <span
        style={{ height: `${baseHeight * 1.5}px`, backgroundColor: `${volume > 7 ? MainGreen : Opacity_16}`  }}
        className={`audio-level__indicator`}
      />
      <span
        style={{ height: `${baseHeight * 2}px`, backgroundColor: `${volume > 14 ? MainGreen : Opacity_16}`  }}
        className={`audio-level__indicator`}
      />
    </div>
  );
}
