import React, { useRef, useEffect } from 'react'
import ScrollableListProvider from './providers/ScrollableListProvider';
import { smoothScrollBySteps } from '../../utils/smooth-scroll';
import './PageScrollContainer.scss';

export const PageScrollContainer = ({ resetTarget, scrollTargetElement, children }) => {

    const containerWrapperRef = useRef(null);

    useEffect(() => {
      if (scrollTargetElement) {
        smoothScrollBySteps(
            containerWrapperRef.current, 
            containerWrapperRef.current.scrollTop, 
            scrollTargetElement, 
            'top', 
            containerWrapperRef.current.scrollTop <= scrollTargetElement ? 1 : -1).then(() => resetTarget())
      }

    }, [scrollTargetElement])

    return (
        <ScrollableListProvider wrapperRef={containerWrapperRef}>
            <div className='scrollable-list-wrapper'>
                <div ref={containerWrapperRef} className='scrollable-list-container'>
                    {children}
                </div>
            </div>
        </ScrollableListProvider>
    )
}
