import React, { useState, useEffect } from 'react'
import { getRandom } from '../../../../utils';
import './styles.scss';

export const AllHostsAvatar = ({ hosts }) => {
    const [currentHosts, setCurrentHosts] = useState([]);

    useEffect(() => {
        if (currentHosts.length === 0) {
            setCurrentHosts(getRandom(hosts, 4).concat(Array.from({ length: 4 - hosts.length })));
        }
    }, [hosts]);

    return (
        <div className='multiple-host-avatar-container'>
            {currentHosts.map((hostData, index) => <ResizableHostAvatar key={`${index}`} avatar={hostData && hostData.avatar } />)}
        </div>
    )
}

const ResizableHostAvatar = ({ avatar, ...props }) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [showInitials, setShowInitials] = useState(false);

    const fallbackToInitials = () => {
        setShowInitials(true);
    }

    const animateImageOnLoad = () => {
        setIsImageLoaded(true);
    }

    return (
        <div className='host-avatar-container-wrapper' {...props}>
            {avatar && avatar.imageUrl ?
                showInitials ?
                    <span className='host-avatar initials'>{avatar.initials}</span>
                    : <img onError={() => fallbackToInitials()} onLoad={animateImageOnLoad} className={`host-avatar${isImageLoaded ? ' loaded' : ''}`} src={avatar.imageUrl} />
                : <span className={`host-avatar initials${!avatar ? ' empty' : ''}`}>{avatar && avatar.initials}</span>}
        </div>
    )
}
