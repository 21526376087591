import { useEffect, useState } from 'react';

// @ts-ignore
var AudioContext = window.AudioContext // Default
    || window.webkitAudioContext;// Safari and old versions of Chrome

let audioContext;

export function initializeAnalyser(stream) {
    audioContext = audioContext || new AudioContext();
    const audioSource = audioContext.createMediaStreamSource(stream);

    // TODO: [CRRC-81] consider adding a bandpass/lowpass filter to register human voice only
    const analyser = audioContext.createAnalyser();
    analyser.smoothingTimeConstant = 0.4;
    analyser.fftSize = 512;

    audioSource.connect(analyser);
    return analyser;
}

export default function useAudioVolume(audioTrack, onVolumeChange) {
    const [analyser, setAnalyser] = useState();
    
    const [volume, setVolume] = useState(0);

    useEffect(() => {
        if (audioTrack) {
          // Here we create a new MediaStream from a clone of the mediaStreamTrack.
          // A clone is created to allow multiple instances of this component for a single
          // AudioTrack on iOS Safari.
          let newMediaStream = new MediaStream([audioTrack.clone()]);
    
          // Here we listen for the 'stopped' event on the audioTrack. When the audioTrack is stopped,
          // we stop the cloned track that is stored in 'newMediaStream'. It is important that we stop
          // all tracks when they are not in use. Browsers like Firefox don't let you create a new stream
          // from a new audio device while the active audio device still has active tracks.
          const stopAllMediaStreamTracks = () => newMediaStream.getTracks().forEach(track => track.stop());
    
          const reinitializeAnalyser = () => {
            stopAllMediaStreamTracks();
            newMediaStream = new MediaStream([audioTrack.clone()]);
            setAnalyser(initializeAnalyser(newMediaStream));
          };
    
          setAnalyser(initializeAnalyser(newMediaStream));
    
          // Here we reinitialize the AnalyserNode on focus to avoid an issue in Safari
          // where the analysers stop functioning when the user switches to a new tab
          // and switches back to the app.
          window.addEventListener('focus', reinitializeAnalyser);
    
          return () => {
            stopAllMediaStreamTracks();
            window.removeEventListener('focus', reinitializeAnalyser);
          };
        }
      }, [audioTrack]);

    useEffect(() => {
        if (analyser) {
            
            const sampleArray = new Uint8Array(analyser.frequencyBinCount);

            const timer = setInterval(() => {
                analyser.getByteFrequencyData(sampleArray);
                let values = 0;

                // where 0 dB is the loudest possible sound,
                // 0 = The default value is -100 dB.
                // 255 = The default value is -30 dB (or higher freq)
                // each byte value = 0.27db --> -50dB = 182 byte value
                const length = sampleArray.length;
                var maxVolume = -Infinity
                for (let i = 0; i < length; i++) {
                    values += sampleArray[i];
                    if (sampleArray[i] !== -Infinity && sampleArray[i] > maxVolume) {
                        maxVolume = sampleArray[i];
                    }
                }

                // calcualted volume is on range of 0 to 21 - this is mainly for display convience 
                const volume = Math.min(21, Math.max(0, Math.log10(values / length / 3) * 14));
                setVolume(volume);

                // callback will get the avg and max decible levels
                onVolumeChange && onVolumeChange(values / length, maxVolume);
            }, 50);

            return () => {
                clearInterval(timer);
            };
        } else {
            onVolumeChange && onVolumeChange(null, null);
        }
    }, [analyser]);

    return volume;
}
