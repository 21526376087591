import * as React from "react"

function BottomArrow(props) {
  return (
    <svg viewBox="0 0 512 512" width={props.width || 20} height={props.height || 28} {...props}>
      <path
        d="M437.333 85.333c-19.958 0-38.708 7.771-53.021 22.094L256 243.292 127.479 107.208c-14.104-14.104-32.854-21.875-52.813-21.875C33.5 85.333 0 118.833 0 160c0 19.896 7.729 38.604 21.771 52.688l181.438 192.104c10.396 10.396 23.458 17.438 37.75 20.354 4.917 1.01 9.979 1.521 15.042 1.521s10.125-.51 15.021-1.521c14.313-2.917 27.375-9.958 37.979-20.573l181.125-191.781C504.229 198.698 512 179.948 512 160c0-41.167-33.5-74.667-74.667-74.667z"
        data-original="#000000"
        className="prefix__active-path"
        data-old_color="#000000"
        fill={props.fill || "#FFF"}
      />
    </svg>
  )
}

export default BottomArrow
