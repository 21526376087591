import React, { createContext, useContext } from 'react';

export const ScrollableListContext = createContext(null);

export default function ScrollableListProvider({ wrapperRef, children }) {
    return <ScrollableListContext.Provider value={{
        wrapperRef
    }}>
        {children}
    </ScrollableListContext.Provider>
}

export function useScrollableListProvider() {
    const context = useContext(ScrollableListContext);
    if (!context) {
        throw new Error('useScrollableListProvider must be used within the scope of ScrollableListContext');
    }
    return context;
}
