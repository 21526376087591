import React from 'react';
import { ItemControls } from './item-controls';
import { currencyFormatter } from '../../../../../../utils';
import './product-details.scss';

export const ProductDetails = ({ product, snapshot, isMobile, viewItem, updateCartProduct, removeCartProduct, withoutStepper }) => {

  const {
    currencyCode,
    currentPrice,
    currentCompareToPrice,
    productTitle,
    productDescription,
  } = product;

  return (
    <div className='product-card-details cart'>
      <div className='description-container'>
        <h5 onClick={() => { viewItem() }} className='product-title'>{productTitle}</h5>
        <div className='price-container'>
          <h5 className='actual-price'>{currencyFormatter(currentPrice, currencyCode)}</h5>
          {currentPrice !== currentCompareToPrice && currentCompareToPrice ? (
              <h5 className='old-price'>
                {currencyFormatter(currentCompareToPrice, currencyCode)}
              </h5>
            ) : null}
        </div>
      </div>

      <ItemControls
        product={product}
        snapshot={snapshot}
        updateCartProduct={updateCartProduct}
        removeCartProduct={removeCartProduct}
        isMobile={isMobile}
        withoutStepper={withoutStepper}
      />
    </div>
  );
};
