import React, { useState, createContext, useContext, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { en, fr, it, de, es } from '../assets/translations';
import storageFactory from "../utils/storageFactory";

export const LocalizationSettingsContext = createContext(null);

const appLocales = [
	'en',
	'fr',
	'it',
	'de',
	'es',
];

const msg = {
	'en': en,
	'fr': fr,
	'it': it,
	'de': de,
	'es': es,
};

const DEFAULT_LOCALE = 'en';

export default function LocalizationSettingsProvider({ locale, shopId, customMessages, children }) {
	const LOCAL_STORAGE_KEY = `caazam:locales:${shopId}`;
	const [customerLocale, setCustomerLocale] = useState(DEFAULT_LOCALE);	
	const [messages, setMessages] = useState(null);
	const localStore = storageFactory(()=> localStorage);

	const getLocale = () => {
		let storaged_locale = getPersistedLocale();
		if (storaged_locale) return storaged_locale;

		let selectedLocale;
		if (locale) {
			selectedLocale = locale.slice(0, 2);
		}
		
		let isAvailableLanguage = appLocales.includes(selectedLocale);
		if (!isAvailableLanguage) {
			const language = (navigator.languages && navigator.languages[0]) ||
				navigator.language ||
				navigator.userLanguage;
			if (language) {
				selectedLocale = language.slice(0, 2);
				isAvailableLanguage = appLocales.includes(selectedLocale);

				if (!isAvailableLanguage) {
					return DEFAULT_LOCALE;
				}
			} else {
				return DEFAULT_LOCALE;
			}
		}
		return selectedLocale;
	}

	const getPersistedLocale = () => {
		let storaged_locale = localStore.getItem(LOCAL_STORAGE_KEY);
		if (storaged_locale && storaged_locale.length > 0) {
			let parsedLocale = JSON.parse(storaged_locale);
			return parsedLocale.value ? parsedLocale.value : parsedLocale;
		}
		return null;
	}

	useEffect(() => {
		let selectedLocale = getLocale();
		if (selectedLocale !== customerLocale) {
			setCustomerLocale(selectedLocale);
		}
	}, [])

	useEffect(() => {
		const customMessagesLocale = customMessages && customMessages[customerLocale];
		setMessages(Object.assign({}, msg[customerLocale], customMessagesLocale));
	}, [customerLocale])

	const selectLanguage = (newLocale) => {
		if (!newLocale) {
			localStore.removeItem(LOCAL_STORAGE_KEY);
			setCustomerLocale(getLocale());
		} else {
			setCustomerLocale(newLocale);
			localStore.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newLocale));
		}
	}

	if (!messages) return null;

	return (
		<LocalizationSettingsContext.Provider value={{ customerLocale, appLocales, selectLanguage, getPersistedLocale }}>
			<IntlProvider messages={messages} locale={customerLocale}>
				{children}
			</IntlProvider>
		</LocalizationSettingsContext.Provider>
	);
}

export function useLocalizationSettingsProvider() {
	const context = useContext(LocalizationSettingsContext);
	if (!context) {
		throw new Error('useLocalizationSettingsProvider must be used within the scope of LocalizationSettingsContext');
	}
	return context;
}
