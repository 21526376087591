
import { MSG_STATUS } from "../constants/messaging";

export function getMessageRecepients(senderUuid, participants) {
    return Object.keys(participants).filter(participantId =>  participantId !== senderUuid && !participants[participantId].leaveTimestamp);
}

export function buildMessageSendStatus(senderUuid, recipients) {
    const status = {
        [senderUuid]: MSG_STATUS.SEEN,
    }
    for (const receipient of recipients) {
        status[receipient] = MSG_STATUS.SENT;
    }
    return status;
}
