import { useState, useEffect, useRef } from 'react';
import { useSessionShowroom } from '.';
import { useCallContextProvider } from '../components/CallContextProvider';
import usePrevious from '../modules/chat/hooks/usePreviousState';
import { checkMobileOnly } from '../utils';
import { state, tabLabels } from '../constants/menuDrawer';

export const useShowroomCounter = (openShowroom, drawerSize, closeDrawer, activeTab) => {
    const [showroomCounter, setShowRoomCounter] = useState({
        total: 0,
        isIncreased: false,
    });
    const isMobile = useRef(checkMobileOnly());
    const { callId } = useCallContextProvider();
    const { sessionSnapshots, sessionSnapshotsLength, sessionSnapshotLoading } = useSessionShowroom(callId);
    const [sessionSnapshotsCount, setSessionSnapshotsCount] = useState(999);
    const prevSessionSnapshotsCount = usePrevious(sessionSnapshotsCount);

    useEffect(() => {
        if (drawerSize === state.COLLAPSED && prevSessionSnapshotsCount < sessionSnapshotsLength && !isMobile.current) {
            openShowroom();
        }
        if (activeTab === tabLabels.SHOWROOM && drawerSize > state.COLLAPSED && !isMobile.current && prevSessionSnapshotsCount > 0 && sessionSnapshotsLength === 0) {
            closeDrawer();
        }
        setSessionSnapshotsCount(sessionSnapshotsLength);
    }, [sessionSnapshotsLength]);

    useEffect(() => {
        if (!sessionSnapshots) return;

        if (sessionSnapshots.length > showroomCounter.total) {
            setShowRoomCounter({
                total: sessionSnapshots.length,
                isIncreased: true,
            });
            setTimeout(() => {
                setShowRoomCounter((previousCounter) => {
                    return {
                        ...previousCounter,
                        isIncreased: false,
                    };
                });
            }, 3000);
        } else {
            setShowRoomCounter({ total: sessionSnapshots.length });
        }
    }, [sessionSnapshots]);

    return { sessionSnapshots, showroomCounter }
}
