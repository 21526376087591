import React, {useEffect} from 'react';
import { FilledButton } from '../../../../../../components';
import './styles.scss';

export const InfoModal = ({ title, content, buttonLabel, isOpen, hide }) => {

	useEffect(() => {
			const modal = document.getElementById('product-alert');
			if (modal) {
				document.body.appendChild(modal);
				return () => {
					document.body.removeChild(modal);
				}
			}
	}, [])

	if (!isOpen) {
		return null;
	}

	return (
		<div id='product-alert'>
			<div className='mobile-dialog'>
				<div className='mobile-alert'>
					<div className='mobile-alert-dialog'>
						<div id="mobile-alert-dialog-slide-title">
							<h2>
								{title}
							</h2>
						</div>
						<div className='dialog-content__root'>
							<p id="alert-dialog-slide-description">
								{content}
							</p>
						</div>
						<div className='dialog-actions__root'>
							<FilledButton className='mobile-accept-button' onClick={hide}>
								{buttonLabel}
							</FilledButton>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
