import React, { useState, useRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSearchSidebar } from '../../../../../../hooks/useSearchProducts';
import { SearchResults } from './components/search-results/SearchResults';
import { SearchInput } from './components/search-input/SearchInput';
import { useSessionShowroom } from '../../../../../../hooks';
import { useCallContextProvider } from '../../../../../../components/CallContextProvider';
import { sidebarMessages } from '../../../../intl/sidebar-messages/messages';
import './SearchView.scss';

export const SearchView = () => {
	const intl = useIntl();
	const [searchTerm, setSearchTerm] = useState(null);
    const { shopId, callId, callData } = useCallContextProvider();
    const { sessionSnapshots } = useSessionShowroom(callId);

	const {
		searchForProducts,
		fetchNextPage,
		noResults,
		cursor,
		productIdsInShowroom,
		isSearching,
		isFetchingMore,
		searchResults
	} = useSearchSidebar(sessionSnapshots, shopId, callData);

	const handleOnSubmit = (query) => {
		setSearchTerm(query);
		searchForProducts(query, productIdsInShowroom)
	}
	
	return (
		<div className='mobile-search-container'>
			<div className='mobile-search-input-wrapper'>
				<SearchInput
					isLoading={isSearching}
					onSubmit={handleOnSubmit}
					placeholder={intl.formatMessage(sidebarMessages.product_search_helper)} />
			</div>
			<SearchResults
				key={searchTerm}
				noResults={noResults}
				noResultsMessage={<FormattedMessage {...sidebarMessages.product_search_no_results} />}
				searchResults={!isSearching && searchResults}
				hasMore={cursor}
				loadMore={() => fetchNextPage(searchTerm, searchResults)}
				isLoading={isFetchingMore} />
		</div>
	);
};
