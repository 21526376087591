import React from 'react';
import firebase from '../Firebase'
import {getDatabase, ref} from 'firebase/database';
import { useObjectVal } from 'react-firebase-hooks/database';

export const useSessionDiscount = (callId, activeCartId) => {
  const db = getDatabase(firebase);
  const discountRef = ref(db, `session/${callId}/cart/${activeCartId}/discount`);
  const [cartDiscount, cartDiscountLoading, cartDiscountError] = useObjectVal(discountRef);

  return { cartDiscount, cartDiscountLoading, cartDiscountError };
};

