import React, { useRef, useEffect, useState, useContext } from 'react';
import {Switch, Route, useRouteMatch, useHistory, useLocation} from 'react-router-dom';
import { useCaazamVideoContext, isBrowserSupported } from '@caazam/caazam-video-room';
import { logger } from '../logging';
import CallContextProvider from '../components/CallContextProvider';
import { useClientAuthProvider } from '../components/ClientAuthProvider';
import { useClientConfigProvider } from '../components/ClientConfigProvider';
import VideoScreenPreviewProvider from '../components/video-screen-preview/VideoScreenPreviewProvider';
import VideoLobby from '../pages/video-lobby/video-lobby';
import CallScreen from '../pages/video-screen/call-screen';
import ThankYou from '../pages/thank-you-page/thank-you-page';
import { isMobile } from 'react-device-detect';
import { WaitingRoom } from '../pages/waiting-room/waiting-room';
import AppDiagnosticsProvider from "./AppDiagnosticsProvider";
import { BrowserCompabilityModal } from './video-screen-preview/video-screen-preview/components/video-screen-preview-loader/browser-compability-modal/BrowserCompabilityModal';
import { AppHeaderContext } from './AppHeaderProvider';
import AudioVideoSettingsDialog from './AudioVideoSettingsDialog';
import './video-call.scss';
import CaazamError from '../utils/errors';

export const VIDEO_CALL_PAGES = {
    ROOT: '',
    VIDEO_LOBBY: 'video-lobby',
    WAITING_ROOM: 'waiting-room',
    CALL: 'call',
    THANK_YOU: 'thank-you',
}

export default function VideoCall({ onError, onClose, onCallRequested, onCallConnect, onCallDisconnect }) {
    const { path } = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const [callConnectedTimestamp, setCallConnectedTimestamp] = useState(null);
    const currentLocalTracks = useRef([]);
    const { shopId, contextId } = useClientAuthProvider();
    const {
        getLocalTracks,
        localTracks,
        connect,
        disconnect,
    } = useCaazamVideoContext();
    const { dynamicConfig } = useClientConfigProvider();
    const unloadEvent = isMobile ? 'pagehide' : 'unload';
    const MIN_CALL_FOR_FEEDBACK = dynamicConfig.minCallDurationForFeedback || 2 * 60 * 1000
    const showFeedback = callConnectedTimestamp ? Date.now() - callConnectedTimestamp > MIN_CALL_FOR_FEEDBACK : false;
    const [localTracksError, setLocalTracksError] = useState(null);
    const isBoutiqVideoSupported = isBrowserSupported;
    const { AVSettingVisible, setAVSettingVisible } = useContext(AppHeaderContext);

    const stopAllLocalTracks = () => {
        logger.info('stopping local tracks');
        currentLocalTracks.current.forEach(track => track.stop());
    }

    const handleLocalTracksResult = (trackRes) => {
        if (trackRes.audio.audioError) {
            logger.error(`getLocalTracks`, trackRes.audio.audioError);
        }
        if (trackRes.video.videoError) {
            setLocalTracksError(trackRes.video.videoError)
        } else {
            setLocalTracksError(null)
        }
        // if both audioError & videoError theh localTracks = [] and call will not start

        logger.info('local tracks result', {
            audio: {
                track: {
                    id: trackRes.audio.audioTrack?.id,
                    name: trackRes.audio.audioTrack?.name,
                    label: trackRes.audio.audioTrack?.mediaStreamTrack?.label
                },
                audioError: trackRes.audio.audioError?.toString(),
                selectedAudioDevice: trackRes.audio.defaultAudioDevice,
                hasSelectedAudioDevice: trackRes.audio.hasSelectedAudioDevice,
            },
            video: {
                track: {
                    id: trackRes.video.videoTrack?.id,
                    name: trackRes.video.videoTrack?.name,
                    label: trackRes.video.videoTrack?.mediaStreamTrack?.label
                },
                videoError: trackRes.video.videoError?.toString(),
                selectedVideoDevice: trackRes.video.defaultVideoDevice,
                hasSelectedVideoDevice: trackRes.video.hasSelectedVideoDevice,
            },
        });
    }

    const onVideoCallRequested = () => {
        logger.info('onVideoCallRequested');
        onCallRequested && onCallRequested();
        history.push(`${path}/${VIDEO_CALL_PAGES.WAITING_ROOM}`);
    }

    const onVideoCallDisconnect = () => {
        logger.info('onVideoCallDisconnect');
        window.removeEventListener(unloadEvent, disconnect);
        stopAllLocalTracks();
        history.push(`${path}/${VIDEO_CALL_PAGES.THANK_YOU}`);
        onCallDisconnect && onCallDisconnect();
    }

    const onVideoCallLeave = () => {
        logger.info('onVideoCallLeave');
        onCallDisconnect && onCallDisconnect();
        onError && onError(new CaazamError(499, 'client left waiting room'));
    }

    const onVideoCallConnect = (callToken) => {
        logger.info('onVideoCallConnect', localTracks);
        // this WebRTC/Twilio will connect after our sig state machine is ready (connected)
        connect(callToken, null, onVideoCallDisconnect)
            .then(() => {
                logger.info('CONNECTED');
                window.addEventListener(unloadEvent, disconnect);
                onCallConnect && onCallConnect();
                setCallConnectedTimestamp(Date.now());
                history.push(`${path}/${VIDEO_CALL_PAGES.CALL}`)
            })
            .catch(connectError => {
                logger.error('CONNECT ERROR', connectError);
                onError && onError(connectError);
            });

    }

    const onVideoCallRejoin = () => {
        logger.info('onVideoCallRejoin');
        if (isBoutiqVideoSupported) {
            getLocalTracks()
                .then(handleLocalTracksResult)
                .catch(error => setLocalTracksError(error))
                .finally(() => history.push(`${path}`));// TODO  should this be replcae instead?
        }
    }

    useEffect(() => {
        if (isBoutiqVideoSupported) {
            logger.info('getting local tracks');;
            getLocalTracks()
                .then(handleLocalTracksResult)
                .catch(error => setLocalTracksError(error));

            return () => stopAllLocalTracks();
        }
    }, []);

    useEffect(() => {
        currentLocalTracks.current = localTracks;
        logger.info('change in local tracks',
            localTracks?.map(t => {
                if (t.kind === 'video')
                    return { kind: t.kind, dimensions: t.dimensions, label: t.mediaStreamTrack?.label }
                else
                    return { kind: t.kind, label: t.mediaStreamTrack?.label }
            }));
    }, [localTracks]);

    useEffect(() => {
        if (localTracksError) {
            logger.error('Failed getting local tracks', localTracksError);
        }
    }, [localTracksError]);

    const [isButtonDisabled, setButtonDisabled] = useState(true);

    return (
        <>
            <CallContextProvider shopId={shopId} contextId={contextId} onRequest={onVideoCallRequested} onConnect={onVideoCallConnect} onError={onError}>
                <VideoScreenPreviewProvider
                    setButtonDisabled={setButtonDisabled}
                    localTracksError={localTracksError}
                    isBoutiqVideoSupported={isBoutiqVideoSupported}
                >
                    <div className={`video-call-container-wrapper ${location?.pathname === path && ' video-call-container-wrapper_form-position'}`} style={{ width: '100%' }}>
                        {!isBoutiqVideoSupported && isMobile && <BrowserCompabilityModal />}
                        <AppDiagnosticsProvider>
                            <Switch>
                                <Route exact path={path}>
                                    <VideoLobby
                                        isButtonDisabled={isButtonDisabled}
                                    />
                                </Route>
                                <Route path={`${path}/${VIDEO_CALL_PAGES.WAITING_ROOM}`}>
                                    <WaitingRoom
                                        onLeave={onVideoCallLeave}
                                    />
                                </Route>
                                <Route path={`${path}/${VIDEO_CALL_PAGES.CALL}`}>
                                    <CallScreen />
                                </Route>
                                <Route path={`${path}/${VIDEO_CALL_PAGES.THANK_YOU}`}>
                                    <ThankYou
                                        close={onClose}
                                        onRejoin={onVideoCallRejoin}
                                        showFeedback={showFeedback}
                                    />
                                </Route>
                            </Switch>
                        </AppDiagnosticsProvider>
                    </div>
                </VideoScreenPreviewProvider>
            </CallContextProvider>
            {AVSettingVisible &&
                <div className='av-settings-dialog-container'>
                    <AudioVideoSettingsDialog
                        onClose={() => setAVSettingVisible(!AVSettingVisible)} />
                </div>
            }
        </>
    )
}
