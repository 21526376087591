import React from 'react';
import { DiscountInput } from '../../../../../../components/discount-input/DiscountInput';
import { FilledButton } from '../../../../../../components';
import { FormattedMessage } from 'react-intl';
import { useCallScreenProvider } from '../../../../../../components/CallScreenProvider';
import { useCallContextProvider } from '../../../../../../components/CallContextProvider';
import { useClientConfigProvider } from '../../../../../../components/ClientConfigProvider';
import { useCartData } from '../../../../../../hooks/useCartData';
import { useSessionDiscount } from '../../../../../../hooks';
import { currencyFormatter } from '../../../../../../utils';
import { CART_DISABLE_REASON } from  '../../../../../../constants/features';
import { cartComponentsMessages } from '../../../../intl/cart-components/messages';
import './cart-footer.scss';

export const CartFooter = () => {
	const { cartData } = useCartData();
	const { openCheckout, cartSnapshots } = useCallScreenProvider();
	const { callId, activeCartId } = useCallContextProvider();
	const { cartDiscount } = useSessionDiscount(callId, activeCartId);
	const { featureSupport } = useClientConfigProvider();
	const { checkoutDisabled } = featureSupport;

	return (
		<div className='mobile-cart-footer'>
			<div className='mobile-discount-code-input-wrapper'>
				<DiscountInput discount_message={<FormattedMessage {...cartComponentsMessages.applied_at_checkout} />} />
			</div>
			{checkoutDisabled && cartData.total > 0 &&
				<div className='mobile-ios-15-warning'>
					<p><FormattedMessage {...cartComponentsMessages.cart_disabled_title} values={{ device: checkoutDisabled?.reason === CART_DISABLE_REASON.IOS_15_1 ? 'ios' : 'not_ios' }} /></p>
          			<p><FormattedMessage {...cartComponentsMessages.cart_disabled_text} /></p>
				</div>
			}
			<div className='mobile-cart-footer-content'>
				{cartData.total !== null && cartData.total >= 0 &&
					<div className='mobile-cart-total-amount-container'>
						<div className='mobile-total-amount-title'>
							<p id='total-cart-price'>
								{currencyFormatter(cartData.total, cartData.currencyCode)}
							</p>
						</div>
						<div className='mobile-total-products-in-cart'>
							{cartSnapshots.length === 1 && <FormattedMessage {...cartComponentsMessages.cart_counter_one} />}
							{cartSnapshots.length !== 1 && <FormattedMessage {...cartComponentsMessages.cart_counter} values={{ count: cartSnapshots.length }} />}
						</div>
						<FilledButton
							onClick={() => openCheckout(cartData)}
							disabled={cartData.total === null || checkoutDisabled}
							className='mobile-checkout-btn'>
							<FormattedMessage {...cartComponentsMessages.cta_checkout} />
						</FilledButton>
					</div>
				}
			</div>
		</div>
	);
};
