export const innerLinkHandler = (event, onlineStoreUrl) => {
	if (event.nativeEvent.target) {
		const url = event.nativeEvent.target.href;
		let targetUrl = '';
		let objectUrl = '';
		if (isExternalLink(url)) {
			targetUrl = url;
		} else {
			if (onlineStoreUrl) {
				const linkUrl = event.nativeEvent.target.getAttribute('href');
				if (linkUrl[0] === '/') {
					objectUrl = new URL(onlineStoreUrl);
					targetUrl = `${objectUrl.origin}${linkUrl}`;
				} else {
					targetUrl = `${onlineStoreUrl}${linkUrl}`
				}
			}
		}
		if (targetUrl) {
			let targetUrlObj = new URL(targetUrl);
			targetUrlObj.searchParams.append('boutiqAppSource', 'true');
			window.open(targetUrlObj.toString(), '_blank').focus();
		}
		event.stopPropagation();
		event.preventDefault();
	}
}

const isExternalLink = (url) => {
	if (url) {
		try {
			const objectUrl = new URL(url);
			return objectUrl.host !== window.location.host;
		} catch (e) {
			return false;
		}
	}
	return false;
};
