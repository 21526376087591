import {useEffect, useState} from "react";
import firebase from '../Firebase'
import {getFirestore, onSnapshot, query, collection, where} from 'firebase/firestore';
import {useClientAuthProvider} from "../components/ClientAuthProvider";
import {useCallContextProvider} from "../components/CallContextProvider";
import {DRAFT_ORDER_OPEN} from "../constants/draftOrder";

const useDraftOrder = () => {
  const { shopId } = useClientAuthProvider()
  const { callId } = useCallContextProvider();
  const [draftOrder, setDraftOrder] = useState(null);
  const [draftOrders, setDraftOrders] = useState([]);
  const firestore = getFirestore(firebase);
  //FOR: update current draft order if status was change to 'COMPLETED'
  //FOR: if we have more than one draft order find 'OPEN'
  useEffect(() => {
    const openDraftOrder = draftOrders.find(order => order.status === DRAFT_ORDER_OPEN)
    if (openDraftOrder) {
      setDraftOrder(openDraftOrder)
    } else if (draftOrder) {
      setDraftOrder(draftOrders.find(order => order.id === draftOrder.id))
    }
  }, [JSON.stringify(draftOrders)])

  useEffect(() => {
    if (callId && shopId) {
      const q = query(collection(firestore, "shops", shopId, 'draftOrders'), where("callId", "==", callId));
      const draftOrderRef = onSnapshot(q, (snapshot) => {
        if (snapshot.docs) {
          setDraftOrders(snapshot.docs
            .map(doc => ({
                ...doc.data(),
                draftOrderId: doc.id
              })
            )
          )
        } else {
          setDraftOrder(null)
        }
      });
      return () => {
        draftOrderRef()
      }
    }
  }, [callId, shopId])

  const cleanDraftOrder = () => {
    setDraftOrder(null)
    setDraftOrders([])
  }

  return {
    draftOrder,
    cleanDraftOrder
  }
}

export default useDraftOrder;
