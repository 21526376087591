import firebase from '../Firebase'
import {getDatabase, ref, child, onValue} from 'firebase/database';
import { useEffect, useState } from 'react';

export default function useIntroConfig(shopId) {
  const [introVideosConfig, setIntroVideosConfig] = useState(null);
  const [introVideosConfigLoading, setIntroVideosConfigLoading] = useState(true);

  const db = getDatabase(firebase);

  useEffect(() => {
    if (shopId) {
      const shopRefId = shopId.split('.').join('-');
      onValue(child(ref(db, 'shopConfig'), shopRefId + '/introVideos'), (snapshot) => {
        setIntroVideosConfig(snapshot.val());
          setIntroVideosConfigLoading(false)
      },(error) => {
          setIntroVideosConfigLoading(false)
        console.error('useIntroConfig', error)
      }, {
          onlyOnce: true
        }
      )
    }
  }, [shopId]);

  return { introVideosConfig, introVideosConfigLoading };
};
