import React from 'react';
import { useSessionShowroomActions } from '../../hooks';
import { currencyFormatter } from '../../utils';
import { useCallContextProvider } from '../CallContextProvider';
import EmptyImage from '../emptyImage';
import { useProductViewProvider } from '../ProductViewProvider';
import { FormattedMessage } from 'react-intl';
import { productDetailsMessages } from './messages';
import { FilledButton } from '../buttons/buttons';
import AddRounded from '../../assets/icons/AddRounded';
import './styles.scss';

export const ProductSerachResult = ({ product }) => {
	const { viewProduct } = useProductViewProvider();
	const { callId } = useCallContextProvider();
	const { addToSession } = useSessionShowroomActions(callId);

	const renderAddedToShowroom = () =>
		<span id='added-msg' className='added-to-showroom-msg'>
			<FormattedMessage {...productDetailsMessages.added_to_showroom} />
		</span>

	const renderAddToShowroomBtn = (product) =>
		<FilledButton
			id='add-showroomBtn'
			onClick={() => {
				addToSession(product);
			}}>
			<span className='add-to-showroom-button-group'><AddRounded size='13' />&nbsp;<FormattedMessage {...productDetailsMessages.showroom} /></span>
		</FilledButton>

	const renderCurrentPrice = (product) => {
		if (product.maxVariantPrice !== product.minVariantPrice) {
			return (
				<>
					<h4>
						{`${currencyFormatter(product.minVariantPrice, product.currencyCode)} — ${currencyFormatter(product.maxVariantPrice, product.currencyCode)}`}
					</h4>
				</>
			)
		} else {
			return (
				<>
					<h4>
						{product.price && currencyFormatter(product.price, product.currencyCode)}
					</h4>
					{product.compareAtPrice && product.price !== product.compareAtPrice ? (
						<h4 className='old-price'>
							{currencyFormatter(product.compareAtPrice, product.currencyCode)}
						</h4>
					) : null}
				</>
			)
		}
	}

	return (
		<div className='product-details-wrapper'>
			<div className='image-container'>
				{product.image ? (
					<>
						<div onClick={() => viewProduct(product.productId)}
							className='product-overlay'>
						</div>
						<img
							src={product.image}
							alt={product.title}
						/>
					</>
				) : (
					<EmptyImage
						product={product}
						viewItem={() => viewProduct(product.productId)}
					/>
				)}
			</div>
			<div className='product-card-details-container'>
				<h5>{product.title}</h5>
				<div className='price-container'>
					{renderCurrentPrice(product)}
				</div>
			</div>
			{product.isInSR ? renderAddedToShowroom() : renderAddToShowroomBtn(product)}
		</div>
	)
}
