import React from 'react';
import { useCustomerDetails } from '../../../../../../../../../components/CustomerDetailsProvider';
import { FormattedMessage } from 'react-intl';
import { CartItemslist } from '../../../../../cart-components/cart-items-list/cart-items-list';
import { useCallScreenProvider } from '../../../../../../../../../components/CallScreenProvider';
import { CartFooter } from '../../../../../cart-components/cart-footer/cart-footer';
import { cartMessages } from '../../../../../../../intl/cart-messages/messages';
import './CartTab.scss';
import {DRAFT_ORDER_COMPLETED} from "../../../../../../../../../constants/draftOrder";
import {DraftOrderFooter} from "../../../../../draft-order-components/draft-order-footer/draft-order-footer";
import {DraftOrderItemsList} from "../../../../../draft-order-components/draft-order-items-list/draft-order-items-list";
import {normalizeDraftOrderProducts} from "../../../../../../../../../utils/draftOrder";

export const CartTab = () => {
	const { name } = useCustomerDetails();
	const { isCheckoutComplete, draftOrder } = useCallScreenProvider();

	const productContainer = () => draftOrder ? <DraftOrderItemsList products={normalizeDraftOrderProducts(draftOrder)} /> : <CartItemslist />

	const footerContainer = () => draftOrder ? <DraftOrderFooter /> : <CartFooter/>

	return (
		<div className='mobile-cart-products-container'>
			{isCheckoutComplete || draftOrder?.status === DRAFT_ORDER_COMPLETED  ? (
				<div className='mobile-post-purchase-message'>
					<p>
						<FormattedMessage {...cartMessages.thank_you} values={{ name: name ? `, ${name}` : '' }} />!
					</p>
				</div>
			) : productContainer()}
			{footerContainer()}
		</div>
	);
};
