import React, {useState, useEffect} from 'react';
import {useIntl, FormattedMessage} from 'react-intl';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useSelector} from "react-redux";

import ChatHeader from '../../../../components/chatHeader';
import {chatScreenMessages} from '../../messages';
import {StyledInput} from '../../../../components';
import {FilledButton} from '../../../../components/boutiq-buttons/BoutiqButton';

import { isEmail, isEmptyString } from '../../../../utils'

import {CHAT_ROUTES} from '../../../../constants/routes';
import {useCustomerChatData} from '../../../../modules/chat/hooks/useCustomerChatData';

import {errorPageMessages} from '../../../error-page/messages';
import { useCustomerDetails } from '../../../../components/CustomerDetailsProvider';
import { useSchdeuleProvider } from '../../../../components/SchdeuleProvider';
import { useClientAuthProvider } from '../../../../components/ClientAuthProvider';
import { useClientConfigProvider } from '../../../../components/ClientConfigProvider';
import './styles.scss';

const WelcomeChatScreen = ({ initChatRoom }) => {
  const intl = useIntl();
  const { path } = useRouteMatch();
  const { shopId , user, contextId } = useClientAuthProvider();
  const { isScheduleLoading, getSchdeuleStatus } = useSchdeuleProvider();
  const { isChatEnabled, realtimeConfigLoading} = useClientConfigProvider();
  const history = useHistory();
  const { id: roomId } = useSelector(state => state.activeRoom);

  const [isChatLoading, setChatLoading] = useState(false);
  const [startChatRequested, setStartChatRequested] = useState(false);

  useCustomerChatData(user, shopId);

  const [fieldsError, setError] = useState(null);
  const {
    name,
    email,
    setCustomerName,
    setCustomerEmail,
  } = useCustomerDetails();

  useEffect(() => {
    if (startChatRequested) {
      setChatLoading(true);
      if (!isScheduleLoading && contextId && !realtimeConfigLoading)
        startChat();
    }
  }, [startChatRequested, isScheduleLoading, contextId, realtimeConfigLoading])

  const onCustomerName = (e) => setCustomerName(e.target.value);

  const onCustomerEmail = (e) => setCustomerEmail(e.target.value);


  const startChat = async () => {
    try {
      const status = await getSchdeuleStatus();

      if (status && isChatEnabled) {
        await initChatRoom(!roomId);
        setChatLoading(false);
        history.push(`${path}${CHAT_ROUTES.MESSENGER}`);
      } else {
        setChatLoading(false);
        history.push(`${path}${CHAT_ROUTES.ERROR}`);
      }
    } catch (error) {
      console.error('startChat', error);
      setChatLoading(false);
      history.push(`${path}${CHAT_ROUTES.ERROR}`);
    }

  }

  const onCreateChat = async () => {
    if (!name && !email) {
      setError(errorPageMessages.email_name_error);
    } else if (isEmptyString(name)) {
      setError(errorPageMessages.name_error);
    } else if (!email) {
      setError(errorPageMessages.email_error);
    } else if (!isEmail(email)) {
      setError(errorPageMessages.email_invalid);
    } else if (name && email) {
      setError(null);
      setStartChatRequested(true);
    }
  };

  return (
    <div className='welcome-chat'>
      <ChatHeader />
      <div className='welcome-chat_content'>
        <StyledInput
          placeholder={intl.formatMessage(chatScreenMessages.name)}
          onChange={onCustomerName}
          name='name'
          value={name}
          className='welcome-chat_input'
        />
        <span className='welcome-chat_divider'/>
        <StyledInput
          placeholder={intl.formatMessage(chatScreenMessages.email) + '*'}
          value={email}
          onChange={onCustomerEmail}
          name='email'
          type='email'
          className='welcome-chat_input'
        />
        {fieldsError &&
          <span className='welcome-chat_error'>
            <FormattedMessage {...fieldsError} />
          </span>
        }
        <FilledButton
          onClick={onCreateChat}
          className='welcome-chat_send'
          disabled={isScheduleLoading}
          type='submit'
          isLoading={isScheduleLoading || isChatLoading}
        >
          <FormattedMessage {...chatScreenMessages.start} />
        </FilledButton>
      </div>
    </div>
  );
};

export default WelcomeChatScreen;
