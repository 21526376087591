const PAGES = {
  BASE: '/',
  CHAT: '/chat',
  CLOSE_PAGE: '/close-page',
  INTRO: '/intro',
  VIDEO_CALL: '/video-call',
  SCHEDULING: '/scheduling',
  CONTACT: '/contact',
  ERROR: '/error',
};

const CHAT_ROUTES = {
  WELCOME: '/welcome',
  MESSENGER: '/messenger',
  ERROR: '/error',
}

export {
  PAGES,
  CHAT_ROUTES,
}
