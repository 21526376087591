import React, { useState, useEffect, } from 'react';
import { FilledButton } from '../../../../../../components';
import { useClientConfigProvider } from '../../../../../../components/ClientConfigProvider';
import { currencyFormatter } from '../../../../../../utils';
import { CART_DISABLE_REASON } from '../../../../../../constants/features';
import LocalMallIcon from '../../../../../../assets/icons/LocalMall';
import { FormattedMessage } from 'react-intl';
import { cartComponentsMessages } from '../../../../intl/cart-components/messages';

import './cart-footer.scss';

export const CartFooter = ({ cartData, isMobile, cartSnapshots, cartDiscount, openCheckout, isCheckoutComplete }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1000);
  useEffect(()=> {
    const handleResize = setIsSmallScreen(window.innerWidth < 1000);
    window.addEventListener('resize', handleResize);
    return window.removeEventListener('resize', handleResize);
  }, []);
  const { featureSupport } = useClientConfigProvider();

  const { checkoutDisabled } = featureSupport;

  const renderCheckoutPanel = () => {
    if (isMobile) {
      if (cartData.total !== null && cartData.total !== 0) {
        return (
          <div>
            {renderCheckoutButton()}
          </div>
        );
      }
      return null;
    }
    return (
      <div className='end'>
        <div className='checkout-messages'>
          {/* {cartData.totalDiscount > 0 && !isSmallScreen &&
            <p className='discount-text'>
              <FormattedMessage {...cartComponentsMessages.cart_savings} values={{ percents: cartData.totalDiscount }} />
            </p>
          } */}
        </div>
        {renderCheckoutButton()}
      </div>
    );
  }

  const renderCheckoutButton = () => {
    const style = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 20px',
    }

    if (isMobile) {
      style.width = '140px';
      style.height = '40px';
      style.padding = '0px';
    }
    return (
      <div
        className='checkout-actions'
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <FilledButton
          onClick={() => openCheckout(cartData)}
          disabled={!cartData.total || checkoutDisabled}
          className='button'
          style={style}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}>
            {!isMobile &&
              <LocalMallIcon size={24} className='cart__local-mail--icon' />
            }
            <FormattedMessage {...cartComponentsMessages.cta_checkout} />
          </div>

        </FilledButton>
      </div>
    );
  }

  const showDivider = !isMobile || (cartData.total > 0)

  return (
    <div className='cart-footer'>
      {showDivider && <div className='divider' />}
      {checkoutDisabled && cartData.total > 0 &&
        <div className='ios-15-warning'>
          <p><FormattedMessage {...cartComponentsMessages.cart_disabled_title} values={{ device: checkoutDisabled?.reason === CART_DISABLE_REASON.IOS_15_1 ? 'ios' : 'not_ios' }} /></p>
          <p><FormattedMessage {...cartComponentsMessages.cart_disabled_text} /></p>
        </div>
      }
      {cartDiscount && cartData.total > 0 && !isCheckoutComplete &&
        <div className='dicount-applied'>
          <p className='discount-text'>{cartDiscount.summary}</p>
          <p className='discount-text'> - <FormattedMessage {...cartComponentsMessages.applied_at_checkout} /></p>
        </div>
      }
      <div className='cart-footer-content'>
        {cartData.total > 0
          ?
          <div className='start'>
            <h1 id='total-cart-price'>{currencyFormatter(cartData.total, cartData.currencyCode)}</h1>
            <p id='total-cart-text'>
                  {cartSnapshots.length === 1 && <FormattedMessage {...cartComponentsMessages.cart_counter_one} />}
                  {cartSnapshots.length !== 1 && <FormattedMessage {...cartComponentsMessages.cart_counter} values={{ count: cartSnapshots.length }} />}
            </p>

          </div>
          : null}
        {renderCheckoutPanel()}
      </div>
    </div>
  );
};
