import React from 'react';
import { useCallContextProvider } from '../../../../../../../../../components/CallContextProvider';
import { useProductViewProvider } from '../../../../../../../../../components/ProductViewProvider';
import { useSessionShowroomActions } from '../../../../../../../../../hooks';
import AddRoundedIcon from '../../../../../../../../../assets/icons/AddRounded';
import { useIntl, FormattedMessage } from 'react-intl';
import { CheckoutComplete } from '../../../../../../components/checkout-complete/CheckoutComplete';
import { useCallScreenProvider } from '../../../../../../../../../components/CallScreenProvider';
import { ShowRoomProduct } from '../../../../../showroom-product/ShowRoomProduct';
import { useModalViewProvider } from '../../../../../../../../../components/mobile-providers/ModalViewProvider';
import { ProductDetailsView } from '../../../../../modals/product-details-view/ProductDetailsView';
import { SearchView } from '../../../../../modals/search-view/SearchView';
import { EmptyTab } from '../../../../../empty-tab/EmptyTab';
import { parseIdString } from '../../../../../../../../../utils';
import { cartMessages } from '../../../../../../../intl/cart-messages/messages';
import { IconEmptyShowroomMobile } from '../../../../../../../../../assets/icons/mobile/EmptyShowroomMobile';
import './ShowroomTab.scss';
import {DRAFT_ORDER_COMPLETED} from "../../../../../../../../../constants/draftOrder";

export const ShowroomTab = () => {
	const intl = useIntl();

	const {
		isCheckoutComplete,
		setCheckoutComplete,
		sessionSnapshots,
		cartSnapshots,
		isSearchDisabled,
		draftOrder,
		cleanDraftOrder
	} = useCallScreenProvider();
	const { callId } = useCallContextProvider();
	const { removeSessionProduct, clearSession } = useSessionShowroomActions(callId);
	const { viewProduct, clearSelectedProduct } = useProductViewProvider();
	const { showView } = useModalViewProvider();

	const isCompleted = isCheckoutComplete || draftOrder?.status === DRAFT_ORDER_COMPLETED

	const removeItem = (productKey) => {
		removeSessionProduct(productKey);
	};

	const clearCompletedCheckout = () => {
		cleanDraftOrder()
		setCheckoutComplete(false);
	};

	const clearSessionAndCheckout = () => {
		clearSession();
		clearCompletedCheckout();
	};

	const openDetails = async (e) => {
		await viewProduct(e);
		const modal = {
			dismissAlloved: true,
			onDismiss: clearSelectedProduct,
			type: 'product-details',
			component: <ProductDetailsView />
		}
		showView(modal);
	};

	const openSearchSidebar = () => {
		const modal = {
			dismissAlloved: true,
			type: 'search-sidebar',
			headerLabel: 'Add products to showroom',
			component: <SearchView />
		}
		showView(modal);
	}

	return (
		<div className='mobile-showroom-content-wrapper'>
			{sessionSnapshots && sessionSnapshots.length ? (
				<div className='mobile-showroom-products-container'>
					{sessionSnapshots.map((snapshot) => {
						const product = snapshot.val();
						const isProductInCart = (product) => {
							return cartSnapshots.find((snapshot) => snapshot.val().productId === parseIdString(product.productId))
						}
						return (
							<ShowRoomProduct
								product={product}
								key={snapshot.key}
								snapshotKey={snapshot.key}
								isProductInCart={isProductInCart(product)}
								viewProduct={(e) => openDetails(e)}
								removeItem={removeItem} />
						);
					})}
					{!isSearchDisabled && <button onClick={() => openSearchSidebar()} className='mobile-add-products-button'>
						<AddRoundedIcon size={35} />
					</button>}
				</div>
			) : (
				<EmptyTab
					icon={<IconEmptyShowroomMobile />}
					message={<FormattedMessage {...cartMessages[!isSearchDisabled ? 'showroom_empty' : 'showroom_empty_no_search']}
						values={{ host_noun: intl.formatMessage(cartMessages.host_noun) }}
					/>}
					onSubmit={openSearchSidebar}
					buttonLabel={!isSearchDisabled ? <FormattedMessage {...cartMessages.cta_add_products} /> : null}
				/>
			)}
			{isCompleted  &&
				<CheckoutComplete
					clearCompletedCheckout={clearCompletedCheckout}
					clearSessionAndCheckout={clearSessionAndCheckout} />}
		</div>
	);
};
