import firebase from '../Firebase'
import {doc, getFirestore} from 'firebase/firestore';
import { useClientAuthProvider } from '../components/ClientAuthProvider';
import { Counter } from '../utils/sharded-counter';

const firestore = getFirestore(firebase);

export const useStoryViewCounter = () => {
  const { shopId } = useClientAuthProvider()

  const logStoryView = (storyId) => {
    let storyDoc = doc(firestore, 'shops', shopId, 'stories', storyId)

    // Initialize the sharded counter.
    let storyViewsCounter = new Counter(storyDoc, "views");

    // Increment the field "visits" of the document "pages/hello-world".
    storyViewsCounter.incrementBy(1).catch(error => {
      console.error('logStoryView', error);
    });
  }

  return { logStoryView };
};
