import React from 'react';
import './AnimatedHeaderTabs.scss';

export const AnimatedHeaderTabs = () =>
    <>
        <div className='slots-tabs-navigation'></div>
        <div className='available-days-list-wrapper'>
            <div className='available-days-list'>
                {Array.from({ length: 3 }, (_, index) =>
                    <div key={index} className='date-tile-wrapper'>
                        <div className='date-title'>
                            <div className='date-tile-content'>
                                <span className='day-name animated-loader-background'></span>
                                <span className='day-tab-item animated-loader-background'></span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
        <div className='slots-tabs-navigation'></div>
    </>
