import React from 'react';

export const SmallCalendar = () =>
<svg width="22px" height="25px" viewBox="0 0 22 25" version="1.1" xmlns="http://www.w3.org/2000/svg" >
    <g id="client-flow---Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="confrim-call-(scheduled-call)" transform="translate(-503.000000, -279.000000)">
            <g id="Group-15" transform="translate(480.000000, 160.000000)">
                <g id="small-calendar-with-color" transform="translate(23.000000, 119.000000)">
                    <path d="M20.0909091,3 L1.90909091,3 C0.854733432,3 0,3.85473343 0,4.90909091 L0,23.0909091 C0,24.1452816 0.854730245,25 1.90909091,25 L20.0909091,25 C21.1452847,25 22,24.1452847 22,23.0909091 L22,4.90909091 C22,3.85473024 21.1452816,3 20.0909091,3 Z M20,5 L20,23 L2,23 L2,5 L20,5 Z" id="Path" fill="#3F3F3F" fillRule="nonzero"></path>
                    <rect id="Rectangle" fill="#A0D4FF" x="2" y="5" width="18" height="7"></rect>
                    <path d="M16.5,0 C17.0128358,0 17.4355072,0.38604019 17.4932723,0.883378875 L17.5,1 L17.5,5 C17.5,5.55228475 17.0522847,6 16.5,6 C15.9871642,6 15.5644928,5.61395981 15.5067277,5.11662113 L15.5,5 L15.5,1 C15.5,0.44771525 15.9477153,0 16.5,0 Z" id="Path" fill="#3F3F3F" fillRule="nonzero"></path>
                    <path d="M5.5,0 C6.01283584,0 6.43550716,0.38604019 6.49327227,0.883378875 L6.5,1 L6.5,5 C6.5,5.55228475 6.05228475,6 5.5,6 C4.98716416,6 4.56449284,5.61395981 4.50672773,5.11662113 L4.5,5 L4.5,1 C4.5,0.44771525 4.94771525,0 5.5,0 Z" id="Path" fill="#3F3F3F" fillRule="nonzero"></path>
                    <polygon id="Path-2" fill="#3F3F3F" fillRule="nonzero" points="21 11 21 13 1 13 1 11"></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>
