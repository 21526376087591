import React from 'react';

import './styles.scss';
import {MSG_TYPES, TEMP_MESSAGE} from "../../modules/chat/constants/messaging";
import ChatUserIcon from "../chatUserIcon";
import {formatMsgTime} from "../../modules/chat/utils/time";
import {buildUserEntity, getHostName} from "../../modules/chat/utils/auth";
import {chatScreenMessages} from "../../pages/chat-page/messages";
import {FormattedMessage} from "react-intl";
import AnimatedDots from "../animatedDots";
import ImageMessage from "../ImageMessage";

const MessageItem = ({msg, user, typing, incrementLoadedImages, setFullSizeChatImage = null, hideStartVideoCallMessage,
  navigateToLobby, videoInitiated}) => {
  const currentUser = buildUserEntity(user);

  const getLink = (textLink) => {
    return textLink.startsWith('http://') || textLink.startsWith('https://') ? textLink : `http://${textLink}`;
  }

  const checkLink = (textToCheck) => {
    let expression = /([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#\.]?[\w-]+)*\/?/gm;
    let regex = new RegExp(expression);
    let match = '', splitText = [], startIndex = 0;
    while ((match = regex.exec(textToCheck)) != null) {
       splitText.push({text: textToCheck.substr(startIndex, (match.index - startIndex)), type: 'text'});
       splitText.push({text: textToCheck.substr(match.index, (match[0].length)), type: 'link'});

       startIndex = match.index + (match[0].length);
    }
    if (textToCheck && startIndex < textToCheck.length) splitText.push({text: textToCheck.substr(startIndex), type: 'text'});

    return splitText;
  };

  const showModal = (item) => {
    setFullSizeChatImage(item);
  };

  if (msg && msg.key === TEMP_MESSAGE.IMAGE) {
    return (
      <div className='message-item message-item_my'>
        <div className='message-item_info message-item_info_my'>
          <ImageMessage msg={msg} isHost={false} incrementLoadedImages={incrementLoadedImages} isMessageLoading={true} />
          <p className='message-item_info_time message-item_info_time_my'>{formatMsgTime(new Date())}</p>
        </div>
      </div>
    );
  }

  if (msg && msg.key === TEMP_MESSAGE.START_VIDEO_CALL) {
    return (
      <div className='message-item message-item_my'>
        <div className='message-item_info message-item_info_my'>
          <div className='message-item_start-call'>
            <p className='message-item_start-call_question'>
              <FormattedMessage {...chatScreenMessages.startVideoCall} />
            </p>
            <p id='start-video-call' onClick={navigateToLobby}>
              <FormattedMessage {...chatScreenMessages.startButton}/>
            </p>
            <p id='cancel-video-call' onClick={hideStartVideoCallMessage}>
              <FormattedMessage {...chatScreenMessages.cancelButton}/>
            </p>
          </div>
          <p className='message-item_info_time message-item_info_time_my'>{formatMsgTime(new Date())}</p>
        </div>
      </div>
    );
  }

  if (msg && msg.type === MSG_TYPES.CALL_INVITATION) {
    return (
      <div className={`message-item${videoInitiated ? ' message-item_hidden-message' : ''}`}>
        <ChatUserIcon user={msg.owner} />
        <div className='message-item_info'>
          <div className='message-item_start-call message-item_start-call_info'>
            <p className='message-item_start-call_question'>
              <FormattedMessage {...chatScreenMessages.hostInvited} values={{name: getHostName(msg.owner)}} />
            </p>
            <p onClick={navigateToLobby}>
              <FormattedMessage {...chatScreenMessages.joinButton} />
            </p>
          </div>
          <p className='message-item_info_time'>{formatMsgTime(msg.timestamp)}</p>
        </div>
      </div>
    );
  }

  const textWithLink = msg ? checkLink(msg.text) : [];

  if (typing) {
    return (
      <div className='message-item message-item_typing'>
        <ChatUserIcon user={user} />
        <div className='message-item_info'>
          <div className='message-item_info_message_typing no-select'>
            <FormattedMessage {...chatScreenMessages.typing} />
            <AnimatedDots />
          </div>
        </div>
      </div>
    )
  }

  if (msg.owner.uuid === null) {
    if (msg.type === MSG_TYPES.META && msg.owner.system && msg.metadata) {
      const displayName = msg.metadata.displayName
      return (
        <div className='message-item message-item_system'>
          <div className='message-item_system_delimiter'/>
          <div className='message-item_system_text'>
            <FormattedMessage id={msg.metadata.key} values={{name: displayName || 'host'}} />
          </div>
          <div className='message-item_system_delimiter'/>
        </div>
      );
    }

    if (msg.type === MSG_TYPES.IMAGE && msg.metadata) {
      return (
        <div
          className='message-item'
          onClick={() =>
            !!setFullSizeChatImage
              ? showModal(msg.metadata.url)
              : window.open(msg.metadata.url, "_blank")
          }
        >
          <ChatUserIcon user={msg.owner} />
          <div className='message-item_info'>
            <ImageMessage msg={msg} isHost={true} incrementLoadedImages={incrementLoadedImages} />
            <p className='message-item_info_time'>{formatMsgTime(msg.timestamp)}</p>
          </div>
        </div>
      );
    }

    return (
      <div className='message-item'>
        <div className='message-item_circle'/>
        <div className='message-item_info'>
          <div className='message-item_info_message-text message-item_info_message-text_opponent'>
            {textWithLink.map((item, index) => {
              if (item.type === 'link') {
                return <a href={getLink(item.text)} key={index} target='_blank'>{item.text}</a>
              } else {
                return <p key={index}>{item.text}</p>
              }
            })}
          </div>
          <p className='message-item_info_time'>{formatMsgTime(msg.timestamp)}</p>
        </div>
      </div>
    );
  }

  if (currentUser?.uuid !== msg.owner.uuid) {
    if (msg.type === MSG_TYPES.IMAGE && msg.metadata) {
      return (
        <>
          <div
            className='message-item'
            onClick={() =>
              !!setFullSizeChatImage
                ? showModal(msg.metadata.url)
                : window.open(msg.metadata.url, "_blank")
            }
          >
            <ChatUserIcon user={msg.owner} />
            <div className='message-item_info'>
              <ImageMessage msg={msg} isHost={true} incrementLoadedImages={incrementLoadedImages} />
              <p className='message-item_info_time'>{formatMsgTime(msg.timestamp)}</p>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className='message-item'>
          <ChatUserIcon user={msg.owner} />
          <div className='message-item_info'>
            <div className='message-item_info_message-text message-item_info_message-text_opponent'>
              {textWithLink.map((item, index) => {
                if (item.type === 'link') {
                  return <a href={getLink(item.text)} key={index} target='_blank'>{item.text}</a>
                } else {
                  return <p key={index}>{item.text}</p>
                }
              })}
            </div>
            <p className='message-item_info_time'>{formatMsgTime(msg.timestamp)}</p>
          </div>
        </div>
      </>
    );
  }

  if (msg.type === MSG_TYPES.IMAGE && msg.metadata) {
    return (
      <div
        className='message-item message-item_my'
        onClick={() =>
          !!setFullSizeChatImage
            ? showModal(msg.metadata.url)
            : window.open(msg.metadata.url, "_blank")
        }
      >
        <div className='message-item_info message-item_info_my'>
          <ImageMessage msg={msg} isHost={false} incrementLoadedImages={incrementLoadedImages} />
          <p className='message-item_info_time message-item_info_time_my'>{formatMsgTime(msg.timestamp)}</p>
        </div>
      </div>
    );
  }

  return (
    <div className='message-item message-item_my'>
      <div className='message-item_info message-item_info_my'>
        <div id='incoming-message-item_info_message-text' className='message-item_info_message-text message-item_info_message-text_my'>
          {textWithLink.map((item, index) => {
            if (item.type === 'link') {
              return <a href={getLink(item.text)} key={index} target='_blank'>{item.text}</a>
            } else {
              return <p key={index}>{item.text}</p>
            }
          })}
        </div>
        <p className='message-item_info_time message-item_info_time_my'>{formatMsgTime(msg.timestamp)}</p>
      </div>
    </div>
  );
};

export default MessageItem;
