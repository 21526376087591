
const checkTodayTomorrow = (dateObject) => {
  let daySpec = new Intl.DateTimeFormat([], { weekday: 'long' }).format(dateObject);
  
  const today = new Date();
  if (today.toDateString() === dateObject.toDateString()) {
    daySpec = 'today';
  } else if (
    today.getFullYear() === dateObject.getFullYear() &&
    today.getMonth() === dateObject.getMonth() &&
    today.getDate() + 1 === dateObject.getDate()
  ) {
    daySpec = 'tomorrow';
  } 

  return daySpec;
}


export default checkTodayTomorrow;
