import {useDispatch, useSelector} from 'react-redux';
import {setDoc, doc} from 'firebase/firestore';
import {firestore} from '../../firebaseInitialization';
import {FIRESTORE_COLLECTIONS} from "../../constants/firestore";
import {
  sendTypingStatusFailure,
  sendTypingStatusRequest,
  sendTypingStatusSuccess,
} from "../../actions/activeRoom";

export default function useFeatureRequests({ userUuid }) {
  const dispatch = useDispatch();
  const { id: activeChatId } = useSelector(state => state.activeRoom);
  const fs = firestore();

  const updateTypingStatus = ({ typing, roomId }) => {
    const roomIdToUpdate = roomId || activeChatId;

    if (!roomIdToUpdate) {
      return;
    }

    dispatch(sendTypingStatusRequest());
    const metaRef = doc(fs, FIRESTORE_COLLECTIONS.CHAT_ROOMS, roomIdToUpdate, FIRESTORE_COLLECTIONS.ROOM_FEATURES, FIRESTORE_COLLECTIONS.TYPING_DOC);
    return setDoc(metaRef, { [userUuid]: typing }, { merge : true })
      .then(() => dispatch(sendTypingStatusSuccess()))
      .catch((error) => dispatch(sendTypingStatusFailure(error.message)));
  }

  return {
    updateTypingStatus,
  };
}
