import { defineMessages } from 'react-intl';

export const deviceSelectorMessages = defineMessages({
  enable_audio: {
    id: 'messages.enable_audio',
  },
  enable_video: {
    id: 'messages.enable_video',
  },
  camera_is_muted: {
    id: 'messages.camera_is_muted',
  },
  microphone_is_muted: {
    id: 'messages.microphone_is_muted',
  },
  default_speaker: {
    id: 'messages.default_speaker',
  },
  audio: {
    id: 'nouns.audio'
  },
  video: {
    id: 'nouns.video'
  },
  microphone: {
    id: 'nouns.microphone'
  },
  speakers: {
    id: 'nouns.speakers'
  },
  camera: {
    id: 'nouns.camera'
  },
  cta_test: {
    id: 'button_actions.cta_test'
  },
  cta_done: {
    id: 'button_actions.cta_done'
  },
});
