import { defineMessages } from 'react-intl';

export const cartMessages = defineMessages({
  thank_you: {
    id: 'nouns.thank_you'
  },  
  showroom_empty: {
    id: 'messages.showroom_empty'
  },
  showroom_empty_no_search: {
    id: 'messages.showroom_empty_no_search'
  },
  showroom_remove: {
    id: 'messages.showroom_remove'
  },  
  cta_keep: {
    id: 'button_actions.cta_keep'
  },  
  cta_remove: {
    id: 'button_actions.cta_remove'
  },  
  host_noun: {
    id: 'nouns_host'
  },  
  cta_add_products: {
    id: 'button_actions_cta_add_products'
  }
});
