import { defineMessages } from 'react-intl';

export const introWelcomeMessages = defineMessages({
  cta_connect_now: {
    id: 'button_actions_cta_connect_now'
  },
  cta_schedule: {
    id: 'button_actions.cta_schedule'
  },
  messages_welcome_primary: {
    id: 'messages_welcome_primary'
  },
  messages_welcome_secondary: {
    id: 'messages_welcome_secondary'
  },
  messages_welcome_secondary_cta: {
    id: 'messages_welcome_secondary_cta'
  },
  salutation: {
    id: 'messages.welcome_message'
  },
  service_noun: {
    id: 'nouns_service'
  }
})
