import React from 'react';
import { useIntl } from 'react-intl';
import { BoutiqTextField } from '../../../../../components/boutiq-form/components/boutiq-text-field/BoutiqTextField';
import { formMessages } from '../messages';
import './SheduleClientNameForm.scss';

export const SheduleClientNameForm = ({
    handleChange,
    validateOnBlur,
    values,
    errors
}) => {
    const intl = useIntl();

    return (
        <div className='scheduler-form-container'>
            <form autoComplete='off'>
                <BoutiqTextField
                    label={intl.formatMessage(formMessages.name)}
                    type='text'
                    fieldName='name'
                    validationType='name'
                    required={true}
                    value={values.name}
                    handleChange={handleChange}
                    error={errors && errors.name}
                    onSubmit={validateOnBlur}
                />
                <BoutiqTextField
                    label={intl.formatMessage(formMessages.email)}
                    fieldName='email'
                    type='email'
                    validationType='email'
                    required={true}
                    value={values.email}
                    handleChange={handleChange}
                    error={errors && errors.email}
                    onSubmit={validateOnBlur}
                />
            </form>
        </div>
    )
}
