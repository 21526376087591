import React, { useState } from 'react';
import './index.scss';

export const AvatarImage = ({ avatar, size = 'md' }) => {

    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [showInitials, setShowInitials] = useState(false);
    const fallbackToInitials = () => {
        setShowInitials(true);
    }

    const animateImageOnLoad = () => {
        setIsImageLoaded(true);
    }

    return (
        <div className={`avatar-container size-${size}${isImageLoaded ? ' loaded' : ''}${(showInitials || !avatar.imageUrl) ? ' initials' : ''}`}>
            {avatar.imageUrl ?
                showInitials ? <span className='avatar'>{avatar.initials}</span>
                    : <img onError={() => fallbackToInitials()} onLoad={animateImageOnLoad} className={`avatar`} src={avatar.imageUrl} />
                : <span className='avatar'>{avatar.initials}</span>}
        </div>
    )
}
