import { defineMessages } from 'react-intl';

export const loaderMessages = defineMessages({
  connecting: {
    id: 'messages.connecting',
  },
  please_wait: {
    id: 'messages.please_wait',
  },
});
