import React from 'react';
import ShoppingBasketIcon from '../../../../../../assets/icons/ShoppingBasket';
import { useIntl, FormattedMessage } from 'react-intl';
import { cartComponentsMessages } from '../../../../intl/cart-components/messages';

export const EmptyCard = ({ isMobile }) => {
  const intl = useIntl();
  return (
    <div className={`cart-empty ${isMobile && 'mobile'}`}>
      <ShoppingBasketIcon color='black64' size={50} className='cart_basket-icon' />
      <p id='cart_empty'>
        <FormattedMessage {...cartComponentsMessages.cart_empty} values={{
          host_noun: intl.formatMessage(cartComponentsMessages.host_noun),
        }} />
      </p>
    </div>
  );
};
