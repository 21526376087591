import CameraAltIcon from "../../assets/icons/CameraAlt";
import React from "react";

import './styles.scss';

const SIZES_KEY = {
  S: 's',
  M: 'm',
  L: 'l',
  XL: 'xl',
  ADAPTIVE: 'adaptive'
};

const getImageSize = (size) => {
  switch (size) {
    case SIZES_KEY.ADAPTIVE: return 'adaptive'
    case SIZES_KEY.M: return 'm-size'
    case SIZES_KEY.L: return 'l-size'
    case SIZES_KEY.XL: return 'xl-size'
    case SIZES_KEY.S:
      default:
      return 's-size';
  }
}

const EmptyImage = ({
  viewItem = () => {},
  size = 's',
  isMobile = false,
  label = null
}) => {

  const action = () => {
    viewItem();
  }

  return (
    <div className={`empty-image  ${isMobile ? 'mobile' : ''} ${getImageSize(size)}`} onClick={action}>
      {
        label !== null
          ? <p>{label}</p>
          : <CameraAltIcon color='black64' className={`camera-alt-icon ${getImageSize(size)}`} />
      }
    </div>
  );
}

export default EmptyImage;
