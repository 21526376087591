import React, { useEffect, useRef, useState } from 'react';
import { useProductDetailsProvider } from '../../../../../../components/ProductDetailsProvider';
import ArrowBackIosOutlined from '../../../../../../assets/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlined from '../../../../../../assets/icons/ArrowForwardIosOutlined';
import { MainImage } from '../../main-image/MainImage';
import { Loader } from '../../../../../../components';
import { useProductViewProvider } from '../../../../../../components/ProductViewProvider';
import './FullScreenProductView.scss';

export const FullScreenProductView = ({ isPresentationMode }) => {
  const imagesRefs = useRef([]);
  const producImagesContainerRef = useRef(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [selectedImageChanged, setSelectedImageChanged] = useState(null);
  const [isProductImageReady, setProductImageReady] = useState(false);
  const { selectedImage, setSelectedImage, currentImages } = useProductDetailsProvider();
  const { isPresentationModeReady } = useProductViewProvider();

  const initImgReferences = (id, ref) => {
    const imgIndex = imagesRefs.current.findIndex(i => i.id === id);
    if (ref !== null && imgIndex === -1) {
      imagesRefs.current.push({ id: id, ref })
    }
  }

  useEffect(() => {
    if (producImagesContainerRef.current && selectedImageIndex !== null) {
      const currentRef = imagesRefs.current[selectedImageIndex];
      if (currentRef) {
        const position = currentRef.ref.offsetLeft;
        if (!isPresentationMode) {
          const currentImage = currentImages.find(item => item.id === currentRef.id);
          if (currentImage) {
            setSelectedImage(currentImage)
          }
        }
        producImagesContainerRef.current.style.transform = `translateX(${-position}px)`
      }
    }
  }, [producImagesContainerRef.current, selectedImageIndex, imagesRefs])

  useEffect(() => {
    if ((selectedImage && (selectedImageIndex === null || isPresentationMode))) {
      const imgIndex = currentImages.findIndex(i => i.id === selectedImage.id);
      if (imgIndex !== selectedImageIndex) {
        setSelectedImageIndex(imgIndex);
      }
    }
  }, [selectedImage])

  const prevImage = () => {
    if (selectedImageIndex > 0) {
      const newValue = selectedImageIndex - 1;
      setSelectedImageIndex(newValue);
      setSelectedImageChanged(true);
    }
  }

  const nextImage = () => {
    if ((imagesRefs.current.length - 1) > selectedImageIndex) {
      const newValue = selectedImageIndex + 1;
      setSelectedImageIndex(newValue)
      setSelectedImageChanged(true);
    }
  }

  useEffect(() => {
    if (isPresentationMode) {
      if (selectedImageIndex !== null && isPresentationModeReady) {
        setProductImageReady(true);
      }
    } else {
      if (selectedImageIndex !== null) {
        setProductImageReady(true);
      }
    }
  }, [selectedImageIndex, isPresentationModeReady])

  return (
    <div className='mobile-full-screen-product-view-container'>
      <div className='mobile-gallery-controls'>
        {!isPresentationMode &&
          <button onClick={prevImage} disabled={selectedImageIndex === 0} className='mobile-swipe-left-button'>
            <ArrowBackIosOutlined color='black64' />
          </button>}
        {isProductImageReady ?
          <div ref={producImagesContainerRef} className={`mobile-product-images-gallery${selectedImageChanged ? ' mobile-selected-changed' : ''}`}>
            {currentImages.map((image, index) => (
              <div key={index} ref={(ref) => initImgReferences(image.id, ref)} className='mobile-product-image-wrapper'>
                <MainImage animateAreaWhileLoading={true} src={image.fullSizedUrl} />
              </div>
            ))}
          </div> : <Loader />}
        {!isPresentationMode && <button onClick={nextImage} disabled={(imagesRefs.current.length - 1) === selectedImageIndex} className='mobile-swipe-right-button'>
          <ArrowForwardIosOutlined color='black64' />
        </button>}
      </div>
    </div>
  );
}
