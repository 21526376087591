import React from 'react';

export const ErrorMark = () =>
    <svg width="114px" height="114px" viewBox="0 0 114 114" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="icon/error-mark" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <circle id="Oval" stroke="var(--main-color)" strokeWidth="4" cx="57" cy="56.5" r="50"></circle>
            <path d="M62.805777,28.7331081 L61.5920295,65.3731081 C61.5741711,65.9122092 61.1319745,66.34 60.5925778,66.34 L53.0854225,66.34 C52.5468277,66.34 52.1049864,65.9134364 52.0860414,65.3751749 L50.7964347,28.7351749 C50.7770082,28.1832319 51.208698,27.7200454 51.760641,27.7006188 C51.7723614,27.7002063 51.7840882,27.7 51.7958159,27.7 L61.8063252,27.7 C62.3586099,27.7 62.8063252,28.1477153 62.8063252,28.7 C62.8063252,28.7110383 62.8061424,28.7220759 62.805777,28.7331081 Z" id="Path" fill="var(--main-color)" fillRule="nonzero"></path>
            <path d="M57.08,85.06 C55.0533333,85.06 53.3866667,84.4333333 52.08,83.18 C50.7733333,81.9266667 50.12,80.3666667 50.12,78.5 C50.12,76.6333333 50.7733333,75.0733333 52.08,73.82 C53.3866667,72.5666667 55.0533333,71.94 57.08,71.94 C59.0533333,71.94 60.68,72.5666667 61.96,73.82 C63.24,75.0733333 63.88,76.6333333 63.88,78.5 C63.88,80.3666667 63.24,81.9266667 61.96,83.18 C60.68,84.4333333 59.0533333,85.06 57.08,85.06 Z" id="Path" fill="var(--main-color)" fillRule="nonzero"></path>
        </g>
    </svg>
