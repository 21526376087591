import React, { useState } from 'react';
import './index.scss'

const ShopLogoContainer = ({ src, isAnimateOnload }) => {
    const [loaded, setLoaded] = useState(false);
    const animateLogo = () => {
        setLoaded(true);
    }

    return (
        <div className={`shop-logo-container-wrapper${src ? ' logo-available' : ''}`}>
            <div className="shop-logo-content-wrapper">
                <div className="shop-logo-content">
                    {src && 
                    <img src={src} onLoad={animateLogo} className={`shop-logo${isAnimateOnload ? ' animate-onload': ''}${loaded ? ' visible' : ''}`} />}
                </div>
            </div>
        </div>
    );
};

export default ShopLogoContainer;
