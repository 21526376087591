import React, { useState, useRef } from 'react';
import ChatIcon from '../../../../../../assets/icons/Chat';
import { OutlinedButton } from '../../../../../../components/buttons/buttons';
import { useIntl, FormattedMessage } from 'react-intl';
import { ProductSerachResult } from '../../../../../../components/productSerachResult';
import { useCallScreenProvider } from '../../../../../../components/CallScreenProvider';
import { useSearchSidebar } from '../../../../../../hooks/useSearchProducts';
import { SearchInput } from './components/search-input/SearchInput';
import { sidebarMessages } from '../../../../intl/sidebar-messages/messages';
import './search-sidebar.scss';

const SearchSidebar = ({
	setVideoChatOpen,
	numberOfUnreadMessages,
	isSearchDisabled
}) => {
	const intl = useIntl();
	const listRef = useRef(null);
	const [searchTerm, setSearchTerm] = useState(null);
	const {
		sessionSnapshots,
		shopId,
		callData
	} = useCallScreenProvider();
	const {
		searchForProducts,
		fetchNextPage,
		noResults,
		cursor,
		productIdsInShowroom,
		isSearching,
		isFetchingMore,
		searchResults
	} = useSearchSidebar(sessionSnapshots, shopId, callData);

	const openVideoChat = () => {
		setVideoChatOpen(true);
	};

	const handleOnSubmit = (query) => {
		setSearchTerm(query);
		searchForProducts(query, productIdsInShowroom);
		if (listRef.current) {
			listRef.current.scrollTop = 0;
		}
	}

	return (
		<div className='search-container'>
			<span className='row-chat'>
				{!isSearchDisabled && <SearchInput
					isLoading={isSearching}
					onSubmit={handleOnSubmit}
					placeholder={intl.formatMessage(sidebarMessages.product_search_helper)}
				/>}
				<ChatIcon className='icon-chat' onClick={openVideoChat} color='gray' size={30} />
				{numberOfUnreadMessages > 0 &&
					<div className='icon-badges'>
						<p>
							{numberOfUnreadMessages}
						</p>
					</div>
				}
			</span>
			{isSearchDisabled && <div className='empty-search-message'>
				<FormattedMessage {...sidebarMessages.showroom_empty_no_search} values={{ host_noun: intl.formatMessage(sidebarMessages.host_noun) }} />
			</div>
			}
			{noResults ? (
				<div className='empty-results'>
					<FormattedMessage {...sidebarMessages.product_search_no_results} values={{ host_noun: intl.formatMessage(sidebarMessages.host_noun) }} />
				</div>
			) : (
				searchResults ? (
					<>
						<ul ref={listRef} className='search-results'>
							{searchResults.map((result, index) =>
								<li key={index} >
									<ProductSerachResult product={result} setVideoChatOpen={setVideoChatOpen} />
								</li>
							)}
							{cursor && (
								<OutlinedButton
									onClick={() => fetchNextPage(searchTerm, productIdsInShowroom)}
									isLoading={isFetchingMore}
									className='load-more-button'>
									<FormattedMessage {...sidebarMessages.cta_load_more} />
								</OutlinedButton>
							)}
						</ul>
					</>
				) :
					!isSearchDisabled && <div className='empty-search-message'>
						<FormattedMessage {...sidebarMessages.showroom_empty} values={{ host_noun: intl.formatMessage(sidebarMessages.host_noun) }} />
					</div>
			)}
		</div>
	);
};

export default SearchSidebar;
