import {useDispatch, useSelector} from 'react-redux';

import {firestore} from '../../firebaseInitialization';
import {collection, query, orderBy, limit, getDocs, startAfter, where} from 'firebase/firestore';
import {parseMsgObject} from "../../utils/parsers";
import {FIRESTORE_COLLECTIONS} from "../../constants/firestore";
import {
  appendMessages,
  getMessagesFailure,
  getMessagesRequest,
  getMessagesSuccess,
} from "../../actions/messages";
import usePrevious from '../../hooks/usePreviousState';
import {LIMIT_CHAT_MESSAGES} from '../../constants/messaging';

const fs = firestore();

export default function useGetMessages(pendingRoomId = null, userId) {
  const dispatch = useDispatch();
  const {id, metadata} = useSelector(state => state.activeRoom);

  const idForMessages = pendingRoomId ? pendingRoomId : id;
  const prevRoomId = usePrevious(idForMessages);

  const loadMessages = async (lastMessage = null, numberOfUnreadMessages = 0) => {
    dispatch(getMessagesRequest());
    let newLastMessage;
    try {
      const messagesRef = collection(fs, FIRESTORE_COLLECTIONS.CHAT_ROOMS, idForMessages, FIRESTORE_COLLECTIONS.CHAT_MESSAGES);
      let messages = [];
      if (lastMessage === null) {
        const date = metadata?.seenStatus && metadata?.seenStatus[userId] ? new Date(metadata?.seenStatus[userId]) : new Date();
        const q = query(messagesRef, orderBy("timestamp", "desc"), where("timestamp", "<=", date), limit(numberOfUnreadMessages < LIMIT_CHAT_MESSAGES ? LIMIT_CHAT_MESSAGES : numberOfUnreadMessages));
        messages = await getDocs(q);
      } else {
        const q = query(messagesRef, orderBy("timestamp", "desc"), limit(numberOfUnreadMessages < LIMIT_CHAT_MESSAGES ? LIMIT_CHAT_MESSAGES : numberOfUnreadMessages), startAfter(lastMessage));
        messages = await getDocs(q);
      }
      if (messages && messages.docs) {
        let data = messages.docs.map(item => parseMsgObject({...item.data(), id: item.id}));
        if (!lastMessage && data.length && prevRoomId !== idForMessages) {
          dispatch(getMessagesSuccess(data));
        } else if (lastMessage && data.length) {
          dispatch(appendMessages(data));
        } else {
          dispatch(getMessagesFailure('Empty list'));
        }
        newLastMessage = messages.docs[messages.docs.length - 1];
      } else {
        dispatch(getMessagesFailure('Empty list'));
      }
    } catch (e) {
      console.error(e)
      dispatch(getMessagesFailure());
    }
    return newLastMessage;
  }

    return {
      loadMessages
    };
  };
