import React, { useContext, useEffect } from 'react';
import { Switch, Route, Redirect, useRouteMatch, useHistory } from 'react-router-dom';
import IntroVideo from './introVideo/intro-video';
import IntroWelcome from './introWelcome/intro-welcome';
import { useIntroPage } from './IntroPageProvider';
import useIntroConfig from '../../hooks/useIntroConfig';
import { useClientAuthProvider } from '../../components/ClientAuthProvider';
import UserActivityButtons from './activityButtons/userActivityButtons';
import { AppHeaderContext } from '../../components/AppHeaderProvider';
import IntroPageProvider from './IntroPageProvider';
import './index.scss';
import {PAGES} from "../../constants/routes";
import {isIgnoreIntroVideo} from "../../utils/history";

export const INTRO_PAGES = {
    ROOT: '',
    INTRO_WELCOME: 'intro-welcome',
}

function IntroPage({ isAppDisplayed }) {
    const { shopId } = useClientAuthProvider()
    const { introVideosConfig, introVideosConfigLoading } = useIntroConfig(shopId);
    const { path } = useRouteMatch();
    const history = useHistory();

    const { backFuncRef } = useContext(AppHeaderContext);
    const {
        playList,
        setPlayingVideoIndex
    } = useIntroPage();

    const navigateIntroVideo = () => {
        setPlayingVideoIndex(0);
        history.push(path);
    }

    useEffect(() => {
        if (history.location.pathname === `${PAGES.INTRO}/${INTRO_PAGES.INTRO_WELCOME}` && playList.length > 0) {
            backFuncRef(() => navigateIntroVideo());
        }
    }, [history.location.pathname, playList])

    const ignoreIntroVideo = isIgnoreIntroVideo(path, history);
    return (
        <div className='intro-preview-container'>
            {!introVideosConfigLoading && isAppDisplayed &&
                <Switch>
                    <Route exact
                        path={path}>
                        {introVideosConfig && introVideosConfig.length > 0 && !ignoreIntroVideo
                            ? <IntroVideo
                                isAppDisplayed={isAppDisplayed}
                                onVideoEnds={() => {
                                    history.push(`${path}/${INTRO_PAGES.INTRO_WELCOME}`)
                                }}
                            />
                            : <Redirect to={`${path}/${INTRO_PAGES.INTRO_WELCOME}`} />
                        }
                    </Route>
                    <Route path={`${path}/${INTRO_PAGES.INTRO_WELCOME}`} render={() => <IntroWelcome />} />
                </Switch>
            }
            <UserActivityButtons />
        </div>
    )
}

export default function IntroPageWrapper({ isAppDisplayed }) {

    const { shopId } = useClientAuthProvider()
    const { introVideosConfig, introVideosConfigLoading } = useIntroConfig(shopId);

    return (
        <IntroPageProvider
            shopId={shopId}
            introVideosConfig={introVideosConfig}
            introVideosConfigLoading={introVideosConfigLoading}>
            <IntroPage isAppDisplayed={isAppDisplayed}/>
        </IntroPageProvider>
    )
}
