import React, { useState, useCallback, useEffect } from 'react';
import ThumbUpIcon from '../../assets/icons/ThumbUp';
import ThumbDownIcon from '../../assets/icons/ThumbDown';
import { useCallContextProvider } from '../../components/CallContextProvider';
import { useCallContextAPI } from '../../hooks/useCallContextAPI';
import { sendCallEvent } from '../../utils/analytics-cross-domain'
import { FormattedMessage, useIntl } from 'react-intl';
import { thankYouPageMessages } from './messages';
import { useCustomerDetails } from '../../components/CustomerDetailsProvider';
import { useClientConfigProvider } from '../../components/ClientConfigProvider';
import { PageHeader } from '../../components/page-header/PageHeader';
import { PageFooter } from '../../components/page-footer/PageFooter';
import { OutlinedButton } from '../../components/boutiq-buttons/BoutiqButton';
import ShopLogoContainer from '../../components/shop-logo-container/shop-logo-container';
import { BoutiqTextField } from '../../components/boutiq-form/components/boutiq-text-field/BoutiqTextField';
import { PageScrollContainer } from '../../components/page-scroll-container/PageScrollContainer';
import './thank-you-page.scss';

const RATE_MARKS = {
	RATING: 'rating',
	NPSRATING: 'npsRating',
	CSATRATING: 'csatRating',
	MESSAGE: 'message'
}

const ThankYou = ({ close, onRejoin, showFeedback = true }) => {
	const intl = useIntl();
	const { contextId, callId } = useCallContextProvider();
	const { name } = useCustomerDetails();
	const { dynamicConfig, realtimeConfig, realtimeConfigLoading } = useClientConfigProvider();
	const { contextCallFeedback } = useCallContextAPI(contextId);
	const [selectedGrade, setSelectedGrade] = useState(null);
	const [feedbackMessage, setFeedbackMessage] = useState(null);
	const [sendinInProgress, setSendinInProgress] = useState(false);
	const [showFeedbackForm, setShowFeedbackForm] = useState(true);
	const [shouldSubmitForm, setShouldSubmitForm] = useState(true);
	const customLogo = dynamicConfig && dynamicConfig.app_custom_logo;

	useEffect(() => {
		setShowFeedbackForm(showFeedback && realtimeConfig.callFeedbackType !== 'none');
		setShouldSubmitForm(showFeedback && realtimeConfig.callFeedbackType !== 'none' && realtimeConfig.callFeedbackType !== 'simple');
	}, [showFeedback, realtimeConfig]);

	const sendFeedback = (rating) => {
		contextCallFeedback(callId, rating)
			.then(() => sendCallEvent('feedback_posted', rating, rating))
			.catch(() => setSendinInProgress(false))
			.finally(() => close({resetStack: true}));
	};

	const downVote = () => {
		setSendinInProgress(true);
		sendFeedback({ [RATE_MARKS.RATING]: 1 });
	};
	const upVote = () => {
		setSendinInProgress(true);
		sendFeedback({ [RATE_MARKS.RATING]: 5 });
	};

	const onSubmitFeedback = useCallback(() => {
		setSendinInProgress(true);
		let feedbackData = {}
		switch (realtimeConfig?.callFeedbackType) {
			case 'nps':
				feedbackData[RATE_MARKS.NPSRATING] = selectedGrade;
				break;
			case 'csat':
				feedbackData[RATE_MARKS.CSATRATING] = selectedGrade;
				break;
			case 'simple':
				feedbackData[RATE_MARKS.RATING] = selectedGrade;
				break;
			case 'none':
				return;
			default:
				feedbackData[RATE_MARKS.CSATRATING] = selectedGrade;
		}

		if (feedbackMessage) {
			feedbackData = { ...feedbackData, ...{ [RATE_MARKS.MESSAGE]: feedbackMessage } }
		}
		sendFeedback(feedbackData);
	}, [realtimeConfig?.callFeedbackType, selectedGrade, feedbackMessage])

	const handleChange = (event) => {
		setFeedbackMessage(event.target.value);
	}

	const renderNPSFeedback = () => {
		return (
			<>
				<p className='tu-message'>
					<FormattedMessage {...thankYouPageMessages.messages_nps_feedback}
						values={{
							merchant_name: dynamicConfig.shop_name ? ` ${dynamicConfig.shop_name}` : ''
						}} />
				</p>
				<div className='tu-grade-btn-container-nps'>
					<div className='tu-grade-btn-container'>
						{[...Array(11).keys()].map(grade =>
							<div key={grade} className='tu-grade-button'>
								<div
									className={`tu-grade-button-item${selectedGrade === grade ? ' active' : ''}`}
									onClick={() => setSelectedGrade(grade)}>
									<span style={{ padding: 5 }}>
										{grade}
									</span>
								</div>
							</div>
						)}
					</div>
					<div className='tu-grade-btn-labels-container'>
						<p className='tu-negative-feedback'>
							<FormattedMessage {...thankYouPageMessages.messages_nps_negative_feedback} />
						</p>
						<p className='tu-positive-feedback'>
							<FormattedMessage {...thankYouPageMessages.messages_nps_positive_feedback} />
						</p>
					</div>
				</div>
				<div className='tu-feedback-message-container'>
					<BoutiqTextField
						placeholder={`${intl.formatMessage(thankYouPageMessages.messages_nps_comment)}`}
						rows={2}
						handleChange={handleChange}
						value={feedbackMessage || ''}
					/>
				</div>
			</>
		)

	}

	const renderCSATFeedback = () => {
		return (
			<>
				<p className='tu-message'>
					<FormattedMessage {...thankYouPageMessages.messages_csat_feedback}
						values={{
							merchant_name: dynamicConfig.shop_name ? ` ${dynamicConfig.shop_name}` : ''
						}} />
				</p>
				<div className='tu-grade-btn-container-csat'>
					<div className='tu-grade-btn-container'>
						{[1, 2, 3, 4, 5].map(grade =>
							<div key={grade} className='tu-grade-button'>
								<div
									className={`tu-grade-button-item${selectedGrade === grade ? ' active' : ''}`}
									onClick={() => setSelectedGrade(grade)}>
									<span style={{ padding: 5 }}>
										{grade}
									</span>
								</div>
							</div>
						)}
					</div>
					<div className='tu-grade-btn-labels-container'>
						<p className='tu-negative-feedback'>
							<FormattedMessage {...thankYouPageMessages.messages_csat_negative_feedback} />
						</p>
						<p className='tu-positive-feedback'>
							<FormattedMessage {...thankYouPageMessages.messages_csat_positive_feedback} />
						</p>
					</div>
				</div>
				<div className='tu-feedback-message-container'>
					<BoutiqTextField
						placeholder={`${intl.formatMessage(thankYouPageMessages.messages_csat_comment)}`}
						rows={2}
						handleChange={handleChange}
						value={feedbackMessage || ''}
					/>
				</div>
			</>
		)
	}

	const renderSimpleFeedback = () => {

		return (
			<>
				<p className='tu-message'>
					<FormattedMessage {...thankYouPageMessages.feedback_text} />.
					<br />
					<FormattedMessage {...thankYouPageMessages.feedback_question} />
				</p>
				<div className='tu-btn-container'>
					<div className='tu-vote-button'>
						<OutlinedButton onClick={downVote}>
							<ThumbDownIcon color='red' size={60} />
						</OutlinedButton>
						<p><FormattedMessage {...thankYouPageMessages.cta_feedback_negative} /></p>
					</div>
					<div className='tu-vote-button'>
						<OutlinedButton onClick={upVote}>
							<ThumbUpIcon color='green' size={60} />
						</OutlinedButton>
						<p><FormattedMessage {...thankYouPageMessages.cta_feedback_positive} /></p>
					</div>
				</div>
			</>
		)
	}

	const renderFeedbackForm = () => {
		if (realtimeConfigLoading) return null;
		switch (realtimeConfig?.callFeedbackType) {
			case 'nps':
				return renderNPSFeedback();
			case 'csat':
				return renderCSATFeedback();
			case 'simple':
				return renderSimpleFeedback();
			case 'none':
				return null;
			default:
				return renderCSATFeedback();

		}
	}

	return (
		<div className='thank-you-page-container'>
			<PageHeader>
				<div className='thank-you-header-container'>
					{customLogo && <ShopLogoContainer src={customLogo} />}
					<div className='thank-you-title-wrapper'>
						<p className='thank-you-title'>
							{`${intl.formatMessage({ ...thankYouPageMessages.thank_you }, { name: name ? `, ${name}` : '' })}!`}
						</p>

					</div>
					{onRejoin &&
						<OutlinedButton style={{ minHeight: 34, alignSelf: 'center', width: 'auto', padding: '0 16px' }} onClick={onRejoin}>
							<FormattedMessage {...thankYouPageMessages.rejoin_call} />
						</OutlinedButton>}
				</div>
			</PageHeader>
			<PageScrollContainer children={
				<div className='thank-you-feedback-form-wrapper'>
					{showFeedbackForm && renderFeedbackForm()}
				</div>
			}>
			</PageScrollContainer>
			{showFeedbackForm
				? <PageFooter
					shadow={false}
					isLoading={sendinInProgress}
					isDisabled={shouldSubmitForm ? selectedGrade === null : false}
					onSubmitPrimary={shouldSubmitForm ? () => onSubmitFeedback() : () => close({resetStack: true})}
					primaryButtonLabel={shouldSubmitForm
						? <FormattedMessage {...thankYouPageMessages.button_actions_cta_submit} />
						: <FormattedMessage {...thankYouPageMessages.cta_done} />}
				/>
				: <PageFooter
					shadow={false}
					onSubmitPrimary={() => close({resetStack: true})}
					primaryButtonLabel={<FormattedMessage {...thankYouPageMessages.cta_done} />}
				/>
			}

		</div>
	);
};

export default ThankYou;
