import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {useLocation} from "react-router-dom";
import { footerMessages } from './messages/messages';
import './AppFooter.scss';
import { useClientAuthProvider } from '../../components/ClientAuthProvider'

import { VIDEO_CALL_PAGES } from '../VideoCall';
import {PAGES} from "../../constants/routes";

export const AppFooter = () => {
    const location = useLocation();
    const showFooter = useMemo(() => location.pathname !== `${PAGES.VIDEO_CALL}/${VIDEO_CALL_PAGES.CALL}`,[location]);
    const { shopId } = useClientAuthProvider();
    if (!showFooter) return null;
    return (
        <a href={`https://getboutiq.com?utm_campaign=powered_by&utm_medium=referral&utm_source=${shopId}`} target='_blank' className='app-footer-boutiq-mark'>
            <FormattedMessage {...footerMessages.powered_by_caazam} />
        </a>
    )
}