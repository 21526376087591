import React, { Fragment, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { boutiqFormMessages } from '../../../messages';
import { BoutiqTextField } from '../../boutiq-text-field/BoutiqTextField';
import { ErrorContainer } from '../../error-container/ErrorContainer';
import '../styles/BoutiqChoice.scss';

export const BoutiqCheckbox = ({ field, refProvider, fieldChanged, value, errors, omitError, validateOnBlur }) => {
    const intl = useIntl();
    const [inputValue, setInputValue] = useState('');

    const [checkedState, setCheckedState] = useState(
        field.options.map(option => (
            { selected: false, value: option.value, optionValue: option.value }
        )));

    useEffect(() => {
        if (value) {
            setCheckedState(value);
        }
    }, [value])

    const handleChangeInput = (position, e, optionValue, inputId, value) => {
        e.persist();
        const updatedCheckedState = checkedState.map((item, index) => {
            if (index === position) {
                return { ...item, value: { optionValue, value } }
            } else {
                return item;
            }
        });
        setInputValue(value);
        setCheckedState(updatedCheckedState);
        fieldChanged(e, field.id, updatedCheckedState);
        omitError(inputId);
    }

    const handleOnChange = (e, position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? { ...item, selected: !item.selected } : item);
        setCheckedState(updatedCheckedState);
        fieldChanged(e, field.id, updatedCheckedState);

        if (field.options[position].innerComponent) {
            if (checkedState[position].selected) {
                const nonUsedFields = field.options.filter(item => item.innerComponent);
                nonUsedFields.map(item => omitError(item.innerComponent.id))
            }
        }
    };

    return (
        <div ref={refProvider(field.id)} className='boutiq-checkbox-button-group'>
            {field.label && <div className='boutiq-checkbox-button-group-label'>{`${field.label}${field.required ? ` ${intl.formatMessage(boutiqFormMessages.required)}` : ''}`}</div>}
            {field.options.map((option, index) => {
                return (
                    <Fragment key={option.value}>
                        <label className={`checkbox-option-label${option.innerComponent ? ' group' : ''}`}>
                            <input
                                type="checkbox"
                                value={checkedState[index].optionValue}
                                checked={checkedState[index].selected}
                                onChange={(e) => handleOnChange(e, index, true)}
                            />
                            {option.innerComponent ?
                                <BoutiqTextField
                                    focus={checkedState[index].selected}
                                    key={`${option.value}-${index}`}
                                    type={option.innerComponent.type}
                                    disabled={!checkedState[index].selected}
                                    validationType={option.innerComponent.type}
                                    fieldName={option.innerComponent.id}
                                    value={checkedState[index].value.value || inputValue}
                                    placeholder={option.innerComponent.placeholder}
                                    required={true}
                                    handleChange={(e, _, val) => { handleChangeInput(index, e, checkedState[index].optionValue, option.innerComponent.id, val) }}
                                    errorHandler={true}
                                    onSubmit={validateOnBlur}
                                    error={errors && errors[option.innerComponent.id]} />
                                :
                                <span className='option-value'>{`${option.label}${!field.label && field.required ? ` ${intl.formatMessage(boutiqFormMessages.required)}` : ''}`}</span>}
                        </label>
                    </Fragment>
                );
            })}
            <div className='field-group-error-message'>
                <ErrorContainer required={field.required} error={errors[field.id]} />
            </div>
        </div>
    )
}
