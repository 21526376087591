const parseRoomObject = (room = {}) => {
  if (!room) {
    return null;
  }
  const createdAt = room.createdAt ? room.createdAt.toDate().toISOString() : null;
  const updatedAt = room.updatedAt ? room.updatedAt.toDate().toISOString() : null;
  const statusUpdatedAt = room.statusUpdatedAt ? room.statusUpdatedAt.toDate().toISOString() : null;
  const formattedParticipants = {};
  if (room.participants) {
    Object.keys(room.participants).forEach(k => {
      const participantData = room.participants[k];
      if (participantData) {
        formattedParticipants[k] = {
          ...participantData,
          joinTimestamp: participantData.joinTimestamp ? participantData.joinTimestamp.toDate().toISOString() : null,
          leaveTimestamp: participantData.leaveTimestamp ? participantData.leaveTimestamp.toDate().toISOString() : null,
        }
      }
    })
  }

  const meta = room.metadata ? room.metadata : {};
  return {
    ...room,
    createdAt,
    statusUpdatedAt,
    updatedAt,
    participants: formattedParticipants,
    metadata: {
      ...meta,
      lastMessage: meta.lastMessage
        ? {
          ...meta.lastMessage,
          timestamp: meta.lastMessage && meta.lastMessage.timestamp ? meta.lastMessage.timestamp.toDate().toISOString() : null
        }
        : null,
      seenStatus: parseSeenStatus(meta)
    },
  }
};

// TODO: delete after updating the client
const parseSeenStatus = (meta = null) => {
  const seenStatus = {};
  if (meta !== null && meta.seenStatus) {
    Object.keys(meta.seenStatus).forEach(k => {
      seenStatus[k] = meta.seenStatus[k].toDate ? meta.seenStatus[k].toDate().toISOString() : meta.seenStatus[k];
    })
  }
  return seenStatus;
}

const parseMsgObject = (msg = {}) => {
  const timestamp = msg.timestamp ?  msg.timestamp.toDate().toISOString() : new Date().toISOString();
  return {
    ...msg,
    timestamp,
  }
};

const parseMetadata = (meta = {}) => {
  const timestamp = meta.lastMessage && meta.lastMessage.timestamp ? meta.lastMessage.timestamp.toDate().toISOString() : null;
  delete meta.seenStatus;
  return {
    ...meta,
    lastMessage: {
      ...meta.lastMessage,
      timestamp,
    },
    seenStatus: parseSeenStatus(meta)
  }
};

export {
  parseRoomObject,
  parseMsgObject,
  parseMetadata,
}
