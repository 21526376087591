const restoreVideoViews = () => {
  const localVideo = document.getElementById('LocalParticipantInfo.container');
  if (localVideo) {
    localVideo.style.visibility = 'visible';
  }
  const remoteVideo = document.getElementById('MainParticipantInfo.container');
  if (remoteVideo) {
    const remoteVideoTag = remoteVideo.getElementsByTagName('video');
    if (remoteVideoTag && remoteVideoTag[0]) {
      const videoTag = remoteVideoTag[0];
      videoTag.style = {};
      videoTag.style.width = '100%';
      videoTag.style.height = '100%';
      videoTag.style.objectFit = 'cover';
      return videoTag;
    }
  }
  return null;
}

const restoreMobileVideoViews = () => {
  restoreVideoViews();
}

const updateMobileVideoViews = () => {
  const localVideo = document.getElementById('LocalParticipantInfo.container');
  if (localVideo) {
    localVideo.style.visibility = 'hidden';
  }
  const remoteVideo = document.getElementById('MainParticipantInfo.container');
  if (remoteVideo) {
    remoteVideo.style.width = '100vw';
    remoteVideo.style.touchAction = 'none';
    const remoteVideoTag = remoteVideo.getElementsByTagName('video');
    if (remoteVideoTag && remoteVideoTag[0]) {
      const videoTag = remoteVideoTag[0];
      videoTag.style.zIndex = 1001;
      videoTag.style.height = '100px';
      videoTag.style.width = '100px';
      videoTag.style.borderRadius = '32px';
      videoTag.style.top = '70px';
      videoTag.style.position = 'absolute';
      videoTag.style.marginLeft = '22px';
      videoTag.style.marginBottom = '22px';
      videoTag.style.alignSelf = 'flex-end';
      videoTag.style.objectFit = 'cover';
      videoTag.style.touchAction = 'none';
    }
  }
}

const restoreDesktopVideoViews = () => {
  const videoTag = restoreVideoViews();
  if (videoTag !== null) {
    videoTag.style.transform = 'rotateY(180deg)';
  }
}

const updateDesktopVideoViews = () => {
  const localVideo = document.getElementById('LocalParticipantInfo.container');
  if (localVideo) {
    localVideo.style.visibility = 'hidden';
  }
  const remoteVideo = document.getElementById('MainParticipantInfo.container');
  if (remoteVideo) {
    const remoteVideoTag = remoteVideo.getElementsByTagName('video');
    if (remoteVideoTag && remoteVideoTag[0]) {
      const videoTag = remoteVideoTag[0];
      videoTag.style.zIndex = 1001;
      videoTag.style.height = '159px';
      videoTag.style.width = '159px';
      videoTag.style.borderRadius = '32px';
      videoTag.style.top = '20px';
      videoTag.style.position = 'absolute';
      videoTag.style.marginLeft = '22px';
      videoTag.style.boxSizing = 'border-box';
      videoTag.style.border = '1px solid #979797';
      videoTag.style.backgroundColor = '#D8D8D8';
    }
  }
}

const isLastStackScreens = (location = null) => {
  if (location !== null && location.pathname) {
    const lastScreens = ['/error', '/scheduling/succsess', '/video-call/thank-you', '/contact']
    return lastScreens.includes(location.pathname);
  }
}

export {
  updateMobileVideoViews,
  restoreMobileVideoViews,
  restoreDesktopVideoViews,
  updateDesktopVideoViews,
  isLastStackScreens,
}
