import {PAGES} from "../constants/routes";

export function isHistoryRoot(history) {
    if (!history) return false;

    if (history.length === 1) return true;

    return history.location.pathname === history.entries[0].pathname;
}

export function checkPreviousPage(history, activePage, previousPage, isStrict = false) {
    const routes = JSON.parse(JSON.stringify(history?.entries))?.reverse();
    const activePageIndex = routes?.findIndex(ent => ent.pathname === activePage);
    if (activePageIndex === -1) {
        return false
    }

    if (isStrict) {
        return routes[activePageIndex + 1]?.pathname === previousPage;
    }

    return routes[activePageIndex + 1]?.pathname?.startsWith(previousPage);
}

export const isIgnoreIntroVideo = (activePage, history) => {
    if (history?.entries?.length === 1) {
        return false;
    }

    const routes = JSON.parse(JSON.stringify(history?.entries))?.reverse();
    const activePageIndex = routes?.findIndex(ent => ent.pathname === activePage);
    if (activePageIndex === -1) {
        return false
    }
    const previousRoute = routes[activePageIndex - 1]?.pathname;
    return activePage === PAGES.INTRO
      && (previousRoute?.startsWith(PAGES.SCHEDULING)
      || previousRoute?.startsWith(PAGES.CHAT)
      || previousRoute?.startsWith(PAGES.VIDEO_CALL))
}
