import {putFileToStorage, mediaStorage} from "./utils/firebaseUtils";
import {ref as storageReference} from 'firebase/storage';
import { toBlob } from 'html-to-image';
import { logger } from './logging';

const st = mediaStorage();

class ScreenshotLogger {
    constructor() {
        this.uuid = null;
        this.sessionId = null;
    }

    startScreenshotCapture(uuid, sessionId) {
        this.uuid = uuid;
        this.sessionId = sessionId;
        logger.info('startScreenshotCapture', uuid, sessionId);
    }

    stopScreenshotCapture(uuid, sessionId) {
        this.uuid = null;
        this.sessionId = null;
    }

    async captureScreenshot(name, ref) {
        if (ref.current === null) {
            return
        }
        if (!this.uuid || !this.sessionId) return;
        try {
            const blob = await toBlob(ref.current, { cacheBust: true, })
            const filename = `${this.sessionId}_${name}_${Date.now()}`
            const storageRef = storageReference(st, `/userMedia/${this.uuid}/screenshots/${filename}`);
            await putFileToStorage(storageRef, blob, { customMetadata: { contentType: 'image/png', sessionId: this.sessionId } });
            logger.info(`sent capture ${filename}`);
        } catch (error) {
            logger.error(`failed to send capture ${name}`, error);
        }
    }
}

export const appScreenshotLogger = new ScreenshotLogger();
