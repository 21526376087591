import React from 'react';
import { PropTypes } from 'prop-types';
import Modal from '../../../components/Modal/index';
import { OutlinedButton, DestructiveButton } from '../../../components/boutiq-buttons/BoutiqButton';
import './confirm.scss';

const buttonStyles = {
  height: '32px',
  width: 'unset',
  padding: '0 16px',
};

const Confirm = ({
  title,
  content,
  okText = 'OK',
  cancelText = 'Cancel',
  okFunc,
  cancelFunc,
  modalClass,
  loading,
}) => {
  return (
    <Modal modalClass={modalClass}>
      {!loading && (
        <>
          {title && <h3>{title}</h3>}
          {typeof content === 'string' ? <p>{content}</p> : <>{content}</>}

          <div className='actions'>
            <OutlinedButton
              onClick={cancelFunc}
              style={{ ...buttonStyles, marginRight: '10px' }}>
              {cancelText}
            </OutlinedButton>
            <DestructiveButton
              onClick={okFunc}
              style={{
                ...buttonStyles
              }}>
              {okText}
            </DestructiveButton>
          </div>
        </>
      )}
    </Modal>
  );
};

Confirm.propTypes = {
  title: PropTypes.object,
  content: PropTypes.node.isRequired,
  okText: PropTypes.object,
  cancelText: PropTypes.object,
  okFunc: PropTypes.func.isRequired,
  cancelFunc: PropTypes.func.isRequired,
  modalClass: PropTypes.string,
};

export default Confirm;
