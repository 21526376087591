import React from 'react';
import AddAPhotoIcon from '../../../../../../assets/icons/AddAPhoto';
import SendIcon from '../../../../../../assets/icons/Send';
import TextareaAutosize from 'react-textarea-autosize';
import { useIntl } from 'react-intl';
import { useChatData } from '../../../../../../hooks/useChatData';
import { chatScreenMessages } from '../../../../../chat-page/messages';
import './ChatActions.scss';

export const ChatActions = () => {
    const {
        seenMessages,
        updateMessage,
        message,
        openGalleryDialog,
        sendNewMessage,
        sendNewImage,
        fileInputRef
    } = useChatData();
    const intl = useIntl();

    return (
        <div className='mobile-chat-messenger-controls'>
            <div className='mobile-attach-image-button' onClick={openGalleryDialog}>
                <AddAPhotoIcon size={24} />
            </div>
            <TextareaAutosize
                placeholder={intl.formatMessage(chatScreenMessages.message)}
                onChange={updateMessage}
                value={message}
                className='mobile-textfield-input'
                minRows={2}
                maxRows={3}
                cacheMeasurements={true}
                onFocus={seenMessages}
            />
            <div className='mobile-send-button' onClick={sendNewMessage}>
                <SendIcon size={24} />
            </div>
            <input
                className='mobile-file-input'
                ref={fileInputRef}
                onChange={sendNewImage}
                type='file'
                accept='image/*'
                value=''
            />
        </div>
    )
}
