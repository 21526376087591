import { useMemo } from 'react';
import { buildUserEntity } from "../modules/chat/utils/auth";
import { useSelector } from "react-redux";
import { useClientAuthProvider } from "../components/ClientAuthProvider";
import {getNumberOfUnreadMessages} from "../modules/chat/utils/rooms";
import { ROLES } from '../modules/chat/constants/participantRoles';

export const useChatUnreadMessages = (isChatEnabled) => {

    const  { user } = useClientAuthProvider()
    const activeRoom = useSelector(state => state.activeRoom);
    const userEntity = useMemo(() => buildUserEntity({ ...user, role: ROLES.PARTICIPANT }), [user])
	const numberOfUnreadMessages = activeRoom && activeRoom.id && isChatEnabled ? getNumberOfUnreadMessages(activeRoom, userEntity) : 0;

    return { numberOfUnreadMessages }
}

