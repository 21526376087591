import React from 'react';
import './loader.scss';
import { FormattedMessage } from 'react-intl';
import { loaderMessages } from './messages';

export const Loader = ({ name, hasText = false, height = '4.67rem', width = '4.67rem' }) => {
  return (
    <div className='loader-wrapper'>
      <div className='loader' style={{height, width}}></div>
      {hasText ? (
        <>
          <h3><FormattedMessage {...loaderMessages.connecting} /></h3>
          <p>
            <FormattedMessage {...loaderMessages.please_wait} values={{name: name ? <b>{name}</b> : ''}} />.
          </p>
        </>
      ) : null}
    </div>
  );
};
