import { defineMessages } from 'react-intl';

export const cartComponentsMessages = defineMessages({
  cta_checkout: {
    id: 'button_actions.cta_checkout'
  },
  cta_save_for_later: {
    id: 'button_actions.cta_save_for_later'
  },
  cart_counter: {
    id: 'messages.cart_counter'
  },
  cart_counter_one: {
    id: 'messages.cart_counter_one'
  },
  cart_savings: {
    id: 'messages.cart_savings'
  },
  cart_savings_benefits: {
    id: 'messages.cart_savings_benefits'
  },
  cart_empty: {
    id: 'messages.cart_empty'
  },
  applied_at_checkout: {
    id: 'messages_applied_at_checkout'
  },
  host_noun: {
    id: 'nouns_host'
  },
  cart_disabled_title: {
    id: 'messages_cart_disabled_title'
  },
  cart_disabled_text: {
    id: 'messages_cart_disabled_text'
  },
  draft_order_discount: {
    id: 'nouns_discount'
  },
  draft_order_shipping: {
    id: 'nouns_shipping'
  },
  draft_order_tax: {
    id: 'nouns_tax'
  },
  draft_order_total: {
    id: 'nouns_total'
  },
  draft_order_sub_total: {
    id: 'nouns_sub_total'
  }

});
