import { useCallback } from 'react';
import firebase from '../Firebase'
import {getFirestore, doc, setDoc} from 'firebase/firestore';
import { useClientAuthProvider } from '../components/ClientAuthProvider';
import { useCallContextProvider } from '../components/CallContextProvider';


export const useSessionDataClientView = () => {
  const firestore = getFirestore(firebase);
  const { user } = useClientAuthProvider();
  const { callId } = useCallContextProvider();
  const sessionDataDoc = doc(firestore, 'calls', callId, 'sessionData', 'clientView');

  const updateClientProductView = useCallback(async ({productId = null, showroomKey = null, cartKey = null, imageId = null}) => {
    await setDoc(sessionDataDoc, {
      [user.uid]: {
        productView: {
          productId,
          showroomKey,
          cartKey,
          imageId,
        }
      }
    }, { merge: true })
  }, [user]);

  const updateClientTabView = useCallback(async (tabLabel) => {
    await setDoc(sessionDataDoc, {
      [user.uid]: {
        tabView: tabLabel,
      }
    }, { merge: true })
  }, [user]);

  const clearClientView = useCallback(async ()=>{
    await setDoc(sessionDataDoc, {
      [user.uid]: null,
    }, { merge: true })
  },[user])

  return { updateClientProductView, updateClientTabView, clearClientView };

};
