import React, { useEffect } from 'react';
import { useSchdeuleProvider } from '../../../../../components/SchdeuleProvider';
import { useSchdeuleConfigProvider } from '../../../../../components/ScheduleConfigProvider';
import { EventItemData } from '../../../components/eventItemData/EventtemData';
import './ScheduleEventsList.scss';

export const ScheduleEventsList = ({ listRef }) => {
    const {
        selectedEventType, setSelectedEventType
    } = useSchdeuleConfigProvider();
    const { scheduleEventTypes } = useSchdeuleProvider();

    useEffect(() => {
        if (scheduleEventTypes.length === 1) {
            setSelectedEventType(scheduleEventTypes[0].id)
        }
    }, [scheduleEventTypes])

    return (
        <ul ref={listRef} className='select-host-list'>
            {scheduleEventTypes.map((event, index) =>
                <EventItemData
                    key={index}
                    data={event}
                    setSelectedEvent={setSelectedEventType}
                    selectedEvent={selectedEventType}
                />)}
        </ul>
    )
}
