import React, { useRef, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { checkMobileOnly } from '../../../../utils';
import { scheduleScreenMessages } from '../../messages';
import { ScheduledTag } from '../scheduled-tag/ScheduledTag';
import './HostItemData.scss';

export const HostItemData = ({ data, setSelectedHost, selectedHost, scheduledHost, selectedAllHosts }) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [showInitials, setShowInitials] = useState(false);
    const [openHostBio, setOpenHostBio] = useState(false);
    const [bioMaxHeight, setBioMaxHeight] = useState(null);
    const [defaultBioHeight, setDefaultBioHeight] = useState(0);
    const [isToggleButtonAvailable, setIsToggleButtonAvailable] = useState(false);
    const hostBioRef = useRef(null);
    const isMobile = useRef(checkMobileOnly());

    const fallbackToInitials = () => {
        setShowInitials(true);
    }

    const animateImageOnLoad = () => {
        setIsImageLoaded(true);
    }

    const toggleHostBio = () => {
        setOpenHostBio(!openHostBio);
    }

    useEffect(() => {
        if (hostBioRef.current) {
            if (hostBioRef.current.clientHeight < hostBioRef.current.scrollHeight) {
                setIsToggleButtonAvailable(true)
            }
            if (!defaultBioHeight) {
                setDefaultBioHeight(hostBioRef.current.clientHeight)
            }
            setBioMaxHeight(openHostBio ? hostBioRef.current.scrollHeight : isMobile.current ? 35 : 45);
        }
    }, [hostBioRef.current, openHostBio])

    const onSelectHost = (e, hostId) => {
        if (!e.target.classList.contains('host-description-toggle-button-label')) {
            setSelectedHost(hostId)
        }
    }

    return (
        <li onClick={(e) => onSelectHost(e, data.hostId)}
            className={
                `host-data-container
                ${selectedHost === data.hostId ? ' checked' : ''}
                ${scheduledHost === data.hostId ? ` scheduled${!selectedAllHosts && !selectedHost ? ' selected' : ''}` : ''}`} >
            <div className='host-avatar-container'>
                {data.avatar.imageUrl ?
                    showInitials ?
                        <span className='host-avatar initials'>{data.avatar.initials}</span>
                        : <img 
                            onError={() => fallbackToInitials()} 
                            onLoad={animateImageOnLoad} 
                            className={`host-avatar${isImageLoaded ? ' loaded' : ''}`} 
                            src={data.avatar.imageUrl} />
                    : <span className='host-avatar initials'>{data.avatar.initials}</span>}
            </div>
            <div className='host-details-data-container'>
                <div className='host-name-wrapper'>
                    <p className='host-name'>
                        {data.displayName}
                    </p>
                    <ScheduledTag shadow={false} />
                </div>
                {data.bio &&
                    <>
                        <p ref={hostBioRef} style={{ maxHeight: `${bioMaxHeight}px`, '--default-max-height': `${bioMaxHeight}px` }} className={`host-bio${openHostBio ? ' open' : ''}`}>
                            {data.bio}
                        </p>
                        <button onClick={() => toggleHostBio()} className={`host-description-toggle-button ${isToggleButtonAvailable ? ' available' : '' }`} >
                            <div className={`host-description-toggle-button-animated-labels-container${openHostBio ? ' open' : ''}`}>
                                <div className='host-description-toggle-button-label'>
                                    <FormattedMessage {...scheduleScreenMessages.cta_show_more} />
                                </div>
                                <div className='host-description-toggle-button-label active'>
                                    <FormattedMessage {...scheduleScreenMessages.cta_show_less} />
                                </div>
                            </div>
                        </button>
                    </>}
            </div>
        </li>
    )
}
