const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';
const defaultTypes = [REQUEST, SUCCESS, FAILURE];

const createRequestTypes = (base, types = defaultTypes) => {
  const res = {};
  types.forEach((type) => (res[type] = `${base}_${type}`));
  return res;
};

const CHAT_NOTIFICATIONS = {
  ADD_CHAT_NOTIFICATION: 'ADD_CHAT_NOTIFICATION',
  DELETE_CHAT_NOTIFICATION: 'DELETE_CHAT_NOTIFICATION',
};

const ROOMS = {
  GET_ROOMS: createRequestTypes('GET_ROOMS'),
  INITIATE_CHAT_CONVERSATION: createRequestTypes('INITIATE_CHAT_CONVERSATION'),
  ASSIGN_TO_ROOM: createRequestTypes('ASSIGN_TO_ROOM'),
  ARCHIVE_ROOM: createRequestTypes('ARCHIVE_ROOM'),
  JOIN_ROOM: createRequestTypes('JOIN_ROOM'),
  CONTINUE_CHAT: createRequestTypes('CONTINUE_CHAT'),
  SET_ACTIVE_HOST: createRequestTypes('SET_ACTIVE_HOST'),
  SAVE_PENDING_ROOMS: 'SAVE_PENDING_ROOMS',
  SAVE_ACTIVE_ROOMS: 'SAVE_ACTIVE_ROOMS',
};

const ACTIVE_CHAT = {
  UPDATE_METADATA: 'UPDATE_METADATA',
  SET_ACTIVE_ROOM: 'SET_ACTIVE_ROOM',
  SET_ACTIVE_ROOM_ID: 'SET_ACTIVE_ROOM_ID',
  RESET_ACTIVE_ROOM: 'RESET_ACTIVE_ROOM',
  SEND_TYPING_STATUS: createRequestTypes('SEND_TYPING_STATUS'),
  UPDATE_ROOM_BADGES: createRequestTypes('UPDATE_ROOM_BADGES'),
  INIT_MY_BADGES: createRequestTypes('INIT_MY_BADGES'),
};

const MESSAGES = {
  SEND_MESSAGE: createRequestTypes('SEND_MESSAGE'),
  SEEN_MESSAGE: createRequestTypes('SEEN_MESSAGE'),
  UPDATE_LAST_MESSAGE: createRequestTypes('UPDATE_LAST_MESSAGE'),
  GET_MESSAGES: createRequestTypes('GET_MESSAGES'),
  CLEAR_MESSAGES: 'CLEAR_MESSAGES',
  APPEND_MESSAGES: 'APPEND_MESSAGES',
  INIT_ROOM_LISTENER_ID: 'INIT_ROOM_LISTENER_ID',
  SEEN_ACTIVATED: 'SEEN_ACTIVATED',
  SAVE_NEW_MESSAGE: 'SAVE_NEW_MESSAGE',
  SET_TEMP_IMAGE_MESSAGE: 'SET_TEMP_IMAGE_MESSAGE',
  SHOW_VIDEO_CALL_MESSAGE: 'SHOW_VIDEO_CALL_MESSAGE',
  SAVE_CLIENTS_INFO: createRequestTypes('SAVE_CLIENTS_INFO'),
};

export {
  CHAT_NOTIFICATIONS,
  ROOMS,
  ACTIVE_CHAT,
  MESSAGES,
};
