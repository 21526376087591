import React, { useEffect, useState, useRef } from 'react'
import { FormattedMessage } from 'react-intl';
import { isEmail, isEmptyString, isPhone } from '../../../utils';
import { boutiqFormMessages } from '../messages';

export const useForm = (callback, showErrorFieldCallback, createFieldsRef, sessionData, schema, scheduleItemData) => {

    const [values, setValues] = useState({ ...sessionData });
    const [errors, setErrors] = useState({});
    const refs = useRef(null);

    useEffect(() => {
        if (schema?.fields) {
            setValues({ ...loadFormData(schema.fields, scheduleItemData?.data), ...values });
        }

    }, [schema, scheduleItemData])

    const refProvider = key => r => {
        if (createFieldsRef) {
            refs.current = { ...refs.current, ...{[key]: r} };
        }
    }

    function loadFormData(fields, data) {
        let newValues;
        if (data) {
            newValues = fields.reduce((obj, field) => {
                const persistedFieldData = data.find(item => item.id === field.id);
                const type = field.type;
                switch (type) {
                    case 'text': case 'email': case 'phone': case 'select': case 'checkbox':
                        if (persistedFieldData?.value) {
                            obj[field.id] = persistedFieldData.value;
                        } else {
                            if (field.required) {
                                obj[field.id] = null;
                            }
                        }
                        break;
                    case 'choice':
                        if (persistedFieldData?.value) {
                            const option = field.options.find(option => option.value === persistedFieldData.value);
                            if (option) {
                                obj[field.id] = {
                                    selected: persistedFieldData.value,
                                    optionValue: persistedFieldData.value,
                                    value: persistedFieldData.value
                                };
                            } else {
                                obj[field.id] = {
                                    selected: persistedFieldData.value.optionValue,
                                    optionValue: persistedFieldData.value.optionValue,
                                    value: persistedFieldData.value
                                };
                            }
                        } else {
                            if (field.required) {
                                obj[field.id] = null;
                            }
                        }
                        break;
                    case 'multi-choice':
                        if (persistedFieldData?.value) {
                            obj[field.id] = field.options.map(option => {
                                const value = persistedFieldData.value.find(val => typeof val !== 'object' ? val === option.value : val.optionValue === option.value)
                                return { selected: !!value, value: value || option.value, optionValue: option.value }
                            })
                        } else {
                            if (field.required) {
                                obj[field.id] = null;
                            }
                        }
                        break;
                    default:
                        break;
                }
                return obj;
            }, {});
        } else {
            newValues = fields.reduce((obj, field) => {
                if (field.required) {
                    obj[field.id] = null;
                }
                return obj;
            }, {});
        }
        return newValues;
    };

    const omitSingle = (key, { [key]: _, ...obj }) => obj;

    const validate = (name, value, validationType, required) => {
        let errorObj;
        //A function to validate each input values
        if (required) {
            if (isEmptyString(value) || value === null || value === false || Array.isArray(value) && value.every(value => !value.selected)) {
                switch (validationType) {
                    case 'name':
                        errorObj = { [name]: <FormattedMessage {...boutiqFormMessages.name_error} /> }
                        break;
                    case 'email':
                        errorObj = { [name]: <FormattedMessage {...boutiqFormMessages.email_error} /> }
                        break;
                    case 'phone':
                        errorObj = { [name]: <FormattedMessage {...boutiqFormMessages.input_phone_error} /> }
                        break
                    default:
                        errorObj = { [name]: <FormattedMessage {...boutiqFormMessages.empty_field_error} /> }
                        break;
                }
            } else {
                errorObj = defaultValidation(name, value, validationType)
            }
        } else {
            errorObj = defaultValidation(name, value, validationType)
        }
        if (errorObj) {
            setErrors({
                ...errors,
                ...errorObj
            })
        }

        return errorObj
    }

    const defaultValidation = (name, value, validationType) => {
        let errorObj;

        switch (validationType) {
            case 'name':
                omitError(name);
                break;
            case 'email':
                if (!isEmptyString(value) && !isEmail(value)) {
                    errorObj = { [name]: <FormattedMessage {...boutiqFormMessages.email_invalid} /> }
                } else {
                    omitError(name);
                }
                break;
            case 'phone':
                if (!isEmptyString(value) && !isPhone(value) || value && isEmptyString(value)) {
                    errorObj = { [name]: <FormattedMessage {...boutiqFormMessages.input_phone_error} /> }
                }
                else {
                    omitError(name);
                }
                break;
            default:
                omitError(name);
                break;
        }
        return errorObj;
    }

    //A method to handle form inputs
    const handleChange = (e, name, val) => {
        //To stop default events    
        e.persist();
        omitError(name)
        setValues({
            ...values,
            [name]: val,
        })
    }

    const omitError = (name) => {
        const newObj = omitSingle(name, errors);
        setErrors(newObj);
    }

    const handleSubmit = (event, schema) => {
        if (event) event.preventDefault();
        let updatedErrors = errors;
        if (schema?.fields) {
            const errorsArray = schema.fields.reduce((errorObj, item) => {
                let obj;
                obj = validate(item.id, values[item.id] || null, item.type, item.required)
                if (obj) {
                    return { ...errorObj, ...obj }
                } else {
                    return errorObj
                }
            }, {})
            updatedErrors = {
                ...errors,
                ...errorsArray
            }

            setErrors(updatedErrors)
        }

        if (Object.keys(updatedErrors).length === 0) {
            callback();
        } else if (createFieldsRef) {
            const getElementReference = schema.fields.find(field => {
                const isFieldNotValid = updatedErrors[field.id];
                if (isFieldNotValid) {
                    return true;
                } else {
                    if (field.options) {
                        const isFieldHaveInnerComponents = field.options.find(option => option.innerComponent);
                        if (isFieldHaveInnerComponents && updatedErrors[isFieldHaveInnerComponents.innerComponent.id]) {
                            return true;
                        }
                    }
                }
            });

            if (getElementReference && getElementReference.id && refs && refs.current) {
                const targetElRef = refs.current[getElementReference.id];
                if (targetElRef)
                    showErrorFieldCallback(targetElRef)
            }
        }
    }

    return {
        values,
        errors,
        setErrors,
        handleChange,
        omitError,
        validate,
        handleSubmit,
        refProvider
    }
}
