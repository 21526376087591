import { useState, useRef } from "react";

import { useCallContextAPI, useCheckoutComplete } from ".";
import { useCallContextProvider } from "../components/CallContextProvider";

import { useInterWindowMessageProvider, REQ_HEADER_TYPES } from "../components/InterWindowMessageProvider";
import { logger } from "../logging";
import { sendEcommEvent } from "../utils/analytics-cross-domain";
//import { buildCheckoutLink } from "../utils/shopify"; deprecated shai 2022-05-31

export const useCheckoutAction = () => {
    const { callId, activeCartId, contextId, disableCartSync, shopifyLocaleRoot } = useCallContextProvider();
    const { contextCallCheckout, contextCallDraftOrderCheckout } = useCallContextAPI(contextId);
    const { postMessage } = useInterWindowMessageProvider();
    const checkoutWindowRef = useRef(null);
    const [isCheckoutComplete, setCheckoutComplete] = useState(false);
    const openCheckout = async (cartData, draftOrderId) => {
        let cartSyncEnabled = !disableCartSync;
        checkoutWindowRef.current = window.open();
        try {
          let response;
          if (draftOrderId) {
            response = await contextCallDraftOrderCheckout(callId, draftOrderId, cartSyncEnabled, shopifyLocaleRoot)
          } else {
            response = await contextCallCheckout(callId, activeCartId, cartSyncEnabled, shopifyLocaleRoot )
          }
          const {checkoutUrl} = response;
          logger.info('checkout started by client', { checkoutUrl });
          checkoutWindowRef.current.location = checkoutUrl;

          const analyticsPayload = {
            value: cartData.total,
            currency: cartData.currencyCode,
          };

          sendEcommEvent('begin_checkout', analyticsPayload);
          postMessage(REQ_HEADER_TYPES.CHECKOUT_STARTED, analyticsPayload);
        } catch (error) {
          console.error('contextCallCheckout', error);
        }
      };

    useCheckoutComplete(callId, activeCartId, () => {
      logger.info('checkout complete', { activeCartId });
        setCheckoutComplete(true);
    });

    return { openCheckout, isCheckoutComplete, setCheckoutComplete }
}
