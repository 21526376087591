const FIRESTORE_COLLECTIONS = {
  CHAT_ROOMS: 'chatRooms',
  CHAT_MESSAGES: 'messages',
  ROOM_METADATA: 'metadata',
  ROOM_FEATURES: 'features',
  TYPING_DOC: 'typing',
  LAST_MESSAGE_DOC: 'lastMessage',
  BADGES_DOC: 'badges',
};

export {
  FIRESTORE_COLLECTIONS,
}
