import React, { useState } from 'react';
import { useIntroPage } from '../IntroPageProvider';
import './index.scss';

export default function IntroWelcome() {
    const { introWelcomeStyles } = useIntroPage();
    const [isBackgroundImageLoaded, setIsBackgroundImageLoaded] = useState(false);
    const animateBackgroundImage = () => {
        setIsBackgroundImageLoaded(true);
    }

    return (
        <>
            {introWelcomeStyles && 
                <div
                    className={`intro-welcome-wrapper${introWelcomeStyles.app_custom_background_url ? isBackgroundImageLoaded ? ' sync-animation-background' : '' : ' sync-animation-background'}`}
                    style={{ color: introWelcomeStyles.app_custom_background_color ? introWelcomeStyles.app_custom_background_color : '#fff' }}  >
                    {introWelcomeStyles.app_custom_background_url && <img src={introWelcomeStyles.app_custom_background_url}
                        className='welcome-background-image'
                        onLoad={() => animateBackgroundImage()}
                        style={{
                            opacity: isBackgroundImageLoaded ? introWelcomeStyles.app_custom_background_opacity ?
                                introWelcomeStyles.app_custom_background_opacity :
                                1 : 0
                        }}>
                    </img>}
                </div>}
        </>
    )
}
