import React, {useEffect, useRef, useMemo, useState} from 'react';
import {useHistory} from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import {FormattedMessage} from 'react-intl';

import './styles.scss';
import {useSelector, useDispatch} from "react-redux";

import MessageItem from "../messageItem";
import {useRoomIsTyping} from '../../modules/chat/api/features/listeners';
import {TEMP_MESSAGE} from "../../modules/chat/constants/messaging";
import {showStartVideoCallMessage} from "../../modules/chat/actions/messages";
import {setPreferredHost} from "../../actions/currentSession";
import {Loader} from '../loader/loader';
import useGetMessages from '../../modules/chat/api/messages/api';
import {buildUserEntity} from '../../modules/chat/utils/auth';
import {chatScreenMessages} from "../../pages/chat-page/messages";
import {PAGES} from "../../constants/routes";
import MessageItemWrapper from "../messageItem/wrapper";
import {isMobileSafariOnly} from "../../utils";

const ChatView = ({user, setFullSizeChatImage, videoInitiated, seenMessages, roomMessagesDisplayed = true,
  scrollPosition, setScrollPosition,
}) => {

  const IS_MOBILE_SAFARI_BROWSER = isMobileSafariOnly();

  const lastMessageRef = useRef(null);
  const isScrolled = useRef(false);
  const initialScrollPosition = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const {messages, tempImageMessageId, isShowStartVideoCallMessage, isMessagesLoading} = useSelector(state => state.chatMessages);
  const room = useSelector(state => state.activeRoom);
  const { loadMessages } = useGetMessages(room.id, user?.uid);

  const lastMessage = useRef(null);
  const scrollableDivRef = useRef(null);
  const infiniteScrollRef = useRef(null);
  const [visibleNewMessagesSeparator, setVisibleNewMessagesSeparator] = useState(false);
  const [roomSeenStatus, setRoomSeenStatus] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [messagesList, setMessagesList] = useState([]);
  const [items, setItems] = useState(messages);
  const currentUser = buildUserEntity(user);

  useEffect(() => {
    if (IS_MOBILE_SAFARI_BROWSER && roomMessagesDisplayed) {
      scrollableDivRef.current.addEventListener('scroll', function(e) {
        isScrolled.current = true;
        const {isLastMessageSeen, elementPosition} = getScrollableListParams();
        handleScroll(isLastMessageSeen, elementPosition);
      });
      initialScrollPosition.current = scrollableDivRef.current.getBoundingClientRect().bottom;

      return () => {
        scrollableDivRef.current.removeEventListener("scroll", handleScroll);
      }
    }
  }, [])

  useEffect(() => {
    lastMessage.current = null;
    (async function fetchFirstMessages() {
      const newLastMessage = await loadMessages(null, room.metadata && room.metadata.badges ? room.metadata.badges[user.id] : 0);
      lastMessage.current = newLastMessage;
    })();
  }, [room.id]);

  useEffect(() => {
    setItems(messages);
  }, [messages]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setRoomSeenStatus(!roomSeenStatus);
    }, 1000);
    return () => clearTimeout(timer);
  }, [room?.metadata?.seenStatus]);

  const getScrollableListParams = () => {
    const elementPosition = scrollableDivRef.current.scrollTop;
    const isLastMessageSeen = initialScrollPosition.current - infiniteScrollRef.current.getBoundingClientRect().bottom > -50;
    return {isLastMessageSeen, elementPosition}
  }

  const fetchOldMessages = () => {
    if (hasMoreData) {
      loadMessages(lastMessage.current)
        .then((newLastMessage) => {
          setHasMoreData(newLastMessage ? lastMessage.current?.id !== newLastMessage?.id : false);
          if (newLastMessage) {
            lastMessage.current = newLastMessage;
          }
          if (scrollableDivRef.current) {
            scrollableDivRef.current.scrollBy(0, 1)
          }
        });
    }
  };

  const handleScroll = (isLastMessageSeen, elementPosition) => {
    if (elementPosition === 0) {
      fetchOldMessages();
    }
    setScrollPosition(isLastMessageSeen ? 0 : elementPosition);
  };

  const handleInfiniteScroll = (event) => {
    const position = event.target.scrollTop;
    if (position > -15 && scrollPosition !== 0) {
      setScrollPosition(0);
    } else if (position < -15 && scrollPosition === 0) {
      setScrollPosition(position);
    }
  };

  const isImageLoaded = useMemo(() => {
    if (tempImageMessageId === null) {
      return false;
    }
    const messageIndex = messages.findIndex(m => m.id === tempImageMessageId);
    return messageIndex !== -1;
  }, [messages.length, tempImageMessageId]);

  const [loadedImages, updateLoadedImages] = useState(0);

  const { roomTyping } = useRoomIsTyping();
  const remoteTyping = roomTyping && Object.keys(roomTyping).filter(id => id !== user.uid).find(id => !!roomTyping[id]);
  const userForTyping = remoteTyping && room && room.participants ? room.participants[remoteTyping] : null;

  const incrementLoadedImages = () => {
    updateLoadedImages(loadedImages + 1);
  }

  const hideStartVideoCallMessage = () => {
    dispatch(showStartVideoCallMessage(false));
  }

  const navigateToLobby = () => {
    if (room.activeHost !== null) {
      dispatch(setPreferredHost(room.activeHost.uuid));
    }
    history.push(PAGES.VIDEO_CALL);
  }

  useEffect(() => {
    if (visibleNewMessagesSeparator && scrollPosition === 0 && roomMessagesDisplayed) {
      seenMessages();
      const timer = setTimeout(() => {
        setVisibleNewMessagesSeparator(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [visibleNewMessagesSeparator, scrollPosition, roomMessagesDisplayed]);

  useEffect(() => {
    if (messages.length > 0) {
      let list = [...messages];
      const lastSeen = room.metadata && room.metadata.seenStatus && room.metadata.seenStatus[currentUser.uid]
        ? new Date(room.metadata.seenStatus[currentUser.uid]).valueOf()
        : 0

      let lastNewMessagesIndex = JSON.parse(JSON.stringify(messages)).reverse().findIndex((m, i) => {
        return new Date(new Date(m.timestamp).toUTCString()).valueOf() > lastSeen
          && ( m.owner.uuid !== currentUser.uid && !m.owner.system)
      });

      if (lastNewMessagesIndex > 0) {
        const separatorIndex = messages.length - lastNewMessagesIndex;
        setVisibleNewMessagesSeparator(true);
        list = messages.slice(0, separatorIndex)
          .concat([{id: `${new Date().valueOf()}_new`, timestamp: lastSeen}])
          .concat(messages.slice(separatorIndex, messages.length + 1))
      }
      setMessagesList(list);
    }
  }, [messages.length, roomSeenStatus]);

  const setLastMessageRef = (ref, index) => {
    if (index === 0 && IS_MOBILE_SAFARI_BROWSER && roomMessagesDisplayed) {
      if (!lastMessageRef?.current) {
        ref.scrollIntoView();
      }

      if (ref) {
        lastMessageRef.current = ref;
        const {isLastMessageSeen} = getScrollableListParams();
        if (isLastMessageSeen || (!isLastMessageSeen && !isScrolled.current)) {
          ref.scrollIntoView();
          isScrolled.current = false;
        }
      }
    }
  }

  const renderMessages = () => {
    if (messagesList.length > 0) {
      return messagesList.map((msg, index) => {
        if (msg.id.indexOf('_new') !== -1) {
          return (
            <div className='new-message-item new-message-item_newmessage' key={`${msg.id}_new`} ref={setLastMessageRef}>
              <div className='new-message-item_newmessage_delimiter'/>
              <FormattedMessage {...chatScreenMessages.newMessages}/>
              <div className='new-message-item_newmessage_delimiter'/>
            </div>
          );
        }

        return (
          <MessageItemWrapper
            setLastMessageRef={setLastMessageRef}
            index={index}
            key={msg.id}
            children={<MessageItem
              msg={msg}
              user={user}
              room={room}
              setFullSizeChatImage={setFullSizeChatImage}
              incrementLoadedImages={incrementLoadedImages}
              videoInitiated={videoInitiated}
              navigateToLobby={navigateToLobby}
            />}
          />
        )
      });
    }
    return null;
  };

  const renderTypingMessage = () => {
    if (userForTyping && room) {
      return (
        <MessageItemWrapper
          setLastMessageRef={setLastMessageRef}
          key='typing'
          children={<MessageItem typing={true} user={userForTyping}/>}
        />
      )
    }
  }

  const renderStartVideoMessage = () => {
    if (isShowStartVideoCallMessage && !videoInitiated) {
      return (
        <MessageItemWrapper
          setLastMessageRef={setLastMessageRef}
          key={TEMP_MESSAGE.START_VIDEO_CALL}
          children={<MessageItem
            msg={{key: TEMP_MESSAGE.START_VIDEO_CALL}}
            user={user}
            hideStartVideoCallMessage={hideStartVideoCallMessage}
            navigateToLobby={navigateToLobby}
            room={room} />}
        />
      )
    }
  }

  const renderTempImageMessage = () => {
    if (tempImageMessageId !== null && !isImageLoaded) {
      return (
        <MessageItemWrapper
          setLastMessageRef={setLastMessageRef}
          key={TEMP_MESSAGE.IMAGE}
          children={<MessageItem
            msg={{key: TEMP_MESSAGE.IMAGE}}
            user={user}
            room={room}
            incrementLoadedImages={incrementLoadedImages} />}
        />
      )
    }
  }

  const renderChatView = () => {
    if (IS_MOBILE_SAFARI_BROWSER) {
      return (
        <div className='chat-view' ref={scrollableDivRef}>
          {
            isMessagesLoading && hasMoreData &&
            <Loader height={'2rem'} width={'2rem'} />
          }
          <div className='infinite-scroll' ref={infiniteScrollRef}>
            {renderTypingMessage()}
            {renderStartVideoMessage()}
            {renderTempImageMessage()}
            {renderMessages()}
          </div>
        </div>
      )
    } else {
      return (
        <div className='chat-view'>
          <div
            id="caazam-chat-scrollable-div"
            style={{
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column-reverse',
            }}
          >
            <InfiniteScroll
              dataLength={items.length}
              next={fetchOldMessages}
              className='infinite-scroll'
              inverse={true}
              hasMore={hasMoreData}
              loader={<Loader height={'2rem'} width={'2rem'} />}
              scrollableTarget="caazam-chat-scrollable-div"
              onScroll={handleInfiniteScroll}
              initialScrollY={0}
              key={room.id}
            >
              {renderTypingMessage()}
              {renderStartVideoMessage()}
              {renderTempImageMessage()}
              {renderMessages()}
            </InfiniteScroll>
          </div>
        </div>
      )
    }
  }

  return renderChatView();
};

export default ChatView;
