import React from 'react';

export const ChevronRight = () =>
    <svg width="10px" height="18px" viewBox="0 0 10 18" version="1.1" xmlns="http://www.w3.org/2000/svg" >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="arrow-left-(1)" fill="#66676C" fillRule="nonzero">
                <path d="M8.9843775,17.1484 C8.9062475,17.2266 8.7890575,17.2656 8.6718775,17.2656 C8.5156275,17.2656 8.3984375,17.2266 8.3203175,17.1484 L0.1562495,8.94531 C0.0390625,8.86719 0,8.78906 0,8.63281 C0,8.51562 0.0390625,8.39844 0.1562495,8.32031 L8.3203175,0.117188 C8.3984375,0.0390625 8.5156275,0 8.6718775,0 C8.7890575,0 8.9062475,0.0390625 8.9843775,0.117188 L9.7656275,0.898438 C9.8437475,0.976562 9.9218775,1.09375 9.9218775,1.21094 C9.9218775,1.36719 9.8437475,1.48438 9.7656275,1.5625 L2.6953175,8.63281 L9.7656275,15.7031 C9.8437475,15.8203 9.9218775,15.9375 9.9218775,16.0547 C9.9218775,16.2109 9.8437475,16.2891 9.7656275,16.3672 L8.9843775,17.1484 Z" id="Path" transform="translate(4.960939, 8.632800) scale(-1, 1) translate(-4.960939, -8.632800) "></path>
            </g>
        </g>
    </svg>
