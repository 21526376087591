import React from "react"
import { currencyFormatter } from "../../../../../../../../../../utils";
import './CurrentPrice.scss';

export const CurrentPrice = ({
    maxVariantPrice,
    minVariantPrice,
    currencyCode,
    price,
    compareAtPrice
}) => <div className='mobile-price-container'>
        {(maxVariantPrice !== minVariantPrice) ?
            <h4>
                {`${currencyFormatter(minVariantPrice, currencyCode)} — ${currencyFormatter(maxVariantPrice, currencyCode)}`}
            </h4> :
            <>
                <h4>
                    {price && currencyFormatter(price, currencyCode)}
                </h4>
                {compareAtPrice && price !== compareAtPrice ? (
                    <h4 className='mobile-old-price'>
                        {currencyFormatter(compareAtPrice, currencyCode)}
                    </h4>
                ) : null}
            </>}
    </div>
