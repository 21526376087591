import React from 'react'
import './PageHeader.scss';

export const PageHeader = ({ title, subTitle, children }) => {
    return (
        <div className='page-header'>
            <h3 className='page-title'>
                {title}
            </h3>
            {subTitle && <div className='page-subtitle'>
                {subTitle}
            </div>}
            {children && <div className='page-header-child'>
                {children}
            </div>}
        </div>
    )
}
