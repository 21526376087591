import React from 'react';
import { FormattedMessage } from 'react-intl';
import { scheduleScreenMessages } from '../../messages';
import './LeaveMessageEvent.scss';

export const LeaveMessageEvent = () =>
    <button className='leave_us_a_message_button'>
        <FormattedMessage
            {...scheduleScreenMessages.leave_message}
        />
    </button>
