import React from 'react';
import { OptionListModal } from '../../../../components/option-list-modal/OptionListModal';
import { useSchdeuleConfigProvider } from '../../../../components/ScheduleConfigProvider';
import './AddToCalendarModal.scss';

export const AddToCalendarModal = ({ closeModal }) => {
    const { scheduleData } = useSchdeuleConfigProvider()

    const downloadGoogleEvent = async () => {
        try {
            let newWindow = window.open();
            if (scheduleData.icsDownloadUrl) {
                let url = scheduleData.icsDownloadUrl + `&google=true`;
                let res = await fetch(url);
                if (!res.ok) {
                    throw new Error('Failed to download Google Calendar Event');
                }
                let { googleCalendarUrl } = await res.json();
                newWindow.location = googleCalendarUrl;
            }
        } catch (error) {
            console.error(error);
        } finally {
            closeModal();
        }
    }

    const downloadIcs = async () => {
        try {
            if (scheduleData.icsDownloadUrl) {
                let icsData = await fetch(scheduleData.icsDownloadUrl);
                if (!icsData.ok) {
                    throw new Error('Failed to download Event ICS file');
                }
                let blobUrl = URL.createObjectURL(await icsData.blob());
                const a = document.createElement('a')
                a.href = blobUrl;
                a.download = 'invite.ics';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        } catch (error) {
            console.error('downloadIcs', error);
        } finally {
            closeModal();
        }
    }

    return (
        <OptionListModal positionY='top' positionX='left' onClose={(e) => {e.preventDefault(); e.stopPropagation(); closeModal(e)}} children={
            <ul className='calendar-options-list'>
                <li className='calendar-option' onClick={downloadGoogleEvent}>Google</li>
                <li className='calendar-option' onClick={downloadIcs}>iCal</li>
                <li className='calendar-option' onClick={downloadIcs}>Outlook</li>
            </ul>
        } />
    )
};
