import React from 'react';
import { FormattedMessage } from 'react-intl';
import { scheduleScreenMessages } from '../messages';
import { ErrorMark } from '../../../assets/icons/ErrorMark';
import { PageFooter } from '../../../components/page-footer/PageFooter';
import { useSchdeuleProvider } from '../../../components/SchdeuleProvider';
import { useSchdeuleConfigProvider } from '../../../components/ScheduleConfigProvider';
import {useRouteMatch} from "react-router-dom";
import './ScheduleError.scss';

const ScheduleErrorScreen = ({navigateToNextScheduleScreen}) => {
	const { refresh } = useSchdeuleProvider();
	const { schedulingError } = useSchdeuleConfigProvider();
	const { path } = useRouteMatch();

	const SchedulingErrorMessage = () => {
		if (schedulingError?.statusCode === 409) {
			return (<p><FormattedMessage {...scheduleScreenMessages.schdeule_slot_unavailable} /></p>)
		} else {
			return (<p><FormattedMessage {...scheduleScreenMessages.schdeule_error} /></p>)
		}
	}

	return (
		<>
			<div className='error-schedule-content'>
				<ErrorMark />
				<h3>
					<FormattedMessage {...scheduleScreenMessages.contact_error_1} />
				</h3>
				<SchedulingErrorMessage/>
			</div>
			<PageFooter
				primaryButtonLabel={<FormattedMessage {...scheduleScreenMessages.cta_try_again} />}
				onSubmitPrimary={() => {
					refresh();
					navigateToNextScheduleScreen(path);
				}}
			/>
		</>
	);
};

export default ScheduleErrorScreen;
