import { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {collection, onSnapshot, doc} from 'firebase/firestore';
import {firestore} from '../../firebaseInitialization';
import {parseRoomObject} from "../../utils/parsers";
import {FIRESTORE_COLLECTIONS} from "../../constants/firestore";
import {setActiveRoom} from "../../actions/activeRoom";

export default function useActiveRoomListener() {
  const fs = firestore();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.activeRoom);

  const listenActiveRoom = () => {
    return onSnapshot(doc(fs, FIRESTORE_COLLECTIONS.CHAT_ROOMS, id),
        (doc) => {
          if (doc && doc.data()) {
            dispatch(setActiveRoom(parseRoomObject(doc.data())))
          }
        }, listenError => console.error('listenActiveRoom',listenError));
  }

  useEffect(() => {
    const activeRoomListener = listenActiveRoom();
    return () => {
      activeRoomListener();
    }
  },[id]);
}
