import React from "react";
import { MainImage } from "../../main-image/MainImage";
import './FullScreenView.scss'

export const FullScreenView = ({ src = null }) =>
    <div className='mobile-full-screen-product-view-container'>
        {src &&
            <div className={`mobile-product-images-gallery`}>
                {
                    <div className='mobile-product-image-wrapper'>
                        <MainImage src={src} />
                    </div>
                }
            </div>}
    </div>
