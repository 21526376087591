import React from 'react';
import {isMobileSafariOnly} from "../../utils";

const MessageItemWrapper = ({children, setLastMessageRef, index}) => {

  const setRef = (ref) => {
    setLastMessageRef(ref, index);
  }

  return isMobileSafariOnly()
    ?  <div ref={setRef}>
        {children}
      </div>
    : children
};

export default MessageItemWrapper;
