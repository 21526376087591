import React, {useState} from 'react';
import ReactPlaceholder from 'react-placeholder';
import 'react-placeholder/lib/reactPlaceholder.css';

import './styles.scss';

const ImageMessage = ({msg, isHost, incrementLoadedImages, isMessageLoading = true}) => {
  const [isLoaded, setLoadingStatus] = useState(!isMessageLoading);

  const hiddenStyle = !isLoaded ? { display: "none" } : {};

  const load = () => {
    incrementLoadedImages();
    setLoadingStatus(true)
  };

  return (
    <div className={`message-item_info_message-image message-item_info_message-text_${isHost ? 'opponent' : 'my'} no-select ${isLoaded || 'loading-style'}`}>
      {
        !isLoaded &&
          <ReactPlaceholder
            className='image-placeholder'
            type='rect'
            ready={isLoaded}
            showLoadingAnimation={true}
            color={'gray'}
          />
      }
      {
        msg && msg.metadata && msg.metadata.url &&
          <img
            src={msg.metadata.url}
            alt='Image'
            style={hiddenStyle}
            className='image-real'
            onError={incrementLoadedImages}
            onLoad={load} />
      }
    </div>
  );
}

export default ImageMessage;
