import React, { useEffect,useState } from 'react';
import firebase from '../Firebase'
import {getDatabase, ref, onChildAdded, off} from 'firebase/database';
import { parseIdString } from '../utils/';

const productCache = {};
export const useProducts = (shopId, callId, locale = "", countryCode = null) => {
  const [isProductLoading, setProductLoading] = useState(false);
  const [productLoadingError, setProductLoadingError] = useState(null);
  const db = getDatabase(firebase);

  const fetchProduct = async (productId) => {
    setProductLoadingError(null);
    let params = new URLSearchParams({ locale, cacheKey: callId });
    if (countryCode) {
      params.append('pricingContext', countryCode);
    }
    const res = await fetch(
      `${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopId}/productsv2/${productId}?${params.toString()}`
    );
    if (!res.ok) {
      console.error(`fetchProduct for ${productId} failed`);
      setProductLoadingError(res.error);
    } else {
      const data = await res.json();
      const product = { ...data, isInShowroom: true };
      productCache[productId] = product;
      return product;
    }
  };

  useEffect(() => {
    if (callId) {
      const sessionRef = ref(db, `session/${callId}/products`);
      onChildAdded(sessionRef,(snapshot) => {
        const productId = snapshot.val().productId;
        fetchProduct(productId);
      });
      return () => off(sessionRef);
    }
  }, [callId]);

  const getCachedProduct = async (productId) => {
    const cleanProductId = parseIdString(productId);
    if (productCache[cleanProductId]) {
      return productCache[cleanProductId];
    } else {
      setProductLoading(true);
      const product = await fetchProduct(cleanProductId);
      setProductLoading(false);
      return product;
    }
  };

  const updateCachedProduct = async (productId, newData) => {
    const cleanId = parseIdString(productId);
    if (!productCache[cleanId]) {
      await fetchProduct(cleanId);
    }
    productCache[cleanId] = { ...productCache[cleanId], ...newData };
  };

  return {
    getCachedProduct,
    updateCachedProduct,
    isProductLoading,
    productLoadingError,
  };
};

export const useProductVariants = (shopId, callId, countryCode = null) => {

  const fetchVariant = async (productId, variantId) => {
    const params = new URLSearchParams();
    if (countryCode) {
      params.append('pricingContext',countryCode);
    }
    const res = await fetch(
      `${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopId}/productsv2/${productId}/variants/${variantId}?${params.toString()}`
    );
    if (!res.ok) {
      console.error(`fetchVariant for ${productId}/${variantId} failed`,res.status);
    } else {
      return res.json();
    }
  };

  return { fetchVariant }

}

export const useProductMetadata = (shopId, callId, locale = "", countryCode = null) => {

  const fetchProductMetadata = async (productId) => {
    const params = new URLSearchParams({ locale });
    if (countryCode) {
      params.append('pricingContext', countryCode);
    }
    const res = await fetch(
      `${process.env.REACT_APP_CAAZAM_REST_EP}/vs/shop/${shopId}/productsv2/${productId}/metadata?${params.toString()}`
    );
    if (!res.ok) {
      console.error(`fetchProductMetadata for ${productId} failed`, res.status);
    } else {
      return res.json();
    }
  };

  return { fetchProductMetadata }

}

