const CURRENT_SESSION = {
  SET_PREFERRED_HOST: 'SET_PREFERRED_HOST',
  SET_SHOPIFY_CONTEXT: 'SET_SHOPIFY_CONTEXT',
  SET_ACTIVE_CART_ID: 'SET_ACTIVE_CART_ID',
  SET_ORIGIN_URL: 'SET_ORIGIN_URL',
  SET_SHOPIFY_CART: 'SET_SHOPIFY_CART',
  SET_GLOBAL_CONFIG: 'SET_GLOBAL_CONFIG',
};

const SYSTEM = {
  CLOSE_APP: 'CLOSE_APP',
};

export {
  CURRENT_SESSION,
  SYSTEM,
};
