const isSameDates = (firstDate, secondDate) => {
  if (!firstDate) {
    return;
  }
  const fDate = new Date(firstDate);
  const sDate = secondDate ? new Date(secondDate) : new Date();
  return fDate.getFullYear() === sDate.getFullYear() && fDate.getMonth() === sDate.getMonth() && fDate.getDate() === sDate.getDate();
}

const isToday = (date) => {
  return isSameDates(date);
}
const isYesterday = (date) => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);
  return isSameDates(date, currentDate);
}
const isWithinAWeek = (date) => {
  const newDate = new Date(date);
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 7);
  return newDate > currentDate;
}

const formatMsgTime = (time) => {
  if (isToday(time)) {
    const date = new Date(time);
    return new Intl.DateTimeFormat([], { timeStyle: 'short'}).format(date);
  } else if (isYesterday(time)) {
    return 'yesterday';
  } else if (isWithinAWeek(time)) {
    const date = new Date(time);
    return new Intl.DateTimeFormat([], { weekday: 'long' }).format(date);
  }
  const date = new Date(time);
  return new Intl.DateTimeFormat([], { year: 'numeric', month: 'numeric', day: 'numeric' }).format(date);
};

const convertDateToUnixTimestamp = (date) => {
  return Math.round((new Date(date)).getTime() / 1000);
};

export {
  formatMsgTime,
  convertDateToUnixTimestamp,
}
