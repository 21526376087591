import { combineReducers } from '@reduxjs/toolkit';
import { rooms, activeRoom, chatMessages } from '../modules/chat/reducers';
import currentSession from './currentSession';
import appDiagnostics from './appDiagnostics';
import { SYSTEM } from "../constants/sessionActionTypes";

const rootReducer = combineReducers({
  rooms,
  activeRoom,
  chatMessages,
  currentSession,
  appDiagnostics,
});

export default (state, action) => rootReducer(action.type === SYSTEM.RESET ? undefined : state, action);
