import React, { useState, useEffect, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import { SheduleClientNameForm } from '../components/forms/shedule-client-name-form/SheduleClientNameForm';
import { useClientConfigProvider } from '../../../components/ClientConfigProvider';
import { isHistoryRoot } from '../../../utils/history';
import { scheduleScreenMessages } from '../messages';
import { AppHeaderContext } from '../../../components/AppHeaderProvider';
import { useSchdeuleProvider } from '../../../components/SchdeuleProvider';
import { LeaveMessageEvent } from '../components/leave-message-event/LeaveMessageEvent';
import { useSchdeuleConfigProvider } from '../../../components/ScheduleConfigProvider';
import { isEmptyString } from '../../../utils';
import { useForm } from '../../../components/boutiq-form/hooks/useForm';
import { PageHeader } from '../../../components/page-header/PageHeader';
import { PageFooter } from '../../../components/page-footer/PageFooter';
import './ScheduleWelcome.scss';
import { logger } from '../../../logging';
import {PAGES} from "../../../constants/routes";

export const ScheduleWelcome = ({navigateToNextScheduleScreen}) => {
	const intl = useIntl();
	const history = useHistory();
	const { path } = useRouteMatch();
	const { preventForm } = useLocation();
	const { backFuncRef } = useContext(AppHeaderContext);
	const { scheduleEventTypes, isScheduleLoading } = useSchdeuleProvider();
	const { name, email, setCustomerName, setCustomerEmail, isRescheduling, setSelectedEventType } = useSchdeuleConfigProvider();
	const { hostSelectionConfig, preselectedEventType } = useClientConfigProvider();
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);
	const [isLoadingState, setIsLoadingState] = useState(false);

	const onSubmitSchedulingForm = () => {
		setCustomerName(values.name || name);
		setCustomerEmail(values.email || email || '');
		setIsFormSubmitted(true);
		setIsLoadingState(true);
	}

	const { handleChange, validate, values, errors, handleSubmit } = useForm(onSubmitSchedulingForm, () => { }, false, { name, email });

	useEffect(() => {
		if (!isHistoryRoot(history)) {
			backFuncRef(history.goBack);
		}
	}, [])

	useEffect(() => {
		if (preventForm) {
			setIsFormSubmitted(true);
		}
	}, [preventForm])

	useEffect(() => {
		if (isFormSubmitted) {
			logger.info('schedule form submitted', { isScheduleLoading, hostSelectionConfig, isRescheduling, eventTypesCount : scheduleEventTypes?.length, preselectedEventType })
			if (!isScheduleLoading && hostSelectionConfig.loaded) {
				navigateToNextScheduleScreen(path, {preselectedEventType, isRescheduling, isSelectedEventTypesLoaded: scheduleEventTypes.length > 0});
			}
		}
	}, [isFormSubmitted, isScheduleLoading, isRescheduling, preselectedEventType, hostSelectionConfig])

	return (
		<>
			<PageHeader title={<FormattedMessage {...scheduleScreenMessages.schedule_only_header}
				values={{
					service_noun: intl.formatMessage(scheduleScreenMessages.service_noun)
				}}
			/>} />
			<div className='client-name-form-wrapper'>
				<SheduleClientNameForm
					handleChange={handleChange}
					validateOnBlur={validate}
					values={values}
					errors={errors}
				/>
			</div>
			<PageFooter
				shadow={false}
				onSubmitPrimary={handleSubmit}
				isDisabled={isEmptyString(values.name) || isEmptyString(values.email)}
				isLoading={isLoadingState}
				primaryButtonLabel={<FormattedMessage {...scheduleScreenMessages.cta_next} />}
				secondaryButtonLabel={<LeaveMessageEvent />}
				onSubmitSecondary={() => history.push({
					pathname: PAGES.CONTACT,
					isBackAllowed: true,
					preventSchedule: true
				})} />
		</>
	)
}
