import React from 'react';
import { PropTypes } from 'prop-types';
import './modal.scss';

const Modal = ({ closeFunc, children, modalClass = '', isClosable = false }) => {
  return (
    <div className='modal-overlay'>
      <div className={`modal ${modalClass}`}>
        {isClosable && (
          <button className='close-button' onClick={closeFunc}>
            &#215;
          </button>
        )}
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  closeFunc: PropTypes.func,
  children: PropTypes.node.isRequired,
  modalClass: PropTypes.string,
  isClosable: PropTypes.bool,
};

export default Modal;
