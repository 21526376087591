import React, { useState, useRef, useEffect } from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import { useCustomerDetails } from '../../../components/CustomerDetailsProvider';
import { useClientConfigProvider } from '../../../components/ClientConfigProvider';
import { useIntl, FormattedMessage } from 'react-intl';
import { welcomFormMessages } from './messages';
import ClientNameForm from '../../../components/client-name-form/client-name-form';
import { Loader } from '../../../components';
import { PAGES } from '../../../constants/routes';
import './index.scss';
import {checkPreviousPage} from "../../../utils/history";

const WelcomeForm = ({ isLoading, onStartCall, onStartSchedule, isButtonDisabled }) => {
	const intl = useIntl();
	const history = useHistory();
	const { path } = useRouteMatch();
	const {
		name,
	} = useCustomerDetails();
	const [ formContainerHeght, setFormContainerHeight ] = useState(0);
    const formContainerRef = useRef(null);

	const { isChatEnabled, dynamicConfig } = useClientConfigProvider();

	const openChat = () => {
		history.push(PAGES.CHAT);
	};

	useEffect(() => {
        if (formContainerRef.current) {
            setFormContainerHeight(getComputedStyle(formContainerRef.current).height);
        }
    }, [formContainerRef]);

	const isChatPreviosPage = checkPreviousPage(history, path, PAGES.CHAT);
	const renderForm = () =>
	(
		<>
			{isLoading &&
				<div className='loader-container-wrapper' style={{height: formContainerHeght}}>
					<h3>
						<FormattedMessage
							{...welcomFormMessages.welcome_message}
							values={{
								name: name ? ` ${name}` : '',
								content: intl.formatMessage(welcomFormMessages.welcome_message_content)
							}} />
					</h3>
					<Loader />
				</div>}
			{!isLoading &&
				<div className='form-container-wrapper' ref={formContainerRef}>
					<ClientNameForm
						title={
							<FormattedMessage
								{...welcomFormMessages.welcome_message}
								values={{
									name: name ? ` ${name}` : '',
									content: intl.formatMessage(welcomFormMessages.welcome_message_content)
								}} />}
						primaryButton={<FormattedMessage {...welcomFormMessages.ready} />}
						termsConfig={dynamicConfig.appForms?.videoCall?.terms?.hidden ? null : dynamicConfig.appForms?.videoCall?.terms}
						onClickPrimaryButton={onStartCall}
						isButtonDisabled={isButtonDisabled}
						isPrimaryButtonValidation={dynamicConfig.appForms?.videoCall?.email?.required ?? false} // false if email is optional
						isSecondaryButtonValidation={true}
						secondaryButton={onStartSchedule && !dynamicConfig.appForms?.videoCall?.email?.hidden && !isChatPreviosPage ? <FormattedMessage {...welcomFormMessages.schedule_later} /> : null}
						onClickSecondaryButton={onStartSchedule}
						tertiaryButton={(isChatEnabled && !isChatPreviosPage) ?  <FormattedMessage {...welcomFormMessages.start_chat} /> : null}
						onClickTertiaryButton={openChat}
						emailFormConfig={dynamicConfig.appForms?.videoCall?.email}
					/>
				</div>

			}
		</>
	);

	return (
		renderForm()
	)
};

export default WelcomeForm;
