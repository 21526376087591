import { defineMessages } from 'react-intl';

export const languageSelectMessages = defineMessages({
  choose_language: {
    id: 'languages.choose_language'
  },
  en: {
    id: 'languages.en'
  },
  fr: {
    id: 'languages.fr'
  },
  it: {
    id: 'languages.it'
  },
  de: {
    id: 'languages.de'
  },
  es: {
    id: 'languages.es'
  },
  cta_change: {
    id: 'button_actions.cta_change'
  },
  cta_cancel: {
    id: 'button_actions.cta_cancel'
  },
  default: {
    id: 'languages.default_locale'
  }
});
