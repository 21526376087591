import React from "react";
import './ProductGallery.scss';

export const ProductGallery = ({ currentImages, setSelectedImage }) => {

	return (
		(currentImages && currentImages.length > 0) &&
		<div className='mobile-image-gallery-wrapper'>
			<div className='mobile-image-gallery'>
				{currentImages.length > 1 &&
					currentImages.map((image, i) => (
						<img
							src={image.url}
							onClick={() => setSelectedImage(image)}
							key={image.url}
							alt={`gallery item ${i + 1}`}
						/>
					))}
			</div>
		</div>
	)
}
