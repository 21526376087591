import * as React from "react"

function Trash(props) {
  return (
    <svg viewBox="0 0 512.001 512.001" width={15} height={15} {...props}>
      <path
        d="M75.872 139.062v309.729c0 34.766 28.444 63.21 63.21 63.21h233.832c34.766 0 63.21-28.444 63.21-63.21V139.062H75.872zm107.419 316.011h-31.604V195.912h31.604v259.161zm88.493 0H240.18V195.912h31.604v259.161zm88.494 0h-31.604V195.912h31.604v259.161zM303.389 44.247V0h-94.815v44.247H31.625v63.21h448.751v-63.21z"
        data-original="#000000"
        className="prefix__active-path"
      />
    </svg>
  )
}

export default Trash
