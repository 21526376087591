import React, { useRef } from 'react'
import { Loader } from '../../../../../../../../components';
import SearchIcon from '../../../../../../../../assets/icons/Search';
import CloseIcon from '../../../../../../../../assets/icons/Close';
import './SearchInput.scss';
import '../../../../../animations/Animations.scss'

export const SearchInput = ({
	placeholder,
	onSubmit,
	isLoading
}) => {
	const searchInputRef = useRef(null);

	const handleKeyUp = (e) => {
		if (e.keyCode === 13) {
			if (searchInputRef.current) {
				searchInputRef.current.blur();
				onSubmit(searchInputRef.current.value);
			}
		}
	};

	const clearSearchQuery = () => {
		if (searchInputRef.current) {
			searchInputRef.current.value = '';
			searchInputRef.current.blur();
			onSubmit()
		}
	}

	return (
		<span id='search-item-input' className='mobile-search-input'>
			<SearchIcon color='gray' size={24}/>
			<input
				ref={searchInputRef}
				className={`mobile-search-field ${isLoading ? 'mobile-loading-overflow' : ''}`}
				type='search'
				enterkeyhint='search'
				placeholder={placeholder}
				onKeyUp={handleKeyUp}
			/>
			{!isLoading && searchInputRef.current && searchInputRef.current.value && <button onClick={() => clearSearchQuery()} className='mobile-clear-search-button'><CloseIcon  />
			</button>}
			{isLoading && <Loader height='14px' width='14px' name={null} hasText={false} />}
		</span>
	)
}
