import React from 'react';
import Cart from '../cart/cart';
// import { tabLabels } from '../../constants/menuDrawer'
import { ShowRoom } from '../showroom/showroom';
import './panel.scss';
import { tabLabels } from '../../../../../constants/menuDrawer';

export const VideoRoomPanel = ({ view, isCheckoutComplete, setCheckoutComplete }) => {

  const renderContent = () => {
    if (view === tabLabels.SHOWROOM) {
      return (
        <ShowRoom
          isCheckoutComplete={isCheckoutComplete}
          setCheckoutComplete={setCheckoutComplete}
        />
      );
    }
    if (view === tabLabels.CART) {
      return (
        <Cart />
      );
    }
    return null;
  };

  return (
    <div className='video-room-panel' >
      {renderContent()}
    </div>
  );
};
