import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import TextareaAutosize from "react-textarea-autosize";
import AddAPhotoIcon from '../../../../assets/icons/AddAPhoto';
import SendIcon from '../../../../assets/icons/Send';
import { useSelector } from "react-redux";
import { isMobile } from 'react-device-detect';

import ChatHeader from "../../../../components/chatHeader";
import { chatScreenMessages } from "../../messages";
import { useActiveRoomData } from "../../../../modules/chat/hooks/useActiveRoomData";
import ChatView from "../../../../components/chatView";
import useMessagesRequests from "../../../../modules/chat/api/messages/requests";
import useFeatureRequests from "../../../../modules/chat/api/features/requests";
import {MSG_TYPES, TYPING_TIME} from "../../../../modules/chat/constants/messaging";
import { buildUserEntity } from "../../../../modules/chat/utils/auth";
import { ROOM_STATUS } from "../../../../modules/chat/constants/roomStatuses";
import "./styles.scss";
import { ROLES } from "../../../../modules/chat/constants/participantRoles";
import { useCustomerDetails } from "../../../../components/CustomerDetailsProvider";
import {getImageParams, resizeFile} from "../../../../modules/chat/utils/images";
import {IMAGE_MAX_LENGTH, IMAGE_MAX_SIZE} from "../../../../modules/chat/firebaseInitialization";
import { sendChatEvent } from '../../../../utils/analytics-cross-domain';
import useMetadataRequests from "../../../../modules/chat/api/metadata/requests";
import { logger } from "../../../../logging";

const Messenger = ({ user, initChatRoom = null, videoChatAction = null, roomMessagesDisplayed = true,
                     setFullSizeChatImage = null, videoInitiated = false }) => {
  const room = useSelector(state => state.activeRoom);
  const { name, email } = useCustomerDetails();
  const currentUser = buildUserEntity({ ...user, displayName: name, email });
  const intl = useIntl();
  let typingRef = useRef(null);
  const fileInputRef = useRef(null);

  const userEntity = { ...currentUser, role: ROLES.PARTICIPANT };

  const { sendMessage, sendFile } = useMessagesRequests({ user: userEntity, recipient: { id: room?.activeHost?.uuid } });
  const { seenMessages } = useMetadataRequests({ user: userEntity});
  const { updateTypingStatus } = useFeatureRequests({ userUuid: user.uid });
  useActiveRoomData(user, room, null, roomMessagesDisplayed);

  const [message, setMessage] = useState('');
  const [isTyping, setTyping] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const lastRoomMessage = room && room.metadata && room.metadata.lastMessage && room.metadata.lastMessage;
  const roomStatus = room && room.status;
  const roomStatusUpdateAt = room && room.statusUpdatedAt;
  const roomId = room && room.id;

  useEffect(() => {
    updateTypingStatus({ typing: isTyping });
  }, [isTyping]);

  useEffect(() => {
      logger.info('Starting chat UI', { id: roomId });
  }, [roomId])

  // if last message in chat is from this user while chat is archived while messenger UX is open, ask server to
  // restart chat in background
  useEffect(() => {
    if (lastRoomMessage && roomStatusUpdateAt) {
      if (roomStatus === ROOM_STATUS.ARCHIVED && lastRoomMessage.owner.uuid === user.uid &&
        new Date(lastRoomMessage.timestamp) > new Date(roomStatusUpdateAt) && !!initChatRoom) {
        initChatRoom(false /* withWelcome */).catch(error=>{
          console.error('Error reiniting archived chat',error);
        });
      }
    }

  }, [lastRoomMessage, roomStatus, roomStatusUpdateAt])

  const updateTyping = () => {
    if (!isTyping) {
      setTyping(true)
    }

    clearTimeout(typingRef.current);
    typingRef.current = setTimeout(() => setTyping(false), TYPING_TIME)
  }

  const updateMessage = (e) => {
    updateTyping();
    setMessage(e.target.value);
  };

  const sendNewMessage = () => {
    if (message) {
      setMessage('');
      sendMessage({ msg: message });
      sendChatEvent('chat_message_sent','text');
    }
  };

  const sendNewImage = async (event) => {
    const file = event.target.files[0];
    const {width, height} = await getImageParams(file);

    if (file.size > IMAGE_MAX_SIZE || width > IMAGE_MAX_LENGTH || height > IMAGE_MAX_LENGTH) {
      file.data = await resizeFile(file);
      file.isBase64 = true;
    }

    await sendFile({
      file,
      chatRoomId: room.id,
      type: MSG_TYPES.IMAGE
    });
    sendChatEvent('chat_message_sent','image');
  };

  const openGalleryDialog = () => {
    fileInputRef.current.click();
  };

  return (
    <div className='messenger_container'>
      <ChatHeader
        room={room}
        videoChatAction={videoChatAction}
        seenMessages={seenMessages}
        scrollPosition={scrollPosition}
      />
      <ChatView
        user={user}
        setFullSizeChatImage={setFullSizeChatImage}
        videoInitiated={videoInitiated}
        seenMessages={seenMessages}
        roomMessagesDisplayed={roomMessagesDisplayed}
        scrollPosition={scrollPosition}
        setScrollPosition={setScrollPosition}
      />
      <div className={`messenger_controls${isMobile ? '' : ' messenger_controls_client_radius-styles'}`}>
        <div className='messenger_controls_send-image' onClick={openGalleryDialog}>
          <AddAPhotoIcon size={24} color='main' />
        </div>
        <TextareaAutosize
          placeholder={intl.formatMessage(chatScreenMessages.message)}
          onChange={updateMessage}
          value={message}
          className='messenger_controls_input'
          minRows={1}
          maxRows={2}
          cacheMeasurements={true}
          onFocus={seenMessages}
        />
        <div className='messenger_controls_send' onClick={sendNewMessage}>
          <SendIcon size={24} />
        </div>
        <input
          className='messenger_controls_file-input'
          ref={fileInputRef}
          onChange={sendNewImage}
          type="file"
          accept="image/*"
          value=""
        />
      </div>
    </div>
  );
};

export default Messenger;
