import React, { useRef, useEffect, useState } from 'react';
import { currencyFormatter } from '../../utils';
import EmptyImage from '../emptyImage';
import './styles.scss';

export const ProductDetails = ({
	selectedImage,
	showFullSizedImageView,
	currentImages,
	setSelectedImage,
	product,
	selectedProductVariant,
	showFullSizedMobileDescription,
	renderActions,
	imagesRefs,
}) => {
	const [allowedContainerHeight, setAllowedContainerHeight] = useState(null);
	const mainImageRef = useRef(null);

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
			setAllowedContainerHeight(null);
		};
	}, [product]);

	const handleResize = () => {
		if (mainImageRef.current) {
			setAllowedContainerHeight(mainImageRef.current.clientHeight);
		}
	}

	const handleDescriptionBlock = (imageHeight) => {
		let descriptionHeight;
		if (imageHeight) {
			
				descriptionHeight = `${imageHeight}px`
		} else {
			
				descriptionHeight = '150px'
		}
		return descriptionHeight;
	}

	const renderCurrentPrice = (product) => {
		if (selectedProductVariant) {
			return (
				<>
					<h4 id='item-price'>
						{currencyFormatter(parseFloat(selectedProductVariant.price), selectedProductVariant.currencyCode)}
					</h4>
					<h4 className='old-price'>
						{selectedProductVariant.compareAtPrice &&
							currencyFormatter(
								selectedProductVariant.compareAtPrice,
								selectedProductVariant.currencyCode)}
					</h4>
				</>
			)
		}
		if (product.maxVariantPrice !== product.minVariantPrice) {
			return (
				<>
					<h4>
						{`${currencyFormatter(product.minVariantPrice, product.currencyCode)} — ${currencyFormatter(product.maxVariantPrice, product.currencyCode)}`}
					</h4>
				</>
			)
		} else {
			return (
				<>
					<h4>
						{currencyFormatter(parseFloat(product.price), product.currencyCode)}
					</h4>
					<h4 className='old-price'>
						{product.compareAtPrice && product.compareAtPrice !== product.price
							? currencyFormatter(
								parseFloat(product.compareAtPrice),
								product.currencyCode)
							: null}
					</h4>
				</>
			)
		}
	}

	const renderImages = () => {
		if (!currentImages || !currentImages.length) {
			return <EmptyImage 
				product={product} 
				size='xl' 
				viewItem={showFullSizedMobileDescription} />;
		}
		return (
			<img
				className='main-image'
				ref={mainImageRef}
				src={selectedImage ? selectedImage.url : ''}
				alt='main'
				onClick={showFullSizedImageView}
				onLoad={() => handleResize()}
			/>
		);
	};

	return (
		<div className='product-details-description-wrapper' >
			<div className='main-image-wrapper'>
				{renderImages()}
			</div>
			<div style={{ height: handleDescriptionBlock(allowedContainerHeight) }}
				className={`product-details`}>
				<p className='product-title'>
					<strong>{product.title}</strong>
				</p>
				<ProductDescritionContainer
					showFullSizedMobileDescription={showFullSizedMobileDescription}
					allowedContainerHeight={allowedContainerHeight}
					descriptionHtml={product.descriptionHtml} />
				<div className='price-container'>
					{renderCurrentPrice(product)}
				</div>
			</div>
			<div style={{ height: 'auto' }} className='image-gallery'>
				{currentImages.length > 1 &&
					currentImages.map((image, i) => (
						<img
							ref={(ref) => imagesRefs.current.push({ id: image.id, ref })}
							src={image.url}
							onClick={() => setSelectedImage(image)}
							key={image.url}
							alt={`gallery item ${i + 1}`}
						/>
					))}
			</div>
			{renderActions()}
		</div>
	)
}

const ProductDescritionContainer = ({ allowedContainerHeight, showFullSizedMobileDescription, descriptionHtml }) => {
	const producDescriptionRef = useRef(null);
	const producDescriptionContainerRef = useRef(null);
	const [isOverflowed, setIsOverflowed] = useState(false);

	const handleClick = (event) => {
		if (event.nativeEvent.target) {
			event.stopPropagation();
			event.preventDefault();
			showFullSizedMobileDescription();
		}
	}

	useEffect(() => {
		const containerHeight = producDescriptionContainerRef.current.clientHeight;
		const contentHeight = producDescriptionRef.current.clientHeight;
		setIsOverflowed(containerHeight < contentHeight)
	}, [allowedContainerHeight, descriptionHtml])

	return (
		<div ref={producDescriptionContainerRef} onClick={showFullSizedMobileDescription} className={`product-description-wrapper${isOverflowed ? ' overflow-background' : ''}`}>
			<div ref={producDescriptionRef} className='product-description'>
				<div onClick={handleClick} dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
			</div>
			{isOverflowed && <span className='more-button'>more</span>}
		</div>
	)
}
