import React, { createContext, useContext, useState, useEffect } from 'react';
import { parseIdString } from '../utils';
import { useProducts } from '../hooks';
import usePresentationMode from "../hooks/usePresentationMode";

export const ProductViewContext = createContext(null);

export default function ProductViewProvider({ shopId, callId, callData, openSidebar, children, }) {

  const [productViewData, setProductViewData] = useState({});

  const {presentedImage, isPresentationEnabled} = usePresentationMode(callId);
  const [isPresentationModeReady, setIsPresentationModeReady] = useState(false);

  const { getCachedProduct, isProductLoading } = useProducts(
    shopId,
    callId,
    callData.customerLocale,
    callData.countryCode,
  );

  useEffect(() => {
    if (isPresentationEnabled && presentedImage) {
      (async function fetchPresentedProduct() {
        const presentedProduct = await getCachedProduct(presentedImage.productId);
        const presentedEntity = {
          product: presentedProduct,
          presentedVariantId: presentedImage.variantId,
          presentedImage: isPresentationEnabled ? presentedImage.imageId : null,
          presentedProductId: presentedImage.productId,
          cartKey: presentedImage.cartKey,
        };
        if (presentedImage.cartKey) {
          presentedEntity.cartKey = presentedImage.cartKey;
          presentedEntity.cartData = {
            variantId: presentedImage.variantId
          };
        } else if (presentedImage.selectedOptions) {
          presentedEntity.presentedVariantOptions = presentedImage.selectedOptions;
        }
        setProductViewData(presentedEntity);
        setIsPresentationModeReady(true);
        openSidebar();
      })();
    } else if (productViewData) {
      setProductViewData({...productViewData, presentedImage: null, presentedProductId: null, variantId: null});
      setIsPresentationModeReady(false);
    }
  }, [isPresentationEnabled, presentedImage]);

  const viewProduct = async (productId, showroomKey = null, showroomData = null) => {
    const cleanProductId = parseIdString(productId);
    const product = await getCachedProduct(cleanProductId);
    setProductViewData({ product, showroomKey, showroomData  });
    openSidebar();
  };

  const viewCartProduct = async (cartKey, cartData) => {
    const product = await getCachedProduct(cartData.productId);
    setProductViewData({ product, cartKey, cartData });
    openSidebar();
  };

  const clearSelectedProduct = () => {
    setProductViewData({});
  };

  return <ProductViewContext.Provider value={{
    productViewData,
    isProductLoading,
    viewProduct,
    clearSelectedProduct,
    viewCartProduct,
    getCachedProduct,
    isPresentationEnabled,
    isPresentationModeReady
  }}>
    {children}
  </ProductViewContext.Provider>
}

export function useProductViewProvider() {
  const context = useContext(ProductViewContext);
  if (!context) {
    throw new Error('useProductViewProvider must be used within the scope of ProductViewContext');
  }
  return context;
}
