import React, { useState, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { isEmail, isEmptyString } from '../../utils';
import { useCustomerDetails } from '../CustomerDetailsProvider';
import { StyledInput } from '../inputs/inputs';
import { formMessages } from './messages';
import { FilledButton } from '../boutiq-buttons/BoutiqButton';
import './index.scss';

const ClientNameForm = ({
    title,
    subTitle,
    primaryButton,
    onClickPrimaryButton,
    onClickSecondaryButton,
    secondaryButton,
    isSecondaryButtonValidation,
    isButtonDisabled,
    isPrimaryButtonValidation = true,
    tertiaryButton = null,
    onClickTertiaryButton = null,
    emailFormConfig = { hidden: false, required: false },
    termsConfig
}) => {
    const intl = useIntl();
    const {
        name,
        email,
        setCustomerName,
        setCustomerEmail,
    } = useCustomerDetails();
    const [formData, setFormData] = useState({
        name: name || '',
        email: email || '',
    });
    const [nameError, setNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [termsError, setTermsError] = useState(null);
    const [isTermsSubmitted, setIsTermsSubmitted] = useState(false);

    const setNameValue = (e) => {
        const newFormData = { ...formData, name: e.target.value };
        setFormData(newFormData);
        setNameError(null);
    };

    const setEmailValue = (e) => {
        const newFormData = { ...formData, email: e.target.value };
        setFormData(newFormData);
        setEmailError(null);
    };

    const validateForm = (withEmail = false, validateTerms = false) => {
        if (isEmptyString(formData.name)) {
            setNameError(formMessages.name_error);
            return false;
        } else if (withEmail & !formData.email) {
            setEmailError(formMessages.email_error);
            return false;
        } else if (formData.email && !isEmail(formData.email)) {
            setEmailError(formMessages.email_invalid);
            return false;
        } else if (validateTerms) {
            if (termsConfig && !isTermsSubmitted) {
                setTermsError(formMessages.terms_error);
                return false;
            }
        }

        setNameError(null);
        setEmailError(null);
        setTermsError(null);
        return true;
    };

    const setCustomerData = (data) => {
        setCustomerName(data.name);
        setCustomerEmail(data.email);
    }

    const onSubmit = () => {
        const isFormValid = validateForm(isPrimaryButtonValidation, true);
        if (isFormValid) {
            setCustomerData(formData);
            onClickPrimaryButton(formData);
        }
    };

    const onSecondarySubmit = () => {
        if (isSecondaryButtonValidation) {
            const isFormValid = validateForm(true);
            if (isFormValid) {
                setCustomerData(formData);
                onClickSecondaryButton(formData)
            }
        } else {
            onClickSecondaryButton(formData)
        }
    };

    const handleChange = (e, openLink) => {
        if (openLink) {
            e.stopPropagation();
            return;
        }
        setIsTermsSubmitted(!isTermsSubmitted)
    }

    return (
        <div className={`form-container${emailFormConfig.hidden ? ' name-field-only' : ''}${termsConfig?.hidden ? '' : ' terms'}`}>
            <form autoComplete='off'>
                {title && <h3 className='form-header-title'>
                    {title}
                </h3>}
                {subTitle && <p>
                    {subTitle}
                </p>}
                <StyledInput
                    placeholder={intl.formatMessage(formMessages.name) + intl.formatMessage(formMessages.required)}
                    onChange={setNameValue}
                    name='name'
                    value={formData.name}
                />
                {!emailFormConfig.hidden && <StyledInput
                    placeholder={intl.formatMessage(formMessages.email) + `${!emailFormConfig.required ? `` : intl.formatMessage(formMessages.required)}`}
                    value={formData.email}
                    onChange={setEmailValue}
                    name='email'
                    type='email'
                />}
                {termsConfig &&
                    <div className='boutiq-checkbox-button-group'>
                        <label className='checkbox-option-label'>
                            <input
                                type="checkbox"
                                checked={isTermsSubmitted}
                                onChange={() => handleChange()}
                            />
                            <span onClick={(e) => handleChange(e, true)} className='option-value' dangerouslySetInnerHTML={{ __html: termsConfig?.label }}></span>
                        </label>
                    </div>}
                <div className='actions-container'>
                    {primaryButton && <FilledButton
                        id='ready-mockup-button'
                        onClick={onSubmit}
                        className='wtr-connect-btn'
                        disabled={isButtonDisabled}>
                        {primaryButton}
                    </FilledButton>}
                    {secondaryButton && <span className='secondary-button' onClick={onSecondarySubmit}>
                        {secondaryButton}
                    </span>}
                </div>
            </form>

            {nameError ? (
                <p className='error-message'>
                    <FormattedMessage {...nameError} />
                </p>
            ) : emailError ? (
                <p className='error-message'>
                    <FormattedMessage {...emailError} />
                </p>
            ) : termsError ? (
                <p className='error-message'>
                    <FormattedMessage {...termsError} />
                </p>
            ) : (
                <div className='error-message-warning' />
            )}

            {tertiaryButton &&
                <span className='chat-link' onClick={onClickTertiaryButton}>
                    {tertiaryButton}
                </span>
            }

        </div>

    )
};

export default ClientNameForm;
