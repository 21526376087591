import React, { useState, useRef, useMemo } from 'react'
import styles from './styles.scss'

const Zoom = ({height = 0, img = null, transitionTime = 0.1, width = 0, zoomScale = 3}) => {
  const imageRef = useRef(null);
  const [zoom, setZoom] = useState(false);
  const [mouseX, setMouseX] = useState(false);
  const [mouseY, setMouseY] = useState(false);

  const innerDivStyle = useMemo(() => ({
    height: `${height}px`,
    maxHeight: '500px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '100% auto',
    transition: `transform ${transitionTime}s ease-out`,
    backgroundImage: `url('${img}')`,
  }), [img]);

  const outerDivStyle = useMemo(() => ({
    height: `${height}px`,
    maxHeight: '500px',
    width: `${width}px`,
    overflow: 'hidden',
  }), [height, width]);

  const handleMouseOver = () => setZoom(true);

  const handleMouseOut = () => setZoom(false);

  const handleMouseMovement = (e) => {
    const {left: offsetLeft, top: offsetTop} = imageRef.current.getBoundingClientRect()

    const { current: { style: {height, width}}} = imageRef;

    const x = ((e.pageX - offsetLeft) / parseInt(width, 10)) * 100
    const y = ((e.pageY - offsetTop) / parseInt(height, 10)) * 100

    setMouseX(x);
    setMouseY(y);
  }

  return (
    <div
      style={outerDivStyle}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onMouseMove={handleMouseMovement}
      ref={imageRef}
    >
      <div
        style={{
          ...innerDivStyle,
          transformOrigin: `${mouseX}% ${mouseY}%`,
          transform: zoom ? `scale(${zoomScale})` : 'scale(1.0)',
        }}
        className={styles.zoomImg}
      />
    </div>
  )
}

export default Zoom;
