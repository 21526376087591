import React, {useEffect, useState, useRef} from 'react';
import {FormattedMessage} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import './styles.scss';
import {chatScreenMessages} from '../../pages/chat-page/messages';
import ActiveHostAvatars from '../../pages/intro/activeHostAvatars/activeHostAvatars';
import { checkMobileOnly } from '../../utils';
import { useClientConfigProvider } from '../../components/ClientConfigProvider';
import ChatUserIcon from '../chatUserIcon';
import StartVideoCall from "../../assets/icons/StartVideoCall";
import ArrowBackIcon from '../../assets/icons/ArrowBack';
import CloseIcon from '../../assets/icons/Close';
import {showStartVideoCallMessage} from "../../modules/chat/actions/messages";
import {ArrowLeft} from "../../assets/icons/ArrowLeft";

const MAX_NUMBER_OF_AVATARS = 3;

const ChatHeader = ({room = null, error = false, videoChatAction = null, seenMessages, scrollPosition}) => {
  const chatHost = room && room.activeHost && room.activeHost.uuid && room.participants[room.activeHost.uuid];
  const hasActiveStatus = chatHost ? room.status === 'active' : false;
  const history = useHistory();
  const dispatch = useDispatch();
  const {isShowStartVideoCallMessage} = useSelector(state => state.chatMessages);
  const { hostAvatars, dynamicConfig } = useClientConfigProvider();
  const isMobile = useRef(checkMobileOnly());
  const chatHeaderAvatars = hostAvatars.slice(0, MAX_NUMBER_OF_AVATARS ? MAX_NUMBER_OF_AVATARS : hostAvatars.length);

  const hostAvatarsDisplayed = dynamicConfig && dynamicConfig.hostAvatarsDisplayed;

  const navigateBack = () => {
    history.goBack();
  };

  const suggestVideoCall = () => {
    if (!isShowStartVideoCallMessage) {
      dispatch(showStartVideoCallMessage(true));
    }
  }

  const getStyle = () => {
    if (!!videoChatAction) {
      if (isMobile.current) {
        return {marginLeft: '25px'};
      } else {
        return {marginLeft: '30px'};
      }
    } else {
      if (isMobile.current) {
        return {marginLeft: '55px'};
      } else {
        return {marginLeft: '70px'};
      }
    }
  };

  const closeVideoChat = () => {
    if (!!videoChatAction) {
      if (scrollPosition === 0) {
        seenMessages()
          .finally(() => {
            videoChatAction();
          })
      } else {
        videoChatAction();
      }
    }
  };

  if (error) {
    return (
      <div className='chat-header_container'>
        <span className='back-btn' style={isMobile.current ? {left: '5px'} : {left: '12px'}} onClick={navigateBack}>
          <ArrowBackIcon size={24} color='black44' />
        </span>
        <span className='chat-header_title-error' style={isMobile.current ? {marginLeft: '45px'} : {marginLeft: '76px'}}>
          <FormattedMessage {...chatScreenMessages.chatErrorHeader} />
        </span>
        {/* <div className='chat-header_menu_btn' style={isMobile.current ? {right: '5px'} : {right: '65px'}}>
          <MoreVertIcon className='chat-header_menu_btn_dots-icon'/>
        </div> */}
      </div>
    )
  }

  return (
    <div className={`chat-header_${!!videoChatAction ? 'video-' : ''}container`}>
      {!videoChatAction &&
        <span className={`back-btn${isMobile.current ? '-mobile' : ''}`} onClick={navigateBack}>
          <ArrowLeft size='24_20'/>
        </span>
      }
      {(chatHost && hasActiveStatus)
          ? (<div
              className='chat-header_avatars'
              style={getStyle()}
            >
            <ChatUserIcon user={chatHost} type={'header'} />
          </div>)
          : (<div
              className='chat-header_avatars'
              style={getStyle()}
            >
            {hostAvatarsDisplayed && chatHeaderAvatars.length > 0 &&
              <ActiveHostAvatars avatars={chatHeaderAvatars} size='sm'/>}
          </div>)
      }
      <span className={`chat-header_title_${chatHost ? 'host' : 'room'} text-ellipsis ${!(chatHost && hasActiveStatus) && 'short-title'}`}>
        {(chatHost && hasActiveStatus)
          ? <FormattedMessage {...chatScreenMessages.chatHost} values={{name: chatHost.displayName || chatHost.profileInitials }} />
          : <FormattedMessage {...chatScreenMessages.chatOurRoom} />
        }
      </span>
      {!!videoChatAction &&
        <span
          className={`close-button${isMobile.current ? '-mobile': ''}`}
          onClick={closeVideoChat}
        >
          <CloseIcon size={20} color='black4'/>
        </span>
      }
      {
        room && !videoChatAction &&
          <span className={`video-btn${isMobile.current ? '-mobile' : ''}`} onClick={suggestVideoCall}>
            <StartVideoCall color='black' size={25} className='start-video-call__icon'/>
          </span>
      }
      {/* <div className='chat-header_menu_btn' style={isMobile.current ? {right: '5px'} : {right: '65px'}}>
        <MoreVertIcon className='chat-header_menu_btn_dots-icon'/>
      </div> */}
    </div>
  );
};

export default ChatHeader;
