import { defineMessages } from 'react-intl';

export const welcomFormMessages = defineMessages({
  ready: {
    id: 'button_actions_cta_imready',
  },
  welcome_message: {
    id: 'messages.welcome_message'
  },
  welcome_message_content: {
    id: 'messages_welcome_message_content',
  },
  getting_ready: {
    id: 'messages_welcome_getting_ready',
  },
  getting_ready_minor: {
    id: 'messages_welcome_getting_ready_minor',
  },
  schedule_later: {
    id: 'messages_schedule_later',
  },
  start_chat: {
    id: 'button_actions_chat_with_team',
  },
  name: {
    id: 'nouns_name'
  },
  email: {
    id: 'nouns.email'
  },
  cta_schedule: {
    id: 'button_actions.cta_schedule'
  },
  name_error: {
    id: 'messages_name_error'
  },
  email_error: {
    id: 'messages.email_error'
  },
  email_invalid : {
    id: 'messages.email_invalid'
  },
  service_noun: {
    id: 'nouns_service'
  }
});
