import React, { useRef, useEffect, createContext, useContext } from 'react';
import { v4 as uuidv4 } from "uuid";
import { logger } from '../logging';

export const MESSAGE_APP_NAME = 'caazamApp';

export const REQ_HEADER_TYPES = {
    CLOSE_CAAZAM: 'closeCaazam',
    RESET_APP_STATE: 'resetAppState',
    SHRINK_CAAZAM: 'shrinkCaazam',
    EXPAND_CAAZAM: 'expandCaazam',
    RECENTLY_VIEWED: 'reqRecentlyViewed',
    SCHEDULING_SUCCESS: 'schedulingSuccess',
    VIDEO_CALL_REQUESTED: 'videoCallRequested',
    VIDEO_CALL_CONNECTED: 'videoCallConnected',
    VIDEO_CALL_DISCONNECTED: 'videoCallDisconnected',
    ADD_TO_CART: 'reqAddToCart',
    REMOVE_CART_PRODUCT: 'reqRemoveCartProduct',
    UPDATE_CART_DISCOUNT: 'reqUpdateCartDiscount',
    UPDATE_CART_PRODUCT: 'reqUpdateCartProduct',
    GET_CART_PRODUCTS: 'reqGetCartProducts',
    UPDATE_CART_ATTRIBUTE: 'reqUpdateCartAttribute',
    CHECKOUT_STARTED: 'checkoutStarted',
    SHOPIFY_CONTEXT: 'reqShopifyContext',
    CLIENT_INITIALIZED: 'caazamClientInitialized',
}

export const RES_HEADER_TYPES = {
    RECENTLY_VIEWED_RES: 'resRecentlyViewed',
    GET_CART_PRODUCTS_RES: 'resGetCartProducts',
    SHOPIFY_CONTEXT: 'resShopifyContext',
    CART_ACTIONS: 'resCartActions',
    CLOSE_CAAZAM_CLIENT_APP: 'reCloseCaazamClientApp',
}

export const PUBLISH_HEADERS = {
    CONFIG_UPDATE: 'configUpdate',
    CLOSE_CLIENT_APP: 'closeClientApp',
}

export const InterWindowMessageContext = createContext(null);

export default function InterWindowMessageProvider({
    onMessageConfigReceived,
    onMessageClientAppClose,
    children,
    targetOriginUrl,
    configLoading,
}) {
    const responseCallbacks = useRef({});

    //const shopifyWindowRef = window.parent === window.self ? window.opener : window.parent;
    const shopifyWindowRef = window.parent; // we are assuming the app's origin is same as the "hosting" shopify page's origin (no cross domain)

    const postMessage = (type, payload, callback) => {
        let messageId = uuidv4();
        if (callback) {
            responseCallbacks.current[messageId] = callback;
        }
        shopifyWindowRef && shopifyWindowRef.window.postMessage({ app: MESSAGE_APP_NAME, type, payload, messageId }, targetOriginUrl);
    }

    const messageReceiver = (e) => {
        if (e.origin !== targetOriginUrl && e.origin !== window.location.origin)
            return;

        if (process.env.REACT_APP_ALLOW_DEV_MODE && e?.data?.type === 'INIT_INSTANCE') {
            postMessage(REQ_HEADER_TYPES.CLIENT_INITIALIZED);
            return;
        }
        if (typeof e.data === 'object' &&
            e.data.app &&
            e.data.app === MESSAGE_APP_NAME
        ) {
            switch (e.data.type) {
                case PUBLISH_HEADERS.CONFIG_UPDATE:
                    onMessageConfigReceived && onMessageConfigReceived(e.data.payload);
                    break;
                case PUBLISH_HEADERS.CLOSE_CLIENT_APP:
                    onMessageClientAppClose && onMessageClientAppClose();
                    break;
                default:
                    let callback = responseCallbacks.current[e.data.messageId]
                    if (callback) {
                        callback(e.data.payload);
                        delete responseCallbacks.current[e.data.messageId];
                    }
                    break;
            }

        }
    }

    useEffect(() => {
        window.self.addEventListener('message', messageReceiver);
        logger.info('messageReceiver handler activated for source/target:', targetOriginUrl);
        logger.info('shopifyWindowRef', {
            parent: window.parent !== window.self,
            opener: !!window.opener,
        });
        logger.info(`Sending ${REQ_HEADER_TYPES.CLIENT_INITIALIZED}`);
        postMessage(REQ_HEADER_TYPES.CLIENT_INITIALIZED);
        return () => {
            window.self.removeEventListener('message', messageReceiver);
            logger.info('messageReceiver handler removed');
        }
    }, [targetOriginUrl]);

    return <InterWindowMessageContext.Provider value={{
        postMessage,
    }}>
        {!configLoading && children}
    </InterWindowMessageContext.Provider>
}

export function useInterWindowMessageProvider() {
    const context = useContext(InterWindowMessageContext);
    if (!context) {
        throw new Error('useInterWindowMessageProvider must be used within the scope of InterWindowMessageContext');
    }
    return context;
}
