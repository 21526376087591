import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSchdeuleProvider } from '../../../../components/SchdeuleProvider';
import { useSchdeuleConfigProvider } from '../../../../components/ScheduleConfigProvider';
import { scheduleScreenMessages } from '../../messages';
import './SelectedHostInfoRow.scss';
import {useRouteMatch} from "react-router-dom";

export const SelectedHostInfoRow = ({ isChangeAvailable = true, navigateToSelectHost }) => {

    const { hostIdForScheduling } = useSchdeuleConfigProvider();
    const { sessionHosts } = useSchdeuleProvider();
    const intl = useIntl();
    const { path } = useRouteMatch();

    const selectedHostName = () => {
		const selectedHost = sessionHosts.find(host => host.hostId === hostIdForScheduling);
		if (selectedHost) {
			return selectedHost.displayName;
		} else {
			return intl.formatMessage(scheduleScreenMessages.any_host);
		}
	}

    return (
        <div className='selected-host-additional-data'>
            <p className='selected-host-for-appointment'>
                <FormattedMessage
                    {...scheduleScreenMessages.selected_host}
                    values={{ host_noun: intl.formatMessage(scheduleScreenMessages.host_noun) }} />
                        <span className='scheduled-host-name'>{` ${selectedHostName()}`}</span>
            </p>
            {isChangeAvailable &&
                <button
                    onClick={navigateToSelectHost}
                    className='change-selected-host-button'>
                        <FormattedMessage {...scheduleScreenMessages.cta_change} />
                </button>}
        </div>
    )
}

