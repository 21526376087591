import React from 'react';

export const ArrowLeft = () =>
    <svg width="24px" height="20px" viewBox="0 0 24 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="client-flow---Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g id="select-assistant-(scheduled-call)" transform="translate(-508.000000, -189.000000)" stroke="#3F3F3F" strokeWidth="2">
                <g id="Group-9" transform="translate(480.000000, 159.000000)">
                    <g id="Group-3" transform="translate(24.000000, 24.000000)">
                        <g id="ArrowLeft" transform="translate(5.000000, 7.000000)">
                            <line x1="22" y1="9" x2="0" y2="9" id="Path"></line>
                            <polyline id="Path" points="9 0 0 9 9 18"></polyline>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
