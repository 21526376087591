import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import { useDeviceSelectors } from 'react-device-detect';
import { waitingRoomMessages } from '../../../messages';
import { useClientConfigProvider } from '../../../../../../components/ClientConfigProvider'
import { isHistoryRoot } from '../../../../../../utils/history';
import { VIDEO_CALL_PAGES } from '../../../../../../components/VideoCall';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FilledButton } from '../../../../..';
import InputWithCopy from '../../../../../../components/input-with-copy/InputWithCopy';
import './BrowserCompabilityModal.scss';

export const BrowserCompabilityModal = () => {
    const [deviceSelectors] = useDeviceSelectors();
    const { isIOS, isAndroid, browserName } = deviceSelectors;
    const { sourceUrl } = useClientConfigProvider()
    const history = useHistory();
    const { path } = useRouteMatch();
    const Intl = useIntl();

    const navigateBack = () => {
        history.goBack();
    };

    const SUPPORTED_BROWSERS_LIST = {
        IOS: `Safari ${Intl.formatMessage({ ...waitingRoomMessages.conjunction_or })} Chrome`,
        ANDROID: 'Chrome',
    }

    const copyStrings = {
        copy: Intl.formatMessage({ ...waitingRoomMessages.verb_copy }),
        copied: Intl.formatMessage({ ...waitingRoomMessages.verb_copy_past }),
    }

    return (
        <div className='browser-compatibility-modal-wrapper'>
            <div className='browser-compatibility-modal-container'>
                <div className='browser-compatibility-modal-body'>
                    <h3 className='browser-compatibility-modal-title'>
                        <FormattedMessage {...waitingRoomMessages.browser_media_not_supported} values={{ browserName, }} />
                    </h3>
                    {isIOS && <div className='browser-compatibility-modal-description'>
                        <FormattedMessage {...waitingRoomMessages.browser_media_not_supported_ios} />{'. '}
                        <FormattedMessage {...waitingRoomMessages.browser_copy_paste_action} values={{ supported_browser_list: SUPPORTED_BROWSERS_LIST.IOS }} />
                    </div>}
                    {isAndroid && <div className='browser-compatibility-modal-description'>
                        <FormattedMessage {...waitingRoomMessages.browser_media_not_supported_android} />{'. '}
                        <FormattedMessage {...waitingRoomMessages.browser_copy_paste_action} values={{ supported_browser_list: SUPPORTED_BROWSERS_LIST.ANDROID }} />
                    </div>}
                    <InputWithCopy inputText={sourceUrl} strings={copyStrings}/>
                </div>
                {!isHistoryRoot(history)
                    && history.location.pathname !== `${path}/${VIDEO_CALL_PAGES.VIDEO_LOBBY}` &&
                    <FilledButton style={{marginTop: 20}} onClick={navigateBack}>
                        <FormattedMessage {...waitingRoomMessages.cta_ok} />
                    </FilledButton>
                }
            </div>
        </div>
    )
}
