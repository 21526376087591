
import React, { useEffect, useState } from 'react';
import Firebase from '../Firebase'
import {getDatabase, ref, child, off, onValue} from 'firebase/database';
import storageFactory from "../utils/storageFactory";
import { logger } from '../logging';

export default function useDynamicConfig(shopId) {
    const [configObj, setConfigObject] = useState(null);
    const [realtimeConfigObj, setRealtimeConfigObject] = useState(null);
    const [configLoading, setConfigLoading] = useState(true);       // config loaded (from cache or from server)
    const [realtimeConfigLoading, setRealtimeConfigLoading] = useState(true);  // config refreshed = latest config loaded from server
    const localStore = storageFactory(()=> localStorage);
    const db = getDatabase(Firebase);

    const LOCAL_STOREAGE_KEY = `caazam:clientConfig:${shopId}`;

    useEffect(() => {
      if (shopId) {
        const shopRef = child(ref(db, 'shopConfig'), shopId.split('.').join('-'));
        const localConfig = localStore.getItem(LOCAL_STOREAGE_KEY);
        if (localConfig) {
          setConfigObject(JSON.parse(localConfig));
          setConfigLoading(false);
          logger.info('App dynamic config loaded from local storage cache');
        } else {
          onValue(shopRef, (snapshot) => {
            if (snapshot.exists()) {
              setConfigObject(snapshot.val());
            } else {
              setConfigObject({});
            }
            setConfigLoading(false);
            logger.info('App dynamic config loaded from remote storage');
          }, (error) => {
            logger.error('useDynamicConfig failed to load config', error)
            setConfigLoading(false);
          }, {onlyOnce: true})
        }

        onValue(shopRef,
          snapshot => {
            if (snapshot.exists()) {
              const newConfig = snapshot.val();
              localStore.setItem(LOCAL_STOREAGE_KEY, JSON.stringify(newConfig));
              setRealtimeConfigObject(newConfig);
            }
            else {
              localStore.setItem(LOCAL_STOREAGE_KEY, JSON.stringify({}));
              setRealtimeConfigObject({});
            }
            setRealtimeConfigLoading(false);
            logger.info('App dynamic config refreshed from remote storage');
          }, error => {
            logger.error('useDynamicConfig failed to listen to config', error)
            setRealtimeConfigObject({});
            setRealtimeConfigLoading(false);
          });
        return () => off(shopRef);
      }
    }, [shopId]);

    return {configObj, realtimeConfigObj, configLoading, realtimeConfigLoading};

}
