import { boutiqFormMessages } from '../messages';

const DEFAULT_SCHEDULING_FORM_SCHEMA = {
    version: 'default',
    createdAt: '2022-06-05T10:22:17.848Z',
    fields: [
        {
            type: 'title',
            multiLanguageLabel: boutiqFormMessages.scheduling_form_desc
        },
        {
            type: 'phone',
            id: 'phone',
            multiLanguageLabel: boutiqFormMessages.enter_phone
        },
        {
            type: 'text',
            multiLanguageLabel: boutiqFormMessages.textarea_placeholder,
            rows: 2,
            id: 'message',
            multiLanguagePlaceholder: boutiqFormMessages.scheduling_form_text_placeholder
        }
    ]
}

export default function getDefaultFormSchema(config) {
    let schema = DEFAULT_SCHEDULING_FORM_SCHEMA;
    if (config?.scheduling) {
        let phoneIndex = schema.fields.findIndex(field => field.id === 'phone');
        if (phoneIndex > 0) {
            if (config.scheduling.phone?.hidden) {
                schema.fields.splice(phoneIndex, 1);
            } else {
                schema.fields[phoneIndex].required = !!config.scheduling.phone?.required;
            }
        }
    }
    return schema;
}
