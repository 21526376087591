import React, { useCallback } from 'react';
import { useDispatch } from "react-redux";
import { logger } from '../../logging';
import { APP_DIAGNOSTICS_ACTION_TYPES, TWILIO_STATUS_URL } from "../../constants/appDiagnostics";

export default function useTwilioStatus() {

  const dispatch = useDispatch();

  const getTwilioStatus = useCallback(() => {
    logger.info('getting video service status');
    return fetch(TWILIO_STATUS_URL)
      .then(async (response) => {
        const json = await response.json();

        if (!json || !json.components || json.components.length === 0) {
          dispatch({ type: APP_DIAGNOSTICS_ACTION_TYPES.SET_TWILIO_STATUS_ERROR, error: 'twilio status failed: components do not exist' });
        }

        logger.info('fetched video service status');
        const ALLOWED_COMPONENTS = [
          'Group Rooms',
          'Peer-to-Peer Rooms',
          'Compositions',
          'Recordings',
          'Network Traversal Service',
          'Go Rooms',
          'PROGRAMMABLE VIDEO',
        ];

        const statusObj = {};

        json.components.forEach(({ name, status }) => {
          if (ALLOWED_COMPONENTS.includes(name)) {
            statusObj[name] = status;
          }
        });        
        dispatch({ type: APP_DIAGNOSTICS_ACTION_TYPES.SET_TWILIO_STATUS, statusObj });
      })

      .catch((error) => {
        logger.error('getting video service status failed',error);
        dispatch({ type: APP_DIAGNOSTICS_ACTION_TYPES.SET_TWILIO_STATUS_ERROR, error: 'twilio status failed: ' + error.message  });
      });
  }, []);

  return {
    getTwilioStatus,
  };
}
