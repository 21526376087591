import { CURRENT_SESSION, SYSTEM } from "../constants/sessionActionTypes";

const initialState = {
  globalConfig: null,
  isAppDisplayed: false,
  preferredHost: null,
  shopifyContext: null,
  shopifyUrlOrigin: null,
  shopifyCartData: null,
  shopifyCartLoading: true,
  disableCartSync: true,
  activeCartId: null,
  androidTrustedWebActivity: false,
  shopifyLocaleRoot: '/',
};

const currentSession = (state = initialState, action) => {
  switch (action.type) {
    case CURRENT_SESSION.SET_GLOBAL_CONFIG:
      return {
        ...state,
        globalConfig: action.globalConfig,
        isAppDisplayed: action.globalConfig?.isIframeOpen,
      }
    case SYSTEM.CLOSE_APP:
      return {
        ...state,
        isAppDisplayed: false,
      }
    case CURRENT_SESSION.SET_PREFERRED_HOST:
      return {
        ...state,
        preferredHost: action.preferredHost
      }
    case CURRENT_SESSION.SET_ORIGIN_URL:
      return {
        ...state,
        shopifyUrlOrigin: action.shopifyUrlOrigin,
      }
    case CURRENT_SESSION.SET_SHOPIFY_CONTEXT:

      if (state.shopifyContext) return { ...state };

      const shopifyLocaleRoot = (action?.shopifyContext?.routes?.root) ?? '/';
      const androidTrustedWebActivity = action.referrer === "android-app://com.google.android.gm/";

      return {
        ...state,
        shopifyContext: action.shopifyContext,
        androidTrustedWebActivity,
        shopifyLocaleRoot,
      }
    case CURRENT_SESSION.SET_ACTIVE_CART_ID:
      return {
        ...state,
        activeCartId: action.activeCartId,
      }
    case CURRENT_SESSION.SET_SHOPIFY_CART:
      return {
        ...state,
        shopifyCartData: action.shopifyCartData,
        disableCartSync: action.disableCartSync,
        shopifyCartLoading: false,
      }

    default:
      return state;
  }
};

export default currentSession;
