import React from 'react';
import { CalendarCheckmark } from '../../../../assets/icons/CalendarCheckmark';
import { formatTimezone } from '../../../../utils/formatDateString';
import './BigCalendar.scss';

export const BigCalendar = ({ date }) => {

    const timezone = formatTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);

    const month = (dateObject) => new Intl.DateTimeFormat([], {
        month: 'short',
    }).format(dateObject);

    const day = (dateObject) => new Intl.DateTimeFormat([], {
        day: 'numeric',
    }).format(dateObject);

    const longDate = (dateObject) => new Intl.DateTimeFormat([], {
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
    }).format(dateObject);

    return (
        <div className='big-calendar-container'>
            <div className='big-calendar-header-container'>{month(date)}</div>
            <div className='big-calendar-content'>
                <p className='big-calendar-day'>{day(date)}</p>
                <p className='big-calendar-day-time'>{longDate(date)}
                    <span className='big-calendar-day-timezone'>{timezone}</span>
                </p>
            </div>
            <div className='checkmark-container'>
                <CalendarCheckmark />
            </div>
        </div>
    )
}
