import {ACTIVE_CHAT, MESSAGES} from "../constants/chatActionTypes";

const setActiveRoom = (activeRoom) => ({type: ACTIVE_CHAT.SET_ACTIVE_ROOM, activeRoom});
const updateMetadata = (metadata) => ({type: ACTIVE_CHAT.UPDATE_METADATA, metadata});
const setActiveRoomId = (id) => ({type: ACTIVE_CHAT.SET_ACTIVE_ROOM_ID, id});
const resetActiveRoom = () => ({type: ACTIVE_CHAT.RESET_ACTIVE_ROOM });

const sendTypingStatusRequest = () => ({type: ACTIVE_CHAT.SEND_TYPING_STATUS.REQUEST})
const sendTypingStatusFailure = (error) => ({type: ACTIVE_CHAT.SEND_TYPING_STATUS.FAILURE, error})
const sendTypingStatusSuccess = () => ({type: ACTIVE_CHAT.SEND_TYPING_STATUS.SUCCESS})

const updateLastMessageRequest = () => ({type: MESSAGES.UPDATE_LAST_MESSAGE.REQUEST})
const updateLastMessageFailure = (error) => ({type: MESSAGES.UPDATE_LAST_MESSAGE.FAILURE, error})
const updateLastMessageSuccess = () => ({type: MESSAGES.UPDATE_LAST_MESSAGE.SUCCESS})

const updateClientsInfoRequest = () => ({type: MESSAGES.SAVE_CLIENTS_INFO.REQUEST})
const updateClientsInfoFailure = (error) => ({type: MESSAGES.SAVE_CLIENTS_INFO.FAILURE, error})
const updateClientsInfoSuccess = () => ({type: MESSAGES.SAVE_CLIENTS_INFO.SUCCESS})

const updateRoomBadgesRequest = () => ({type: ACTIVE_CHAT.UPDATE_ROOM_BADGES.REQUEST})
const updateRoomBadgesFailure = (error) => ({type: ACTIVE_CHAT.UPDATE_ROOM_BADGES.FAILURE, error})
const updateRoomBadgesSuccess = () => ({type: ACTIVE_CHAT.UPDATE_ROOM_BADGES.SUCCESS})

export {
  setActiveRoom,
  setActiveRoomId,
  resetActiveRoom,
  sendTypingStatusRequest,
  sendTypingStatusFailure,
  sendTypingStatusSuccess,
  updateMetadata,
  updateLastMessageRequest,
  updateLastMessageFailure,
  updateLastMessageSuccess,
  updateRoomBadgesRequest,
  updateRoomBadgesFailure,
  updateRoomBadgesSuccess,
  updateClientsInfoRequest,
  updateClientsInfoFailure,
  updateClientsInfoSuccess,
}
