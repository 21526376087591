import React from 'react';
import './AnimatedHostData.scss';

export const AnimatedHostData = () =>
    Array.from({ length: 4 }, (_, index) =>
        <li key={index} className='host-data-container' >
            <div className='host-avatar-container animated-loader-background'>
            </div>
            <div className='host-details-data-container'>
                <div className='host-name-wrapper'>
                    <p className='host-name animated-loader-background'></p>
                </div>
                <p style={{ height: 45 }} className='host-bio animated-loader-background' >
                </p>
                <button className='host-description-toggle-button' >
                    <div className='host-description-toggle-button-label animated-loader-background'></div>
                </button>
            </div>
        </li>)
