import {MESSAGES} from "../constants/chatActionTypes";

const sendMessageRequest = () => ({type: MESSAGES.SEND_MESSAGE.REQUEST})
const sendMessageFailure = (error) => ({type: MESSAGES.SEND_MESSAGE.FAILURE, error})
const sendMessageSuccess = () => ({type: MESSAGES.SEND_MESSAGE.SUCCESS})

const seenMessageRequest = () => ({type: MESSAGES.SEEN_MESSAGE.REQUEST})
const seenMessageFailure = (error) => ({type: MESSAGES.SEEN_MESSAGE.FAILURE, error})
const seenMessageSuccess = (userId) => ({type: MESSAGES.SEEN_MESSAGE.SUCCESS, userId})

const getMessagesRequest = () => ({type: MESSAGES.GET_MESSAGES.REQUEST})
const getMessagesFailure = (error) => ({type: MESSAGES.GET_MESSAGES.FAILURE, error})
const getMessagesSuccess = (messages) => ({type: MESSAGES.GET_MESSAGES.SUCCESS, messages})

const clearMessages = () => ({type: MESSAGES.CLEAR_MESSAGES})
const appendMessages = (messages) => ({type: MESSAGES.APPEND_MESSAGES, messages})
const saveNewMessage = (messages) => ({type: MESSAGES.SAVE_NEW_MESSAGE, messages})
const saveInitRoomId = (roomId) => ({type: MESSAGES.INIT_ROOM_LISTENER_ID, roomId});
const sendSeenActivated = (status) => ({type: MESSAGES.SEEN_ACTIVATED, status});
const setTempImageMessage = (id = null) => ({type: MESSAGES.SET_TEMP_IMAGE_MESSAGE, id})
const showStartVideoCallMessage = (state) => ({type: MESSAGES.SHOW_VIDEO_CALL_MESSAGE, state})

export {
  sendMessageRequest,
  sendMessageFailure,
  sendMessageSuccess,
  clearMessages,
  appendMessages,
  saveNewMessage,
  setTempImageMessage,
  seenMessageRequest,
  seenMessageFailure,
  seenMessageSuccess,
  showStartVideoCallMessage,
  saveInitRoomId,
  sendSeenActivated,
  getMessagesRequest,
  getMessagesSuccess,
  getMessagesFailure,
}
