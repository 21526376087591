import checkTodayTomorrow from './date-formatter';

export function formatDateString(todayName, tomorrowName, dateObject) {

    var daySpec = checkTodayTomorrow(dateObject);
    if (daySpec == 'today') daySpec = todayName
    else if (daySpec == 'tomorrow') daySpec = tomorrowName

    const dateTime = new Intl.DateTimeFormat([], {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }).format(dateObject);

    return `${daySpec}, ${dateTime}`;
  }

  export function formatTimezone(timezone) {
		return timezone && new Intl.DateTimeFormat([], {timeZoneName: "short", timeZone: timezone}).formatToParts().find(({type}) => type === "timeZoneName").value || 'local';
	}
