import React, { createContext, useContext, useEffect, useState } from 'react';
import {getAuth, signInAnonymously, onAuthStateChanged, signInWithCustomToken, getIdToken} from 'firebase/auth';
import { useSelector, useDispatch } from "react-redux";
import { appLogger, logger } from '../logging';
import { appScreenshotLogger } from '../screenshots';
import CaazamError from '../utils/errors';
import { useCustomerDetails } from './CustomerDetailsProvider';
import { useInterWindowMessageProvider, REQ_HEADER_TYPES } from './InterWindowMessageProvider';
import { CURRENT_SESSION } from '../constants/sessionActionTypes';
import firebase from '../Firebase';
import * as analytics from '../utils/analytics-cross-domain';

import {
    createAuthenticatedPostRequest,
    handleResponse,
} from '../utils/http';

export const ClientAuthContext = createContext(null);

export default function ClientAuthProvider({ shopId, accessCode, accessCodeExpiresAt, callLinkId, scheduleLinkId, windowUrl, trackingConsent, children }) {

    const [userUuid, setUserUuid] = useState(null);
    const [user, setUser] = useState(null);
    const [authError, setAuthError] = useState(null);
    const [authLoading, setAuthLoading] = useState(true);
    const [contextId, setContextId] = useState(null);
    const [sessionData, setSessionData] = useState(null);

    const dispatch = useDispatch();
    const { postMessage } = useInterWindowMessageProvider();

    const {
        name,
        email,
        shopifyId,
    } = useCustomerDetails();

    const { shopifyContext } = useSelector((state) => state.currentSession);

    const signIn = () => {

        signInAnonymously(getAuth(firebase))
            .catch(error => {
                setAuthError(new CaazamError(403, error.message));
                setUser(null)
                setUserUuid(null);
                setAuthLoading(false);
                logger.error('ClientAuthProvider sign in failed', error);
            })
    }

    useEffect(() => {
        const shopifyContextTimer = setTimeout(() => {
            logger.warn(`ClientAuthProvider fetching shopify context timed out`);
            dispatch({ type: CURRENT_SESSION.SET_SHOPIFY_CONTEXT, shopId, shopifyContext: { error: 'time out' } });
        }, 500);

        const timestamp = Date.now();
        logger.info(`ClientAuthProvider requesting shopify context`);
        postMessage(REQ_HEADER_TYPES.SHOPIFY_CONTEXT, null, (payload => {
            logger.info(`ClientAuthProvider shopify context fecthed in ${Date.now() - timestamp}ms`);
            clearTimeout(shopifyContextTimer);
            try {
                let jsonPayload = JSON.parse(payload);
                if (jsonPayload) {
                    let { shopifyContext, referrer } = jsonPayload;
                    if (shopifyContext) {
                        logger.info(`ClientAuthProvider shopify context`, { shopifyContext, referrer });
                        dispatch({ type: CURRENT_SESSION.SET_SHOPIFY_CONTEXT, shopId, shopifyContext, referrer });
                    } else {
                        throw new CaazamError(400, 'Shopify context is null');
                    }
                } else {
                    throw new CaazamError(400, 'Shopify context payload is empty');
                }
            } catch (error) {
                logger.error(`ClientAuthProvider fetching shopify context failed`, error, error.reason);
                dispatch({ type: CURRENT_SESSION.SET_SHOPIFY_CONTEXT, shopId, shopifyContext: { error: error.toString() } });
            }
        }))

        return () => clearTimeout(shopifyContextTimer);
    }, []);

    useEffect(() => {
        const unsub = onAuthStateChanged(getAuth(firebase), user => {
            logger.info(`auth client user uid ${user?.uid}`, { isAnonymous: user?.isAnonymous, providerId: user?.providerId } );
            if (user) {
                setUser(user);
            } else {
                signIn();
            }
        })

        return () => {
            unsub();
        }

    }, []);

    useEffect(() => {
        if (user) {
            setUserUuid(user.uid);
            return () =>
                setUserUuid(null);
        }
    }, [user])

    useEffect(() => {

        if (userUuid && shopifyContext && accessCode) {
            let tokenRequestUrl = `${process.env.REACT_APP_CAAZAM_REST_EP}/vs/context/token`;
            let tokenRequestBody = {
                accessCode,
                accessCodeExpiresAt,
                shopId,
                customerId: shopifyId,
                customerName: name,
                customerEmail: email,
                windowUrl,
                currency: shopifyContext.currency ?? null,
                countryCode: shopifyContext.country ?? null,
                locale: shopifyContext.locale ?? null,
                shopifyRoutes: shopifyContext.routes ?? null,
                customerUserAgent: navigator.userAgent ?? null,
                clientAppVersion: process.env.REACT_APP_VERSION,
            }

            if (!trackingConsent) {
                tokenRequestBody.noTracking = true;
            }

            if (callLinkId) {
                tokenRequestUrl = `${process.env.REACT_APP_CAAZAM_REST_EP}/vs/context/tokenFromCallLink/${callLinkId}`
            } else if (scheduleLinkId) {
                tokenRequestUrl = `${process.env.REACT_APP_CAAZAM_REST_EP}/vs/context/tokenFromScheduleLink/${scheduleLinkId}`
            }

            createAuthenticatedPostRequest(user, tokenRequestUrl, tokenRequestBody)
                .then((requestHostResponse) => {
                    return handleResponse(requestHostResponse);
                })
                .then(({ contextId, token, sessionData }) => {
                    return signInWithCustomToken(getAuth(firebase), token)
                        .then(userCred => {
                            setUser(userCred.user);
                            setContextId(contextId);
                            setSessionData(sessionData);
                            setAuthLoading(false);
                            logger.info(`session auth successful`, { userUuid, sessionId: contextId, isAnonymous: userCred.user.isAnonymous, providerId: userCred.user.providerId  });
                        })
                })
                .catch(error => {
                    let newAuthError = new CaazamError(403, error.message);
                    setAuthError(newAuthError);
                    setAuthLoading(false);
                    logger.error(`ClientAuthProvider ${tokenRequestUrl} failed`, error);
                });
        }

        return () => {
            setContextId(null);
            setSessionData(null);
        }
    }, [userUuid, shopId, shopifyContext, accessCode, accessCodeExpiresAt, windowUrl]);

    useEffect(() => {
        if (contextId) {
            analytics.setContext(contextId);
            appLogger.startRemote(contextId, () => getIdToken(user, true));
            appScreenshotLogger.startScreenshotCapture(user?.uid, contextId);
        }
        return () => {
            appScreenshotLogger.stopScreenshotCapture();
        }
    }, [contextId, user]);

    return <ClientAuthContext.Provider value={{
        shopId,
        user,
        authError,
        authLoading,
        contextId,
        sessionData,
        scheduleId: (sessionData && sessionData.scheduleId) || null,
    }}>
        {children}
    </ClientAuthContext.Provider>
}

export function useClientAuthProvider() {
    const context = useContext(ClientAuthContext);
    if (!context) {
        throw new Error('useClientAuthProvider must be used within the scope of ClientAuthProvider');
    }
    return context;
}
