import React, { createContext, useEffect, useContext, useState, useCallback } from 'react';
import { IconChevronLeft } from '../../assets/icons/mobile/ChevronLeft';
import '../modal-view/ModalView.scss';

export const ModalViewContext = createContext(null);

export const ModalViewProvider = ({ children }) => {
	const [viewQueue, setViewQueue] = useState([]);
	const [isPreventAnimation, setIsPreventAnimation] = useState(false);
	const [currentView, setCurrentView] = useState(null);
	const [isModalViewAnimationDone, setModalViewAnimationDone] = useState(true);
	const stepsAppearing = 30;
	const stepsHiding = 25;

	const showView = (view) => {
		const isDuplicatedView = viewQueue.find(renderedView => renderedView.type === view.type);
		if (!isDuplicatedView) {
			setModalViewAnimationDone(false);
			setViewQueue([...viewQueue, view]);
			setIsPreventAnimation(false);
		} else {
			setModalViewAnimationDone(true);
		}
	}

	const viewAnimation = {
		positionBySteps(start, end, step, steps) {
			return Math.max(0, Math.min(end, this.easeOutQuad(start, end, step, steps)));
		},
		easeOutQuad(start, end, t, steps) {
			t /= steps;
			t--;
			return -end * (t * t * t * t - 1) + start;
		},
	};

	const animateView = (direction, element) => {
		if (!element) return;
		return new Promise(function (resolve) {
			let step = 0;
			const start = 0;
			const end = 100;
			const currentSteps = direction ? stepsAppearing : stepsHiding;
			if (direction) {
				element.parentElement.classList.add('enter')
			} else {
				element.parentElement.classList.remove('enter')
				if (element.parentElement.previousSibling) {
					element.parentElement.previousSibling.classList.add('active')
				}
			}
			let requestID;

			const animate = () => {
				const contentPosition = viewAnimation.positionBySteps(
					start,
					end,
					step,
					currentSteps);
				if (step <= currentSteps) {
					step++;
					element.style.transform = `translateX(${direction ? 100 - contentPosition : 1.1 * contentPosition}%)`;
					requestID = requestAnimationFrame(() => {
						animate();
					});
				} else {
					cancelAnimationFrame(requestID);
					resolve();
				}
			}
			requestID = requestAnimationFrame(animate);
		});
	}

	useEffect(() => {
		if (currentView) {
			setModalViewAnimationDone(true);
		}
	}, [currentView])

	const dismissView = async (onDismiss) => {
		setIsPreventAnimation(true);
		setModalViewAnimationDone(false);
		if (currentView) {
			await animateView(false, currentView);
				const array = [...viewQueue];
				array.pop();
				setViewQueue(array);
				setModalViewAnimationDone(true);
				onDismiss && onDismiss();
		}
	}

	const destroyModalViews = () => {
		setViewQueue([])
		setModalViewAnimationDone(true);
	}

	const lastViewElementRef = useCallback(
		async (node) => {
			if (node) {
				if (!isPreventAnimation) {
					await animateView(true, node);
					setCurrentView(node);
				}
				setCurrentView(node);
			}
		},
		[isPreventAnimation]
	);

	return <ModalViewContext.Provider value={{
		showView,
		dismissView,
		destroyModalViews,
		isModalViewAnimationDone
	}}>
		<>
			{
				viewQueue && viewQueue.map((view, index) => {
					if (viewQueue.length === index + 1) {
						return <div key={index} className={`modal-view-wrapper active${view.viewPort ? ' full-screen' : ''}`}>
							<div ref={lastViewElementRef} className='modal-view-content'>
								<div className='modal-view-header'>
									<button
										onClick={() => dismissView(view.onDismiss)}
										disabled={!view.dismissAlloved} className='dismiss-button'>
										<IconChevronLeft />
									</button>
									<div className='mobile-view-title'>{view.headerLabel}</div>
								</div>
								{view.component}
							</div>
						</div>
					} else {
						return <div key={index} className={`modal-view-wrapper${view.viewPort ? ' full-screen' : ''}`}>
							<div className='modal-view-content'>
								<div className='modal-view-header'>
									<button
										onClick={() => dismissView(view.onDismiss)}
										disabled={!view.dismissAlloved} className='dismiss-button'>
										<IconChevronLeft />
									</button>
									<div className='mobile-view-title'>{view.headerLabel}</div>
								</div>
								{view.component}
							</div>
						</div>
					}
				})
			}
			{children}
		</>
	</ModalViewContext.Provider>
}

export function useModalViewProvider() {
	const context = useContext(ModalViewContext);
	if (!context) {
		throw new Error('useModalViewProvider must be used within the ModalViewContext');
	}
	return context;
}
