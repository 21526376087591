import {QUALITY_LABEL, QUALITY_SCORE} from "../constants/appDiagnostics";

export const formatNumber = (val = null) => {
  if (val === null) {
    return 0;
  }
  return parseFloat(val.toFixed(3));
};

const getSingleQualityScore =(stat, goodThreshold, suboptimalThreshold, poorThreshold, descending = false) => {
  if (typeof stat === 'undefined') {
    // We ignore values that are missing
    return QUALITY_SCORE.EXCELLENT;
  }

  if (descending) {
    if (stat > goodThreshold) return QUALITY_SCORE.EXCELLENT;
    if (stat > suboptimalThreshold) return QUALITY_SCORE.GOOD;
    if (stat > poorThreshold) return QUALITY_SCORE.SUBOPTIMAL;
    return QUALITY_SCORE.POOR;
  }

  if (stat >= poorThreshold) return QUALITY_SCORE.POOR;
  if (stat >= suboptimalThreshold) return QUALITY_SCORE.SUBOPTIMAL;
  if (stat >= goodThreshold) return QUALITY_SCORE.GOOD;
  return QUALITY_SCORE.EXCELLENT;
}

const getLatency = (preflightTestReport) => {
  if (preflightTestReport && preflightTestReport.stats && preflightTestReport.stats.rtt) {
    return {
      average: formatNumber(preflightTestReport.stats.rtt.average),
      max: formatNumber(preflightTestReport.stats.rtt.max),
      qualityScore: getSingleQualityScore(preflightTestReport.stats.rtt.average, 100, 250, 400),
    }
  }
  return {average: 0, max: 0, qualityScore: 0};
}

const getJitter = (preflightTestReport) => {
  if (preflightTestReport && preflightTestReport.stats && preflightTestReport.stats.jitter) {
    return {
      average: formatNumber(preflightTestReport.stats.jitter.average),
      max: formatNumber(preflightTestReport.stats.jitter.max),
      qualityScore: getSingleQualityScore(preflightTestReport.stats.jitter.average, 5, 10, 30),
    }
  }
  return {average: 0, max: 0, qualityScore: 0};
}

const getPacketLoss = (preflightTestReport) => {
  if (preflightTestReport && preflightTestReport.stats && preflightTestReport.stats.packetLoss) {
    return {
      average: formatNumber(preflightTestReport.stats.packetLoss.average),
      max: formatNumber(preflightTestReport.stats.packetLoss.max),
      qualityScore: getSingleQualityScore(preflightTestReport.stats.packetLoss.average, 1, 3, 8),
    }
  }
  return {average: 0, max: 0, qualityScore: 0};
}

const getQualityScore =(preflightTestReport) => {
  const latency = getLatency(preflightTestReport);
  const jitter = getJitter(preflightTestReport);
  const packetLoss = getPacketLoss(preflightTestReport);
  const totalQualityScore = Math.min(latency.qualityScore, jitter.qualityScore, packetLoss.qualityScore);
  const totalQualityLabel = QUALITY_LABEL[totalQualityScore];
  return {
    latency,
    jitter,
    packetLoss,
    totalQualityScore,
    totalQualityLabel,
  }
};

export {
  getQualityScore,
}
