import React, { useRef, useEffect } from 'react';
import { runPreflight } from 'twilio-video';
import {useDispatch} from "react-redux";
import {useCallContextAPI} from "../../hooks";

import {APP_DIAGNOSTICS_ACTION_TYPES} from "../../constants/appDiagnostics";
import { logger } from '../../logging';
export default function usePreflightTest(contextId) {

  const { getDiagnosticsToken } = useCallContextAPI(contextId);
  const dispatch = useDispatch();
  const preflightTestRef = useRef();

  useEffect(() => {
    return () => stopPreflightTest();
  }, [])

  const stopPreflightTest = () => {
    if (preflightTestRef.current) {
      preflightTestRef.current.stop();
      preflightTestRef.current = null;
    }
  }

  const startPreflightTest = async () => {
    // Don't start a new preflight test if one is already running
    if (preflightTestRef.current) {
      return;
    }

    dispatch({ type: APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_STARTED });
    let token = null;
    try {
      let res = await getDiagnosticsToken();
      token = res.token;
    } catch (tokenError) {
      logger.error('failed to fetch diagnostics token', tokenError);
      dispatch({ type: APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_FAILED, error: 'Token fetch error: ' + tokenError.message, reoprt: null });
      return;
    }

    logger.info('starting preflight tests');
    preflightTestRef.current = runPreflight(token);

    preflightTestRef.current.on('progress', (progress) => {
      logger.debug('preflight progress', progress);
      dispatch({ type: APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_PROGRESS, progress });
    });

    preflightTestRef.current.on('completed', (report) => {
      logger.info('preflight completed');
      dispatch({ type: APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_COMPLETED, report });
      preflightTestRef.current = null;
    });

    preflightTestRef.current.on('failed', (error, report) => {
      if (error.message != 'stopped') {
        logger.error('preflight error', error);
        let errorMessage = typeof error === 'string' ? error : error.message;
        dispatch({ type: APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_FAILED, error: 'preflight failed: ' + errorMessage, report });
      } else {
        logger.debug('preflight stopped');
        dispatch({ type: APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_STOPPED, report });
      }
    });
  }

  return {
    startPreflightTest,
    stopPreflightTest,
  };
}
