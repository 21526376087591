import React, { useRef, useState } from 'react';
import LocalMallIcon from '../../../../../assets/icons/LocalMall';
import { FilledButton } from '../../../../../components/buttons/buttons';
import { useCallContextProvider } from '../../../../../components/CallContextProvider';
import { useProductViewProvider } from '../../../../../components/ProductViewProvider';
import { useSessionShowroom, useSessionShowroomActions } from '../../../../../hooks';
import { checkMobileOnly } from '../../../../../utils';
import CloseIcon from '../../../../../assets/icons/Close';
import EmptyImage from "../../../../../components/emptyImage";
import { useIntl, FormattedMessage } from 'react-intl';
import { cartMessages } from '../../../intl/cart-messages/messages';
import './showroom.scss';

export const ShowRoom = ({ isCheckoutComplete, setCheckoutComplete }) => {
  const intl = useIntl();
  const [activeEditonMobileDevice, setactiveEditonMobileDevice] = useState(false);
  const isMobile = useRef(checkMobileOnly());
  const { callId } = useCallContextProvider();

  const { sessionSnapshots } = useSessionShowroom(callId);
  const { removeSessionProduct, clearSession } = useSessionShowroomActions(callId);

  const { viewProduct } = useProductViewProvider();

  const removeItem = (productKey) => {
    removeSessionProduct(productKey);
  };

  const clearCompletedCheckout = () => {
    setCheckoutComplete(false);
  };
  const clearSessionAndCheckout = () => {
    clearSession();
    clearCompletedCheckout();
  };

  const editProductsMobile = () => {
    setactiveEditonMobileDevice(!activeEditonMobileDevice);
  }

  return (
    <>
      {sessionSnapshots && sessionSnapshots.length ? (
        <>
          <div className={`product-row-wrapper${isMobile.current ? ' mobile' : ''}`}>
            <div className='product-row'>
            {sessionSnapshots.map((snapshot) => {
              const product = snapshot.val();
              return (
                <div className='product-container' key={snapshot.key}>
                    {!isMobile.current ?
                      <div className='close-icon-container'>
                        <CloseIcon className='delete-icon' color='black64' onClick={() => removeItem(snapshot.key)} />
                      </div>
                      :
                      activeEditonMobileDevice && <div className='close-icon-container'>
                        <CloseIcon className='delete-icon' color='black64' onClick={() => removeItem(snapshot.key)} />
                      </div>
                    }
                  <div id='product-overlay' className='product-overlay' onClick={() => viewProduct(product.productId, snapshot.key, product )}></div>
                  {product.image
                      ? <img
                          src={product.image}
                          key={`${product.saleId}${product.lineItemid}`}
                          className='product-image'
                          alt={product.productTitle}
                        />
                      : <EmptyImage
                          size='l'
                          viewItem={() => viewProduct(product.productId, snapshot.key, product )}
                          product={product}
                          isMobile={isMobile.current}
                          label={product.productTitle}
                        />
                  }
                </div>
              );
            })}
          </div>

          </div>

        </>
      ) : (
          <div className={`empty-showroom ${isMobile.current && 'mobile'}`}>
            <LocalMallIcon size={50} color='black64' className='showroom__local-mail__icon' />
            <p>
              <FormattedMessage {...cartMessages.showroom_empty} values={{
                host_noun: intl.formatMessage(cartMessages.host_noun) }} />
            </p>
          </div>
        )}
      {isCheckoutComplete ? (
        <div className='showroom-overlay'>
          <h1>
            <FormattedMessage {...cartMessages.showroom_remove} />
          </h1>
          <div>
            <FilledButton
              onClick={clearCompletedCheckout} className='keep'>
              <FormattedMessage {...cartMessages.cta_keep} />
            </FilledButton>
            <FilledButton
            className='remove'
              onClick={clearSessionAndCheckout}>
              <FormattedMessage {...cartMessages.cta_remove} />
            </FilledButton>
          </div>
        </div>
      ) : null}
    </>
  );
};
