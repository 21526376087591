import React from 'react';
import './AnimatedSlotData.scss';

export const AnimatedSlotData = () =>
    Array.from({ length: 6 }, (_, index) => {
        return (
            <div key={index} className='time-slot'>
                <p className='mock-time-slot animated-loader-background'></p>
            </div>
        );
    })
