import EventEmitter from 'events';
import { io } from "socket.io-client";
import CaazamError from '../utils/errors';
import { logger } from '../logging';

const baseUrl = process.env.REACT_APP_CAAZAM_REST_EP;

export const CallStatus = {
  setup: 'setup',
  ringing: 'ringing',
  connecting: 'connecting',
  connected: 'connected',
  rejected: 'rejected',
  completed: 'completed',
  cancelled: 'cancelled',
  error: 'error',
}
export default class CallSignaling extends EventEmitter {
  constructor(callId, user) {
    super();
    this.callId = callId;
    this.user = user;
    this.callData = {};
    this.socket = null;
  }

  getStatus() {
    return this.callData.status || null;
  }

  shouldFailOnSignaling(error) {
    switch (this.getStatus()) {
      case CallStatus.connected:
      case CallStatus.error:
        return false;
      case CallStatus.completed:
        switch (error.statusCode) {
          case 410: return true; // this means we're trying to connect to a completed rooom
          default: return false;
        }
      case CallStatus.setup:
      case CallStatus.ringing:
      case CallStatus.connecting:
      default:
        return true;
    }
  }

  connect() {
    if (this.callId && this.user) {
      this.user.getIdToken(/* force refresh */ true)
        .then(token => {
          let socket = io(`${baseUrl}/call_signaling`, { auth: { callId: this.callId, token }});

          socket.on("connect", () => {
            logger.info('callsignaling connect', socket.id);
          });

          socket.on("connect_error", (error) => {
            logger.error(`callsignaling connect_error ${socket.id}`, error);
            let connectError;
            if (error.data) {
              connectError = new CaazamError(error.data.code, error.data.reason);
            } else {
              connectError = new CaazamError(400, 'sig failed - ' + error.message);
            }
            if (this.shouldFailOnSignaling(connectError)) {
              this.emit('call_error', connectError);
            }
          });

          socket.on("disconnect", (reason) => {
            logger.info(`callsignaling disconnect`, { reason });
          });

          socket.on("call_sig_status", data => {
            const statusChange = this.callData.status !== data.status;
            this.callData = data;
            if (statusChange)
              if (data.status === CallStatus.error)  {
                let sigError = new CaazamError(400, 'signaling error');                
                if (data.error.error) {
                  sigError = new CaazamError(data.error.error.code, data.error.error.reason);
                }
                logger.error(`callsignaling call_error ${socket.id}`, sigError);
                this.emit('call_error', sigError);
              } else {
                logger.info(`callsignaling call_status ${socket.id}`, { status: data.status });
                this.emit('call_status', data);
              }
          });

          socket.on("call_sig_error", data => {
            let sigError = new CaazamError(data.error.code, data.error.reason);
            logger.error(`callsignaling call_sig_error ${socket.id}`, sigError);
            if (this.shouldFailOnSignaling(sigError)) {
              this.emit('call_error', sigError);
            }
          });

          this.socket = socket;
        })
        .catch(error => {
          this.emit('call_error', new CaazamError(401, 'local auth error' + error.message));
        });
    }
  }

  disconnect() {
    this.socket && this.socket.disconnect();
  }
}