import React from "react";
import './ErrorContainer.scss';

export const ErrorContainer = ({required, error}) => {
    return (
        required ? <div className='text-field-error-container'>
        <p className={`text-field-error-message${error ? ' active' : ''}`}>
            {error}
        </p> 
    </div> : null
    )
}
