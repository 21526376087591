import Resizer from "react-image-file-resizer";
import {IMAGE_MAX_LENGTH} from "../firebaseInitialization";

const getImageParams = (file) =>
  new Promise((resolve) => {
    const fileReader = new FileReader;
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      const img = new Image;
      img.src = fileReader.result;

      img.onload = () => {
        resolve({
          width: img.width,
          height: img.height
        });
      };
    };
  });

const resizeFile = (file, maxSize = IMAGE_MAX_LENGTH) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxSize,
      maxSize,
      file.type.split('/')[1],
      85,
      0,
      (uri) => resolve(uri),
      "base64",
    );
  });

export {
  getImageParams,
  resizeFile,
}
