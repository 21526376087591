import React, { useEffect, useRef, useState } from 'react';
import { useIntroPage } from '../../IntroPageProvider';
import { sendStoryEvent } from '../../../../utils/analytics-cross-domain'
import { useStoryViewCounter } from '../../../../hooks/useStoryViewCounter'
import './index.scss'

export default function VideoPlayer({
    duration,
    playListEnded,
}) {
    const videoRef = useRef(null);
    const [isFirstFrameLoaded, setIsFirstFrameLoaded] = useState(false);
    const {
        isMuted,
        playingVideoIndex,
        setVideoElement,
        autoplayPermissionsDenied,
        setAutoplayPermissionsDenied,
        isUserInteractWithVideoPlayer,
        setPlayingVideoIndex,
        setIsVideoBuffering,
        playList,
        updateVideoPlayingState,
    } = useIntroPage();

    const { logStoryView } = useStoryViewCounter();
    const isStoryViewLogged = useRef(false);
    useEffect(() => {
        setIsPlayerInteracted(isUserInteractWithVideoPlayer); // rename
    }, [isUserInteractWithVideoPlayer])

    /** Player interacted state */
    const isPlayerInteractedRef = useRef(false);
    const setIsPlayerInteracted = data => {
        isPlayerInteractedRef.current = data;
    };
    /** */

    const onloadMeta = () => {
        duration({ index: playingVideoIndex, range: videoRef.current.duration * 1000 });
    }

    const loadeddata = () => {
        if (autoplayPermissionsDenied) {
            if (isPlayerInteractedRef.current) {
                videoRef.current.load();
            }
        }
        setIsFirstFrameLoaded(true);
    }

    const ended = () => {
        const nextVideo = playingVideoIndex + 1;
        updateVideoPlayingState(playingVideoIndex);
        if (nextVideo < playList.length) {
            setPlayingVideoIndex(nextVideo);
        } else {
            playListEnded();
        }
    }

    const playing = () => {
        setIsVideoBuffering(false);
    }

    const timeupdate = () => {
        // only log a view if video runs for more than 3 sec
        if (videoRef.current.currentTime > 3.0 && !isStoryViewLogged.current) {
            let id = playList[playingVideoIndex] && playList[playingVideoIndex].id;
            sendStoryEvent('playing', id);
            if (id) {
                logStoryView(id);
            }
            isStoryViewLogged.current = true;
        }
    }

    const waiting = () => {
        setIsVideoBuffering(true);
    }

    const error = (e) => {
        console.error('error', e.target.error);
        ended();
    }

    useEffect(()=>{
        setVideoElement(videoRef);
    },[])

    useEffect(() => {
        setIsFirstFrameLoaded(false);
        isStoryViewLogged.current = false;
        videoRef.current.addEventListener('error', error)
        videoRef.current.addEventListener('canplay', playIntroVideo);
        videoRef.current.addEventListener('ended', ended);
        videoRef.current.addEventListener('loadeddata', loadeddata);
        videoRef.current.addEventListener('waiting', waiting);
        videoRef.current.addEventListener('playing', playing);
        videoRef.current.addEventListener('timeupdate', timeupdate);
        videoRef.current.addEventListener('loadedmetadata', onloadMeta);

        return () => {
            videoRef.current.removeEventListener('error', error)
            videoRef.current.removeEventListener('canplay', playIntroVideo);
            videoRef.current.removeEventListener('ended', ended);
            videoRef.current.removeEventListener('loadeddata', loadeddata);
            videoRef.current.removeEventListener('waiting', waiting);
            videoRef.current.removeEventListener('playing', playing);
            videoRef.current.removeEventListener('timeupdate', timeupdate);
            videoRef.current.removeEventListener('loadedmetadata', onloadMeta);
        }
    }, [playingVideoIndex]);

    const playIntroVideo = () => {
        if (autoplayPermissionsDenied) {
            if (isPlayerInteractedRef.current) {
                const playPromise = videoRef.current.play();
                if (playPromise !== undefined) {
                    playPromise.then(_ => {
                        setAutoplayPermissionsDenied(false);
                        })
                }
            }
        } else {
            const playPromise = videoRef.current.play();
            if (playPromise !== undefined) {
                playPromise.then(_ => { }).catch(() => {
                    setAutoplayPermissionsDenied(true);

                })
            }
        }
    }

    return (
        <video
            id='intro-video-player'
            ref={videoRef}
            playsInline
            muted={isMuted}
            src={`${playList[playingVideoIndex].src}#t=0.1`}
            className={`intro-video-element${isFirstFrameLoaded ? ' visible' : ''}`}>
        </video>
    )
}
