import React from 'react';
import { Loader } from '../../../../../../../../components';
import { InfiniteScroll } from '../../../../../../../../components/inifinite-scroll/InfiniteScroll';
import { ProductSearchResult } from '../productSearchResult';
import './SearchResults.scss';

export const SearchResults = ({
    noResults,
    noResultsMessage,
    searchResults,
    hasMore,
    loadMore,
    isLoading,
}) =>
    <div className='mobile-search-results'>
        {noResults ?
            <div className='mobile-empty-results'>
                {noResultsMessage}
            </div> :
            <InfiniteScroll
                isLoading={isLoading}
                hasMore={hasMore}
                results={searchResults}
                loadMore={loadMore}
                loader={<Loader height='26px' width='26px' />}
                item={<ProductSearchResult />}
            />
        }
    </div>
