import React, { useMemo} from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSchdeuleProvider } from '../../../../components/SchdeuleProvider';
import { useSchdeuleConfigProvider } from '../../../../components/ScheduleConfigProvider';
import { scheduleScreenMessages } from '../../messages';
import './EventTypeDurationRow.scss';

export const EventTypeDurationRow = () => {
    const intl = useIntl();
    const { availableSlots, scheduleEventTypes } = useSchdeuleProvider();
    const { selectedEventType } = useSchdeuleConfigProvider();

    const currentEvent = scheduleEventTypes.find(event => event.id === selectedEventType)

    const defaultDuration = useMemo(() => {
        let firstSlot = availableSlots?.[0]?.slots?.[0];

        if (firstSlot) {
            const endTimeslot = new Date(firstSlot.timeSlotEnd);
            const activeTimeslot = new Date(firstSlot.timeSlot);
            return Math.abs(endTimeslot.valueOf() - activeTimeslot.valueOf()) / 1000 / 60;
        }

        return null;
    }, [availableSlots]);

    const duration = useMemo(() => {
        return (currentEvent?.duration ?? defaultDuration) ?? '-';
    }, [defaultDuration, currentEvent])


    return (
        <span className='selected-evet-type-duration'>
            <FormattedMessage {...scheduleScreenMessages.nouns_duration} />{': '}
            <span className='selected-evet-type-duration-data'>
                {intl.formatMessage(scheduleScreenMessages.duration_in_minutes, { minutes: duration })}
            </span>
        </span>
    )
}
