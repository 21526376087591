import React, { useEffect } from 'react';
import { BoutiqForm } from '../../../../../components/boutiq-form/BoutiqForm';
import { useScrollableListProvider } from '../../../../../components/page-scroll-container/providers/ScrollableListProvider';
import './ScheduleClientMessageForm.scss';

export const ScheduleClientMessageForm = ({
    handleChange,
    validateOnBlur,
    values,
    errors,
    omitError,
    schema,
    isPrimaryButtonHidden,
    setIsPrimaryButtonHidden,
    refProvider
}) => {

    const { wrapperRef } = useScrollableListProvider();

    const primaryBtnState = () => {
        if (Math.ceil(wrapperRef.current.scrollTop + wrapperRef.current.offsetHeight) + 10 >= wrapperRef.current.scrollHeight) {
            setIsPrimaryButtonHidden(false);
        }
    }
    
    useEffect(() => {
        if (wrapperRef.current && isPrimaryButtonHidden) {
            if (wrapperRef.current.scrollHeight > wrapperRef.current.offsetHeight) {
                wrapperRef.current.addEventListener('scroll', primaryBtnState);
            } else {
                setIsPrimaryButtonHidden(false);
            }
        }

        return () => {
            if (wrapperRef.current) {
                wrapperRef.current.removeEventListener('scroll', primaryBtnState);
            }
        }
    }, [wrapperRef.current, isPrimaryButtonHidden])

    return (
        <div className='schedule-message-form-wrapper'>
            <div className='schedule-message-form-container'>
                <BoutiqForm
                    refProvider={refProvider}
                    schema={schema}
                    handleChange={handleChange}
                    validateOnBlur={validateOnBlur}
                    values={values}
                    errors={errors}
                    omitError={omitError}
                />
            </div>
        </div>
    )
} 
