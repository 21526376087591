import {useDispatch, useSelector} from 'react-redux';
import {collection, doc, setDoc, Timestamp} from 'firebase/firestore';
import {ref, getDownloadURL} from 'firebase/storage';
import {firestore, mediaStorage, putFileToStorage} from '../../firebaseInitialization';
import {MSG_TYPES} from "../../constants/messaging";
import {FIRESTORE_COLLECTIONS} from "../../constants/firestore";
import {
  sendMessageFailure,
  sendMessageRequest,
  sendMessageSuccess,
  setTempImageMessage,
} from "../../actions/messages";
import metadataRequests from "../metadata/requests";
import {buildUserEntity, getUserId} from "../../utils/auth";
import { getMessageRecepients, buildMessageSendStatus } from "../../utils/participants";

export default function useMessagesRequests({user}) {
  const fs = firestore();
  const st = mediaStorage();
  const dispatch = useDispatch();
  const {id, participants } = useSelector(state => state.activeRoom);
  const {updateLastMessage} = metadataRequests({user});

  const sendMessage = async ({msg = null, type = MSG_TYPES.TEXT}) => {
    const privateUserData = participants[getUserId(user)];
    const sender = buildUserEntity(privateUserData);
    const recipients = getMessageRecepients(sender.uid, participants);
    const status = buildMessageSendStatus(sender.uid, recipients);

    if (!id) {
      return;
    }

    dispatch(sendMessageRequest());
    const roomRef = doc(fs, FIRESTORE_COLLECTIONS.CHAT_ROOMS, id)
    const messageRef = doc(collection(fs, roomRef.path, FIRESTORE_COLLECTIONS.CHAT_MESSAGES));
     const timestamp = Timestamp.fromDate(new Date());
    const message = {
      id: messageRef.id,
      owner: {
        uuid: sender.uid,
        role: sender.role,
        displayName: sender.displayName || null,
        profileAvatarUrl: sender?.profileAvatarUrl || null,
        profileInitials: sender?.profileInitials,
      },
      recipients,
      room: {
        id: id,
      },
      type,
      text: msg,
      timestamp,
      status,
    };

    try {
      await setDoc(messageRef, message);
      dispatch(sendMessageSuccess())
      await updateLastMessage({message})
    } catch (e) {
      console.error('Error of message sending');
      dispatch(sendMessageFailure(e.message))
    }
  }

  const sendFile = async ({file, chatRoomId, type = MSG_TYPES.FILE}) => {
    const privateUserData = participants[getUserId(user)];
    const sender = buildUserEntity(privateUserData);
    const recipients = getMessageRecepients(sender.uid, participants);
    const status = buildMessageSendStatus(sender.uid, recipients);
    if (!chatRoomId) {
      return;
    }
    const fileName = `${new Date().valueOf()}_${file.name || file.fileName}`;
    dispatch(sendMessageRequest());
    const roomRef = doc(fs, FIRESTORE_COLLECTIONS.CHAT_ROOMS, chatRoomId)
    const messageRef = doc(collection(fs, roomRef.path, FIRESTORE_COLLECTIONS.CHAT_MESSAGES));
    dispatch(setTempImageMessage(messageRef.id));
    try {
      const storageRef = ref(st, `/userMedia/${sender.uuid}/${fileName}`);
      await putFileToStorage(storageRef, file, {customMetadata: {chatId: chatRoomId}});
      const freeUrl = await getDownloadURL(storageRef);
      const timestamp = Timestamp.fromDate(new Date());
      const message = {
        id: messageRef.id,
        owner: {
          uuid: sender.uid,
          role: sender.role,
          displayName: sender.displayName || null,
          profileAvatarUrl: sender?.profileAvatarUrl || null,
          profileInitials: sender?.profileInitials,
        },
        recipients,
        room: {
          id: chatRoomId,
        },
        type: type,
        text: '',
        metadata: {
          key: 'file',
          type: file.type || null,
          url: freeUrl,
        },
        timestamp,
        status,
      };
      await setDoc(messageRef, message);
      dispatch(sendMessageSuccess());
      await updateLastMessage({message})
      dispatch(setTempImageMessage(null));
    } catch (e) {
      dispatch(setTempImageMessage(null));
      dispatch(sendMessageFailure(e.message))
    }
  }

  return {
    sendMessage,
    sendFile,
  };
}
