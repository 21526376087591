import { defineMessages } from 'react-intl';

export const scheduleScreenMessages = defineMessages({
  schdeule_error: {
    id: 'messages.schdeule_error'
  },  
  please_try_again: {
    id: 'messages.please_try_again'
  },  
  cta_try_again: {
    id: 'button_actions.cta_try_again'
  },  
  contact_error_1: {
    id: 'messages.contact_error_1'
  },  
  schdeule_slot_unavailable: {
    id: 'messages_schdeule_slot_unavailable'
  },
  powered_by_caazam: {
    id: 'messages.powered_by_caazam'
  },  
  cta_add_to_calendar: {
    id: 'button_actions.cta_add_to_calendar'
  },  
  cta_cancel: {
    id: 'button_actions.cta_cancel'
  },  
  save_to_calendar_description: {
    id: 'messages.save_to_calendar_description'
  },  
  save_to_calendar_location: {
    id: 'messages.save_to_calendar_location'
  },  
  save_to_calendar_title: {
    id: 'messages.save_to_calendar_title'
  },  
  schdeule_success: {
    id: 'messages.schdeule_success'
  },  
  schdeule_confirm_sent: {
    id: 'messages_schdeule_confirm_sent'
  },
  reschdeule_confirm_sent: {
    id: 'messages_reschdeule_confirm_sent'
  },
  schdeule_custom_link: {
    id: 'messages_schdeule_custom_link'
  },
  cta_copy_call_link: {
    id: 'button_actions_cta_copy_call_link'
  },  
  schedule_header: {
    id: 'messages_schedule_header'
  },
  select_host_secondary: {
    id: 'messages_host_select_text'
  },
  select_event_secondary: {
    id: 'messages_event_select_text'
  },
  schedule_message_header: {
    id: 'messages_schedule_message_header'
  }, 
  schedule_with_header: {
    id: 'messages_schedule_with_header'
  },
  slot_select_text: {
    id: 'messages.slot_select_text'
  },   
  cta_schedule: {
    id: 'button_actions.cta_schedule'
  },   
  cta_confirm_schedule: {
    id: 'button_actions.cta_confirm_schedule'
  },
  schedule_only_header: {
    id: 'messages.schedule_only_header'
  },
  leave_message: {
    id: 'messages_leave_message'
  },   
  cta_next: {
    id: 'button_actions_cta_next'
  },
  service_noun: {
    id: 'nouns_service'
  },
  nouns_today: {
    id: 'nouns.today'
  },
  nouns_tomorrow: {
    id: 'nouns.tomorrow'
  },
  nouns_duration: {
    id: 'nouns_duration'
  },
  nouns_timezone: {
    id: 'nouns_timezone'
  },
  cta_select_time: {
    id: 'button_actions_cta_select_time'
  },
  duration_in_minutes: {
    id: 'duration_in_minutes',
  },
  no_available_slots: {
    id: 'messages_no_available_slots'
  },
  no_slots_contact_us: {
    id: 'messages_no_available_slots_contact_us'
  },


  cta_ok: {
    id: 'button_actions.cta_ok'
  },
  cta_cancel: {
    id: 'button_actions.cta_cancel'
  },
  cta_done: {
    id: 'button_actions.cta_done'
  },
  cta_reschedule: {
    id: 'button_actions_cta_reschedule'
  },
  cta_cancel_schedule: {
    id: 'button_actions_cta_cancel_schedule'
  },
  cta_select_host: {
    id: 'button_actions_cta_select_host'
  },
  modal_cta_yes: {
    id: 'button_actions_modal_cta_confirm'
  },
  modal_cta_no: {
    id: 'button_actions_modal_cta_cancel'
  },
  delete_schedule_title: {
    id: 'messages_delete_schedule_title'
  },
  schdeule_not_valid: {
    id: 'messages_schdeule_not_valid'
  },
  schdeule_no_change :{
    id: 'messages_schdeule_no_change',
  },
  schedule_delete_succeed: {
    id: 'messages_schedule_delete_succeed'
  },
  schedule_not_found: {
    id: 'messages_schedule_not_found'
  },
  empty_host_list: {
    id: 'messages_empty_host_list'
  },
  any_host: {
    id: 'messages_host_select_any_host'
  },
  cta_select: {
    id: 'button_actions_cta_select'
  },
  cta_selected: {
    id: 'button_actions_cta_selected'
  },
  cta_show_more: {
    id: 'button_actions_cta_show_more'
  },
  cta_show_less: {
    id: 'button_actions_cta_show_less'
  },
  current_tag: {
    id: 'nouns_current_tag'
  },
  selected_host: {
    id: 'messages_schedule_selected_host'
  },
  cta_change: {
    id: 'button_actions.cta_change'
  },
  host_noun: {
    id: 'nouns_host'
  },
  duration_in_minutes: {
    id: 'duration_in_minutes'
  }
});
