import React, {createContext, useContext, useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";

import {useCallContextProvider} from "./CallContextProvider";
import usePreflightTest from "../hooks/appStateDiagnostic/usePreflightTest";
import useTwilioStatus from "../hooks/appStateDiagnostic/useTwilioStatus";
import {useCallContextAPI} from "../hooks";
import usePrevious from "../hooks/usePreviousState";
import {getQualityScore} from "../utils/diagnostics";
import {APP_DIAGNOSTICS_ACTION_TYPES} from "../constants/appDiagnostics";
import { logger } from '../logging';

export const AppDiagnosticsContext = createContext(null);

export default function AppDiagnosticsProvider({children}) {

  const dispatch = useDispatch();
  const { contextId } = useCallContextProvider();
  const { sendDiagnosticReport } = useCallContextAPI(contextId);

  const { startPreflightTest } = usePreflightTest(contextId);
  const { getTwilioStatus } = useTwilioStatus();

  const {
    preflightTest, preflightTestFinished,
    twilioStatus, twilioStatusFinished, twilioStatusError,
    reportSent
  } = useSelector((state) => state.appDiagnostics);

  const previousContextId = usePrevious(contextId);

  useEffect(() => {
    if (contextId && previousContextId !== contextId && !twilioStatusFinished) {
      getTwilioStatus();
    }
  }, [contextId, twilioStatusFinished,])

  useEffect(() => {
    if (contextId && previousContextId !== contextId && !preflightTestFinished) {
      startPreflightTest();
    }
  }, [contextId, preflightTestFinished,])

  useEffect(() => {
    if (twilioStatusFinished && preflightTestFinished && !reportSent) {
      const signalingGateway = preflightTest.signalingGatewayReachable ? 'Reachable' : 'Unreachable';
      const turnServers = preflightTest.turnServersReachable ? 'Reachable' : 'Unreachable';

      const finalTestResults = {
        browserInformation: {
          ua: navigator.userAgent
        },
        connectivityResults: {
          twilioServices: { ...twilioStatus },
          signalingRegion: signalingGateway,
          TURN: turnServers,
          error: twilioStatusError || null,
        },
        preflightTestReport: {
          report: preflightTest.report,
          error: preflightTest.error || null,
          stopped: preflightTest.stopped,
          result: getQualityScore(preflightTest.report),
        },
        appVersion: process.env.REACT_APP_VERSION,
      };

      sendDiagnosticReport(finalTestResults)
        .then(() => {
          logger.info('Sending diag report');
          dispatch({ type: APP_DIAGNOSTICS_ACTION_TYPES.REPORT_SENT });
        })
        .catch(error => {
          logger.error('failed to save diagnostics report', error);
        });
    }
  }, [twilioStatusFinished && preflightTestFinished, reportSent, sendDiagnosticReport])

  return (
    <AppDiagnosticsContext.Provider value={{}}>
      {children}
    </AppDiagnosticsContext.Provider>
  );
}

export function useAppDiagnosticsProvider() {
  const context = useContext(AppDiagnosticsContext);
  if (!context) {
    throw new Error('useAppDiagnosticsProvider must be used within the AppDiagnosticsProvider');
  }
  return context;
}
