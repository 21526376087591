import React from 'react'
import './MobileCounter.scss';

export const MobileCounter = ({ onIncrease, onDecrease, quantity, inventoryPolicy, inventoryQuantity }) => {
    return (
        <div className='mobile-quantity-input'>
          {onDecrease && (
            <button id='decrease-button' className='mobile-decrease-button' onClick={onDecrease}>
              <span className='mobile-decrease-button-icon'></span>
            </button>
          )}
          <input className='mobile-count-field' type='number' value={quantity ? quantity : 1} readOnly />
          {onIncrease && (
            <button id='increase-button' disabled={inventoryPolicy !== 'CONTINUE' && quantity >= inventoryQuantity} className='mobile-increase-button' onClick={onIncrease}>
              <span className='mobile-increase-button-icon'></span>
            </button>
          )}
        </div>
    );
};
