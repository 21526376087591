import {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {collection, onSnapshot, where, query, orderBy} from 'firebase/firestore';
import {firestore} from '../../firebaseInitialization';
import {parseMsgObject} from "../../utils/parsers";
import {FIRESTORE_COLLECTIONS} from "../../constants/firestore";
import {clearMessages, saveNewMessage} from "../../actions/messages";

export default function useMessageListener({userId}) {
  const fs = firestore();
  const dispatch = useDispatch();
  const { id, metadata } = useSelector(state => state.activeRoom);
  const { messages } = useSelector(state => state.chatMessages);
  const roomMessagesListener = useRef(null);

  const listenNewMessages = () => {
    const date = metadata?.seenStatus && metadata?.seenStatus[userId] ? new Date(metadata?.seenStatus[userId]) : new Date();
    const q = query(collection(fs, FIRESTORE_COLLECTIONS.CHAT_ROOMS, id, FIRESTORE_COLLECTIONS.CHAT_MESSAGES),
      where("timestamp", ">", date), orderBy("timestamp", "desc"));
    return onSnapshot(q, (snapshot) => {
      const changes = snapshot.docChanges();
      if (changes) {
        const messages = []
        changes.forEach(ch => {
          if (ch?.type === 'added') {
            messages.push(parseMsgObject({...ch.doc.data(), id: ch.doc.id}));
          }
        })
        if (messages.length > 0) {
          dispatch(saveNewMessage(messages));
        }
      }
    }, (listenError) => {
      console.error('listenRoomMessages', listenError)
    }, null);
  };

  useEffect(() => {
    dispatch(clearMessages());
    if (id) {
      roomMessagesListener.current = listenNewMessages();
    }
    return () => {
      if (roomMessagesListener.current) {
        roomMessagesListener.current();
      }
    }
  }, [id]);

};
