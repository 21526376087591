import { useState, useEffect } from "react";
import { useSessionCart } from ".";
import { useCallContextProvider } from "../components/CallContextProvider";

export const useCartData = () => {
    const [cartData, setCartData] = useState({
		total: null,
		totalDiscount: null,
	});
    const { callId, activeCartId } = useCallContextProvider();
	const { cartSnapshots } = useSessionCart(callId, activeCartId);

    const calculateCart = () => {
		if (!cartSnapshots) return;
		if (cartSnapshots.length === 0) {
			setCartData({
				total: null,
				totalDiscount: null,
			});
			return;
		};
		const cartTotal = cartSnapshots.reduce(
			(cart, snapshot) => {
				const product = snapshot.val();
				const quantity = product.quantity || 1;
				return {
					total: cart.total + Number.parseFloat(product.currentPrice) * quantity,
					totalBeforeDiscount: cart.totalBeforeDiscount + Number.parseFloat(product.currentCompareToPrice || product.currentPrice) * quantity,
					currencyCode: product.currencyCode,
				};
			},
			{ total: 0, totalBeforeDiscount: 0 }
		);
		const totalDiscount = Math.round(
			((cartTotal.totalBeforeDiscount - cartTotal.total) * 100) / cartTotal.totalBeforeDiscount
		)
		setCartData({
			total: cartTotal.total,
			totalDiscount,
			currencyCode: cartTotal.currencyCode,
		});
	};

	useEffect(calculateCart, [cartSnapshots]);

    return { cartData };
}
