import CaazamError from './errors';

export const setContentTypeHeader = () => {
  return {
    'Content-Type': 'application/json',
  };
};

export const createPostRequest = (url, bodyData) => {
  const options = {
    method: 'POST',
    headers: setContentTypeHeader(),
  };
  if (bodyData) {
    options.body = JSON.stringify(bodyData);
  }
  return fetch(url, options);
};

export const createAuthenticatedPostRequest = async (user, url, bodyData) => {

  if (!user)
    throw new Error('cannot create authentictaed POST request w/o user');

  const token = await user.getIdToken(/* force refresh */ true);

  const options = {
    method: 'POST',
    headers: {
      ...setContentTypeHeader(),
      authorization: 'Bearer ' + token,
    },
  };

  if (bodyData) {
    options.body = JSON.stringify(bodyData);
  }
  return fetch(url, options);
};

export const createAuthenticatedPutRequest = async (user, url, bodyData) => {

  if (!user)
    throw new Error('cannot create authentictaed PUT request w/o user');

  const token = await user.getIdToken(/* force refresh */ true);

  const options = {
    method: 'PUT',
    headers: {
      ...setContentTypeHeader(),
      authorization: 'Bearer ' + token,
    },
  };

  if (bodyData) {
    options.body = JSON.stringify(bodyData);
  }
  return fetch(url, options);
};

export const createAuthenticatedDeleteRequest = async (user, url, bodyData) => {

  if (!user)
    throw new Error('cannot create authentictaed DELETE request w/o user');

  const token = await user.getIdToken(/* force refresh */ true);

  const options = {
    method: 'DELETE',
    headers: {
      ...setContentTypeHeader(),
      authorization: 'Bearer ' + token,
    },
  };

  if (bodyData) {
    options.body = JSON.stringify(bodyData);
  }
  return fetch(url, options);
};

export const createAuthenticatedGetRequest = async (user, url) => {

  if (!user)
    throw new Error('cannot create authentictaed GET request w/o user');

  const token = await user.getIdToken(/* force refresh */ true);

  const options = {
    method: 'GET',
    headers: {
      ...setContentTypeHeader(),
      authorization: 'Bearer ' + token,
    },
  };

  return fetch(url, options);
};

export const handleResponse = (response) => {
  if (response.ok) return response.json();
  else {
    return response
      .json()
      .catch((nonJsonError) => {
        throw new CaazamError(
          response.status,
          nonJsonError.message || 'Something went wrong'
        );
      })
      .then((jsonError) => {
        throw new CaazamError(jsonError.error.code, jsonError.error.reason);
      });
  }
};
