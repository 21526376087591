import { useCallback } from 'react';
import { createPostRequest } from '../utils/http';
import { useClientAuthProvider } from '../components/ClientAuthProvider';

const baseUrl = process.env.REACT_APP_CAAZAM_REST_EP;

export const useContact = () => {
  const { shopId, contextId } = useClientAuthProvider();

  return {
    contactRequest: useCallback(
      (name, email, message, errorMsg) => {
        const bodyData = { name, email, message, errorMsg };
        let query = contextId ? `?contextId=${contextId}` : '';
        return createPostRequest(
          `${baseUrl}/vs/shop/${shopId}/contact/${query}`,
          bodyData
        );
      },[shopId, contextId]),
  };
};

export const useProductSearch = (shopId, locale = "", countryCode = null) => {
  return {
    searchProducts: useCallback(
      (searchTerm, limit, cursor) => {
        
        const params = new URLSearchParams({
          search: searchTerm,
          limit,
          locale,
        });
        if (cursor) params.append('cursor', cursor);
        if (countryCode) params.append('pricingContext', countryCode);

        return fetch(
          `${baseUrl}/vs/shop/${shopId}/productsv2/search?${params.toString()}`
        );
      },
      [shopId, countryCode]
    ),
  };
};
