import React, { useState, useRef, useEffect } from 'react';
import ArrowBackIosRoundedIcon from '../../../../../../../assets/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '../../../../../../../assets/icons/ArrowForwardIosRounded';
import AddRoundedIcon from '../../../../../../../assets/icons/AddRounded';
import ProductCurrentPrice from "../../../../../shared/ProductCurrentPrice/productCurrentPrice";
import { FilledButton } from "../../../../../../../components";
import Zoom from "../../../../../../../components/zoomImage";
import { parseIdString } from "../../../../../../../utils";
import { CarouselDots } from '../../../../../../../components/carousel-dots/CarouselDots';
import { innerLinkHandler } from '../../../../../shared/innerLinkHandler';
import './styles.scss';

export function FullScreenProductDescription({ className, selectedImage, currentImages, product,
  isPresentationEnabled, variant, sessionSnapshots, addToSession, setSelectedImage }) {
  const SCROLL_STEP = 65;
  const MAX_IMAGE_HEIGHT = 500;
  const MAX_IMAGE_WIDTH = 450;

  const galleryRef = useRef(null);
  const mainImageRef = useRef(null);
  const imagesRefs = useRef([]);
  const [zoom, setZoom] = useState(false);
  const [mainImageWidth, setMainImageWidth] = useState(0);
  const [mainImageHeight, setMainImageHeight] = useState(0);
  const [inShowroom, toggleIsProductInShowroom] = useState(null);

  const title = product ? product.title : '';
  const description = product ? product.descriptionHtml : '';

  const updateGalleryPosition = () => {
    if (selectedImage) {
      const index = currentImages.findIndex(i => i.id === selectedImage.id);
      if (index !== selectedImage.index) {
        setSelectedImage({ ...selectedImage, index: index || 0 });
      }
      if (index > 2) {
        scroll(SCROLL_STEP * index);
      }
    }
  }

  useEffect(() => {
    if (product) {
      const productIDsInShowroom = sessionSnapshots.map((snapshot) => snapshot.val().productId);
      toggleIsProductInShowroom(productIDsInShowroom.includes(parseIdString(product.productId)));
    }
  }, [product, sessionSnapshots]);

  useEffect(() => {
    updateGalleryPosition();
  }, []);

  useEffect(() => {
    setTimeout(() => scrollToView(), 300);
  }, [selectedImage]);

  const scrollToView = () => {
    if (selectedImage) {
      const activeImageId = imagesRefs.current.findIndex(i => i.id === selectedImage.id);
      if (activeImageId !== -1) {
        imagesRefs.current[activeImageId].ref.scrollIntoView({
          behavior: 'smooth',
          block: "end",
          inline: "nearest"
        });
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', updateNodeParams);
    if (currentImages.length > 1) {
      updateGalleryPosition();
    }

    return () => window.removeEventListener('resize', updateNodeParams);
  }, [])

  useEffect(() => {
    updateGalleryPosition();
  }, [selectedImage]);

  function getRenderedSize(contains, cWidth, cHeight, width, height) {
    const oRatio = width / height;
    const cRatio = cWidth / cHeight;
    let imgWidth = 0;
    let imgHeight = 0;
    if (contains ? (oRatio > cRatio) : (oRatio < cRatio)) {
      imgWidth = cWidth;
      imgHeight = cWidth / oRatio;
    } else {
      imgWidth = cHeight * oRatio;
      imgHeight = cHeight;
    }
    return { width: imgWidth, height: imgHeight }
  }

  const getImgSizeInfo = (img) => {
    const position = window.getComputedStyle(img).getPropertyValue('object-position').split(' ');
    return getRenderedSize(true, img.width, img.height, img.naturalWidth, img.naturalHeight, parseInt(position[0]));
  }

  const updateNodeParams = () => {
    if (mainImageRef.current) {
      const { width, height } = getImgSizeInfo(mainImageRef.current);
      const maxImageHeight = MAX_IMAGE_HEIGHT;
      const maxImageWidth = MAX_IMAGE_WIDTH;
      let activeHeight = height;
      let activeWidth = width;
      const coef = height / width;

      if (height > maxImageHeight) {
        activeHeight = maxImageHeight;
        activeWidth = activeHeight / coef;
      }

      if (activeWidth > maxImageWidth) {
        activeWidth = maxImageWidth;
        activeHeight = activeWidth * coef;
      }

      if (width !== mainImageWidth) {
        setMainImageWidth(activeWidth);
      }
      if (height !== mainImageHeight) {
        setMainImageHeight(activeHeight);
      }
    }
  };

  useEffect(() => {
    if (zoom) {
      updateNodeParams();
    }
  }, [mainImageRef.current, zoom])

  const scrollBack = () => {
    scroll(-SCROLL_STEP);
  }

  const scrollForward = () => {
    scroll(SCROLL_STEP);
  }

  const scroll = (scrollOffset) => {
    galleryRef.current.scroll({ top: 0, left: galleryRef.current.scrollLeft + scrollOffset * 3, behavior: 'smooth' })
  };

  const handleMouseOver = () => setZoom(true);

  const handleMouseOut = () => setZoom(false);

  const updateMainImage = (image, index) => {
    if (!isPresentationEnabled) {
      if (image) {
        setSelectedImage({ ...image, index });
      } else {
        const imageIndex = currentImages.findIndex(i => i.id === selectedImage.id);
        setSelectedImage({ ...selectedImage, index: imageIndex || 0 });
      }
    }
  }

  const initImgReferences = (image, ref) => {
    const imgIndex = imagesRefs.current.findIndex(i => i.id === image.id);
    if (ref !== null && imgIndex === -1) {
      imagesRefs.current.push({ id: image.id, ref })
    }
  }

  const renderImagesList = () => {
    return currentImages.map((image, index) => (
      <img
        ref={(ref) => initImgReferences(image, ref)}
        className={`presentation-view_gallery_block_images-gallery${isPresentationEnabled ? '_enabled' : '_previews'}`}
        src={image.url}
        onClick={() => updateMainImage(image, index)}
        key={image.id}
        id={image.id}
        alt={image.altText}
      />
    ));
  }

  const renderGallery = () => (
    <div className='presentation-view_gallery'>
      <div className='presentation-view_gallery_list'>
        {
          currentImages.length > 1 &&
          <div className='presentation-view_gallery_block'>
            <ArrowBackIosRoundedIcon
              size='25_36'
              className='presentation-view_gallery_block_left-arrow'
              onClick={scrollBack} />
            <div ref={galleryRef} className='presentation-view_gallery_block_images-gallery'>
              {renderImagesList()}
            </div>
            <ArrowForwardIosRoundedIcon
              size='25_36'
              className='presentation-view_gallery_block_right-arrow'
              onClick={scrollForward}
            />
          </div>
        }
        {
          currentImages.length > 1 &&
          <div className='presentation-view_gallery_dots'>
            <CarouselDots
              dotsGap={4}
              dotsSize={8}
              borderSize={1}
              emptyDots={false}
              length={currentImages.length}
              active={selectedImage && selectedImage.index ? selectedImage.index : 0} />
          </div>
        }
      </div>
      {
        selectedImage &&
        <>
          {
            !mainImageWidth && !mainImageHeight &&
            <img
              onLoad={updateNodeParams}
              ref={mainImageRef}
              className='presentation-view_gallery_main-image_default'
              src={selectedImage ? selectedImage.fullSizedUrl : (selectedImage.url ? selectedImage.url : '')}
              alt={selectedImage ? selectedImage.altText : ''}
            />
          }
          {
            (selectedImage.fullSizedUrl || selectedImage.url) && mainImageWidth && mainImageHeight &&
            <div
              className='presentation-view_gallery_zoom'
              onLoad={updateNodeParams}
              onMouseOut={handleMouseOut}
              onMouseOver={handleMouseOver}
            >
              <Zoom
                img={selectedImage ? selectedImage.fullSizedUrl : (selectedImage.url ? selectedImage.url : '')}
                zoomScale={3}
                width={mainImageWidth}
                height={mainImageHeight}
                alt={selectedImage ? selectedImage.altText : ''}
              />
            </div>
          }
        </>
      }
    </div>
  );

  const addToShowroom = () => {
    addToSession(product);
  }

  const renderProductData = () => (
    <div className='presentation-view_details'>
      <p className='presentation-view_details_title'>{title}</p>
      <div className='presentation-view_details_prices'>
        <ProductCurrentPrice product={product} selectedProductVariant={variant} />
      </div>
      {
        !inShowroom
          ? <FilledButton
            onClick={addToShowroom}
            className='presentation-view_details_showroom-btn'>
            <div className='presentation-view_details_showroom-btn_content'>
              <AddRoundedIcon size={24} />
              showroom
            </div>
          </FilledButton>
          : <p className='presentation-view_details_added'>added to showroom</p>
      }
      <div onClick={(e) => innerLinkHandler(e, product.onlineStoreUrl)} className='presentation-view_details_description' dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );

  return (
    <div className={`full-screen-product-container${className}`}>
      {renderGallery()}
      {renderProductData()}
    </div>
  )
}
