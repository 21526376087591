import React, { useRef, useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl';
import { useClientConfigProvider } from '../../../../../components/ClientConfigProvider';
import { AllHostsItemData } from '../../../components/AllHostsItemData/AllHostsItemData';
import { HostItemData } from '../../../components/hostItemData/HostItemData';
import { AnimatedHostData } from '../../animations/animated-host-data/AnimatedHostData';
import { scheduleScreenMessages } from '../../../messages';
import { useSchdeuleConfigProvider } from '../../../../../components/ScheduleConfigProvider';
import { useSchdeuleProvider } from '../../../../../components/SchdeuleProvider';
import { smoothScrollBySteps } from '../../../../../utils/smooth-scroll';
import { useScrollableListProvider } from '../../../../../components/page-scroll-container/providers/ScrollableListProvider';
import './ScheduleHostList.scss';

export const ScheduleHostList = () => {
	const { wrapperRef } = useScrollableListProvider();

    const {
        isRescheduling,
        rescheduledHost,
        setSelectedAllHosts,
        hostIdForScheduling,
        selectedAllHosts,
        setHostForScheduling,
    } = useSchdeuleConfigProvider();

    const {
        sessionHosts : hosts,
        sessionHostsLoading: hostsLoadingState,
    } = useSchdeuleProvider();

    const { 
        hostSelectionConfig, 
    } = useClientConfigProvider();

	const contentRef = useRef(null);
	const [preselected, setPreselected] = useState(false);

    const handleHostSelect = (id) => {
        if (id) {
            setSelectedAllHosts(false);
            setHostForScheduling(id);
        } else {
            setSelectedAllHosts(true);
            setHostForScheduling(null);
        }
    }

    useEffect(() => {
		if (wrapperRef.current && 
			contentRef.current && 
			rescheduledHost && 
			!preselected &&
            isRescheduling) {
			setPreselected(true);
			const index = hosts.findIndex((item => item.hostId === rescheduledHost));
			if (index > 0) {
                const conutToSkip = hostSelectionConfig.anyAvailableEnabled ? 1 : 0;
				const targetEl = contentRef.current.childNodes.item(index + conutToSkip);
				if (targetEl && targetEl.offsetTop > 0) {
					smoothScrollBySteps(
                        wrapperRef.current, 
                        wrapperRef.current.scrollTop, 
                        targetEl.offsetTop - 20
                        ).then(() => {})
				}
			}
		}
	}, [contentRef.current, 
		wrapperRef.current, 
		rescheduledHost, 
		preselected,
        isRescheduling])

    const renderHostList = () => {
        if (hosts.length > 0) {
            return (
                <>
                    {hosts.length > 1 && hostSelectionConfig.anyAvailableEnabled &&
                        <AllHostsItemData
                            key={'all'}
                            hosts={hosts}
                            isSelected={selectedAllHosts}
                            scheduledHost={isRescheduling && !rescheduledHost}
                            setSelectedHost={() => handleHostSelect(null)}
                        />
                    }
                    {hosts.map((host, index) =>
                        <HostItemData
                            key={index}
                            data={host}
                            setSelectedHost={handleHostSelect}
                            selectedHost={hostIdForScheduling}
                            scheduledHost={rescheduledHost}
                            selectedAllHosts={selectedAllHosts} />
                    )}
                </>
            )
        } else {
            return (
                <div className='no-hosts-message-container'>
                    <p>
                        <FormattedMessage {...scheduleScreenMessages.empty_host_list} />
                    </p>
                </div>
            )
        }
    }

    return (
        <ul ref={contentRef} className='select-host-list'>
            {hostsLoadingState ?
                <AnimatedHostData /> :
                renderHostList()
            }
        </ul>
    )
}
