import React, { useEffect, useRef, useState, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { scheduleScreenMessages } from '../messages';
import { PageFooter } from '../../../components/page-footer/PageFooter'
import { PageHeader } from '../../../components/page-header/PageHeader'
import { PageScrollContainer } from '../../../components/page-scroll-container/PageScrollContainer'
import { LeaveMessageEvent } from '../components/leave-message-event/LeaveMessageEvent'
import { ScheduleEventsList } from './components/ScheduleEventsList/ScheduleEventsList'
import {useHistory, useRouteMatch} from 'react-router-dom';
import { useContext } from 'react';
import { SCHEDULING_PAGES } from '../schedule-screen';
import { AppHeaderContext } from '../../../components/AppHeaderProvider';
import { useSchdeuleConfigProvider } from '../../../components/ScheduleConfigProvider';
import { useClientConfigProvider } from '../../../components/ClientConfigProvider';
import { logger } from '../../../logging';
import { appScreenshotLogger } from '../../../screenshots';
import { useMutationObservable } from '../../../utils/MutationObserver';
import {PAGES} from "../../../constants/routes";


const OPTIONS = {
    config: { attributes: false, childList: true, subtree: false },
    debounceTime: 0
};

export const SelectEvent = ({ componentRef, navigateToNextScheduleScreen }) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const { backFuncRef } = useContext(AppHeaderContext);
    const { selectedEventType, setSelectedEventType } = useSchdeuleConfigProvider();
    const { hostSelectionConfig } = useClientConfigProvider();
    const listRef = useRef(null);
    const [isLoggingInProgress, setIsLoggingInProgress] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [mutationDetected, setMutationDetected] = useState(false)

    // Logger
    const onListMutation = useCallback(
        (mutationList) => {
            const captureScreenshot = () => {
                setMutationDetected(true)
                setIsLoggingInProgress(true);
                setTimeout(() => appScreenshotLogger.captureScreenshot('select_event', componentRef)
                    .finally(() => setIsLoggingInProgress(false)), 450);
            }
            if (Array.isArray(mutationList)) {
                if (mutationList[0]?.target?.children?.length > 0 && !mutationDetected) {
                    captureScreenshot()
                }
            } else {
                if (mutationList?.children?.length > 0 && !mutationDetected) {
                    captureScreenshot()
                }
            }
        },
        [mutationDetected, listRef]
    );

    useMutationObservable(listRef.current, onListMutation, OPTIONS)

    useEffect(() => {
        logger.info('rendered SelectEvent');
        backFuncRef(() => {
            setSelectedEventType(null);
            history.goBack();
        })
    }, [])

    useEffect(() => {
        if (isSubmitted && !isLoggingInProgress) {
          navigateToNextScheduleScreen(path);
        }
    }, [isSubmitted, isLoggingInProgress, hostSelectionConfig])

    const onSubmit = () => {
        logger.info('schedule event type submitted', { selectedEventType })
        setIsSubmitted(true);
    }

    return (
        <>
            <PageHeader
                title={<FormattedMessage {...scheduleScreenMessages.select_event_secondary} />}
            />
            <PageScrollContainer children={
                <ScheduleEventsList listRef={listRef} />
            } />
            <PageFooter
                primaryButtonLabel={<FormattedMessage {...scheduleScreenMessages.cta_next} />}
                isLoading={isSubmitted && isLoggingInProgress}
                isDisabled={!selectedEventType}
                onSubmitPrimary={onSubmit}
                secondaryButtonLabel={<LeaveMessageEvent />}
                onSubmitSecondary={() => history.push({
                    pathname: PAGES.CONTACT,
                    isBackAllowed: true,
                    preventSchedule: true
                })} />
        </>
    )
}
