import React, { useState, useEffect, useRef, useCallback } from 'react';
import MicIcon from '../../assets/icons/Mic';
import VideocamIcon from '../../assets/icons/Videocam';
import MicNoneIcon from '../../assets/icons/MicNone';
import VolumeUpOutlinedIcon from '../../assets/icons/VolumeUpOutlined';
import { useDevices } from './hooks/useDevices';
import { DeviceSelector } from './deviceSelector';
import useAudioVolume from './hooks/useAudioVolume';
import AudioLevelIndicator from './indicator/AudioLevelIndicator';
import { useCaazamVideoContext, useMediaStreamTrack } from '@caazam/caazam-video-room';

import { useIntl, FormattedMessage } from 'react-intl';
import { deviceSelectorMessages } from './messages';
import { OutlinedButton } from '../boutiq-buttons/BoutiqButton';
import './index.scss';

const TABS_LABELS = {
    audio: deviceSelectorMessages.audio,
    video: deviceSelectorMessages.video,
};
const CONTROLS_LABELS = {
    audioinput: deviceSelectorMessages.microphone,
    audiooutput: deviceSelectorMessages.speakers,
    videoinput: deviceSelectorMessages.camera,
};
const AUDIO_ERROR_MESSAGE = 'This browser does not support the audio element.';
const submitSettingButton = {
    fontSize: 16,
    width: '100%',
    height: 44,
};

export default function AudioVideoSettingsDialog ({ onClose }) {
    const [isAudioInputEnabled, setAudioInputEnabled] = useState(true);
    const [isVideoInputEnabled, setVideoInputEnabled] = useState(true);

    const [isAudioInputPermissionDenied, setAudioInputPermissionDenied] = useState(false);
    const [isVideoInputPermissionDenied, setVideoInputPermissionDenied] = useState(false);

    const videoElementRef = useRef(null);
    const audioElementRef = useRef(null);

    const [selectedTab, setSelectedTab] = useState(0);

    const [currentAudioInput, setCurrentAudioInput] = useState(null);
    const [currentVideoInput, setCurrentVideoInput] = useState(null);
    const [currentAudioOutput, setCurrentAudioOutput] = useState(null);

    const {
        audioInputDevices,
        videoInputDevices,
        audioOutputDevices,
        cameraPermission,
        microphonePermission,
        isPermissionDeviceSupport
    } = useDevices();

    const {
        localTracks,
        replaceAudioInputDevice,
        replaceVideoInputDevice,
        replaceAudioOutputDevice
    } = useCaazamVideoContext();

    const audioMediaTrack = useMediaStreamTrack(localTracks && localTracks.find(track => track.mediaStreamTrack.kind === 'audio'));
    const volume = useAudioVolume(audioMediaTrack);

    const intl = useIntl();

    useEffect(() => {
        if (isPermissionDeviceSupport) {
            if (microphonePermission && microphonePermission.status === 'granted') {
                setAudioInputPermissionDenied(false);
            } else {
                setAudioInputPermissionDenied(true);
            }
        }
    }, [microphonePermission, isPermissionDeviceSupport]);

    useEffect(() => {
        if (isPermissionDeviceSupport) {
            if (cameraPermission && cameraPermission.status === 'granted') {
                setVideoInputPermissionDenied(false);
            } else {
                setVideoInputPermissionDenied(true);
            }
        }
    }, [cameraPermission, isPermissionDeviceSupport]);

    useEffect(() => {
        if (audioInputDevices.length > 0) {
            if (audioInputDevices[0].deviceId && audioInputDevices[0].label) {
                defineAudioInputPermissions(false);
                const audioInputLocalTrack = localTracks.find(track => track.mediaStreamTrack.kind === 'audio');
                if (audioInputLocalTrack) {
                    setAudioInputEnabled(audioInputLocalTrack.isEnabled);
                    const audioinput = { label: audioInputLocalTrack.mediaStreamTrack.label, value: audioInputLocalTrack.mediaStreamTrack.id };
                    setCurrentAudioInput(audioinput);
                }
            } else {
                defineAudioInputPermissions(true);
            }
        } else {
            defineAudioInputPermissions(true);
        }

    },[JSON.stringify(audioInputDevices)]);

    useEffect(() => {
        if (videoInputDevices.length > 0) {
            if (videoInputDevices[0].deviceId && videoInputDevices[0].label) {
                defineVideoInputPermissions(false);
                const videoInputLocalTrack = localTracks.find(track => track.mediaStreamTrack.kind === 'video');
                setVideoInputEnabled(!!videoInputLocalTrack);
                if (videoInputLocalTrack) {
                    const videoInput = { label: videoInputLocalTrack.mediaStreamTrack.label, value: videoInputLocalTrack.mediaStreamTrack.id };
                    if (videoElementRef.current) {
                        videoInputLocalTrack.attach(videoElementRef.current);
                    }
                    setCurrentVideoInput(videoInput);
                }
            } else {
                defineVideoInputPermissions(true);
            }
        } else {
            defineVideoInputPermissions(true);
        }
    },[JSON.stringify(videoInputDevices), videoElementRef]);

    useEffect(()=> {
        const audioOutputLocalTrack = audioOutputDevices.find((device) => device.deviceId === 'default') ||
            audioOutputDevices[0];
        if (audioOutputLocalTrack && audioOutputLocalTrack.deviceId) {
            const audioOutput = { label: audioOutputLocalTrack.label, value: audioOutputLocalTrack.deviceId };
            setCurrentAudioOutput(audioOutput);
        } else {
            const audioOutput = { label: intl.formatMessage(deviceSelectorMessages.default_speaker), value: null };
            setCurrentAudioOutput(audioOutput);
        }
    }, [JSON.stringify(audioOutputDevices)]);

    const defineAudioInputPermissions = (state) => {
        if (!isPermissionDeviceSupport) {
            setAudioInputPermissionDenied(state);
        }
    }

    const defineVideoInputPermissions = (state) => {
        if (!isPermissionDeviceSupport) {
            setVideoInputPermissionDenied(state);
        }
    }

    const onVideoInputChange = useCallback((device) => {
        const videoTrack = localTracks.find(track => track.mediaStreamTrack.kind === 'video');
        if (videoTrack && videoTrack.mediaStreamTrack.label !== device.label) {
            const selectedDevice = { label: device.label, value: device.deviceId };
            setCurrentVideoInput(selectedDevice);
            replaceVideoInputDevice(device.deviceId);
        }
    }, [localTracks, replaceVideoInputDevice]);

    const onAudioInputChange = useCallback((device) => {
        const audioTrack = localTracks.find(track => track.mediaStreamTrack.kind === 'audio');
        if (audioTrack && audioTrack.mediaStreamTrack.label !== device.label) {
            const selectedDevice = { label: device.label, value: device.deviceId };
            setCurrentAudioInput(selectedDevice);
            replaceAudioInputDevice(device.deviceId);
        }
    }, [localTracks, replaceAudioInputDevice]);

    const onAudioOutputChange = useCallback((device) => {
        attachSinkId(
            audioElementRef.current,
            device.deviceId
        );
        replaceAudioOutputDevice(device.deviceId);
        const selectedDevice = { label: device.label, value: device.deviceId };
        setCurrentAudioOutput(selectedDevice);
    }, [replaceAudioOutputDevice, audioElementRef]);

    const attachSinkId = (testSound, sinkId) => {
        if (typeof testSound.sinkId !== 'undefined') {
            testSound
                .setSinkId(sinkId)
                .catch((error) => {
                    let errorMessage = error;
                    if (error.name === 'SecurityError') {
                        errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
                    }
                    console.error(errorMessage);
                });
        } else {
            console.warn('Browser does not support output device selection.');
        }
    };

    const play = () => {
        audioElementRef.current.pause();
        audioElementRef.current.currentTime = 0;
        audioElementRef.current.play();
    };

    const tabs = [
        {

            label: <FormattedMessage {...TABS_LABELS.audio} />,
            icon: <MicIcon color='current' size={24}/>,
            component: {
                content: (
                    <div className='container'>
                        <div className='section'>
                            <h5>{
                                isAudioInputPermissionDenied ?
                                    <FormattedMessage {...deviceSelectorMessages.enable_audio}/> :
                                        isAudioInputEnabled ?
                                            <FormattedMessage {...CONTROLS_LABELS.audioinput} /> :
                                                <FormattedMessage {...deviceSelectorMessages.microphone_is_muted} />}
                            </h5>
                            {isAudioInputEnabled && !isAudioInputPermissionDenied &&
                                <DeviceSelector
                                    value={currentAudioInput}
                                    onChange={onAudioInputChange}
                                    listitems={audioInputDevices}
                                />
                            }
                        </div>
                        <div className='test-controls button'>
                            {isAudioInputEnabled && !isAudioInputPermissionDenied && <button>
                                <MicNoneIcon color='black64' size={24}/>
                                <AudioLevelIndicator volume={volume} />
                            </button>}
                        </div>
                        {isAudioInputEnabled && !isAudioInputPermissionDenied &&
                            <>
                                <div className='section'>
                                    <h5><FormattedMessage {...CONTROLS_LABELS.audiooutput} /></h5>
                                    <DeviceSelector
                                        value={currentAudioOutput}
                                        onChange={onAudioOutputChange}
                                        listitems={audioOutputDevices}
                                    />
                                </div>
                                <div className='test-controls button'>
                                    <button onClick={play}>
                                        <VolumeUpOutlinedIcon color='black64' size={24}/>
                                        <span>
                                            <FormattedMessage {...deviceSelectorMessages.cta_test} />
                                        </span>
                                    </button>
                                    <audio ref={audioElementRef} title='local audio file'>
                                        <source src='sounds/doorbell.mp3' type='audio/mp3' />
                                        {AUDIO_ERROR_MESSAGE}
                                    </audio>
                                </div>
                            </>
                         }
                    </div>
                ),
            },
        },
        {
            label: <FormattedMessage {...TABS_LABELS.video} />,
            icon: <VideocamIcon color='current' size={24}/>,
            component: {
                content: (
                    <div className='container'>
                        <div className='section'>
                            <h5>{isVideoInputPermissionDenied ?
                                <FormattedMessage {...deviceSelectorMessages.enable_video} /> :
                                    isVideoInputEnabled ?
                                        <FormattedMessage {...CONTROLS_LABELS.videoinput} /> :
                                            <FormattedMessage {...deviceSelectorMessages.camera_is_muted} />}
                            </h5>
                            {isVideoInputEnabled && !isVideoInputPermissionDenied &&
                                <DeviceSelector
                                    value={currentVideoInput}
                                    onChange={onVideoInputChange}
                                    listitems={videoInputDevices}
                                />}

                        </div>
                        <div className='test-controls'>
                            <video ref={videoElementRef} playsInline autoPlay></video>
                        </div>
                    </div>
                ),
            },
        },
    ];

    return (
        <div className={`settings-dialog-container`}>
            <div className='tabs-container'>
                {tabs.map((item, index) => (
                    <button
                        key={index}
                        onClick={() => setSelectedTab(index)}
                        className={`tab${index === selectedTab ? ' selected' : ''}`}
                    >
                        <div className='icon'>{item.icon}</div>
                        {item.label}
                    </button>
                ))}
            </div>
            <div className='tab-content'>
                {tabs.map((item, index) => (
                    <div
                        key={index}
                        className={`content${index === selectedTab ? ' active' : ''}`}
                    >
                        {item.component.label}
                        {item.component.content}
                    </div>
                ))}
            </div>
            <div className='submit-button-container'>
                <OutlinedButton style={submitSettingButton} onClick={onClose}>
                    <FormattedMessage {...deviceSelectorMessages.cta_done} />
                </OutlinedButton>
            </div>
        </div>

    );
};
