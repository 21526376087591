const getUserId = (user) => {
  if (user) {
    if (user.uid) {
      return user.uid;
    } else if (user.uuid) {
      return user.uuid;
    } else if (user.id) {
      return user.id;
    }
  }
  return null;
}

const buildUserEntity = (user) => {
  const userId = getUserId(user);
  return {
    ...user,
    uid: userId,
    uuid: userId,
  };
}

const getHostName = (user) => {
  if (user.firstName) {
    return user.firstName;
  } else if (user.displayName) {
    const userNameParts = user.displayName.split(' ');
    return userNameParts[0];
  }
  return 'Host';
}

export {
  buildUserEntity,
  getHostName,
  getUserId,
}
