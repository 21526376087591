import React, { useState, useEffect } from 'react';
import { TagIcon } from '../../assets/icons/TagIcon';
import { useSessionDiscount } from '../../hooks';
import { useCallContextProvider } from '../CallContextProvider';
import { useCallScreenProvider } from '../CallScreenProvider';
import { useCartData } from '../../hooks/useCartData';
import './DiscountInput.scss';

export const DiscountInput = ({discount_message}) => {
    const { callId, activeCartId } = useCallContextProvider();
    const { cartDiscount } = useSessionDiscount(callId, activeCartId);
    const { isCheckoutComplete } = useCallScreenProvider();
    const { cartData } = useCartData()
    const [isDiscountApplied, setIsDiscountApplied] = useState(false);

    useEffect(() => {
        if (cartDiscount) {
            setIsDiscountApplied(true);
        }
    }, [cartDiscount])

    return (
        (cartDiscount && cartData.total > 0 && !isCheckoutComplete) ?
        <div className={`discount-code-input-container${isDiscountApplied ? ' applied' : ''}`}>
            <div className='discount-code-input-message-container'>
                <div className='alert-icon-container'>
                    <TagIcon />
                </div>
                <div className='discount-code-succeed-text-message'>
                    <p>{cartDiscount.summary}</p><span>&nbsp;| {discount_message}</span>
                </div>
            </div>
        </div> : null
    )
}
