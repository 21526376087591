import React from 'react';
import { FormattedMessage } from 'react-intl';
import { scheduleScreenMessages } from '../../messages';
import { AllHostsAvatar } from '../allHostsAvatar';
import './AllHostsItemData.scss';

export const AllHostsItemData = ({ isSelected, setSelectedHost, hosts, scheduledHost }) =>
    <li onClick={() => setSelectedHost(true)}
        className={
            `all-host-data-container
                ${isSelected ? ' checked' : ''}
                ${scheduledHost ? ' scheduled' : ''}`} >

        <AllHostsAvatar hosts={hosts} />
        <p className='host-name'>
            <FormattedMessage {...scheduleScreenMessages.any_host} />
        </p>
    </li>
