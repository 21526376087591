import { useState, useEffect } from 'react';
import { useSessionCart } from '.';
import { useCallContextProvider } from '../components/CallContextProvider';
import usePrevious from '../modules/chat/hooks/usePreviousState';
import { state } from '../constants/menuDrawer';

export const useCartCounter = (openCart, drawerSize, isMobile) => {
    const { callId, activeCartId } = useCallContextProvider();
    const [cartSnapshotsCount, setCartSnapshotsCount] = useState(999);
    const prevCartSnapshotsCount = usePrevious(cartSnapshotsCount);
    const { cartSnapshots, cartSnapshotsLength, cartSnapshotLoading } = useSessionCart(callId, activeCartId);
    const [cartCounter, setCartCounter] = useState({
        total: 0,
        isIncreased: false,
    });

    useEffect(() => {
        if (cartSnapshotsLength > cartCounter.total) {
            setCartCounter({
                total: cartSnapshotsLength,
                isIncreased: true,
            });
            setTimeout(() => {
                setCartCounter((previousCounter) => {
                    return {
                        ...previousCounter,
                        isIncreased: false,
                    };
                });
            }, 3000);
        } else {
            setCartCounter({ total: cartSnapshotsLength });
        }
    }, [cartSnapshotsLength]);

    useEffect(() => {
        if (drawerSize === state.COLLAPSED && prevCartSnapshotsCount < cartSnapshotsLength && !isMobile) {
            openCart();
        }
        setCartSnapshotsCount(cartSnapshotsLength);
    }, [cartSnapshotsLength]);

    return { cartSnapshots, cartCounter, cartSnapshotLoading }
}
