import React from 'react'
import { AvatarImage } from '../../../components/avatarImage/avatar-image';
import './index.scss';

export default function ActiveHostAvatars({ avatars, size = 'md' }) {

    return (
        <div className='active-host-avatars-container'>
            {avatars.map((hostAvatar, index) => (
                <AvatarImage key={hostAvatar.initials + index + hostAvatar.imageUrl} size={size} avatar={hostAvatar} />
            ))}
        </div>
    )
}
