import { parseIdString } from './index';

const NO_VARIANT_TITLE = 'Default Title';

export const generatVariantTitle = (variant) =>
    variant.title === NO_VARIANT_TITLE ? '' : variant.title;

export const getImagePerSelectedVariant = (product, variant) => {
    if (!product) return null;

    const getDefaultImage = () => {
      return product.images.length > 0 ? product.images[0].url : null;
    }

    if (variant) {
      if (variant.image) {
        return variant.image.url
      } else {
        return getDefaultImage()
      }
    } else {
      return getDefaultImage()
    }
  }

export const caazamCartAttribute = (cartAttribute) => ({
    key: 'boutiqapp',
    value: cartAttribute,
});

/**
 * Build Shopify checkout url
 * @param {string}              shopId
 * @param {string}              callId
 * @param {Array.<LineItemes>}  lineItems
 * @param {string}              [discountCode]
 *
 * LineItemes = {
 *  variantId {string},
 *  quantity {integer}
 * }
 */

 /*
 * links to docuemntation (unofficial mostly):
 * https://community.shopify.com/c/Shopify-Design/Use-cart-permalink-to-get-to-cart-and-not-checkout/td-p/201729
 * https://community.shopify.com/c/Shopify-Design/Cart-Use-permalinks-to-pre-load-the-cart/td-p/613702/page/6
 *
 * Exmaples to building cart links (but we're not using this)
 *   no discount - send directly to cart after adding items and attribute
 *   https://caazam-dev-shop.myshopify.com/cart/add?id[]=31591209271338&id[]=31591209271338&id[]=31591211040810&attributes[caazam]=1002
 *   https://caazam-dev-shop.myshopify.com/cart/add?id[]=32004518051882
 *
 *   with discount  - apply discount first and then redirect to cart (URI encoded)
 *   https://caazam-dev-shop.myshopify.com/discount/10per?redirect=%2Fcart%2Fadd%3Fid%5B%5D%3D31591209271338%26id%5B%5D%3D31591209271338%26id%5B%5D%3D31591211040810%26attributes%caazam%5D%3D1005
 *
 */

 /* deprecated shai 2022-05-31
export function buildCheckoutLink(baseUrl, cartPath, cartAttribute, lineItems, customerEmail, discountCode = null) {

    // example - load cart and checkout: https://caazam-dev-shop.myshopify.com/cart/31591209271338:1,31591211040810:1?attributes[caazam]=005e8969-b463-4e6b-b561-ead2d1772d03:PPBqWA9&ref=caazam.com
    // example - only checkout: https://caazam-dev-shop.myshopify.com/checkout/?attributes[caazam]=005e8969-b463-4e6b-b561-ead2d1772d03:PPBqWA9&ref=caazam.com

    if(cartPath) {
        return `${baseUrl}${cartPath}`;
    }

    let url = `${baseUrl}checkout/`
    if (lineItems.length > 0) {
        url = `${baseUrl}cart/`;
        url += lineItems.map(item => `${item.variantId}:${item.quantity}`).join(',');
    }
    url += `?attributes[boutiqapp]=${cartAttribute}`;
    url += `&ref=boutiqapp`;
    if (!!customerEmail) {
        url += `&checkout[email]=${customerEmail}`
    }
    if (!!discountCode) {
        url += `&discount=${discountCode}`
    }

    return url;
}
*/

export function buildAddSyncCartRequest(variant, cartAttribute) {

    const data = [{
        quantity: variant.quantity,
        id: variant.variantId,
    }];

    const attributes = [caazamCartAttribute(cartAttribute)];

    return { data, attributes };
};


export function buildUpdateSyncCartRequest(newVariant, prevVariantId, cartAttribute) {

    const data = [{
        quantity: newVariant.quantity,
        id: newVariant.variantId,
    }];

    if (prevVariantId && prevVariantId !== newVariant.variantId) {
        data.push({
            id: prevVariantId,
            quantity: 0,            
        })
    }
   
    const attributes = [caazamCartAttribute(cartAttribute)];

    return { data, attributes };
};

export function buildDeleteSyncCartRequest(variantId, cartAttribute) {

    const data = [{
        quantity: 0,
        id: variantId,
    }];

    const attributes = [caazamCartAttribute(cartAttribute)];

    return { data, attributes };
};

export function buildDiscountSyncCartRequest(newDiscount, prevDiscount) {

    const data = {
        newDiscount,
        prevDiscount,
    };

    return { data };
};




export function shopifyDataToCart(fromItem, variantData) {

    return {
        productId: parseIdString(fromItem.product_id),
        image: fromItem.image,
        currentPrice: variantData.price,
        productTitle: fromItem.product_title,
        productDescription: fromItem.descriptionHtml,
        variantId: parseIdString(fromItem.variant_id),
        quantity: fromItem.quantity,
        currencyCode: variantData.currencyCode,
        variantTitle: generatVariantTitle(variantData),
        inventoryPolicy: variantData.inventoryPolicy,
        inventoryQuantity: variantData.inventoryQuantity,
    };
}
