import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { scheduleScreenMessages } from '../messages';
import { useCustomerDetails } from '../../../components/CustomerDetailsProvider';
import { BigCalendar } from '../components/big-calendar/BigCalendar';
import { AddToCalendarModal } from '../components/add-to-calendar-modal/AddToCalendarModal';
import { CopyCallLink } from './compoents/copy-call-link/CopyCallLink';
import { useSchdeuleConfigProvider } from '../../../components/ScheduleConfigProvider';
import { PageHeader } from '../../../components/page-header/PageHeader';
import { PageFooter } from '../../../components/page-footer/PageFooter';
import { PageScrollContainer } from '../../../components/page-scroll-container/PageScrollContainer';
import './ScheduleSuccess.scss';
import {useRouteMatch} from "react-router-dom";

const ScheduleSuccessScreen = ({ navigateToNextScheduleScreen }) => {
    const intl = useIntl();
    const { path } = useRouteMatch();
    const { email } = useCustomerDetails();
    const [showAddToCalendarModal, setShowAddToCalendarModal] = useState(false);
    const { selectedSlot: timeSlot, scheduleData } = useSchdeuleConfigProvider();

  const navigateToNextScreen = () => {
    navigateToNextScheduleScreen(path);
  }

    return (
        <>
            <PageHeader
                title={<div className='schedule-success-title-container'>
                    <FormattedMessage {...scheduleScreenMessages.schdeule_success} values={{
                        service_noun: intl.formatMessage(scheduleScreenMessages.service_noun)
                    }} />
                </div>}
            />
            <PageScrollContainer children={
                <div className='schedule-success-content'>
                    <BigCalendar date={timeSlot.timeSlot} />
                    <div className='schedule-confirm-custom-message'>
                        <p className='schedule-confirm-message'>
                            <FormattedMessage {...scheduleScreenMessages.schdeule_confirm_sent} values={{ email: <span className='schedule-confirmation-email'>{email}</span> }} />
                        </p>
                        <FormattedMessage {...scheduleScreenMessages.schdeule_custom_link} values={{ link: null }}>
                            {
                                (msg) => (
                                    !!msg ?
                                        <a className='schedule-custom-link'
                                            href={msg}
                                            target='_blank'>
                                            <span>
                                                {msg}
                                            </span>
                                        </a> : null
                                )
                            }
                        </FormattedMessage>
                    </div>
                </div>
            } />
            <PageFooter
                shadow={true}
                primaryButtonLabel={<FormattedMessage {...scheduleScreenMessages.cta_done} />}
                onSubmitPrimary={navigateToNextScreen}
                secondaryButtonLabel={
                    <div className='secondary-buttons-container'>
                        {scheduleData && <CopyCallLink callLink={scheduleData.callLink} />}
                        <button onClick={() => setShowAddToCalendarModal(true)} className='footer-add-to-calendar-button' >
                            <FormattedMessage {...scheduleScreenMessages.cta_add_to_calendar} />
                            {showAddToCalendarModal && (
                                <AddToCalendarModal closeModal={() => setShowAddToCalendarModal(false)} />
                            )}
                        </button>
                    </div>
                }
            />
        </>
    );
};

export default ScheduleSuccessScreen;
