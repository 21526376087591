import React, { useRef, useEffect, useState } from 'react';
import { useSchdeuleProvider } from '../../../../../components/SchdeuleProvider';
import { ScheduledTag } from '../../../components/scheduled-tag/ScheduledTag';
import { ChevronLeft } from '../../../../../assets/icons/ChevronLeft';
import { ChevronRight } from '../../../../../assets/icons/ChevronRight';
import { scheduleScreenMessages } from '../../../messages';
import { useIntl } from 'react-intl';
import { AnimatedHeaderTabs } from '../../animations/animiated-header-tabs/AnimatedHeaderTabs';
import checkTodayTomorrow from '../../../../../utils/date-formatter';
import { useSchdeuleConfigProvider } from '../../../../../components/ScheduleConfigProvider';
import { smoothScrollBySteps } from '../../../../../utils/smooth-scroll';
import './SchedulerHeaderTabs.scss';

export const SchedulerHeaderTabs = () => {
	const {
		availableSlots,
		scheduleItemData,
		isScheduleLoading
	} = useSchdeuleProvider();
	const {
		currentDay,
		setCurrentDay,
	} = useSchdeuleConfigProvider();
	const intl = useIntl();
	const leftArrowStateRef = useRef(false);
    const rightArrowStateRef = useRef(true);
	const wrapperRef = useRef(null);
	const contnetRef = useRef(null);
	const [preselected, setPreselected] = useState(false);
	const [isSwipePrevented, setIsSwipePrevented] = useState(false);
	const getSlotDay = (dateObject) =>
		new Date(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate());

	const isDayScheduled = (dataObject, data) => {
		let isSlotScheduled = false;
		if (data) {
			isSlotScheduled = dataObject.getTime() === getSlotDay(new Date(data.startTimestamp)).getTime();
		}
		return isSlotScheduled;
	}

	const formatDateTitle = (dateObject) => {
		let daySpec = checkTodayTomorrow(dateObject);
		if (daySpec == 'today') daySpec = intl.formatMessage(scheduleScreenMessages.nouns_today)
		else if (daySpec == 'tomorrow') daySpec = intl.formatMessage(scheduleScreenMessages.nouns_tomorrow)
		return daySpec;
	}

	const formatDate = (dateObject) =>
		new Intl.DateTimeFormat([], {
			month: 'short',
			day: 'numeric',
		}).format(new Date(dateObject));

	const slotsForPage = availableSlots
		? availableSlots.map(item => {
			return ({
				...item,
				formattedDate: {
					weekday: formatDateTitle(item.date),
					date: formatDate(item.date),
				},
				isScheduled: isDayScheduled(item.date, scheduleItemData)
			})
		})
		: [];

	const swipe = (direction = true) => {
		setIsSwipePrevented(true);
		if (!isSwipePrevented) {
			const directionValue = direction ? 1 : -1;
			
            let targetScrollPosition = wrapperRef.current.scrollLeft + directionValue * contnetRef.current.offsetWidth;
            const possibleLeftScrollPos = wrapperRef.current.scrollWidth - directionValue * contnetRef.current.offsetWidth
            if (targetScrollPosition > possibleLeftScrollPos) {
                targetScrollPosition = possibleLeftScrollPos;
            }

            if (targetScrollPosition < 0) {
                targetScrollPosition = 0;
            }

            rightArrowStateRef.current = !(wrapperRef.current.scrollWidth === (targetScrollPosition + contnetRef.current.offsetWidth));
            leftArrowStateRef.current = !(targetScrollPosition === 0)
			smoothScrollBySteps(
				wrapperRef.current,
				wrapperRef.current.scrollLeft,
				targetScrollPosition,
				'left',
				directionValue
			).then(() => setIsSwipePrevented(false))
		}
	}

	useEffect(() => {
		if (wrapperRef.current &&
			contnetRef.current &&
			slotsForPage &&
			!preselected) {
			let index = 0;
			setPreselected(true);

			if (scheduleItemData) {
				if (currentDay) {
					index = currentDay;
				} else {
					index = slotsForPage.findIndex((item => item.isScheduled))
				}
			} else if (currentDay) {
				index = currentDay;
			}
			if (index >= 0) {
				setCurrentDay(index)
				const countOfSwiping = Math.trunc(index / 3)
				if (countOfSwiping >= 1) {
					setIsSwipePrevented(true);
					if (!isSwipePrevented) {
						smoothScrollBySteps(
							wrapperRef.current,
							contnetRef.current.scrollLeft,
							contnetRef.current.scrollLeft + contnetRef.current.offsetWidth * countOfSwiping,
							'left'
						).then(() => setIsSwipePrevented(false))
					}
				}
			}
		}
	}, [wrapperRef.current, contnetRef.current, slotsForPage, scheduleItemData, preselected, currentDay])

	if (!isScheduleLoading) {
		if (slotsForPage.length > 0) {
			return (
				<div className='tabs'>
					<button className={`slots-tabs-navigation${leftArrowStateRef.current ? ' visible' : ''}`} onClick={() => swipe(false)}>
						<ChevronLeft />
					</button>
					<div ref={wrapperRef} className='available-days-list-wrapper'>
						<div ref={contnetRef} className='available-days-list'>
							{slotsForPage.map((item, index) =>
								<div key={index} className='date-tile-wrapper'>
									<span onClick={() => setCurrentDay(index)}
										className={`date-title${currentDay === index ? ' selected' : ''}${item.isScheduled ? ' scheduled' : ''}`}>
										<div className='date-tile-content'>
											<span className='day-name'>{item.formattedDate.weekday}</span>
											<span className='day-tab-item'>
												{item.formattedDate.date}
											</span>
										</div>
										<ScheduledTag />
									</span>
								</div>
							)}
						</div>
					</div>
					<button className={`slots-tabs-navigation${rightArrowStateRef.current ? ' visible' : ''}`} onClick={() => swipe()}>
						<ChevronRight />
					</button>
				</div>
			);
		} else {
			return (<div style={{ height: 110 }}></div>)
		}
	} else {
		return <div className='tabs'><AnimatedHeaderTabs /></div>
	}
};
