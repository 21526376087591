import React from 'react';
import './draft-order-footer.scss';
import {currencyFormatter} from "../../../../../../../utils";
import {FormattedMessage} from "react-intl";
import {cartComponentsMessages} from "../../../../../intl/cart-components/messages";
import {FilledButton} from "../../../../../../../components";
import {useClientConfigProvider} from "../../../../../../../components/ClientConfigProvider";
import {DRAFT_ORDER_COMPLETED} from "../../../../../../../constants/draftOrder";
import LocalMallIcon from '../../../../../../../assets/icons/LocalMall';

const DraftOrderFooter = ({draftOrder, openCheckout, isMobile}) => {
  const {appliedDiscount, shippingLine, totalTax, totalPrice, currencyCode, draftOrderId, status, subtotalPrice} = draftOrder;
  const { featureSupport } = useClientConfigProvider();

  const { checkoutDisabled } = featureSupport;

  const isCompleted = status === DRAFT_ORDER_COMPLETED

  const subtotal = (parseFloat(subtotalPrice) + parseFloat(appliedDiscount?.amountV2?.amount ?? 0)).toFixed(2);

  const onCheckout = () => {
    openCheckout({
      total: totalPrice,
      currencyCode
    }, draftOrderId)
  }

  const renderCheckoutButton = () => {
    const style = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0 20px',
    }

    if (isMobile) {
      style.width = '140px';
      style.height = '40px';
      style.padding = '0px';
    }
    return (
      <div
        className='checkout-actions'
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          margin: '20px'
        }}>
        <FilledButton
          onClick={onCheckout}
          disabled={checkoutDisabled}
          className='button'
          style={style}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}>
            {!isMobile &&
              <LocalMallIcon style={{ color: 'white', marginRight: '20px' }} />
            }
            <FormattedMessage {...cartComponentsMessages.cta_checkout} />
          </div>

        </FilledButton>
      </div>
    );
  }

  const priceContainer = ({title, price, description = '', isTotal = false}) => (
    <div className={`draft-order-price`}>
      <p className={`title${isTotal ? ' total' : ''}`}><FormattedMessage {...title} /></p>
      <p className='description'>{description}</p>
      <p className={`price${isTotal ? ' bold' : ''}`}>{price}</p>
    </div>
  )

  return (
    <div className='draft-order-footer'>
      <div className='divider' />
      {!isCompleted && (
        <div className='draft-order-footer-description'>
          {(priceContainer({
            title: cartComponentsMessages.draft_order_sub_total,
            price: currencyFormatter(subtotal, currencyCode),
          }))}
          {appliedDiscount && (
            priceContainer({
              title: cartComponentsMessages.draft_order_discount,
              price: currencyFormatter(appliedDiscount.amountV2.amount * -1, currencyCode),
              description: appliedDiscount.description
            })
          )}
          {shippingLine && (
            priceContainer({
              title: cartComponentsMessages.draft_order_shipping,
              price: currencyFormatter(shippingLine.discountedPriceSet.shopMoney.amount, currencyCode),
              description: shippingLine.title
            })
          )}
          {totalTax && (
            (priceContainer({
              title: cartComponentsMessages.draft_order_tax,
              price: currencyFormatter(totalTax, currencyCode)
            }))
          )}
          {(priceContainer({
            title: cartComponentsMessages.draft_order_total,
            price: currencyFormatter(totalPrice, currencyCode),
            isTotal: true
          }))}
        </div>
      )}
      {!isCompleted && (
        <div className='divider' />
      )}
      <div className='end'>
        {!isCompleted && renderCheckoutButton()}
      </div>
    </div>
  )
}

export default DraftOrderFooter