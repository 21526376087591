import React from "react";

export const CloseIcon = () =>
    <svg width="13px" height="13px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="client-flow---Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
            <g id="select-assistant-(scheduled-call)" transform="translate(-914.000000, -193.000000)" stroke="#3F3F3F" strokeWidth="2">
                <g id="Group-9" transform="translate(480.000000, 159.000000)">
                    <g id="icon/X/on-pic" transform="translate(424.000000, 24.000000)">
                        <path d="M11.5955144,21.0761048 L21.0711523,11.5905619 M21.0711523,21.0761048 L11.5955144,11.5905619" id="Combined-Shape"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
