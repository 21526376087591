import React from 'react';
import { BoutiqRadio } from './components/boutiq-choice/boutiq-radio/BoutiqRadio';
import { BoutiqSelect } from './components/boutiq-select/BoutiqSelects';
import { BoutiqTextField } from './components/boutiq-text-field/BoutiqTextField';
import { BoutiqCheckbox } from './components/boutiq-choice/boutiq-checkbox/BoutiqCheckbox';
import { BoutiqSingleCheckbox } from './components/boutiq-choice/boutiq-single-checkbox/BoutiqSingleCheckbox';
import { useIntl } from 'react-intl';
import './BoutiqForm.scss';

export const BoutiqForm = ({
    schema,
    handleChange,
    validateOnBlur,
    values,
    errors,
    omitError,
    refProvider = () => {}
}) => {
    const intl = useIntl();

    return (
        <form className='boutiq-custom-form'>
            {schema.fields?.map((field, index) => {
                switch (field.type) {
                    case 'title':
                        return (
                            <p key={index} className='schedule-form-label'>
                                {field.label || field.multiLanguageLabel && intl.formatMessage(field.multiLanguageLabel)}
                            </p>
                        );
                    case 'text': case 'email': case 'phone':
                        return (
                            <BoutiqTextField
                                fieldId={field.id}
                                refProvider={refProvider}
                                key={index}
                                required={field.required}
                                label={field.label || field.multiLanguageLabel && intl.formatMessage(field.multiLanguageLabel)}
                                placeholder={field.placeholder || field.multiLanguagePlaceholder && intl.formatMessage(field.multiLanguagePlaceholder)}
                                type={field.type}
                                validationType={field.type}
                                fieldName={field.id}
                                value={values[field.id]}
                                rows={field.rows}
                                error={errors && errors[field.id]}
                                handleChange={handleChange}
                                onSubmit={validateOnBlur}
                            />
                        );
                    case 'select':
                        return (
                            <BoutiqSelect
                                refProvider={refProvider}
                                key={index}
                                field={field}
                                errors={errors}
                                fieldChanged={handleChange}
                                value={values[field.id] || ''}
                            />
                        );
                    case 'choice':
                        return (
                            <BoutiqRadio
                                refProvider={refProvider}
                                key={index}
                                field={field}
                                handleChange={handleChange}
                                values={values[field.id] || { selected: '' }}
                                errors={errors}
                                omitError={omitError}
                                validateOnBlur={validateOnBlur}
                            />
                        );
                    case 'multi-choice':
                        return (
                            <BoutiqCheckbox
                                refProvider={refProvider}
                                key={index}
                                field={field}
                                errors={errors}
                                omitError={omitError}
                                fieldChanged={handleChange}
                                value={values[field.id]}
                                validateOnBlur={validateOnBlur}
                            />
                        );
                    case 'checkbox':
                        return (
                            <BoutiqSingleCheckbox
                                refProvider={refProvider}
                                key={index}
                                field={field}
                                errors={errors}
                                fieldChanged={handleChange}
                                value={values[field.id]}
                            />
                        );
                    default:
                        return null
                }
            })}
        </form>
    )
}
