import React from 'react';
import EmptyImage from '../../../../../../components/emptyImage';
import { MainImage } from '../../main-image/MainImage';
import { currencyFormatter } from '../../../../../../utils';
import { MobileCounter } from '../../../../../../components/counter/mobile/MobileCounter';
import { useCallContextProvider } from '../../../../../../components/CallContextProvider';
import { useSessionCart, useSessionCartActions } from '../../../../../../hooks';
import { useProductViewProvider } from '../../../../../../components/ProductViewProvider';
import { EmptyTab } from '../../empty-tab/EmptyTab';
import { useModalViewProvider } from '../../../../../../components/mobile-providers/ModalViewProvider';
import { ProductDetailsView } from '../../modals/product-details-view/ProductDetailsView';
import { useIntl, FormattedMessage } from 'react-intl';
import { cartComponentsMessages } from '../../../../intl/cart-components/messages';
import { IconEmptyCartMobile } from '../../../../../../assets/icons/mobile/EmptyCartMobile';
import { IconTrashMobile } from '../../../../../../assets/icons/mobile/TrashMobile';
import './cart-items-list.scss';

export const CartItemslist = () => {
	const intl = useIntl();
	const { callId, activeCartId } = useCallContextProvider();
	const { cartSnapshots } = useSessionCart(callId, activeCartId);
	const { viewCartProduct, clearSelectedProduct } = useProductViewProvider();
	const { updateCartProduct, removeCartProduct } = useSessionCartActions(callId, activeCartId);
	const { showView } = useModalViewProvider();

	const openDetails = async (key, product) => {
		await viewCartProduct(key, product);
		const modal = {
			dismissAlloved: true,
			type: 'product-details',
			onDismiss: clearSelectedProduct,
			component: <ProductDetailsView />
		}
		showView(modal);
	};

	const addOne = (snapshotKey, quantity) => {
		updateCartProduct(snapshotKey, { quantity: quantity + 1 });
	};

	const removeOne = (snapshotKey, quantity) => {
		updateCartProduct(snapshotKey, { quantity: Math.max(quantity - 1, 1) });
	};

	return (
		cartSnapshots.length > 0 ? <div className='mobile-product-grid'>
			{cartSnapshots.map((snapshot) => {
				const snapshotKey = snapshot.key;
				const product = snapshot.val();
				const {
					image,
					productTitle,
					currencyCode,
					currentPrice,
					currentCompareToPrice,
					variantTitle,
					quantity,
					inventoryPolicy,
					inventoryQuantity,
				} = product;

				return (
					<div className='mobile-product-card' key={snapshotKey}>
						<div className='mobile-product-card-image'>
							{
								image
									? <MainImage
										src={image}
										customSize={'100%'}
										viewItem={() => openDetails(snapshot.key, product)} />
									: <EmptyImage
										isMobile={true}
										product={product}
										viewItem={() => openDetails(snapshot.key, product)}
										size='m'
										label={product.productTitle}
									/>
							}
						</div>
						<div className='mobile-product-card-details'>
							<div className='mobile-description-container'>
								<p onClick={() => openDetails(snapshot.key, product)} className='mobile-product-title'>{productTitle}</p>
								<div className='mobile-variant-options-container'>
									<div className='mobile-price-container'>
										<p className='mobile-actual-price'>{currencyFormatter(currentPrice, currencyCode)}</p>
										{currentPrice !== currentCompareToPrice && currentCompareToPrice ? (
											<p className='mobile-old-price'>
												{currencyFormatter(currentCompareToPrice, currencyCode)}
											</p>
										) : null}
									</div>
									{variantTitle && <div className='mobile-options-container'>
										<p className='mobile-product-variant-data'>
											{variantTitle}
										</p>
									</div>}
								</div>
							</div>
							<div className='mobile-product-params'>
								<MobileCounter
									quantity={quantity}
									inventoryPolicy={inventoryPolicy}
									inventoryQuantity={inventoryQuantity}
									onDecrease={() => removeOne(snapshotKey, quantity)}
									onIncrease={() => addOne(snapshotKey, quantity)} />
							</div>
						</div>
						<div
							id='product-card-trash'
							className='mobile-product-card-trash'
							onClick={() => removeCartProduct(snapshot.key, product)}>
							<IconTrashMobile />
						</div>
					</div>
				);
			})}
		</div> : <EmptyTab
			message={<FormattedMessage {...cartComponentsMessages.cart_empty} values={{
				host_noun: intl.formatMessage(cartComponentsMessages.host_noun),
			}} />}
			icon={<IconEmptyCartMobile />}
		/>
	);
};
