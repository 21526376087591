import React, { useEffect, useState, useCallback } from 'react';
import firebase from './Firebase';
import {getFirestore, setDoc, doc, serverTimestamp} from 'firebase/firestore';

import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { logger } from './logging';
import { CaazamVideoProvider } from '@caazam/caazam-video-room';
import * as analytics from './utils/analytics-cross-domain';
import { useClientAuthProvider } from './components/ClientAuthProvider';
import { useClientConfigProvider } from './components/ClientConfigProvider';
import { useSchdeuleProvider } from './components/SchdeuleProvider';
import { useCallContextAPI } from './hooks/useCallContextAPI';

import VideoCall, { VIDEO_CALL_PAGES } from './components/VideoCall';
import ErrorPage from './pages/error-page/error-page';
import ChatPage from './pages/chat-page';
import SchedulingScreen from './pages/scheduling/schedule-screen';
import { PAGES } from "./constants/routes";
import IntroPage from './pages/intro/intro-page';
import { REQ_HEADER_TYPES, useInterWindowMessageProvider } from './components/InterWindowMessageProvider';
import AppHeaderProvider from './components/AppHeaderProvider';
import ScheduleConfigProvider from './components/ScheduleConfigProvider';
import CaazamError from './utils/errors';

const RECENTLY_VIEWED_WINDOW_HOURS = 24;

const firestore = getFirestore(firebase);

export default function ClientApp({ isAppDisplayed }) {
  const { postMessage } = useInterWindowMessageProvider();
  const currentLocation = useLocation();
  const history = useHistory();

  const { contextId, authError, authLoading } = useClientAuthProvider();

  const {
    isScheduleLink,
    showIntro,
    allowOutgoingCalls,
    disableScheduling,
    isStandaloneApp,
    dynamicConfig,
    isMobile,
    featureSupport,
  } = useClientConfigProvider();
  const { availableSlots, /* isScheduleLoading */ } = useSchdeuleProvider();
  const { contextCancel } = useCallContextAPI(contextId);
  const [appError, setAppError] = useState(null);

  const shouldCancelContext = currentLocation.pathname === '/video-call' || currentLocation.pathname === `/video-call/${VIDEO_CALL_PAGES.WAITING_ROOM}`;

  useEffect(() => {
    analytics.sendScreeView(currentLocation.pathname);
    logger.info('currentLocation', { pathname: currentLocation.pathname })
  }, [currentLocation]);

  useEffect(() => {
    // https://www.igvita.com/2015/11/20/dont-lose-user-and-app-state-use-page-visibility/
    // https://stackoverflow.com/questions/61351103/jquery-detect-mobile-browser-close-event
    if (shouldCancelContext) {
      window.addEventListener('beforeunload', contextCancel);
      window.addEventListener('pagehide', contextCancel);
      return () => {
        window.removeEventListener('beforeunload', contextCancel);
        window.removeEventListener('pagehide', contextCancel);
      }
    }
  }, [shouldCancelContext, contextCancel]);

  useEffect(() => {

    async function callback(payload) {
      if (payload && payload.length > 0) {
        await setDoc(doc(firestore, "sessions", contextId, 'sessionData', 'userRecentlyViewed'), {
          productsViewed: payload,
          updatedAt: serverTimestamp(),
        }, { merge: true })
      }
    }

    if (!authLoading && contextId) {
      postMessage(REQ_HEADER_TYPES.RECENTLY_VIEWED, { periodHours: RECENTLY_VIEWED_WINDOW_HOURS }, callback);
    }
  }, [contextId, authLoading]);

  useEffect(() => {
    if (authError) {
      logger.error('CLIENT APP ERROR', authError);
      analytics.sendCallEvent('auth_error');
      setAppError(authError);
      history.push(PAGES.ERROR);
    }

  }, [authError]);

  useEffect(()=>{
    if (isAppDisplayed && !isScheduleLink && !allowOutgoingCalls && disableScheduling) {
      let deadendError = new CaazamError(405, 'deadend - scheduling disabled and outgoing calls disallowed ');
      onAppError(deadendError);
    }
  },[isAppDisplayed])

  const onCallError = (error) => {
    logger.error('APP CALL ERROR', error);
    error.isCallError = true;
    analytics.sendCallEvent('call_error', error.statusCode || null);
    setAppError(error);
    history.push(PAGES.ERROR);
  };

  const onAppError = (error) => {
    logger.error('APP ERROR', error);
    analytics.sendCallEvent('call_error', error.statusCode || null);
    setAppError(error);
    history.push(PAGES.ERROR);
  };

  const onVideoError = (error) => {
    logger.error('Video ERROR', error);
  };

  const onClose = useCallback((props) => {
    if (shouldCancelContext) contextCancel();
    let payload = null;
    if (props?.resetStack) {
      payload = { resetStack: true };
    }
    postMessage(REQ_HEADER_TYPES.CLOSE_CAAZAM, payload);
  }, [shouldCancelContext, contextCancel])

  const onVideoRequested = () => {
    analytics.sendCallEvent('host_requested');
    postMessage(REQ_HEADER_TYPES.VIDEO_CALL_REQUESTED);
  };

  const onVideoConnect = () => {

    function action() {
      if (!isStandaloneApp && !isMobile) {
        postMessage(REQ_HEADER_TYPES.VIDEO_CALL_CONNECTED);
      }
    };

    action();
    analytics.sendCallEvent('call_connected');
  };

  const onVideoDisconnect = () => {

    function action() {
      if (!isStandaloneApp && !isMobile) {
        postMessage(REQ_HEADER_TYPES.VIDEO_CALL_DISCONNECTED);
      }
    };

    action();
  };

  const saveToCart = (body) => {
    if (!isStandaloneApp) {
      postMessage(REQ_HEADER_TYPES.ADD_TO_CART, { ...body })
    }
  };

  const getVideoRoomTheme = () => {
    // default for the client is black and sharp corners
    var theme = {
      ctrlButtonColor: 'black',
      ctrlBarRadius: '8px',
      statusMessageRadius: '4px',
      statusMessageBGColor: 'black',
    };
    // if config overrides
    if (dynamicConfig && dynamicConfig.app_primary_color) {
      theme.ctrlButtonColor = dynamicConfig.app_primary_color;
      theme.statusMessageBGColor = dynamicConfig.app_primary_color;
    }
    if (dynamicConfig && dynamicConfig.app_corner_style === 'round') {
      delete theme.ctrlBarRadius;
      delete theme.statusMessageRadius;
    }

    return theme;

  }

  const renderVideoCall = () => (
    <CaazamVideoProvider
      allowScreenShare={featureSupport?.screenSharingEnabled}
      gainCorrection={featureSupport?.gainCorrection ?? 0} // for Apple iOS 15.X
      onError={onVideoError}
      onControlEvent={(event, data) => logger.info(`video control user ${event}`, data)}
      themeOptions={getVideoRoomTheme()}>
      {
        isAppDisplayed &&
        <VideoCall
          onError={onCallError}
          onClose={onClose}
          onCallRequested={onVideoRequested}
          onCallConnect={onVideoConnect}
          onCallDisconnect={onVideoDisconnect}
          saveToCart={saveToCart}
        />
      }
    </CaazamVideoProvider>
  )

  const routerDefiner = () => {
    if (!isAppDisplayed) {
      return;
    }

    // if client was opened by schedule link
    if (isScheduleLink)
      return <Redirect to={PAGES.SCHEDULING} />;

    if (showIntro)
      return <Redirect to={PAGES.INTRO} />


    if (allowOutgoingCalls)
      return <Redirect to={PAGES.VIDEO_CALL} />

    if (!disableScheduling)
      return <Redirect to={PAGES.SCHEDULING} />;

    return <Redirect to={PAGES.CONTACT} />;
  }

  return (
    <div className='caazam-main-container-wrapper'>
      <Switch>
        <Route path={PAGES.CHAT}>
          <ChatPage
            close={onClose}
          />
        </Route>
        <AppHeaderProvider onClose={onClose}>
          <Route path='/intro'>
            <IntroPage isAppDisplayed={isAppDisplayed} />
          </Route>
          <Route path={PAGES.VIDEO_CALL}>{renderVideoCall()}</Route>
          <ScheduleConfigProvider>
            <Route path={PAGES.SCHEDULING}>
              <SchedulingScreen onClose={onClose} />
            </Route>
            <Route path={PAGES.CONTACT}>
              <ErrorPage
                close={onClose}
                error={appError}
                contextId={contextId}
                allowScheduling={!disableScheduling && !!(availableSlots?.length > 0)}
                isContact={true}
              />
            </Route>
          </ScheduleConfigProvider>
          <Route path={PAGES.ERROR}>
            <ErrorPage
              close={onClose}
              error={appError}
              contextId={contextId}
              allowScheduling={!disableScheduling && !!(availableSlots?.length > 0)}
            />
          </Route>
          <Route exact path={PAGES.BASE}>
            {routerDefiner()}
          </Route>
        </AppHeaderProvider>
      </Switch>
    </div>
  );
}
