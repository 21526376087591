import {ACTIVE_CHAT} from "../constants/chatActionTypes";

const initialState = {
  id: null,
};

const activeRoom = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVE_CHAT.SET_ACTIVE_ROOM:
      return {
        ...state,
        ...action.activeRoom
      }

    case ACTIVE_CHAT.UPDATE_METADATA:
      return {
        ...state,
        ...action.metadata
      }

    case ACTIVE_CHAT.SET_ACTIVE_ROOM_ID:
      return {
        ...state,
        id: action.id
      }

      case ACTIVE_CHAT.RESET_ACTIVE_ROOM: 
      return {
        id: null,
      }

    default:
      return state;
  }
};

export default activeRoom;
