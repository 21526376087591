import React from 'react';
import './buttons.scss';

const ButtonLoader = () => <div className='button-loader' />;

const CaazamButton = ({ children, isLoading, className, ...buttonProps }) => {
	return (
		<button
			className={`caazam-button ${className}`}
			type={buttonProps.type || 'button'}
			{...buttonProps}
			style={{
				...buttonProps.style,
				backgroundColor: buttonProps.disabled
				  ? 'rgba(0,0,0,0.32)'
				  : null
			}}>
			{isLoading ? <ButtonLoader /> : children}
		</button>
	);
};

export const FilledButton = ({ className, ...props }) => {
	return (
		<CaazamButton
			className={`filled-button ${className}`}
			{...props}
			style={{
				...props.style,
			}}
		/>
	);
};

export const OutlinedButton = ({ className, ...props }) => {
	return (
		<CaazamButton
			className={`outlined-button ${className}`}
			{...props}
			style={{
				...props.style,
			}}
		/>
	);
};

export const TransparentButton = ({
	children,
	isLoading,
	className,
	...buttonProps
}) => {
	return (
		<CaazamButton
			type={'button'}
			className={`transparent-button ${className}`}
			{...buttonProps}
			style={{
				...buttonProps.style,
			}}>
			{isLoading ? <ButtonLoader /> : children}
		</CaazamButton>
	);
};

export const ButtonBase = (props) => {
	return (
		<CaazamButton
			{...props}
			style={{
				color: '#08B273',
				backgroundColor: '#ffffff',
				...props.style,
			}}
		/>
	);
};
