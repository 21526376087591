import React, { useRef } from 'react'
import { Loader } from '../../../../../../../../components';
import SearchIcon from '../../../../../../../../assets/icons/Search';
import './SearchInput.scss';

export const SearchInput = ({
    placeholder,
    isLoading,
    onSubmit
}) => {
    const searchInputRef = useRef(null);

    const handleKeyUp = (e) => {
        if (e.keyCode === 13) {
            if (searchInputRef.current) {
                onSubmit(searchInputRef.current.value);
                searchInputRef.current.blur();
            }
        }
    };

    const handleOnSubmit = () => {
        if (searchInputRef.current) {
            onSubmit(searchInputRef.current.value);
            searchInputRef.current.blur();
        }
    }

    return (
        <span id='search-item-input' className={`search-input`}>
            <input
                ref={searchInputRef}
                className={`search-input-field`}
                type='text'
                placeholder={placeholder}
                onKeyUp={handleKeyUp}
            />
            {isLoading && <Loader width='30px' height='30px' />}
            <SearchIcon
                color='gray' size={24}
                className={`${isLoading ? 'hidden-control' : ''}`}
                onClick={handleOnSubmit}
            />
        </span>
    )
}
