import { useDispatch, useSelector } from 'react-redux';
import {runTransaction, doc, getDoc, updateDoc, serverTimestamp} from 'firebase/firestore';
import { firestore } from '../../firebaseInitialization';
import { FIRESTORE_COLLECTIONS } from "../../constants/firestore";
import {
  updateLastMessageFailure,
  updateLastMessageRequest,
  updateLastMessageSuccess,
  updateRoomBadgesFailure,
  updateRoomBadgesRequest,
  updateClientsInfoRequest,
  updateClientsInfoFailure,
  updateClientsInfoSuccess,
} from "../../actions/activeRoom";
import { buildUserEntity } from "../../utils/auth";
import { seenMessageFailure, seenMessageRequest, seenMessageSuccess, sendMessageFailure } from "../../actions/messages";

export default function useMetadataRequests({ user }) {
  const fs = firestore();
  const dispatch = useDispatch();
  const { id } = useSelector(state => state.activeRoom);

  const updateLastMessage = async ({ message, room = {} }) => {
    const roomId = room.id || id;
    dispatch(updateLastMessageRequest());
    const userEntity = buildUserEntity(user);
    dispatch(updateRoomBadgesRequest());
    const roomRef = doc(fs, FIRESTORE_COLLECTIONS.CHAT_ROOMS, roomId);

    try {
      await runTransaction(fs,async (transaction) => {
        let roomDoc = await transaction.get(roomRef);
        if (roomDoc.exists) {
          const roomData = roomDoc.data();
          let update = { updatedAt: serverTimestamp() };
          if (roomData.metadata?.lastMessage?.timestamp?.toDate().valueOf() < message.timestamp.toDate().valueOf()) {
            update['metadata.lastMessage'] = message;
          } else if (!roomData.metadata?.lastMessage) {
            update['metadata.lastMessage'] = message;
          }

          let badges = roomData.metadata.badges;
          Object.keys(badges).forEach(k => {
            if (k !== userEntity.uuid) {
              if (roomData.metadata.seenStatus && roomData.metadata.seenStatus[k]) {
                if (new Date(roomData.metadata.seenStatus[k]).valueOf() < message.timestamp.toDate().valueOf()) {
                  update[`metadata.badges.${k}`] = badges[k] ? badges[k] + 1 : 1;
                }
              }
              else {
                update[`metadata.badges.${k}`] = badges[k] ? badges[k] + 1 : 1;
              }
            }
          });
          await transaction.update(roomRef, update);
        }
      });
      dispatch(updateLastMessageSuccess());
    } catch (error) {
      console.error('updateLastMessage', error);
      dispatch(updateLastMessageFailure(error.message));
    }
  }

  const seenMessages = async () => {
    try {
      dispatch(seenMessageRequest());

      const roomRef = doc(fs, FIRESTORE_COLLECTIONS.CHAT_ROOMS, id);
      const userEntity = buildUserEntity(user);
      const room = await getDoc(roomRef);

      if (!room.exists) {
        console.error('Room does not exist')
        return;
      }

      const roomData = room.data();
      await updateDoc(roomRef,{
        [`metadata.seenStatus.${userEntity.uid}`]: roomData.metadata.lastMessage.timestamp.toDate().toISOString(),
        [`metadata.badges.${userEntity.uid}`]: 0
      });
      dispatch(seenMessageSuccess(userEntity.uid));
    } catch (error) {
      console.error(error)
      dispatch(seenMessageFailure(error.message))
    }
  }

  const updateClientsInfo = ({ participantUuid, profileId = null, chatId }) => {
    if (!participantUuid) {
      return;
    }

    dispatch(updateClientsInfoRequest());
    const metaRef = doc(fs, FIRESTORE_COLLECTIONS.CHAT_ROOMS, chatId);


    return updateDoc(metaRef, {
      [`participants.${participantUuid}.profileId`]: profileId,
    })
      .then(() => dispatch(updateClientsInfoSuccess()))
      .catch((error) => dispatch(updateClientsInfoFailure(error.message)));
  }

  return {
    updateLastMessage,
    updateClientsInfo,
    seenMessages,
  };
}

