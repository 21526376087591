import React, {useMemo} from 'react';
import useRoomsListener from "../api/rooms/listeners";
import {ROLES} from "../constants/participantRoles";
import {useSelector} from "react-redux";

export const useCustomerChatData = (user, shopId) => {
  const userEntity = useMemo(() => ({...user, role: ROLES.PARTICIPANT}),[user]);
  useRoomsListener(userEntity, shopId);
  const activeRoom = useSelector(state => state.activeRoom);

  return {
    activeRoom,
  };
};
