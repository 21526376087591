import { APP_DIAGNOSTICS_ACTION_TYPES } from "../constants/appDiagnostics";

const initPreflight = {
  report: null,
  error: null,
  stopped: false,
  tokenError: null,
  signalingGatewayReachable: false,
  turnServersReachable: false,
};

const initialState = {
  preflightTest: Object.assign({}, initPreflight),
  twilioStatus: null,
  twilioStatusError: null,
  twilioStatusFinished: false,
  preflightTestInProgress: false,
  preflightTestFinished: false,
  reportSent: false,
};

const appDiagnostics = (state = initialState, action) => {
  switch (action.type) {
    case APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_STARTED:
      return {
        ...state,
        preflightTestInProgress: true,
        preflightTestFinished: false,
        preflightTest: {
          ...Object.assign({}, initPreflight),
          tokenError: action.error,
          error: action.error,
        }
      }    
    case APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_PROGRESS: {
      const preflightTest = {
        ...state.preflightTest,
      };

      // Safari does not support RTCDtlsTransport, so we use 'peerConnectionConnected' to determine if Signaling Gateway is reachable
      if (action.progress === 'dtlsConnected' || action.progress === 'peerConnectionConnected') {
        preflightTest.signalingGatewayReachable = true;
      }

      if (action.progress === 'mediaAcquired') {
        preflightTest.turnServersReachable = true;
      }
      return {
        ...state,
        preflightTest: preflightTest
      }
    }

    case APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_COMPLETED:
      return {
        ...state,
        preflightTest: {
          ...state.preflightTest,
          report: action.report,
        },
        preflightTestFinished: true,
        preflightTestInProgress: false,
      }

    case APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_FAILED:
      return {
        ...state,
        preflightTest: {
          ...state.preflightTest,
          error: action.error,
          report: action.report,
        },
        preflightTestFinished: true,
        preflightTestInProgress: false,
      }
    case APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_STOPPED:
      return {
        ...state,
        preflightTest: {
          ...state.preflightTest,
          stopped: true,
          report: action.report,
        },
        preflightTestInProgress: false,
        preflightTestFinished: false,
      }

    case APP_DIAGNOSTICS_ACTION_TYPES.SET_TWILIO_STATUS:
      return {
        ...state,
        twilioStatus: action.statusObj,
        twilioStatusError: null,
        twilioStatusFinished: true,
      }

    case APP_DIAGNOSTICS_ACTION_TYPES.SET_TWILIO_STATUS_ERROR:
      return {
        ...state,
        twilioStatusError: action.error,
        twilioStatusFinished: true,
      }
      case APP_DIAGNOSTICS_ACTION_TYPES.REPORT_SENT:
        return {
          ...state,
          reportSent: true,
        }
    default:
      return state;
  }
};

export default appDiagnostics;
