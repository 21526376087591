import firebase from '../Firebase'
import {getDatabase, ref, child} from 'firebase/database';
import { useObjectVal } from 'react-firebase-hooks/database';


export const useSessionCartItem = (callId, activeCartId, cartKey) => {
  const db = getDatabase(firebase);
  const cartRef = activeCartId && cartKey ? child(ref(db, `session/${callId}/cart/${activeCartId}/products`), cartKey) : null;

  const [cartItem] = useObjectVal(cartRef);
  return cartItem;
};
