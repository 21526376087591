import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CaazamError from '../../utils/errors';
import { useClientAuthProvider } from '../../components/ClientAuthProvider';
import { useCustomerDetails } from '../../components/CustomerDetailsProvider';
import { useSchdeuleProvider } from '../../components/SchdeuleProvider';
import { useContact } from '../../hooks/useShop';
import { useClientConfigProvider } from '../../components/ClientConfigProvider';
import { sendCallEvent } from '../../utils/analytics-cross-domain';
import { formatDateString } from '../../utils/formatDateString';
import { FormattedMessage, useIntl } from 'react-intl';
import { errorPageMessages } from './messages';
import { AppHeaderContext } from '../../components/AppHeaderProvider';
import { PageFooter } from '../../components/page-footer/PageFooter';
import { PageHeader } from '../../components/page-header/PageHeader';
import { useForm } from '../../components/boutiq-form/hooks/useForm';
import { SecondaryButtonEvent } from '../../components/page-footer/secondary-button-event/SecondaryButtonEvent';
import ShopLogoContainer from '../../components/shop-logo-container/shop-logo-container';
import { PageScrollContainer } from '../../components/page-scroll-container/PageScrollContainer';
import { BoutiqForm } from '../../components/boutiq-form/BoutiqForm';
import './error-page.scss';
import { PAGES } from "../../constants/routes";

export const DEFAULT_SEND_MESSAGE_FORM_SCHEMA = (placeholder) => ({
	version: 'default_contact_form',
	createdAt: '2022-10-13T10:22:17.848Z',
	fields: [
		{
			type: 'email',
			id: 'email',
			multiLanguageLabel: errorPageMessages.email,
			required: true
		},
		{
			type: 'text',
			multiLanguageLabel: errorPageMessages.textarea_label,
			rows: 2,
			id: 'message',
			multiLanguagePlaceholder: placeholder,
		}
	]
})


const ErrorPage = ({
	close,
	error,
	isContact = false,
	allowScheduling,
}) => {
	const intl = useIntl();
	const { name, email } = useCustomerDetails();
	const { sessionData } = useClientAuthProvider();
	const { preventSchedule, isBackAllowed } = useLocation();
	const [formSending, setFormSending] = useState(false);
	const { isShopOpen, allowOutgoingCalls, dynamicConfig, scheduleLateThreshold } = useClientConfigProvider();
	const { scheduleItemData, scheduleId } = useSchdeuleProvider();
	const { contactRequest } = useContact();
	const history = useHistory();
	const authError = error instanceof CaazamError && error.statusCode === 403;
	const { backFuncRef } = useContext(AppHeaderContext);
	const customLogo = dynamicConfig && dynamicConfig.app_custom_logo;

	// "messages.contact_error": "Something went wrong",
	const [errorHeader, setErrorHeader] = useState(<FormattedMessage {...errorPageMessages.contact_error} />);
	// "messages_contact_text": "Send us a message and we'll get back to you",
	const [errorSubheader, setErrorSubeader] = useState(<FormattedMessage {...errorPageMessages.contact_text} />);
	const [scheduledTimeHeader, setScheduledTimeHeader] = useState(null);

	const [hideContactForm, setHideContactFrom] = useState(false);


	const navigateBack = () => {
		history.goBack();
	};

	const onSubmit = async () => {

		if (hideContactForm) {
			close({resetStack: true});
		}

		const customerEmail = values.email
		const message = values.message || ''
		setFormSending(true);

		let errorMsg = null;
		if (error) {
			if (error.name === 'CaazamError') {
				errorMsg = JSON.stringify(error);
			} else {
				errorMsg = error.message;
			}
		}

		try {
			const res = await contactRequest(
				name,
				customerEmail,
				message,
				errorMsg,
			);
			if (res.ok) {
				sendCallEvent('support_message_posted');
				close({resetStack: true});
				return;
			} else {
				const errorJson = await res.json();
				throw new CaazamError(
					res.status,
					errorJson.error.reason || 'Something went wrong'
				);
			}
		} catch (err) {
			console.error(err);
			close({resetStack: true});
		}
	};

	function setHostUnavailableErrorMessage() {
		//"messages_contact_host_busy": "Sorry, your {host_noun} is not available right now and can't take your video call",
		setErrorHeader(<FormattedMessage {...errorPageMessages.contact_host_busy} values={{
			br: <br />,
			host_noun: intl.formatMessage(errorPageMessages.host_noun),
		}} />);
		//"messages_contact_text_host_busy": "Send us a message and we'll get back to you",
		setErrorSubeader(<FormattedMessage {...errorPageMessages.contact_text_host_busy} values={{
			br: <br />,
			host_noun: intl.formatMessage(errorPageMessages.host_noun),
		}} />);
	}

	function setShopUnavailableErrorMessage() {
		//"messages_contact_host_busy": "Sorry, your {host_noun} is not available right now and can't take your video call",
		setErrorHeader(<FormattedMessage {...errorPageMessages.contact_store_busy} values={{
			br: <br />,
			host_noun: intl.formatMessage(errorPageMessages.host_noun),
		}} />);
		// "messages_contact_text": "Send us a message and we'll get back to you",
		setErrorSubeader(<FormattedMessage {...errorPageMessages.contact_text} values={{
			br: <br />,
			host_noun: intl.formatMessage(errorPageMessages.host_noun),
		}} />);
	}

	function handleScheduledCallError(error, scheduleData) {

		const scheduledTimeSlot = new Date(scheduleData.startTimestamp);
		const formattedDate = formatDateString(
			intl.formatMessage(errorPageMessages.nouns_today),
			intl.formatMessage(errorPageMessages.nouns_tomorrow),
			scheduledTimeSlot);

		let { statusCode } = error;

		setHideContactFrom(dynamicConfig?.hideContactForm?.scheduledCalls);

		function getScheduledTimeText(formattedDate) {
			let messageDef = errorPageMessages.scheduled_call_start_at;
			if ((Date.now() - scheduledTimeSlot.getTime()) >= scheduleLateThreshold * 1000) {
				messageDef = errorPageMessages.scheduled_call_start_at_late;
			}

			return (
				<FormattedMessage {...messageDef}
					values={{
						time: formattedDate,
					}} />
			);
		}

		function getContactFormSubheader() {

			return (
				<FormattedMessage {...errorPageMessages.contact_text_host_busy_scheduled}
					values={{
						br: <br />,
						mail_link: email => <a href={`mailto:${email}`}>{email}</a>,
					}} >
					{
						(msg => msg ?? null) // to make it optional
					}
				</FormattedMessage>
			);
		}

		switch (statusCode) {
			case 404:
			case 409:
			case 408:
				// client is too early
				if ((scheduledTimeSlot.getTime() - Date.now()) > 0) {
					//"messages_contact_host_busy_scheduled_early": "Sorry, your {host_noun} is not available right now and can't take your video call",
					//"scheduled_call_start_at": "Your appointment is scheduled for {time}", 
					setErrorHeader(
						<FormattedMessage {...errorPageMessages.contact_host_busy_scheduled_early}
							values={{
								host_noun: intl.formatMessage(errorPageMessages.host_noun),
							}} />
					)
					setScheduledTimeHeader(getScheduledTimeText(formattedDate));
					// "messages_contact_text_host_busy_scheduled": "Send us a message and we'll get back to you",
					setErrorSubeader(getContactFormSubheader())
				} // client is a on time or a little late
				else if ((Date.now() - scheduledTimeSlot.getTime()) < scheduleLateThreshold * 1000) {
					// //"messages_contact_host_busy_scheduled": "Sorry, your {host_noun} is not available right now and can't take your video call",
					setErrorHeader(

						<FormattedMessage {...errorPageMessages.contact_host_busy_scheduled}
							values={{
								host_noun: intl.formatMessage(errorPageMessages.host_noun),
							}} />
					);
					setScheduledTimeHeader(getScheduledTimeText(formattedDate));
					// "messages_contact_text_host_busy_scheduled": "Send us a message and we'll get back to you",
					setErrorSubeader(getContactFormSubheader())
				} //client is very late
				else {
					// "messages_contact_host_busy_scheduled_late": "Sorry, your {host_noun} is not available right now and can't take your video call",
					// "scheduled_call_start_at_late": "Your appointment was scheduled for {time}",
					setErrorHeader(

						<FormattedMessage {...errorPageMessages.contact_host_busy_scheduled_late}
							values={{
								host_noun: intl.formatMessage(errorPageMessages.host_noun),
							}} />
					);
					setScheduledTimeHeader(getScheduledTimeText(formattedDate));
					// "messages_contact_text_host_busy_scheduled": "Send us a message and we'll get back to you",
					setErrorSubeader(getContactFormSubheader());
				}
				return;
			case 499:
				setErrorHeader(getScheduledTimeText(formattedDate));
				//setScheduledTimeHeader(getScheduledTimeText(formattedDate));
				// "messages_contact_text_host_busy_scheduled": "Send us a message and we'll get back to you",
				setErrorSubeader(getContactFormSubheader());
				return;
			default:
				//"messages_contact_store_busy": "Sorry, we're not available right now and can't take your video call",
				setErrorHeader(<FormattedMessage {...errorPageMessages.contact_store_busy} values={{ br: <br /> }} />);
				setErrorSubeader(getContactFormSubheader());
				return;
		}
	}

	useEffect(() => {
		if (isBackAllowed) {
			backFuncRef(() => {
				navigateBack();
			})
		} else {
			backFuncRef(null);
		}
	}, [isBackAllowed])

	useEffect(() => {

		// if auth error - show that as first priority
		if (authError) {
			if (error.isCallLinkError) {
				// "messages.call_link_error": "Sorry, the call link used is invalid",
				return setErrorHeader(<FormattedMessage {...errorPageMessages.call_link_error} />);
			} else {
				// "messages.contact_error": "Something went wrong",
				return setErrorHeader(<FormattedMessage {...errorPageMessages.contact_error} />);
			}
		}

		// if is contact us form (no error)
		if (isContact) {
			// "messages.contact_header": "Contact Us",
			return setErrorHeader(<FormattedMessage {...errorPageMessages.contact_header} />)
		}


		// handle calling errors
		if (error?.isCallError) {
			// for scheduled calls errors
			if (scheduleId) {
				if (scheduleItemData) {
					return handleScheduledCallError(error, scheduleItemData);
				}
			} else if (error instanceof CaazamError) {
				switch (error.statusCode) {
					case 404:
					case 408:
					case 409:
						return sessionData?.host?.id ? setHostUnavailableErrorMessage() : setShopUnavailableErrorMessage();
					case 499:
						return setErrorHeader(<FormattedMessage {...errorPageMessages.contact_header} />);
					default:
						return setShopUnavailableErrorMessage();
				}
			}
			return setShopUnavailableErrorMessage();
		} else if (!isShopOpen) {
			//"messages.sorry_closed_header": "Sorry, we're closed right now for video calls",
			return setErrorHeader(<FormattedMessage {...errorPageMessages.sorry_closed_header} />);
		} else {
			//"messages.contact_error": "Something went wrong",
			return setErrorHeader(<FormattedMessage {...errorPageMessages.contact_error} />);
		}

	}, [authError, error, isContact, allowOutgoingCalls, isShopOpen, scheduleId, scheduleItemData])

	const { handleChange, validate, values, errors, omitError, handleSubmit } = useForm(onSubmit, () => { }, false, { email });

	const formSchema = useMemo(() => {
		let placeholder = allowOutgoingCalls
			? errorPageMessages.contact_helper_text
			: errorPageMessages.contact_helper_text_1

		return DEFAULT_SEND_MESSAGE_FORM_SCHEMA(placeholder);
	}, [allowOutgoingCalls])

	return (
		<div className='error-page-container'>
			<PageHeader>
				<div className='error-header-container'>
					{customLogo && <ShopLogoContainer src={customLogo} />}
					<div className='error-title-wrapper'>
						<p className='error-title'>
							{errorHeader}
						</p>
						{scheduledTimeHeader &&
							<p className='error-title'>
								{scheduledTimeHeader}
							</p>
						}
						<p className='error-subtitle'>
							{errorSubheader}
						</p>
					</div>
				</div>

			</PageHeader>
			{!hideContactForm &&
				<PageScrollContainer children={
					<div className='error-client-feedback-form-wrapper'>
						<BoutiqForm
							schema={formSchema}
							handleChange={handleChange}
							validateOnBlur={validate}
							values={values}
							errors={errors}
							omitError={omitError}
						/>
					</div>
				}>
				</PageScrollContainer>
			}
			<PageFooter
				shadow={false}
				onSubmitPrimary={(e) => handleSubmit(e, formSchema)}
				isLoading={formSending}
				primaryButtonLabel={hideContactForm
					? <FormattedMessage {...errorPageMessages.cta_done} />
					: <FormattedMessage {...errorPageMessages.cta_send} />
				}
				secondaryButtonLabel={allowScheduling && !authError && !preventSchedule && <SecondaryButtonEvent label={<FormattedMessage {...errorPageMessages.or_schedule_later} />} />}
				onSubmitSecondary={() => history.push({
					pathname: PAGES.SCHEDULING,
					preventForm: true
				})}
			/>
		</div>
	);
};

export default ErrorPage;
