import React from 'react';
import CloseIcon from '../../assets/icons/Close';

import './fullSizeImageModal.scss';

const FullSizeImageModal = ({closeAction, fullSizeChatImage}) => {
  return (
    <div className='modal-container'>
      <div className='close-icon'>
        <CloseIcon size={20} onClick={closeAction} />
      </div>
      <div className='image-container'>
        <img src={fullSizeChatImage} alt='Image' className='image-real' />
      </div>
    </div>
  )
}

export default FullSizeImageModal;
