import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FilledButton, OutlinedButton } from '../../../../../../../components';
import { sidebarMessages } from '../../../../../intl/sidebar-messages/messages';
import './ProductDetailsSubmitActions.scss';

export const ProductDetailsSubmitActions = ({
    updateVariant,
    addFullProductToCart,
    isSoldOutBtnVisible,
    isUpdateBtnVisible,
    isUpdateBtnDisabled,
    isAddToCartBtnVisible
}) => {
    return (
        <>
            {isSoldOutBtnVisible ?
                <OutlinedButton
                    className='sold-out-button' >
                    <FormattedMessage {...sidebarMessages.cta_sold_out} />
                </OutlinedButton> :
                (<>
                    {isUpdateBtnVisible &&
                        <FilledButton
                            onClick={updateVariant}
                            style={{ height: '40px', width: '100%' }}
                            disabled={isUpdateBtnDisabled} >
                            <FormattedMessage {...sidebarMessages.cta_update} values={{ divider: '', item: '' }} />
                        </FilledButton>}
                    {isAddToCartBtnVisible &&
                        <FilledButton
                            id='add-to-cart'
                            onClick={addFullProductToCart}
                            className='add-to-cart-button' >
                            <FormattedMessage {...sidebarMessages.cta_add_to_cart} />
                        </FilledButton>}
                </>)
            }
        </>
    )
}
