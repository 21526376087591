import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { languageSelectMessages } from '../../../LanguageSettings/messages';
import { LocalizationSettingsContext } from '../../../LocalizationSettingsProvider';
import { OptionListModal } from '../../../option-list-modal/OptionListModal';
import './LocaleSettings.scss';

export const LocaleSettings = () => {
    const intl = useIntl();
    const [locales, setLocales] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [localeSettingVisible, setLocaleSettingVisible] = useState(false);
    const { getPersistedLocale, selectLanguage, appLocales, customerLocale } = useContext(LocalizationSettingsContext);

    useEffect(() => {
        const currentLocale = getPersistedLocale();
        if (currentLocale) {
            setSelectedLanguage({ label: intl.formatMessage(languageSelectMessages[currentLocale]), value: currentLocale });
        } else {
            //default locale
            setSelectedLanguage({ label: intl.formatMessage(languageSelectMessages.default), value: customerLocale });
        }
        if (appLocales) {
            const locales = appLocales.reduce((acc, item) => {
                return [...acc, { label: intl.formatMessage(languageSelectMessages[item]), value: item }];
            }, []);
            setLocales(locales);
        }
    }, []);

    return (
        <div className='boutiq-locale-settings'>
            <button onClick={() => setLocaleSettingVisible(!localeSettingVisible)} className='boutiq-locale-button'>
                {selectedLanguage && selectedLanguage.value}
            </button>
            {localeSettingVisible &&
                <OptionListModal onClose={() => setLocaleSettingVisible(!localeSettingVisible)} children={
                    locales.map(locale =>
                        <button
                            key={locale.value}
                            onClick={() => { setSelectedLanguage(locale); selectLanguage(locale.value); }}
                            className={`locale-item${selectedLanguage && selectedLanguage.value === locale.value ? ' selected' : ''}`}>
                            {locale.label}
                        </button>)
                } />
            }
        </div>
    )
}
