import { defineMessages } from 'react-intl';

export const productDetailsMessages = defineMessages({
  product_variant_select: {
    id: 'messages_product_variant_select'
  },  
  cart_add_no_variant: {
    id: 'messages.cart_add_no_variant'
  },  
  cart_add_no_inventory: {
    id: 'messages.cart_add_no_inventory'
  },  
  product_empty_options: {
    id: 'messages_product_empty_options'
  },  
  cta_back: {
    id: 'button_actions.cta_back'
  },  
  cta_add_to_cart: {
    id: 'button_actions.cta_add_to_cart'
  },  
  cta_update: {
    id: 'button_actions.cta_update'
  },  
  cta_add_to_showroom: {
    id: 'button_actions.cta_add_to_showroom'
  },  
  cta_add_to: {
    id: 'button_actions.cta_add_to'
  },  
  cta_ok: {
    id: 'button_actions.cta_ok'
  },
  added_to_showroom: {
    id: 'messages.added_to_showroom'
  },  
  showroom: {
    id: 'nouns.showroom'
  },  
  product_search_helper: {
    id: 'messages.product_search_helper'
  },  
  product_search_no_results: {
    id: 'messages.product_search_no_results'
  },  
  host_is_presenting: {
    id: 'messages_host_is_presenting'
  }
});
