import { defineMessages } from 'react-intl';

export const waitingRoomMessages = defineMessages({
  acquire_media: {
    id: 'messages_acquire_media',
  },
  browser_media_not_supported: {
    id: 'messages_browser_media_not_supported',
  },
  browser_media_not_supported_ios: {
    id: 'messages_browser_media_not_supported_ios',
  },
  browser_media_not_supported_android: {
    id: 'messages_browser_media_not_supported_android',
  },
  browser_media_abort: {
    id: 'messages_browser_media_abort',
  },
  browser_media_not_allowed: {
    id: 'messages_browser_media_not_allowed',
  },
  browser_media_not_found: {
    id: 'messages_browser_media_not_found',
  },
  browser_media_error: {
    id: 'messages_browser_media_error',
  },
  browser_copy_paste_action: {
    id: 'messages_browser_copy_paste_action'
  },
  browser_this: {
    id: 'messages_browser_this'
  },
  cta_ok: {
    id: 'button_actions.cta_ok'
  },
  conjunction_or: {
    id: 'conjunction_or',
  },
  verb_copy: {
    id: 'verb_copy',
  },
  verb_copy_past: {
    id: 'verb_copy_past',
  },
  powered_by_caazam: {
    id: 'messages.powered_by_caazam',
  }
});
