import React, {useEffect, useMemo, useState} from 'react';
import useActiveRoomListener from "../api/metadata/listeners";
import useMessagesListener from "../api/messages/listeners";
import {useDispatch, useSelector} from "react-redux";
import messagesRequests from "../api/messages/requests";
import {buildUserEntity} from "../utils/auth";
import usePrevious from "./usePreviousState";
import {ROLES} from '../constants/participantRoles';
import {ROOM_STATUS} from "../constants/roomStatuses";
import useMetadataRequests from "../api/metadata/requests";
import {sendSeenActivated} from "../actions/messages";

export const useActiveRoomData = (user = {}, room = {}, roomMessagesDisplayed = true) => {
  const roomId = room.id || null;

  useActiveRoomListener();
  const dispatch = useDispatch();
  const userEntity = useMemo(() => ({...user, role: ROLES.HOST}), [user]);

  const {sendMessage, sendFile} = messagesRequests({user: userEntity});
  const {seenMessages} = useMetadataRequests({user: userEntity});
  const { metadata, id, status } = useSelector(state => state.activeRoom);
  const prevRoomStatus = usePrevious(status);

  const [lastMessageId, setLastMessageId] = useState(null);
  const prevLastMessageId = usePrevious(lastMessageId);

  useMessagesListener({userId: userEntity.uid});

  useEffect(() => {
    if (buildUserEntity(user).uid && prevRoomStatus === ROOM_STATUS.PENDING && status === ROOM_STATUS.ACTIVE && id === roomId) {
      if (roomMessagesDisplayed) {
        // seenMessages();
      }
    }
  }, [status]);

  useEffect(() => {
    if (buildUserEntity(user).uid && id !== roomId && status === ROOM_STATUS.ACTIVE) {
      if (roomMessagesDisplayed) {
        dispatch(sendSeenActivated(true));
        // seenMessages();
      }
    }
  }, [user.id, roomId, status]);

  useEffect(() => {
    if (buildUserEntity(user).uid && id !== roomId) {
      if (roomMessagesDisplayed) {
        // seenMessages();
      }
    }
  }, [user.id, roomId]);

  useEffect(() => {
    if (metadata && metadata.lastMessage && prevLastMessageId !== metadata.lastMessage.id && roomId && id !== roomId) {
      if (roomMessagesDisplayed) {
        // seenMessages();
        setLastMessageId(metadata.lastMessage.id);
      }
    }
  }, [metadata, roomId]);

  return {
    seenMessages,
    sendMessage,
    sendFile,
  };
};
