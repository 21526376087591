import {useEffect, useState} from 'react';
import firebase from '../Firebase'
import {getFirestore, onSnapshot, doc} from 'firebase/firestore';

const useFullScreenMode = (callId) => {
  const firestore = getFirestore(firebase);
  const [isFullScreenEnabled, setStatus] = useState(false);

  useEffect(() => {
    const fullScreenRef = onSnapshot(doc(firestore, 'calls', callId, 'sessionData', 'fullScreenView'), (snapshot) => {
      if (snapshot) {
        const data = snapshot.data();
        if (data) {
          setStatus(data.isEnabled);
        }
      }
    })
    return () => fullScreenRef();
  }, []);

  return {
    isFullScreenEnabled,
  };

};

export default useFullScreenMode;
