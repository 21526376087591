import React from 'react';
import { useIntl } from 'react-intl';
import { boutiqFormMessages } from '../../messages';
import { ErrorContainer } from '../error-container/ErrorContainer';
import './BoutiqSelect.scss';

export const BoutiqSelect = ({ field, refProvider, fieldChanged, errors, value = '' }) => {
    const intl = useIntl();
    return (
        <div ref={refProvider(field.id)} className='boutiq-select-button-group'>
            {field.label && <div className='boutiq-select-button-group-label'>{`${field.label}${field.required ? ` ${intl.formatMessage(boutiqFormMessages.required)}` : ''}`}</div>}
            <div className='select'>
                <select
                    value={value}
                    name={field.id}
                    onChange={(e) => fieldChanged(e, e.target.name, e.target.value)}
                    required={field.required}>
                    <option disabled value=''>{field.placeholder || intl.formatMessage(boutiqFormMessages.select_option)}</option>
                    {field.options.map((option, index) =>
                        <option key={`${option.value}-${index}`} value={option.value}>{option.label}</option>
                    )}
                </select>
            </div>
            <ErrorContainer required={field.required} error={errors[field.id]} />
        </div>
    )
}
