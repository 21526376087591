import { useCallback } from 'react';
import CaazamError from '../utils/errors';

import { useClientAuthProvider } from '../components/ClientAuthProvider';

import {
  handleResponse,
  createAuthenticatedPostRequest,
  createAuthenticatedPutRequest,
  createAuthenticatedGetRequest,
} from '../utils/http';

const baseUrl = process.env.REACT_APP_CAAZAM_REST_EP;

export const useCallContextAPI = (contextId) => {

  const { user } = useClientAuthProvider();

  // shai: need to convert some/any of these to direct DB updates (with correct rules)
  // becasue DB updates are secure and much quicker than generting any auth token (~350 ms) and calling
  // https REST EP - will result in better performance.
  // https://firebase.google.com/docs/firestore/security/rules-fields#restricting_fields_on_update


  const contextGet = useCallback(() => {
    return createAuthenticatedGetRequest(user, `${baseUrl}/vs/context/${contextId}`)
      .then((getResponse) => handleResponse(getResponse));
  }, [contextId, user]);

  const contextUpdate = useCallback((customerName, customerEmail, customerLocale = null) => {
    return createAuthenticatedPutRequest(user, `${baseUrl}/vs/context/${contextId}/update`,
      {
        customerName,
        customerEmail,
        customerLocale,
      })
      .then((updateRespsone) => {
        if (updateRespsone.ok) return;
        else return handleResponse(updateRespsone);
      });
  }, [contextId, user]);

  const contextCancel = useCallback(() => {
    if (contextId) {
      // sent as a sync beacon because it can be called when tab/window are closing
      let params = new URLSearchParams();
      if (user)
        params.append('uuid', user.uid);

      return navigator.sendBeacon(`${baseUrl}/vs/context/${contextId}/cancel?${params.toString()}`);
    }
  }, [contextId]);


  const contextCallRequest = useCallback((customerName, customerEmail, customerLocale, featureSupport) => {
    return createAuthenticatedPostRequest(user, `${baseUrl}/vs/context/${contextId}/call`,
      {
        customerName,
        customerEmail,
        customerLocale,
        featureSupport,
      })
      .then((requestCallResponse) => handleResponse(requestCallResponse));
  }, [contextId, user]);

  const contextCallRequestHost = useCallback((callId, { preferredHost = null, waitForConnection = true }) => {
    return createAuthenticatedPostRequest(user,
      `${baseUrl}/vs/context/${contextId}/call/${callId}/request`, { preferredHost, waitForConnection })
      .then((requestHostResponse) => handleResponse(requestHostResponse));
  }, [contextId, user]);

  const contextCallCheckout = useCallback((callId, activeCartId, cartSyncEnabled = true, localeRoot = '/' ) => {
    return createAuthenticatedPostRequest(user, `${baseUrl}/vs/context/${contextId}/call/${callId}/checkoutv2`, { activeCartId, cartSyncEnabled, localeRoot })
      .then((checkoutRespsone) => handleResponse(checkoutRespsone));
  }, [contextId, user]);

  const contextCallLoadProducts = useCallback((callId, cart, activeCartId) => {
    return createAuthenticatedPostRequest(user, `${baseUrl}/vs/context/${contextId}/call/${callId}/loadProducts`, { cart, activeCartId })
      .then((checkoutRespsone) => handleResponse(checkoutRespsone));
  }, [contextId, user]);

  const contextCallDraftOrderCheckout = useCallback((callId, draftOrderId, cartSyncEnabled = true, localeRoot = '/' ) => {
    return createAuthenticatedPostRequest(user, `${baseUrl}/vs/context/${contextId}/call/${callId}/checkoutv2`, { draftOrderId, cartSyncEnabled, localeRoot })
      .then((checkoutRespsone) => handleResponse(checkoutRespsone));
  }, [contextId, user]);

  const contextCallConnect = useCallback((callId) => {
    return createAuthenticatedPostRequest(user, `${baseUrl}/vs/context/${contextId}/call/${callId}/connect`)
      .then((connectResponse) => handleResponse(connectResponse));
  }, [contextId, user]);

  const getDiagnosticsToken = useCallback(() => {
    return createAuthenticatedGetRequest(user, `${baseUrl}/vs/context/${contextId}/diagnostic/token`)
      .then((response) => handleResponse(response));
  }, [contextId, user]);

  const sendDiagnosticReport = useCallback((report) => {
    return createAuthenticatedPostRequest(user, `${baseUrl}/vs/context/${contextId}/diagnostic/report`, report)
      .then((response) => {
        if (response.ok) return;
        else return handleResponse(response)
      });;
  }, [contextId, user]);


  const contextCallFeedback = useCallback((callId,feedback)=>{
    return createAuthenticatedPostRequest(user, `${baseUrl}/vs/context/${contextId}/call/${callId}/feedback`, feedback )
      .then((response) => {
        if (response.ok) return;
        else handleResponse(response);
      });
  }, [contextId, user])


  const contextChatRequestHost = useCallback((customerName, customerEmail, customerId, welcomeMessage, chatRoomId) => {
    return createAuthenticatedPostRequest(user,
      `${baseUrl}/vs/context/${contextId}/chatRequest`, {customerName, customerEmail, customerId, welcomeMessage, chatRoomId})
      .then((chatRequestHostResponse) => handleResponse(chatRequestHostResponse));
  }, [contextId, user]);

  return {
    contextGet,
    contextUpdate,
    contextCancel,
    contextCallRequest,
    contextCallRequestHost,
    contextCallConnect,
    contextCallCheckout,
    contextCallLoadProducts,
    contextCallDraftOrderCheckout,
    contextCallFeedback,
    contextChatRequestHost,
    getDiagnosticsToken,
    sendDiagnosticReport,

  };
}
