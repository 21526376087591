import React from "react";
import './TabItem.scss'

export const TabItem = ({ label, name, action, isActive, isExpanded, counter }) => {
	const setActiveTab = () => {
		action(label);
	}

	return (
		<div
			className={`mobile-menu-button
			${isExpanded ? ' mobile-opened-drawer-tab' : ''}
			${isActive ? ' mobile-active-tab' : ''}`}
			onClick={setActiveTab}>
			{name}
			{(counter > 0) && <span className='mobile-counter-badge'>{counter}</span>}
		</div>
	);
}
