import React from 'react';
import './draft-order-list.scss';
import EmptyImage from "../../../../../../../components/emptyImage";
import {ProductDetails} from "../product-details";

const DraftOrderList = ({products, isMobile}) => {
  return (
    <div className='product-grid-wrapper'>
      <div className={`product-grid ${isMobile ? 'mobile' : ''}`}>
        {products.map((product) => {
          const {
            key,
            image,
            productTitle,
          } = product;

          return (
            <div className={`product-card ${isMobile ? 'mobile' : ''}`} key={key}>
              <div className='product-card-image'>
                {
                  image
                    ? <img
                      src={image}
                      alt={productTitle}
                      className='card-image'
                    />
                    : <EmptyImage
                      isMobile={isMobile}
                      product={product}
                      size='m'
                      label={product.productTitle}
                    />
                }
              </div>
              <ProductDetails
                snapshot={key}
                product={product}
                isMobile={isMobile}
                withoutStepper={true}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default DraftOrderList