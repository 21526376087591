import * as React from "react"

const PlayArrow = ({size = 'default', color = 'default', className = '', onClick= null}) => (
  <svg
    className={`caazam-icon ${className} color-${color} size-${size}`}
    viewBox="0 0 24 24"
    aria-hidden="true"
    onClick={onClick}
  >
    <path d="M8 5v14l11-7z" />
  </svg>
)

export default PlayArrow
