import { defineMessages } from 'react-intl';

export const chatScreenMessages = defineMessages({
  welcomeMessage: {
    id: 'chat_welcome_message'
  },
  chatOurRoom: {
    id: 'chat_our_team'
  },
  chatHost: {
    id: 'chat_with_host'
  },
  chatErrorHeader: {
    id: 'chat_error_screen_header'
  },
  send: {
    id: 'button_actions.cta_send'
  },
  start: {
    id: 'chat_start'
  },
  name: {
    id: 'nouns_name'
  },
  email: {
    id: 'nouns.email'
  },
  message: {
    id: 'nouns_message'
  },
  errorMessage: {
    id: 'chat_error_screen_message'
  },
  unavailableTitle: {
    id: 'chat_error_screen_title'
  },
  typing: {
    id: 'nouns_typing'
  },
  startVideoCall: {
    id: 'messages_start_video_call'
  },
  hostInvited: {
    id: 'messages_host_invited_to_video_call'
  },
  startButton: {
    id: 'button_actions.cta_start'
  },
  cancelButton: {
    id: 'button_actions.cta_cancel',
  },
  joinButton: {
    id: 'button_actions.cta_join',
  },
  newMessages: {
    id: 'new_messages'
  },
});
