import React from 'react';
import { useIntroPage } from '../IntroPageProvider';
import './index.scss';

export default function Progress({ index, progressAnimateDuration, isProgressVisible }) {

    const { playingVideoIndex } = useIntroPage();

    const progressbarState = (index) => {
        let state = '';
        if (index === playingVideoIndex) {
            state = ' active'
        } else if (index < playingVideoIndex) {
            state = ' done';
        } else if (index > playingVideoIndex) {
            state = ' pending';
        }
        return state;
    }

    return (
        <div className="progress-video">
            <div className="progress">
                <span
                    style={{ animationDuration: `${(progressAnimateDuration && progressAnimateDuration.index === playingVideoIndex) ? `${progressAnimateDuration.range}ms` : '99999s'}` }}
                    className={`progress-bar${progressAnimateDuration ? ' exist' : ''}${progressbarState(index)}${isProgressVisible ? ' playing' : ' paused'}`}>
                </span>
            </div>
        </div>
    )
}
