import React from 'react';
import { useIntl } from 'react-intl';
import { mobileTabs as tabs, state, tabKey } from '../../../../../../../constants/menuDrawer';
import { useCallScreenProvider } from '../../../../../../../components/CallScreenProvider';
import { TabItem } from './components/tab-item/TabItem';
import './TabsGroup.scss';
import {DRAFT_ORDER_COMPLETED} from "../../../../../../../constants/draftOrder";

export const TabsGroup = () => {
    const intl = useIntl();

    const {
        activeTab,
        openDrawer,
        cartSnapshots,
        sessionSnapshots,
        drawerSize,
        numberOfUnreadMessages,
        isFullScreenEnabled,
        isChatEnabled,
        draftOrder
    } = useCallScreenProvider();

    const cartProductNumber = () => {
        if (draftOrder) {
            if (draftOrder.status === DRAFT_ORDER_COMPLETED) {
                return 0
            }
            return draftOrder.lineItems.length
        } else {
            return cartSnapshots ? cartSnapshots.length : 0
        }
    }

    const changeTab = currentTab => {
        openDrawer(currentTab, state.FULL_EXPANDED);
    };

    const tabCounter = (key) => {
        switch (key) {
            case tabKey.SHOWROOM:
                return sessionSnapshots.length;
            case tabKey.CART:
                return cartProductNumber();
            case tabKey.CHAT:
                return numberOfUnreadMessages;
            default:
                return;
        }
    }

    return (
        <div className={`mobile-drawer-menu${isFullScreenEnabled ? ' mobile-enabled-full-screen' : ''}`}>
            {
                tabs(isChatEnabled, draftOrder).map((tab) => (
                    <TabItem
                        key={`${tab.key}_${tab.label}`}
                        isExpanded={drawerSize === state.FULL_EXPANDED}
                        name={intl.formatMessage(tab.name)}
                        label={tab.label}
                        counter={tabCounter(tab.key)}
                        action={changeTab}
                        isActive={activeTab === tab.label} />
                ))
            }
        </div>

    )
}
