import {useEffect, useState} from 'react';
import firebase from '../Firebase'
import {getFirestore, onSnapshot, doc} from 'firebase/firestore';

const usePresentationMode = (callId) => {
  const [isPresentationEnabled, setStatus] = useState(false);
  const [presentedImage, setPresentedImage] = useState(null);

  const firestore = getFirestore(firebase);

  useEffect(() => {
    const presentationRef = onSnapshot(doc(firestore, 'calls', callId, 'sessionData', 'presentationView'), (snapshot) => {
      if (snapshot) {
        const data = snapshot.data();
        if (data) {
          setStatus(data.isEnabled);
          setPresentedImage(data.isEnabled ? data : null);
        } else {
          setStatus(false);
          setPresentedImage(null);
        }
      }
    });

    return () => presentationRef();
  }, []);

  return {
    isPresentationEnabled,
    presentedImage,
  };

};

export default usePresentationMode;
