import React from "react"

export const IconAddedToCartProduct = () =>
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="icon/added-to-cart-v2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="ShoppingCart" transform="translate(1.500000, 1.500000)" fill="#66676C" fillRule="nonzero">
                <path d="M1.5228696,0 C1.74613178,0 1.96231148,0.0783570734 2.13370806,0.22140363 C2.27084156,0.335846801 2.37235914,0.485891678 2.42792688,0.653797362 L2.46082562,0.782798325 L3.966,9.066 L10.02,9.06666667 C10.2654599,9.06666667 10.4696084,9.24354183 10.5119443,9.47679103 L10.52,9.56666667 C10.52,9.81212656 10.3431248,10.016275 10.1098756,10.058611 L10.02,10.0666667 L3.54969573,10.0666667 C3.33825805,10.0666667 3.15329386,9.93435754 3.08139819,9.74200946 L3.05776078,9.65610941 L1.483,1 L0.5,1 C0.254540111,1 0.0503916296,0.823124839 0.00805566941,0.589875632 L0,0.5 C0,0.254540111 0.176875161,0.0503916296 0.410124368,0.00805566941 L0.5,0 L1.5228696,0 Z" id="Path"></path>
                <path d="M4.12666667,9.06666667 C3.22460368,9.06666667 2.49333333,9.79793904 2.49333333,10.7 C2.49333333,11.602061 3.22460368,12.3333333 4.12666667,12.3333333 C5.02872637,12.3333333 5.76,11.6020597 5.76,10.7 C5.76,9.79794029 5.02872637,9.06666667 4.12666667,9.06666667 Z M4.12666667,10.0666667 C4.47644163,10.0666667 4.76,10.350225 4.76,10.7 C4.76,11.049775 4.47644163,11.3333333 4.12666667,11.3333333 C3.77688905,11.3333333 3.49333333,11.0497768 3.49333333,10.7 C3.49333333,10.3502232 3.77688905,10.0666667 4.12666667,10.0666667 Z" id="Path"></path>
                <path d="M10.02,9.06666667 C9.11794029,9.06666667 8.38666667,9.79794029 8.38666667,10.7 C8.38666667,11.6020597 9.11794029,12.3333333 10.02,12.3333333 C10.9220597,12.3333333 11.6533333,11.6020597 11.6533333,10.7 C11.6533333,9.79794029 10.9220597,9.06666667 10.02,9.06666667 Z M10.02,10.0666667 C10.369775,10.0666667 10.6533333,10.350225 10.6533333,10.7 C10.6533333,11.049775 10.369775,11.3333333 10.02,11.3333333 C9.67022504,11.3333333 9.38666667,11.049775 9.38666667,10.7 C9.38666667,10.350225 9.67022504,10.0666667 10.02,10.0666667 Z" id="Path"></path>
                <path d="M11.8333333,2.26666667 C12.1169261,2.26666667 12.3375722,2.50015634 12.3334775,2.77320271 L12.3252683,2.85610939 L11.6363795,6.64499751 C11.5774381,6.96910524 11.4066457,7.26224664 11.1537358,7.4733097 C10.9369551,7.65422155 10.6716139,7.76501616 10.3927945,7.79297969 L10.252424,7.8 L3.13757493,7.8 C2.86143256,7.8 2.63757493,7.57614237 2.63757493,7.3 C2.63757493,7.05454011 2.81445009,6.85039163 3.0476993,6.80805567 L3.13757493,6.8 L10.252424,6.8 C10.3476355,6.8 10.4398894,6.76656189 10.5130055,6.70554363 C10.5678251,6.65979461 10.6093022,6.60069011 10.6338041,6.53446332 L10.652513,6.46609328 L11.234,3.266 L2.31333333,3.26666667 C2.06787344,3.26666667 1.86372496,3.08979151 1.821389,2.8565423 L1.81333333,2.76666667 C1.81333333,2.52120678 1.99020849,2.3170583 2.2234577,2.27472234 L2.31333333,2.26666667 L11.8333333,2.26666667 Z" id="Path"></path>
            </g>
            <circle id="Oval" fill="#9BCFC8" cx="12.1409681" cy="4.20869897" r="3"></circle>
            <path d="M13.2933492,2.89265842 C13.5248196,2.68051163 13.8795098,2.7017172 14.085572,2.9400224 C14.2687384,3.15184924 14.2728158,3.46390979 14.1088915,3.67963891 L14.0395665,3.75563788 L12.0269537,5.60023411 C11.8414835,5.7702209 11.5736947,5.7920492 11.3670641,5.66537882 L11.2826708,5.60199877 L10.417556,4.81642868 C10.185139,4.60538174 10.1629082,4.2403198 10.367902,4.00104 C10.5501188,3.78834686 10.8505363,3.74663053 11.07828,3.88821109 L11.1599045,3.9499199 L11.6516154,4.39681475 L13.2933492,2.89265842 Z" id="Path" fill="#FFFFFF" fillRule="nonzero"></path>
        </g>
    </svg>
