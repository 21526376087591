import { useEffect, useState } from "react";

const DEFAULT_OPTIONS = {
    config: { attributes: true, childList: true, subtree: true },
    debounceTime: 0
};

function debounce(func, wait) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			func.apply(context, args);
		};
		var callNow = !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

/**
 * This custom hooks abstracts the usage of the Mutation Observer with React components.
 * Watch for changes being made to the DOM tree and trigger a custom callback.
 * @param {Element} targetEl DOM element to be observed
 * @param {Function} cb callback that will run when there's a change in targetEl or any
 * child element (depending on the provided options)
 * @param {Object} options
 * @param {Object} options.config check [options](https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver/observe)
 * @param {number} [options.debounceTime=0] a number that represents the amount of time in ms
 * that you which to debounce the call to the provided callback function
 */
export function useMutationObservable(targetEl, cb, options = DEFAULT_OPTIONS) {
    const [observer, setObserver] = useState(null);

    useEffect(() => {
        if (!cb || typeof cb !== "function") {
            console.warn(
                `You must provide a valid callback function, instead you've provided ${cb}`
            );
            return;
        }
        const { debounceTime } = options;
        const obs = new MutationObserver(
            debounceTime > 0 ? debounce(cb, debounceTime) : cb
        );
        setObserver(obs);
    }, [cb, options, setObserver]);

    useEffect(() => {
        if (!observer) return;

        if (!targetEl) {
            console.warn(
                `You must provide a valid DOM element to observe, instead you've provided ${targetEl}`
            );
        }

        const { config } = options;

        try {
            if (targetEl) {
                cb(targetEl)
            }
            observer.observe(targetEl, config);
        } catch (e) {
            console.error(e);
        }

        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [observer, targetEl, options]);
}
