import React from 'react';
import BottomArrow from '../../../../../assets/icons/BottomArrow';
import ProductDetailsProvider from '../../../../../components/ProductDetailsProvider';
import { state } from '../../../../../constants/menuDrawer';
import { TabsGroup } from './components/tabs-group/TabsGroup';
import { TabsContent } from './components/tabs-content/TabsContent';
import { ModalViewProvider } from '../../../../../components/mobile-providers/ModalViewProvider';
import { useCallScreenProvider } from '../../../../../components/CallScreenProvider';
import './MobileMenu.scss';

export const MobileMenu = () => {
    const { drawerSize, toggleDrawer } = useCallScreenProvider();

    return (
        <div className={`mobile-drawer-container${drawerSize === state.FULL_EXPANDED ? ' mobile-visible' : ''}`}>
            <ProductDetailsProvider>
                <ModalViewProvider>
                    <div className='mobile-drawer-content'>
                        <div className='mobile-drawer-header'>
                            <button className='mobile-close-arrow-tick' onClick={toggleDrawer}>
                                <BottomArrow />
                            </button>
                            <TabsGroup />
                        </div>
                        <div className='mobile-drawer-tabs-content'>
                            <TabsContent />
                        </div>
                    </div>
                </ ModalViewProvider>
            </ ProductDetailsProvider>
        </div>
    )
}
