import React, { useEffect, useContext } from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { scheduleScreenMessages } from '../messages';
import { AppHeaderContext } from '../../../components/AppHeaderProvider';
import { SCHEDULING_PAGES } from '../schedule-screen';
import { ScheduleHostList } from './components/schedule-host-list/ScheduleHostList';
import { LeaveMessageEvent } from '../components/leave-message-event/LeaveMessageEvent';
import { useSchdeuleConfigProvider } from '../../../components/ScheduleConfigProvider';
import { PageHeader } from '../../../components/page-header/PageHeader';
import { PageFooter } from '../../../components/page-footer/PageFooter';
import { PageScrollContainer } from '../../../components/page-scroll-container/PageScrollContainer';
import './SelectHosts.scss';
import {PAGES} from "../../../constants/routes";

export const SelectHost = ({navigateToNextScheduleScreen}) => {
    const {
        isButtonEnabled,
        setHostForScheduling,
        selectedAllHosts,
        hostIdForScheduling,
        rescheduledHost,
    } = useSchdeuleConfigProvider();
    const history = useHistory();
    const { path } = useRouteMatch();
    const { backFuncRef } = useContext(AppHeaderContext);

    useEffect(() => {
        backFuncRef(() => {
            setHostForScheduling(null);
            history.goBack()
        })
    }, [])

    const onSubmit = () => {
        if (!selectedAllHosts && !hostIdForScheduling) {
            setHostForScheduling(rescheduledHost);
        }
        navigateToNextScheduleScreen(path);
    }

    return (
        <>
            <PageHeader
                title={<FormattedMessage {...scheduleScreenMessages.select_host_secondary} />}
            />
            <PageScrollContainer children={
                <ScheduleHostList />
            } />
            <PageFooter
                primaryButtonLabel={<FormattedMessage {...scheduleScreenMessages.cta_next} />}
                isDisabled={!isButtonEnabled}
                onSubmitPrimary={onSubmit}
                secondaryButtonLabel={<LeaveMessageEvent />}
                onSubmitSecondary={() => history.push({
                    pathname: PAGES.CONTACT,
                    isBackAllowed: true,
                    preventSchedule: true
                })} />
        </>
    )
}
