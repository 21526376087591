
import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { useClientAuthProvider } from './ClientAuthProvider';
import { useClientConfigProvider } from './ClientConfigProvider';
import { logger } from '../logging';
import { createAuthenticatedGetRequest, createAuthenticatedPostRequest, createAuthenticatedDeleteRequest, handleResponse } from '../utils/http';
import getDefaultFormSchema from './boutiq-form/consts/DefaultSchema';

export const SchdeulerContext = createContext(null);

export default function SchdeuleProvider({ children }) {
    const { user, shopId, contextId, scheduleId, authLoading } = useClientAuthProvider();
    const { dynamicConfig, hostSelectionConfig } = useClientConfigProvider();
    const [globalSlots, globalSlotsSet] = useState(null);
    const [globalScheduleStatus, globalScheduleStatusSet] = useState(null);
    const [globalScheduleEventTypes, globalScheduleEventTypesSet] = useState([]);
    const [sessionHosts, setSessionHosts] = useState([]);
    const [sessionHostsLoading, setSessionHostsLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [instanceCounter, setInstanceCounter] = useState(0);
    const [hostId, hostIdSet] = useState(null);
    const [hostsData, hostsDataSet] = useState({});
    const [scheduleItemData, setScheduleItemData] = useState(null);
    const [isScheduleItemDataLoading, setScheduleItemDataIsLoading] = useState(true);

    const [schedulingFormSchema, setSchedulingFormSchema] = useState(getDefaultFormSchema(dynamicConfig?.appForms));
    const [schedulingFormSchemaLoading, setSchedulingFormSchemaLoading] = useState(true);


    const getSlotDay = (dateObject) =>
        new Date(dateObject.getFullYear(),dateObject.getMonth(), dateObject.getDate());
    
    const buildSlots = (slots) => {
        if (!slots || slots.length === 0) {
            return [];
        }
        let slotsByDate = [];
        let currentDate = null;
        let currentDateSlots = [];
        slots.forEach((slot) => {
            let slotDate = getSlotDay(new Date(slot.start));            
            if (slotDate.getTime() !== currentDate?.getTime()) {
                if (!!currentDate) {
                    slotsByDate.push({ date: new Date(currentDate), slots: [...currentDateSlots] })
                }
                currentDateSlots = [];
                currentDate = new Date(slotDate);
            }

            currentDateSlots.push({
                isAvailable: slot.isAvailable,
                timeSlot: new Date(slot.start),
                timeSlotEnd: new Date(slot.end),
            });
        });
        // for last date (we've left the loop already)
        slotsByDate.push({ date: new Date(currentDate), slots: [...currentDateSlots] });
        return slotsByDate;
    }

    const scheduleAPIQuery = (timestamp) => {
        let urlParams = new URLSearchParams();
        if (timestamp) {
            urlParams.append('timestamp', timestamp);
        }
        if (scheduleId) {
            urlParams.append('scheduleId', scheduleId);
        }
        if (hostId) {
            urlParams.append('hostId', hostId);
        }
        return urlParams;
    }

    const getConfig = (timestamp = null) => {
        let urlParams = scheduleAPIQuery(timestamp);
        logger.info('Requesting scheduling config', { urlParams: urlParams.toString()});
        return createAuthenticatedGetRequest(user, `${process.env.REACT_APP_CAAZAM_REST_EP}/vs/context/${contextId}/schedule?${urlParams.toString()}`)
            .then((res) => handleResponse(res))
            .then(data => {
                const { slots, status, eventTypes } = data;
                const slotsByDate = buildSlots(slots);
                logger.info('Scheduling config received', { status, eventTypes, slots: slotsByDate?.length });
                return { slotsByDate, status, eventTypes };
            }).catch(error => {
                logger.error('SchdeuleProvider getConfig', error);
                return { slotsByDate: [], status: false, eventTypes: [] };
            });
    }

    const getFormSchema = () => {
        return createAuthenticatedGetRequest(user, `${process.env.REACT_APP_CAAZAM_REST_EP}/vs/context/${contextId}/schedule/clientFormSchema`)
            .then((res) => handleResponse(res))
            .then(data => {
                const { schema } = data;     
                logger.info('Scheduling form schema received', { schemaVersion: schema?.version });
                return schema;
            }).catch(error => {
                logger.error('SchdeuleProvider getFormSchema', error);
                return null;
            });
    }

    const getSlots = (timestamp = null) => {
        let urlParams = scheduleAPIQuery(timestamp);
        return createAuthenticatedGetRequest(user, `${process.env.REACT_APP_CAAZAM_REST_EP}/vs/context/${contextId}/schedule/slots?${urlParams.toString()}`)
            .then((res) => handleResponse(res))
            .then(data => {
                const { slots } = data;
                const slotsByDate = buildSlots(slots);
                if (hostId) {
                    hostsDataSet({ ...hostsData, [hostId]: { ...hostsData[hostId], slots: [...slotsByDate] } });
                } else {
                    globalSlotsSet([...slotsByDate]);
                }
                return slotsByDate;
            }).catch(error => {
                logger.error('SchdeuleProvider getSlots', error);
                return [];
            });
    }

    const getSchdeuleStatus = (timestamp = null) => {
        let urlParams = scheduleAPIQuery(timestamp);
        return createAuthenticatedGetRequest(user, `${process.env.REACT_APP_CAAZAM_REST_EP}/vs/context/${contextId}/schedule/status?${urlParams.toString()}`)
            .then((res) => handleResponse(res))
            .then(data => {
                const { status } = data;
                if (hostId) {
                    hostsDataSet({ ...hostsData, [hostId]: { ...hostsData[hostId], status } });
                } else {
                    globalScheduleStatusSet(status);
                }
                return status;
            }).catch(error => {
                logger.error('SchdeuleProvider getSchdeuleStatus', error);
                globalScheduleStatusSet(false);
                return false;
            });
    }

    const getSchdeuleItem = (scheduleId) => {
        return createAuthenticatedGetRequest(user, `${process.env.REACT_APP_CAAZAM_REST_EP}/vs/context/${contextId}/schedule/${scheduleId}`)
            .then((res) => handleResponse(res))
            .then(data => setScheduleItemData(data.scheduleData))
            .catch(error => {
                logger.error(`SchdeuleProvider getSchdeuleItem ${scheduleId}`, error);
                setScheduleItemData(null);
            });
    }

    /* const getMoreSlots = useCallback(() => {
        let timestamp = null;
        if (globalSlots && globalSlots.length > 0) {
            const lastDay = globalSlots[globalSlots.length - 1];
            if (lastDay.slots.length > 0) {
                timestamp = lastDay.slots[lastDay.slots.length - 1].timeSlot.toISOString();
            }
        }
        getSlots(timestamp).then(newSlots => {
            // TODO: Need to merge last slot day with 1st day of new slots
            globalSlotsSet([...globalSlots, ...newSlots]);
        });
    }, [globalSlots]) */

    const scheduleCall = useCallback((scheduleData) => {
        return createAuthenticatedPostRequest(user, `${process.env.REACT_APP_CAAZAM_REST_EP}/vs/context/${contextId}/schedule`, scheduleData)
            .then(res => handleResponse(res));
    }, [user, shopId, contextId])

    const rescheduleCall = useCallback((scheduleData, scheduleId) => {
        if (!scheduleId) throw new Error('cannot reschedule call w/o valid scheduleId');
        return createAuthenticatedPostRequest(user, `${process.env.REACT_APP_CAAZAM_REST_EP}/vs/context/${contextId}/schedule/${scheduleId}`, scheduleData)
            .then(res => handleResponse(res));
    }, [user, shopId, contextId]);

    const deleteScheduledCall = useCallback((scheduleId) => {
        if (!scheduleId) throw new Error('cannot reschedule call w/o valid scheduleId');
        return createAuthenticatedDeleteRequest(user, `${process.env.REACT_APP_CAAZAM_REST_EP}/vs/context/${contextId}/schedule/${scheduleId}`)
            .then(res => handleResponse(res))
            .catch(error => console.error('deleteScheduledCall', error));
    }, [user, shopId, contextId])

    const getHosts = () => {
        return createAuthenticatedGetRequest(user, `${process.env.REACT_APP_CAAZAM_REST_EP}/vs/context/${contextId}/hosts`)
            .then((res) => handleResponse(res))
            .then(data => {
                const { hosts } = data;
                setSessionHosts(hosts);
            }).catch(error => {
                logger.error('getHosts', error);
                setSessionHosts([]);
            }).finally(() => setSessionHostsLoading(false));
    }

    useEffect(() => {
        if (hostSelectionConfig?.loaded && hostSelectionConfig?.enabled) {
            setSessionHostsLoading(true);
            getHosts();
        }
    },[hostSelectionConfig]);

    // get slots and scehduling status for session 
    useEffect(() => {
        if (!authLoading && user && contextId) {
            if (hostId && !hostsData[hostId]) {
                setIsLoading(true);
                getConfig()
                    .then(({ slotsByDate, status, eventTypes }) => {
                        hostsDataSet({ ...hostsData, [hostId]: { status, slots: [...slotsByDate]} })
                        eventTypes && globalScheduleEventTypesSet(eventTypes);
                    }).finally(() => {
                        setIsLoading(false);
                    });
            } else if (!hostId && !globalSlots) {
                setIsLoading(true);
                getConfig()
                    .then(({ slotsByDate, status, eventTypes }) => {
                        globalScheduleStatusSet(status);
                        if (slotsByDate) {
                            globalSlotsSet([...slotsByDate]);
                        }
                        eventTypes && globalScheduleEventTypesSet(eventTypes);
                    }).finally(() => {
                        setIsLoading(false);
                    });
            }
        }
    }, [authLoading, user, contextId, scheduleId, hostId, instanceCounter]);

    useEffect(() => {
        if (!authLoading && user && contextId) {
            setSchedulingFormSchemaLoading(true);
            getFormSchema()
                .then(schema => {
                    if (schema) {
                        setSchedulingFormSchema(schema);
                    }
                }).finally(() => {
                    setSchedulingFormSchemaLoading(false);
                });
        }
    }, [authLoading, user, contextId])


    // get scheduling data if a rescehduling session
    useEffect(() => {
        if (!authLoading && user && contextId && scheduleId) {
            setScheduleItemDataIsLoading(true);
            getSchdeuleItem(scheduleId).finally(() => setScheduleItemDataIsLoading(false));
            return () => setScheduleItemData(null);
        }
    }, [authLoading, user, contextId, scheduleId]);

    const refresh = useCallback(() => {
            globalScheduleStatusSet(null);
            globalSlotsSet(null);
            globalScheduleEventTypesSet([]);
            hostsDataSet({});
            setInstanceCounter(curr => curr + 1);
    }, [instanceCounter]);

    /* if (hostId) {
        hostsDataSet({ ...hostsData, [hostId]: { ...hostsData[hostId], slots: [...slotsByDate] } });
    } else {
        globalSlotsSet([...slotsByDate]);
    }
    return slotsByDate;
 */
    const setHostForScheduling = (newHostId) => {
        hostIdSet(newHostId);
    }

    return <SchdeulerContext.Provider value={{
        availableSlots: hostId ? hostsData[hostId] && hostsData[hostId].slots : globalSlots,
        scheduleStatus: hostId ? hostsData[hostId] && hostsData[hostId].status : globalScheduleStatus,
        scheduleEventTypes: globalScheduleEventTypes,
        isScheduleLoading: isLoading,
        scheduleCall,
        getSchdeuleStatus,
        scheduleId,
        scheduleItemData,
        isScheduleItemDataLoading,
        rescheduleCall,
        deleteScheduledCall,
        setHostForScheduling,
        hostIdForScheduling: hostId,
        schedulingFormSchema,
        schedulingFormSchemaLoading,
        sessionHosts,
        sessionHostsLoading,
        refresh,
    }}>
        {children}
    </SchdeulerContext.Provider>
}

export function useSchdeuleProvider() {
    const context = useContext(SchdeulerContext);
    if (!context) {
        throw new Error('useSchdeuleProvider must be used within the scope of SchdeulerContext');
    }
    return context;
}
