import React from 'react';

export const IconTrashMobile = () =>
    <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg" >
        <g id="icon/trash" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Trash" transform="translate(4.500000, 3.500000)" fill="#66676C" fillRule="nonzero">
                <path d="M14.5,2.54545388 C14.7761421,2.54545388 15,2.76931128 15,3.04545388 C15,3.29091355 14.823125,3.49506211 14.5898759,3.53739817 L14.5,3.54545388 L0.5,3.54545388 C0.223857852,3.54545388 0,3.32160285 0,3.04545388 C0,2.80000059 0.176874956,2.59585202 0.410124144,2.55351596 L0.5,2.54545388 L14.5,2.54545388 Z" id="Path"></path>
                <path d="M5.59121959,6.36363471 C5.83667948,6.36363471 6.04082796,6.54050987 6.08316392,6.77375908 L6.09121959,6.86363471 L6.09121959,11.9545425 C6.09121959,12.2306849 5.86736196,12.4545425 5.59121959,12.4545425 C5.3457597,12.4545425 5.14161122,12.2776673 5.09927526,12.0444181 L5.09121959,11.9545425 L5.09121959,6.86363471 C5.09121959,6.58749234 5.31507721,6.36363471 5.59121959,6.36363471 Z" id="Path"></path>
                <path d="M9.40940041,6.36363471 C9.6548603,6.36363471 9.85900878,6.54050987 9.90134474,6.77375908 L9.90940041,6.86363471 L9.90940041,11.9545425 C9.90940041,12.2306849 9.68554279,12.4545425 9.40940041,12.4545425 C9.16394052,12.4545425 8.95979204,12.2776673 8.91745608,12.0444181 L8.90940041,11.9545425 L8.90940041,6.86363471 C8.90940041,6.58749234 9.13325804,6.36363471 9.40940041,6.36363471 Z" id="Path"></path>
                <path d="M13.2275812,2.54545388 C13.4730411,2.54545388 13.6771896,2.72232904 13.7195256,2.95557825 L13.7275812,3.04545388 L13.7275812,15.1363598 C13.7275812,15.4377254 13.6078345,15.7267951 13.3947438,15.9398858 C13.2120945,16.1225351 12.9736241,16.236605 12.719436,16.2654689 L12.5912178,16.2727233 L2.40940223,16.2727233 C2.10804272,16.2727233 1.81902097,16.1529915 1.60589815,15.9399141 C1.42322098,15.7572662 1.30915492,15.5187882 1.28029272,15.2645857 L1.27303876,15.1363598 L1.27303876,3.04545388 C1.27303876,2.76931151 1.49689639,2.54545388 1.77303876,2.54545388 C2.01849865,2.54545388 2.22264713,2.72232904 2.26498309,2.95557825 L2.27303876,3.04545388 L2.27303876,15.1363598 C2.27303876,15.17252 2.28740985,15.2072163 2.31293894,15.2327413 C2.33001097,15.2498097 2.35112853,15.26189 2.37401957,15.2680471 L2.40940223,15.2727233 L12.5912178,15.2727233 C12.6273561,15.2727233 12.662075,15.2583411 12.687637,15.2327791 C12.7046783,15.2157377 12.7167509,15.1946267 12.7229058,15.1717384 L12.7275812,15.1363598 L12.7275812,3.04545388 C12.7275812,2.76931151 12.9514389,2.54545388 13.2275812,2.54545388 Z" id="Path"></path>
                <path d="M9.40940041,0 C9.87953643,0 10.3304259,0.186766591 10.6628849,0.519207828 C10.9537973,0.810096957 11.133158,1.1916824 11.1734527,1.59751024 L11.1821274,1.77272694 L11.1821274,3.04545388 C11.1821274,3.32159626 10.9582697,3.54545388 10.6821274,3.54545388 C10.4366675,3.54545388 10.232519,3.36857872 10.190183,3.13532952 L10.1821274,3.04545388 L10.1821274,1.77272694 C10.1821274,1.56778099 10.100719,1.37124389 9.95580167,1.22633817 C9.8398551,1.11039779 9.69087669,1.03510559 9.53100283,1.00962632 L9.40940041,1 L5.59121959,1 C5.38629701,1 5.18975154,1.08141269 5.04481362,1.22634289 C4.92888354,1.34226369 4.85359616,1.49123159 4.82811842,1.65111425 L4.81849264,1.77272694 L4.81849264,3.04545388 C4.81849264,3.32159626 4.59463502,3.54545388 4.31849264,3.54545388 C4.07303276,3.54545388 3.86888427,3.36857872 3.82654831,3.13532952 L3.81849264,3.04545388 L3.81849264,1.77272694 C3.81849264,1.30255776 4.00526381,0.851652547 4.3377304,0.519212541 C4.62863612,0.228322335 5.0102116,0.0489678877 5.41601447,0.00867443544 L5.59121959,0 L9.40940041,0 Z" id="Path"></path>
            </g>
        </g>
    </svg>
