import React from "react"
import { FormattedMessage } from "react-intl";
import { sidebarMessages } from "../../../../../../../../intl/sidebar-messages/messages";
import './AddToShowroomButton.scss';

export const AddToShowroomButton = ({ added, onSubmit }) =>
    <button className={`mobile-add-button${added ? ' mobile-added' : ''}`} onClick={!added ? onSubmit : () => { }}>
        <div className="mobile-add-button-label"><FormattedMessage {...sidebarMessages.cta_add} /></div>
        <div className="mobile-add-button-label-active"><FormattedMessage {...sidebarMessages.cta_added} /></div>
    </button>
