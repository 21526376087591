import { useEffect, useState } from 'react';
import { isBrowserSupported } from '@caazam/caazam-video-room';

export function useDevices(i) {
	const [devices, setDevices] = useState([]);
	const [permissionStatus, setPermissionStatus] = useState(null);
	const [devicesPermission, setDevicesPermission] = useState({});

	useEffect(() => {
		if (isBrowserSupported) {
			const getDevices = () => {
				navigator.mediaDevices.enumerateDevices()
					.then(devices => setDevices(devices))
					.catch(error => console.error('useDevices - error getting devices', error));
			}

			navigator.mediaDevices.addEventListener('devicechange', getDevices);
			getDevices();

			if (navigator.permissions) {
				getPermissions();
			}

			return () => {
				navigator.mediaDevices.removeEventListener('devicechange', getDevices);
			};
		}
	}, []);

	useEffect(() => {
		if (permissionStatus) {
			permissionStatus.map(device => device.addEventListener('change', getPermissions));
			return () => {
				permissionStatus.map(device => device.removeEventListener('change', getPermissions));
			}
		}
	}, [permissionStatus]);

	const getPermissions = () => {
		Promise.all([
			navigator.permissions.query({ name: "camera" }),
			navigator.permissions.query({ name: "microphone" })
		]).then((status) => {
				setPermissionStatus(status);
				const [{ state: camera }, { state: microphone }] = status;
				setDevicesPermission({
						'camera': {
							'status': camera
						},
						'microphone': {
							'status': microphone
						},
					});
			});
	}

	return {
		audioInputDevices: devices.filter(device => device.kind === 'audioinput'),
		videoInputDevices: devices.filter(device => device.kind === 'videoinput'),
		audioOutputDevices: devices.filter(device => device.kind === 'audiooutput'),
		cameraPermission: devicesPermission.camera,
		microphonePermission: devicesPermission.microphone,
		isPermissionDeviceSupport: navigator.permissions,
	};
}
