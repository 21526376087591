import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { boutiqFormMessages } from '../../../messages';
import { ErrorContainer } from '../../error-container/ErrorContainer';
import '../styles/BoutiqChoice.scss';

export const BoutiqSingleCheckbox = ({ field, refProvider, fieldChanged, value, errors }) => {
    const intl = useIntl();

    const [checkedState, setCheckedState] = useState(false);

    useEffect(() => {
        if (value) {
            setCheckedState(value);
        }
    }, [value])

    const handleOnChange = (e) => {
        setCheckedState(!checkedState);
        fieldChanged(e, field.id, !checkedState);
    };

    return (
        <div ref={refProvider(field.id)} className='boutiq-checkbox-button-group'>
            <label className='checkbox-option-label'>
                <input
                    type="checkbox"
                    value={field.value}
                    checked={checkedState}
                    onChange={(e) => handleOnChange(e)}
                />
                <span className='option-value'>{`${field.label}${field.required ? ` ${intl.formatMessage(boutiqFormMessages.required)}` : ''}`}</span>
            </label>
            <div className='field-group-error-message'>
                <ErrorContainer required={field.required} error={errors[field.id]} />
            </div>
        </div>
    )
}
