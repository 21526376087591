import React, { useState, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCallContextProvider } from '../../components/CallContextProvider';
import { useClientConfigProvider } from '../../components/ClientConfigProvider';
import { useSchdeuleProvider } from '../../components/SchdeuleProvider';
import { OutlinedButton } from '../../components/boutiq-buttons/BoutiqButton';
import ShopLogoContainer from '../../components/shop-logo-container/shop-logo-container';
import { formatDateString } from '../../utils/formatDateString';
import ActiveHostAvatars from '../intro/activeHostAvatars/activeHostAvatars';
import { waitingRoomMessages } from './messages';
import './waiting-room.scss'

const MAX_NUMBER_OF_AVATARS = 4;

export const WaitingRoom = ({ onLeave }) => {
    const { contextCancel } = useCallContextProvider()
    const intl = useIntl();
    const [backgroundStyles, setBackgroundStyles] = useState(null);
    const { dynamicConfig, hostAvatars, scheduleEarlyThreshold, scheduleLateThreshold } = useClientConfigProvider();
    const { isScheduleItemDataLoading, scheduleItemData, scheduleId } = useSchdeuleProvider();
    const [isBackgroundImageLoaded, setIsBackgroundImageLoaded] = useState(false);
    const [waitingRoomMessage, setWaitingRoomMessage] = useState(false);
    const animateBackgroundImage = () => {
        setIsBackgroundImageLoaded(true);
    }

    useEffect(() => {
        if (dynamicConfig) {
            const stylesConfig = {
                app_custom_background_color: dynamicConfig.app_custom_background_color ? dynamicConfig.app_custom_background_color : null,
                app_custom_background_opacity: dynamicConfig.app_custom_background_opacity ? dynamicConfig.app_custom_background_opacity : null,
                app_custom_background_url: dynamicConfig.app_custom_background_url ? dynamicConfig.app_custom_background_url : null,
            }
            setBackgroundStyles(stylesConfig);
        }
    }, [dynamicConfig])

    const customLogo = dynamicConfig && dynamicConfig.app_custom_logo;
    const hostAvatarsDisplayed = dynamicConfig && dynamicConfig.hostAvatarsDisplayed;
    const [availableHostsAvatars, setAvailableHostsAvatars] = useState([]);

    useEffect(() => {
        const activeHostsAvatars = hostAvatars.slice(0, MAX_NUMBER_OF_AVATARS);

        if (scheduleId && !isScheduleItemDataLoading && scheduleItemData) {
            setAvailableHostsAvatars(activeHostsAvatars);
            setWaitingRoomMessage(true);
        } else {
            setAvailableHostsAvatars(activeHostsAvatars);
            setWaitingRoomMessage(true);
        }

    }, [isScheduleItemDataLoading, scheduleItemData, scheduleId, hostAvatars])

    function messageMarkup() {

        if (scheduleId) {
            if (!isScheduleItemDataLoading && scheduleItemData) {

                const scheduledTimeSlot = new Date(scheduleItemData.startTimestamp);
                const formattedDate = formatDateString(
                    intl.formatMessage(waitingRoomMessages.nouns_today),
                    intl.formatMessage(waitingRoomMessages.nouns_tomorrow),
                    scheduledTimeSlot);

                // client is too early
                if ((scheduledTimeSlot.getTime() - Date.now()) > scheduleEarlyThreshold * 1000) {
                    return (
                        <>
                            <p className='waiting-room-title-main'>
                                <FormattedMessage {...waitingRoomMessages.scheduled_call_start_at}
                                    values={{
                                        time: formattedDate,
                                    }} />
                            </p>
                            <p className='waiting-room-title-custom'>
                                <FormattedMessage {...waitingRoomMessages.scheduled_call_start_early_custom}
                                    values={{
                                        host_noun: intl.formatMessage(waitingRoomMessages.host_noun),
                                        br: <br />,
                                    }} >
                                    {
                                        (msg => msg ?? null) // to make it optional
                                    }
                                </FormattedMessage>
                            </p>

                        </>
                    )
                } // client is a on time  = little early or a little late
                else if (
                    (scheduledTimeSlot.getTime() - Date.now()) > 0
                    || (Date.now() - scheduledTimeSlot.getTime()) < scheduleLateThreshold * 1000) {
                    return (
                        <p className='waiting-room-title-main'>
                            <FormattedMessage {...waitingRoomMessages.waiting_for_host} values={{
                                host_noun: intl.formatMessage(waitingRoomMessages.host_noun),
                                br: <br />,
                            }} />
                        </p>
                    );
                } //client is very late
                else {
                    return (
                        <>
                            <p className='waiting-room-title-main'>
                                <FormattedMessage {...waitingRoomMessages.scheduled_call_start_at_late}
                                    values={{
                                        time: formattedDate,
                                    }} />
                            </p>
                            <p className='waiting-room-title-custom'>
                                <FormattedMessage {...waitingRoomMessages.scheduled_call_start_late_custom}
                                    values={{
                                        host_noun: intl.formatMessage(waitingRoomMessages.host_noun),
                                        br: <br />,
                                        mail_link: email => <a href={`mailto:${email}`}>{email}</a>,
                                    }} >
                                    {
                                        (msg => msg ?? null) // to make it optional
                                    }
                                </FormattedMessage>
                            </p>

                        </>
                    )
                }
            }
        } else {
            return (
                <p className='waiting-room-title-main'>
                    <FormattedMessage {...waitingRoomMessages.waiting_for_host} values={{
                        host_noun: intl.formatMessage(waitingRoomMessages.host_noun),
                        br: <br />,
                    }} />
                </p>
            );
        }

    }

    return (
        <>
            {backgroundStyles &&
                <div
                    className={`waiting-room-bg-wrapper${backgroundStyles.app_custom_background_url ? isBackgroundImageLoaded ? ' sync-animation-background' : '' : ' sync-animation-background'}`}
                    style={{ color: backgroundStyles.app_custom_background_color ? backgroundStyles.app_custom_background_color : '#fff' }}  >
                    {backgroundStyles.app_custom_background_url && <img src={backgroundStyles.app_custom_background_url}
                        className='welcome-background-image'
                        onLoad={() => animateBackgroundImage()}
                        style={{
                            opacity: isBackgroundImageLoaded ? backgroundStyles.app_custom_background_opacity ?
                                backgroundStyles.app_custom_background_opacity :
                                1 : 0
                        }}>
                    </img>}
                </div>}
            <div className='new-waiting-room-container'>
                <ShopLogoContainer src={customLogo} isAnimateOnload={true} />
                {(availableHostsAvatars.length > 0 && hostAvatarsDisplayed) && <div className='available-hosts'>
                    <ActiveHostAvatars avatars={availableHostsAvatars} />
                </div>}
                <div className={`waiting-room-title${waitingRoomMessage ? ' schedule-data-loaded' : ''}`}>
                    {waitingRoomMessage && messageMarkup()}
                </div>
                <OutlinedButton
                    onClick={() => { contextCancel(); onLeave(); }}
                    className='leave-button'>
                    <FormattedMessage {...waitingRoomMessages.leave_call} />
                </OutlinedButton>
            </div>
        </>
    )
}
