import React from 'react'
import CloseIcon from '../../../../../assets/icons/Close';
import EmptyImage from '../../../../../components/emptyImage';
import { MainImage } from '../main-image/MainImage';
import { IconAddedToCartProduct } from '../../../../../assets/icons/mobile/AddedToCartProduct';
import ProductCurrentPrice from '../../../shared/ProductCurrentPrice/productCurrentPrice';
import './ShowRoomProduct.scss'

export const ShowRoomProduct = ({ snapshotKey, product, viewProduct, removeItem, isProductInCart }) => {
    return (
        <div className='mobile-product-container'>
            <div className='mobile-product-image-wrapper'>
                {product.image
                    ? <MainImage
                        src={product.image}
                        customSize={'100%'}
                        animateAreaWhileLoading={true}
                        isRounded={true}
                        viewItem={() => viewProduct(product.productId, snapshotKey, product)} />
                    : <EmptyImage
                        size='adaptive'
                        viewItem={() => viewProduct(product.productId, snapshotKey, product)}
                        product={product}
                        isMobile={true}
                        label={product.productTitle}
                    />
                }
            </div>
            <div className='mobile-product-description-container'>
                <div className='mobile-product-price-container'>
                    <ProductCurrentPrice
                        product={product} />
                </div>
                {isProductInCart && <div className='mobile-added-to-cart-tag-container'>
                    <IconAddedToCartProduct />
                </div>}
                <div className='mobile-product-name-container'>{product.productTitle}</div>
            </div>
            <div onClick={() => removeItem(snapshotKey)} className='mobile-delete-button'>
                <div className='mobile-close-icon-container'>
                    <CloseIcon size={16}/>
                </div>
            </div>
        </div>
    )
}
