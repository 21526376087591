import {ROOMS} from "../constants/chatActionTypes";

const initialState = {
  pendingRooms: [],
  activeRooms: [],
};

const rooms = (state = initialState, action) => {
  switch (action.type) {
    case ROOMS.SAVE_ACTIVE_ROOMS:
      return {
        ...state,
        activeRooms: action.rooms,
      }

    case ROOMS.SAVE_PENDING_ROOMS:
      return {
        ...state,
        pendingRooms: action.rooms,
      }

    default:
      return state;
  }
};

export default rooms;
