import React from 'react';
import './video-screen-spinner.scss';

export const VideoScreenSpinner = ({isLoading}) => {

    return (
        <div className={`video-spinner-container`}>
            <div className={`ispinner gray large${isLoading ? ' animating' : ' hidden'}`}>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
                <div className="ispinner-blade"></div>
            </div>
        </div>
    )
}
