import { defineMessages } from 'react-intl';

export const menuDrawerMessages = defineMessages({
/*   search: {
    id: 'nouns.search'
  }, */
  showroom: {
    id: 'nouns.showroom'
  },
  cart: {
    id: 'nouns.cart'
  },
  custom_cart: {
    id: 'nouns_custom_cart'
  },
  chat: {
    id: 'nouns_chat'
  },
});
