import { menuDrawerMessages } from './messages';
import {DRAFT_ORDER_COMPLETED} from "./draftOrder";

export const state = {
  FULL_EXPANDED: 3,
  EXTRA_EXPANDED: 2,
  EXPANDED: 1,
  COLLAPSED: 0,
}

export const tabKey = {
  NONE: 0,
  SHOWROOM: 1,
  CART: 2,
  CHAT: 3
}

export const tabLabels = {
  SHOWROOM: 'Showroom',
  CART: 'Cart',
  CHAT: 'Chat',
  NONE: 'None',
}

export const tabNames = {
  SHOWROOM: menuDrawerMessages.showroom,
  CART: menuDrawerMessages.cart,
  CUSTOM_CART: menuDrawerMessages.custom_cart,
  CHAT: menuDrawerMessages.chat,
}

export const mobileTabs = (isChatEnabled = false, draftOrder = null) =>  [
  {key: tabKey.SHOWROOM, label: tabLabels.SHOWROOM, name: tabNames.SHOWROOM },
  {
    key: tabKey.CART,
    label: tabLabels.CART,
    name: draftOrder && draftOrder.status !== DRAFT_ORDER_COMPLETED
      ? tabNames.CUSTOM_CART
      : tabNames.CART
  },
  ... isChatEnabled ? [{key: tabKey.CHAT, label: tabLabels.CHAT, name: tabNames.CHAT}] : [],
];
