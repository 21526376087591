import React, { useState, useEffect } from 'react';
import { AvatarImage } from '../avatarImage/avatar-image';
import './styles.scss';

export const AVATAR_TYPE = {
  MESSAGE: 'message',
  HEADER: 'header'
};

const ChatUserIcon = ({type = AVATAR_TYPE.MESSAGE, user, style = null}) => {
  const [avatar, setAvatar] = useState(user && user.profileAvatarUrl
      ? user.profileAvatarUrl
      : user.avatar && user.avatar.thumbnailUrl
          ? user.avatar.thumbnailUrl
          : null);
  const [hostAvatarData, setHostAvatarData] = useState(null);

  useEffect(() => {
    let data
    if (user) {
      data = {...{initials: user && user.profileInitials}};
      if (avatar) {
        data = {...{imageUrl: avatar}, ...data};
      }
      setHostAvatarData(data);
    }
  }, [user])

  const renderMessageIcon = () => {
    if (avatar) {
      return (
        <img
          style={style}
          key={user.uid}
          className='user-icon'
          src={avatar}
          alt={user.uid}
          onError={() => setAvatar(null)}
        />
      );
    } else {
      return (
        <p
          style={style}
          key={user.uid}
          className='user-icon no-select'>
          {user.profileInitials}
        </p>
      );
    }
  };

  const renderHeaderIcon = () => {
    return (
      hostAvatarData && <AvatarImage avatar={hostAvatarData} size='sm' />
    );
  };

  switch (type) {
    case AVATAR_TYPE.MESSAGE:
      return renderMessageIcon();
    case AVATAR_TYPE.HEADER:
      return renderHeaderIcon();
    default:
      return null;
  }
};

export default ChatUserIcon;
