import React from 'react';
import ShopLogoContainer from '../../../components/shop-logo-container/shop-logo-container';
import ActiveHostAvatars from '../activeHostAvatars/activeHostAvatars';
import { useIntroPage } from '../IntroPageProvider';
import './index.scss'
import {PAGES} from "../../../constants/routes";

const IntroHeader = ({ history }) => {
    const { avatars, playList, config } = useIntroPage();
    const src = config && config.app_custom_logo;
    const hostAvatarsDisplayed = config && config.hostAvatarsDisplayed;

    return (
        <div className='intro-page-header-container'>
            {src &&
                <div className={`shop-logo-wrapper${(history.location.pathname === PAGES.INTRO && playList.length > 0) ? ' hidden' : ''}`}>
                    <ShopLogoContainer
                        src={src}
                        isAnimateOnload={(history.entries && history.entries.length > 2 && history.entries[history.entries.length - 2].pathname !== PAGES.SCHEDULING) ||
                            (history.entries && history.entries.length < 2)} />
                </div>}
            <div className={`host-avatar-section${!src ? ' no-logo' : ''}`}>
                <div className={`shop-avatars-wrapper${!src ? ' no-logo' : ''}${(history.location.pathname === PAGES.INTRO && playList.length > 0) ? ' hidden' : ''}`}>
                    {hostAvatarsDisplayed && avatars.length > 0 && history.location.pathname !== PAGES.INTRO && <div className='shop-avatars-content'>
                        <ActiveHostAvatars avatars={avatars} />
                    </div>}
                </div>
            </div>
        </div>
    );
};

export default IntroHeader;
