import React from 'react';
import { AddToShowroomButton } from './components/add-to-showroom-button/AddToShowroomButton';
import { useModalViewProvider } from '../../../../../../../../components/mobile-providers/ModalViewProvider';
import { useProductViewProvider } from '../../../../../../../../components/ProductViewProvider';
import { CurrentPrice } from './components/current-price/CurrentPrice';
import { ProductDetailsView } from '../../../../../../mobile/components/modals/product-details-view/ProductDetailsView';
import { useSessionShowroomActions } from '../../../../../../../../hooks';
import { useCallContextProvider } from '../../../../../../../../components/CallContextProvider';
import EmptyImage from '../../../../../../../../components/emptyImage';
import './styles.scss';

export const ProductSearchResult = ({ product }) => {
	const { viewProduct, clearSelectedProduct } = useProductViewProvider();
	const { callId } = useCallContextProvider();
	const { addToSession } = useSessionShowroomActions(callId);
	const { showView } = useModalViewProvider();

	const openDetails = async (product) => {
		viewProduct(product.productId);
		const modal = {
			dismissAlloved: true,
			onDismiss: clearSelectedProduct,
			type: 'product-details',
			component: <ProductDetailsView />
		}
		showView(modal);
	};

	return (
		<div className='mobile-product-details-item'>
			<div className='mobile-image-container' onClick={() => openDetails(product)}>
				{product.image ? (
					//FALLBACK IMAGE LOAD FAILDE
					<img
						src={product.image}
						alt={product.title}
					/>
				) : (
					<EmptyImage
						size={'adaptive'}
						isMobile={true}
						product={product}
					/>
				)}
			</div>
			<div className='mobile-product-card-details-container'
				onClick={() => openDetails(product)}>
				<p className='mobile-product-label'>{product.title}</p>
				<CurrentPrice
					maxVariantPrice={product.maxVariantPrice}
					minVariantPrice={product.minVariantPrice}
					currencyCode={product.currencyCode}
					price={product.price}
					compareAtPrice={product.compareAtPrice} />
			</div>
			<AddToShowroomButton
				added={product.isInSR}
				onSubmit={() => addToSession(product)} />
		</div>
	)
}
