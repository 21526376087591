import React, {useState, useEffect} from 'react';
import {useCallContextProvider} from '../../../../../components/CallContextProvider';
import {useSessionCart, useSessionDiscount} from '../../../../../hooks';
import {useSessionCartActions} from '../../../../../hooks/useSessionCart';
import {useProductViewProvider} from '../../../../../components/ProductViewProvider';
import {useCustomerDetails} from '../../../../../components/CustomerDetailsProvider';
import {EmptyCard} from './cart-components/empty-card';
import {CartItemslist} from './cart-components/cart-items-list';
import {CartFooter} from './cart-components/cart-footer';
import {FormattedMessage} from 'react-intl';
import {cartMessages} from '../../../intl/cart-messages/messages';
import {normalizeDraftOrderProducts} from "../../../../../utils/draftOrder";
import DraftOrderFooter from "./cart-components/draft-order-footer/draft-order-footer";
import {DRAFT_ORDER_COMPLETED} from "../../../../../constants/draftOrder";
import DraftOrderList from "./cart-components/draft-order-list/draft-order-list";
import {useCallScreenProvider} from "../../../../../components/CallScreenProvider";

export const Cart = ({isMobile}) => {
  const [cartData, setCartData] = useState({
    total: null,
    totalDiscount: null,
  });

  const {callId, activeCartId} = useCallContextProvider();
  const {draftOrder, isCheckoutComplete, openCheckout} = useCallScreenProvider()
  const {cartSnapshots} = useSessionCart(callId, activeCartId);
  const {updateCartProduct, removeCartProduct} = useSessionCartActions(callId, activeCartId);

  const {cartDiscount} = useSessionDiscount(callId, activeCartId);

  const {viewCartProduct} = useProductViewProvider();
  const {name} = useCustomerDetails();

  const calculateCart = () => {
    if (!cartSnapshots) return;
    if (!cartSnapshots.length) {
      setCartData({
        total: 0,
        totalDiscount: 0,
        currencyCode: null,
      });
    }
    const cartTotal = cartSnapshots.reduce(
      (cart, snapshot) => {
        const product = snapshot.val();
        const quantity = product.quantity || 1;
        return {
          total: cart.total + Number.parseFloat(product.currentPrice) * quantity,
          totalBeforeDiscount: cart.totalBeforeDiscount + Number.parseFloat(product.currentCompareToPrice || product.currentPrice) * quantity,
          currencyCode: product.currencyCode,
        };
      },
      {total: 0, totalBeforeDiscount: 0}
    );
    const totalDiscount = Math.round(
      ((cartTotal.totalBeforeDiscount - cartTotal.total) * 100) / cartTotal.totalBeforeDiscount
    )
    setCartData({
      total: cartTotal.total,
      totalDiscount,
      currencyCode: cartTotal.currencyCode,
    });
  };

  useEffect(calculateCart, [cartSnapshots]);

  const cartContainer = () => (
    cartSnapshots.length ? (
        <>
          <CartItemslist
            cartSnapshots={cartSnapshots}
            isMobile={isMobile}
            viewCartItem={viewCartProduct}
            removeCartProduct={removeCartProduct}
            updateCartProduct={updateCartProduct}
          />
        </>
      ) :
      <EmptyCard/>
  )

  const draftOrderContainer = () => (
    <DraftOrderList
      products={normalizeDraftOrderProducts(draftOrder)}
      isMobile={isMobile}
    />
  )

  const footerContainer = () => (
    draftOrder ? (
      <DraftOrderFooter
        draftOrder={draftOrder}
        openCheckout={openCheckout}
        isMobile={isMobile}
      />
    ) : (
      <CartFooter
        cartSnapshots={cartSnapshots}
        cartDiscount={cartDiscount}
        isMobile={isMobile}
        cartData={cartData}
        openCheckout={openCheckout}
        isCheckoutComplete={isCheckoutComplete}
      />
    )
  )

  return (
    <>
      {isCheckoutComplete || draftOrder?.status === DRAFT_ORDER_COMPLETED ? (
        <div className='post-purchase'>
          <h1>
            <FormattedMessage {...cartMessages.thank_you} values={{name: name ? `, ${name}` : ''}}/>!
          </h1>
        </div>
      ) : (
        draftOrder ? draftOrderContainer() : cartContainer()
      )}
      {footerContainer()}
    </>
  );
};

export default Cart;
