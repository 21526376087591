import React, {useMemo} from 'react';
import { ROLES } from '../../../../../modules/chat/constants/participantRoles';
import { useCustomerChatData } from '../../../../../modules/chat/hooks/useCustomerChatData';
import MessengerScreen from '../../../../chat-page/containers/messenger';
import ProductSidebar from './product-sidebar/product-sidebar';
import SearchSidebar from "./search-sidebar/search-sidebar";
import { buildUserEntity } from '../../../../../modules/chat/utils/auth';
import { useClientAuthProvider } from '../../../../../components/ClientAuthProvider';
import { useCallScreenProvider } from '../../../../../components/CallScreenProvider';
import { useProductViewProvider } from '../../../../../components/ProductViewProvider';
import { getNumberOfUnreadMessages } from '../../../../../modules/chat/utils/rooms';
import './sidebar.scss';

export default function Sidebar({shopId, isFullScreenEnabled, setVideoChatOpen, videoChatOpen, setFullSizeChatImage}) {

  const {productViewData, isProductLoading, isPresentationEnabled} = useProductViewProvider();

  const { isChatEnabled, isSearchDisabled } = useCallScreenProvider();
	const { user } = useClientAuthProvider();
  const userEntity = useMemo(() => buildUserEntity({ ...user, role: ROLES.PARTICIPANT }), [user])
  const { activeRoom } = useCustomerChatData(userEntity, shopId);

  const closeButtonAction = () => {
		setVideoChatOpen(false);
	};

	const numberOfUnreadMessages = activeRoom && activeRoom.id && isChatEnabled ? getNumberOfUnreadMessages(activeRoom, user) : 0;

  const getMessengerScreen = () => {
    if (isChatEnabled && activeRoom && activeRoom.id) {
      return (
        <div className={`chat-container ${!videoChatOpen ? 'no-show' : ''}`}>
          <MessengerScreen
            roomMessagesDisplayed={videoChatOpen}
            user={userEntity}
            videoChatAction={closeButtonAction}
            videoInitiated={true}
            setFullSizeChatImage={setFullSizeChatImage}
          />
        </div>
      );
    }

    return null;
  };

  if (productViewData.product || isProductLoading || isPresentationEnabled) {
    return (
      <>
        <ProductSidebar
          isFullScreenEnabled={isFullScreenEnabled}
          setVideoChatOpen={setVideoChatOpen}
          numberOfUnreadMessages={numberOfUnreadMessages}
          isChatEnabled={isChatEnabled}
          isSearchDisabled={isSearchDisabled}
        />
        { getMessengerScreen() }
      </>
    )
  } else {
    return (
      <>
        <SearchSidebar
          setVideoChatOpen={setVideoChatOpen}
          numberOfUnreadMessages={numberOfUnreadMessages}
          isChatEnabled={isChatEnabled}
          isSearchDisabled={isSearchDisabled}
        />
        { getMessengerScreen() }
      </>
    )
  }
}
