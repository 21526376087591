import React from "react";
import './counter.scss';

export const CounterInput = ({ onIncrease, onDecrease, quantity, inventoryPolicy, inventoryQuantity, withoutStepper }) => {

  return (
    <div className="quantity-input">
      {!withoutStepper && (
        <button id='decrease-button' className='decrease-button' onClick={onDecrease}>-</button>
      )}
      <input className='count-field' type="number" value={quantity ? quantity : 1} readOnly />
      {!withoutStepper && (
        <button id='increase-button' disabled={inventoryPolicy !== 'CONTINUE' && quantity >= inventoryQuantity} className='increase-button' onClick={onIncrease}>+</button>
      )}
    </div>
  );
};
