import firebase from '../../Firebase';
import {getFirestore} from 'firebase/firestore';
import {getStorage, uploadString, uploadBytes} from 'firebase/storage';

const MEDIA_BUCKET = process.env.REACT_APP_FIREBASE_MEDIA_BUCKET;
const IMAGE_MAX_SIZE = 10 * 1024 * 1024;
const IMAGE_MAX_LENGTH = 2000;

const firestore = () => getFirestore(firebase);
const storage = () => getStorage(firebase);
const mediaStorage = () => getStorage(firebase, MEDIA_BUCKET);

const putFileToStorage = async (ref, file, metadata) => {
  if (file.isBase64) {
    return uploadString(ref, file.data, 'data_url', {...metadata, contentType: file.type});
  } else {
    delete file.data;
    delete file.isBase64;
    return uploadBytes(ref, file, {...metadata, contentType: file.type});
  }
}

export {
  firebase,
  firestore,
  storage,
  mediaStorage,
  putFileToStorage,
  MEDIA_BUCKET,
  IMAGE_MAX_SIZE,
  IMAGE_MAX_LENGTH,
};
