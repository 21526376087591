import { defineMessages } from 'react-intl';

export const formMessages = defineMessages({
  welcome_message: {
    id: 'messages.welcome_message'
  },
  welcome_message_content: {
    id: 'messages_welcome_message_content',
  },
  name: {
    id: 'nouns_name'
  },
  name_error: {
    id: 'messages_name_error'
  },
  email: {
    id: 'nouns.email'
  },
  email_error: {
    id: 'messages.email_error'
  },
  email_invalid: {
    id: 'messages.email_invalid'
  },
  terms_error: {
    id: 'messages_terms_error'
  },
  optional: {
    id: 'adverb_optional'
  },
  required: {
    id: 'adverb_required'
  },
  powered_by_caazam: {
    id: 'messages.powered_by_caazam',
  },
});
