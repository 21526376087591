import React, { useState, useEffect } from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { scheduleScreenMessages } from '../messages';
import { useSchdeuleProvider } from '../../../components/SchdeuleProvider';
import { useClientConfigProvider } from '../../../components/ClientConfigProvider';
import Confirm from '../ConfirmDialog/confirm-dialog';
import { BigCalendar } from '../components/big-calendar/BigCalendar';
import ShopLogoContainer from '../../../components/shop-logo-container/shop-logo-container';
import { ErrorMark } from '../../../assets/icons/ErrorMark';
import { LeaveMessageEvent } from '../components/leave-message-event/LeaveMessageEvent';
import { PageHeader } from '../../../components/page-header/PageHeader';
import { PageFooter } from '../../../components/page-footer/PageFooter';
import { PageScrollContainer } from '../../../components/page-scroll-container/PageScrollContainer';
import './Reschedule.scss';
import {PAGES} from "../../../constants/routes";

export const Reschedule = ({ onClose, navigateToNextScheduleScreen }) => {
  const intl = useIntl();
  const history = useHistory();
  const { path } = useRouteMatch();
  const [reschedulePageItems, setReschedulePageItems] = useState(null);
  const { hostSelectionConfig, dynamicConfig } = useClientConfigProvider();
  const [showDeleteScheduleDialog, setShowDeleteScheduleDialog] = useState(false);
  const { scheduleId, scheduleItemData, deleteScheduledCall: deleteSchedule } = useSchdeuleProvider();
  const customLogo = dynamicConfig && dynamicConfig.app_custom_logo;

  const deleteScheduleCall = () => {
    deleteSchedule(scheduleId).then(() => {
      setShowDeleteScheduleDialog(false);
      const pageData = {
        header: <PageHeader
          children={
            <div className='reschedule-header-container'>
              {customLogo && <ShopLogoContainer src={customLogo} />}
              <div className='reschedule-title'><FormattedMessage {...scheduleScreenMessages.schedule_delete_succeed} /></div>
            </div>
          }
        />,
        content: null,
        footer: <PageFooter
          shadow={false}
          primaryButtonLabel={<FormattedMessage {...scheduleScreenMessages.cta_ok} />}
          onSubmitPrimary={() => onClose()}
          secondaryButtonLabel={<LeaveMessageEvent />}
          onSubmitSecondary={() => history.push({ pathname: PAGES.CONTACT, preventSchedule: true, isBackAllowed: true })} />
      }
      setReschedulePageItems(pageData);
    })
  }

  const navigateToNextScreen = () => {
    navigateToNextScheduleScreen(path);
  }

  useEffect(() => {
    if (!reschedulePageItems) {
      let pageData;
      if (scheduleItemData) {
        if (scheduleItemData.status === 'deleted') {
          pageData = {
            header: <PageHeader
              children={
                <div className='reschedule-header-container'>
                  {customLogo && <ShopLogoContainer src={customLogo} />}
                  <div className='reschedule-title'> <FormattedMessage {...scheduleScreenMessages.schdeule_not_valid} /></div>
                </div>
              }
            />,
            content: <PageScrollContainer children={
              <div className='reschedule-main-screen-content'>
                <ErrorMark />
              </div>
            } />,
            footer:
              <PageFooter
                shadow={false}
                primaryButtonLabel={<FormattedMessage {...scheduleScreenMessages.cta_done} />}
                onSubmitPrimary={() => onClose()}
                secondaryButtonLabel={<LeaveMessageEvent />}
                onSubmitSecondary={() => history.push({ pathname: PAGES.CONTACT, preventSchedule: true, isBackAllowed: true })} />
          }
        } else if (scheduleItemData.status === 'completed') {
          pageData = {
            header: <PageHeader
              children={
                <div className='reschedule-header-container'>
                  {customLogo && <ShopLogoContainer src={customLogo} />}
                  <div className='reschedule-title'> <FormattedMessage {...scheduleScreenMessages.schdeule_no_change} /></div>
                </div>
              }
            />,
            content: <PageScrollContainer children={
              <div className='reschedule-main-screen-content'>
                <BigCalendar date={new Date(scheduleItemData.startTimestamp)} />
                <p className='reschedule-confirm-message'>
                  <FormattedMessage {...scheduleScreenMessages.reschdeule_confirm_sent} values={{ email: <span className='reschedule-confirmation-email'>{scheduleItemData.customer.email}</span> }} />
                </p>
              </div>
            } />,
            footer:
              <PageFooter
                shadow={false}
                primaryButtonLabel={<FormattedMessage {...scheduleScreenMessages.cta_done} />}
                onSubmitPrimary={() => onClose()}
                secondaryButtonLabel={<LeaveMessageEvent />}
                onSubmitSecondary={() => history.push({ pathname: PAGES.CONTACT, preventSchedule: true, isBackAllowed: true })} />
          }
        } else {
          pageData = {
            header: <PageHeader
              children={
                <div className='reschedule-header-container'>
                  {customLogo && <ShopLogoContainer src={customLogo} />}
                  <div className='reschedule-title'>  <FormattedMessage {...scheduleScreenMessages.schdeule_success} values={{
                    service_noun: intl.formatMessage(scheduleScreenMessages.service_noun)
                  }} /></div>
                </div>
              }
            />,
            content: <PageScrollContainer children={
              <div className='reschedule-main-screen-content'>
                <BigCalendar date={new Date(scheduleItemData.startTimestamp)} />
                <p className='reschedule-confirm-message'>
                  <FormattedMessage {...scheduleScreenMessages.reschdeule_confirm_sent} values={{ email: <span className='reschedule-confirmation-email'>{scheduleItemData.customer.email}</span> }} />
                </p>
              </div>
            } />,
            slot: scheduleItemData.startTimestamp,
            footer:
              <PageFooter
                shadow={false}
                primaryButtonLabel={<FormattedMessage {...scheduleScreenMessages.cta_reschedule} />}
                primaryOutlineButtonLabel={<FormattedMessage {...scheduleScreenMessages.cta_cancel_schedule} />}
                onSubmitOutlinePrimary={() => setShowDeleteScheduleDialog(true)}
                onSubmitPrimary={navigateToNextScreen}
                secondaryButtonLabel={<LeaveMessageEvent />}
                onSubmitSecondary={() => history.push({ pathname: PAGES.CONTACT, preventSchedule: true, isBackAllowed: true })} />
          }
        }
        setReschedulePageItems(pageData);
      } else {
        pageData = {
          header: <PageHeader
            children={
              <div className='reschedule-header-container'>
                {customLogo && <ShopLogoContainer src={customLogo} />}
                <div className='reschedule-title'>  <FormattedMessage {...scheduleScreenMessages.schedule_not_found} /></div>
              </div>
            }
          />,
          content: <PageScrollContainer children={
            <div className='reschedule-main-screen-content'>
              <ErrorMark />
            </div>
          } />,
          footer:
            <PageFooter
              shadow={false}
              primaryButtonLabel={<FormattedMessage {...scheduleScreenMessages.cta_ok} />}
              onSubmitPrimary={() => onClose()}
              secondaryButtonLabel={<LeaveMessageEvent />}
              onSubmitSecondary={() => history.push({ pathname: PAGES.CONTACT, preventSchedule: true, isBackAllowed: true })} />
        }
        setReschedulePageItems(pageData);
      }
    }
  }, [])

  return (
    <>
      {reschedulePageItems && <>
        {reschedulePageItems.header}
        {reschedulePageItems.content}
        {reschedulePageItems.footer}

        {showDeleteScheduleDialog &&
          <Confirm
            content={<p><FormattedMessage {...scheduleScreenMessages.delete_schedule_title} /></p>}
            okFunc={() => deleteScheduleCall()}
            okText={<FormattedMessage {...scheduleScreenMessages.modal_cta_yes} values={{ cta: intl.formatMessage({ ...scheduleScreenMessages.cta_cancel }) }} />}
            cancelFunc={() => setShowDeleteScheduleDialog(false)}
            cancelText={<FormattedMessage {...scheduleScreenMessages.modal_cta_no} />}
            modalClass='warning'
          />
        }
      </> }
    </>
  )
}
