import firebase from '../Firebase';
import {getStorage, uploadString, uploadBytes} from 'firebase/storage';

const MEDIA_BUCKET = process.env.REACT_APP_FIREBASE_MEDIA_BUCKET;

const mediaStorage = () => getStorage(firebase, MEDIA_BUCKET);

const putFileToStorage = async (ref, file, metadata) => {
  if (file.isBase64) {
    return uploadString(ref, file.data, 'data_url', {...metadata, contentType: file.type});
  } else {
    delete file.data;
    delete file.isBase64;
    return uploadBytes(ref, file, {...metadata, contentType: file.type});
  }
}

export {
  mediaStorage,
  putFileToStorage,
};
