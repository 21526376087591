const APP_DIAGNOSTICS_ACTION_TYPES = {
  PREFLIGHT_PROGRESS: 'preflight-progress',
  PREFLIGHT_COMPLETED: 'preflight-completed',
  PREFLIGHT_FAILED: 'preflight-failed',
  SET_TWILIO_STATUS: 'set-twilio-status',
  SET_TWILIO_STATUS_ERROR: 'set-twilio-status-error',
  PREFLIGHT_STARTED: 'preflight-started',
  PREFLIGHT_STOPPED: 'preflight-stopped',
  REPORT_SENT: 'diagnostics-sent',
}

const TWILIO_STATUS_URL = 'https://status.twilio.com/api/v2/components.json';

const QUALITY_SCORE = {
  POOR: 0,
  SUBOPTIMAL: 1,
  GOOD: 2,
  EXCELLENT: 3,
}

const QUALITY_LABEL = {
  0: 'POOR',
  1: 'SUBOPTIMAL',
  2: 'GOOD',
  3: 'EXCELLENT',
}

export {
  APP_DIAGNOSTICS_ACTION_TYPES,
  TWILIO_STATUS_URL,
  QUALITY_SCORE,
  QUALITY_LABEL,
}
