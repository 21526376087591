import React from 'react';
import { FilledButton } from '../../../../../../components';
import { FormattedMessage } from 'react-intl';
import { useCallScreenProvider } from '../../../../../../components/CallScreenProvider';
import { useClientConfigProvider } from '../../../../../../components/ClientConfigProvider';
import { currencyFormatter } from '../../../../../../utils';
import { CART_DISABLE_REASON } from  '../../../../../../constants/features';
import { cartComponentsMessages } from '../../../../intl/cart-components/messages';
import './draft-order-footer.scss';
import {DRAFT_ORDER_COMPLETED} from "../../../../../../constants/draftOrder";

export const DraftOrderFooter = () => {
  const { openCheckout, draftOrder } = useCallScreenProvider();

  const {
    appliedDiscount,
    shippingLine,
    totalTax,
    totalPrice,
    currencyCode,
    draftOrderId,
    status,
    subtotalPrice
  } = draftOrder;

  const { featureSupport } = useClientConfigProvider();
  const { checkoutDisabled } = featureSupport;

  const isCompleted = status === DRAFT_ORDER_COMPLETED

  const subtotal = (parseFloat(subtotalPrice) + parseFloat(appliedDiscount?.amountV2?.amount ?? 0)).toFixed(2);

  const onCheckout = () => {
    openCheckout({
      total: totalPrice,
      currencyCode
    }, draftOrderId)
  }

  const priceContainer = ({title, price, description = '', isTotal = false}) => (
    <div className={`draft-order-price`}>
      <p className={`title${isTotal ? ' total' : ''}`}><FormattedMessage {...title} /></p>
      <p className='description'>{description}</p>
      <p className={`price${isTotal ? ' bold' : ''}`}>{price}</p>
    </div>
  )

  return (
    <div className='mobile-draft-order-footer'>
      {checkoutDisabled && draftOrder &&
        <div className='mobile-ios-15-warning'>
          <p><FormattedMessage {...cartComponentsMessages.cart_disabled_title} values={{ device: checkoutDisabled?.reason === CART_DISABLE_REASON.IOS_15_1 ? 'ios' : 'not_ios' }} /></p>
          <p><FormattedMessage {...cartComponentsMessages.cart_disabled_text} /></p>
        </div>
      }
      <div className='mobile-draft-order-footer-content'>
        {!isCompleted && (
          <div className='mobile-draft-order-total-amount-container'>
            {priceContainer({
              title: cartComponentsMessages.draft_order_sub_total,
              price: currencyFormatter(subtotal, currencyCode),
            })}
            {appliedDiscount && (
              priceContainer({
                title: cartComponentsMessages.draft_order_discount,
                price: currencyFormatter(appliedDiscount.amountV2.amount  * -1, currencyCode),
                description: appliedDiscount.description
              })
            )}
            {shippingLine && (
              priceContainer({
                title: cartComponentsMessages.draft_order_shipping,
                price: currencyFormatter(shippingLine.discountedPriceSet.shopMoney.amount, currencyCode),
                description: shippingLine.title
              })
            )}
            {totalTax && (
              (priceContainer({
                title: cartComponentsMessages.draft_order_tax,
                price: currencyFormatter(totalTax, currencyCode)
              }))
            )}
            {(priceContainer({
              title: cartComponentsMessages.draft_order_total,
              price: currencyFormatter(totalPrice, currencyCode),
              isTotal: true
            }))}
          </div>
        )}
        {!isCompleted && (
          <FilledButton
            onClick={onCheckout}
            disabled={checkoutDisabled}
            className='mobile-checkout-btn'>
            <FormattedMessage {...cartComponentsMessages.cta_checkout} />
          </FilledButton>
        )}
      </div>
    </div>
  );
};
