import React from 'react';
import { CloseIcon } from '../../../../assets/icons/CloseIcon';
import './CloseBtn.scss';
import {isLastStackScreens} from "../../../../utils/ui";

export const CloseBtn = ({onClose, location}) => {

    const handleCloseButton = () => {
      let params = null;
      if (isLastStackScreens(location)) {
        params = {resetStack: true};
      }
      onClose(params);
    }

    return (
        <button onClick={handleCloseButton} className='boutiq-close-button'>
            <CloseIcon />
        </button>
    )
}
