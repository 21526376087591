import {ROOMS} from "../constants/chatActionTypes";

const initiateChatConversationRequest = () => ({type: ROOMS.INITIATE_CHAT_CONVERSATION.REQUEST})
const initiateChatConversationFailure = (error) => ({type: ROOMS.INITIATE_CHAT_CONVERSATION.FAILURE, error})
const initiateChatConversationSuccess = () => ({type: ROOMS.INITIATE_CHAT_CONVERSATION.SUCCESS})

const assignToRoomRequest = () => ({type: ROOMS.ASSIGN_TO_ROOM.REQUEST})
const assignToRoomFailure = (error) => ({type: ROOMS.ASSIGN_TO_ROOM.FAILURE, error})
const assignToRoomSuccess = () => ({type: ROOMS.ASSIGN_TO_ROOM.SUCCESS})

const archiveRoomRequest = () => ({type: ROOMS.ARCHIVE_ROOM.REQUEST})
const archiveRoomFailure = (error) => ({type: ROOMS.ARCHIVE_ROOM.FAILURE, error})
const archiveRoomSuccess = () => ({type: ROOMS.ARCHIVE_ROOM.SUCCESS})

const joinRoomRequest = () => ({type: ROOMS.JOIN_ROOM.REQUEST})
const joinRoomFailure = (error) => ({type: ROOMS.JOIN_ROOM.FAILURE, error})
const joinRoomSuccess = () => ({type: ROOMS.JOIN_ROOM.SUCCESS})

const setActiveHostRequest = () => ({type: ROOMS.SET_ACTIVE_HOST.REQUEST})
const setActiveHostFailure = (error) => ({type: ROOMS.SET_ACTIVE_HOST.FAILURE, error})
const setActiveHostSuccess = () => ({type: ROOMS.SET_ACTIVE_HOST.SUCCESS})

const savePendingRooms = (rooms) => ({
  type: ROOMS.SAVE_PENDING_ROOMS,
  rooms,
})

const saveActiveRooms = (rooms) => ({
  type: ROOMS.SAVE_ACTIVE_ROOMS,
  rooms,
})

export {
  initiateChatConversationRequest,
  initiateChatConversationSuccess,
  initiateChatConversationFailure,
  archiveRoomRequest,
  archiveRoomSuccess,
  archiveRoomFailure,
  joinRoomRequest,
  joinRoomSuccess,
  joinRoomFailure,
  assignToRoomRequest,
  assignToRoomSuccess,
  assignToRoomFailure,
  setActiveHostRequest,
  setActiveHostSuccess,
  setActiveHostFailure,
  savePendingRooms,
  saveActiveRooms,
}
