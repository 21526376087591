import React from 'react';
import './ProductOptions.scss';

export const ProductOptions = ({ availableOptions, product, selectedOptions, updateSelectedOptions }) =>
	<div className='mobile-options-list'>
		{availableOptions.map((option, optionIndex) =>
			<div key={`${product.productId}${optionIndex}`}
				className='mobile-select-block-wrapper'>
				<div className='mobile-select-block'>
					<select
						onChange={(event) =>
							updateSelectedOptions(option.name, {
								category: option.name,
								label: event.target.value,
								value: event.target.value
							})
						}
						value={selectedOptions[option.name] ? selectedOptions[option.name].value : ''}
						className='mobile-product-sidebar-select'
					>
						<option value='' disabled hidden>
							{option.placeholder}
						</option>
						{option.selectValues.map((val, valIndex) => {
							return (
								<option
									key={valIndex}
									value={val.value}>
									{val.label}
								</option>
							);
						})
						}
					</select>
				</div>
			</div>
		)}
	</div>
