import { defineMessages } from 'react-intl';

export const waitingRoomMessages = defineMessages({
  host_noun: {
    id: 'nouns_host',
  },
  waiting_for_host: {
    id: 'messages_waiting_for_host',
  },
  scheduled_call_start_at: {
    id: 'scheduled_call_start_at',
  },
  scheduled_call_start_at_late: {
    id: 'scheduled_call_start_at_late',
  },
  scheduled_call_start_early_custom: {
    id: 'scheduled_call_start_early_custom',
  }, 
  scheduled_call_start_late_custom: {
    id: 'scheduled_call_start_late_custom',
  }, 
  leave_call: {
    id: 'button_actions_leave_call',
  },
  nouns_today: {
    id: 'nouns.today'
  },
  nouns_tomorrow: {
    id: 'nouns.tomorrow'
  },
});
