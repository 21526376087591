import {parseIdString} from "./index";

export const normalizeDraftOrderProducts = (draftOrder) => {
  return draftOrder.lineItems.map((product) => {
    const {
      image,
      discountedTotal,
      quantity,
      variantTitle,
      title,
      custom,
      id,
      originalTotal
    } = product;
    return ({
      key: custom ? parseIdString(id) : product.variantId,
      currencyCode: draftOrder.currencyCode,
      variantId: custom ? parseIdString(id) : product.variantId,
      image,
      currentPrice: discountedTotal/quantity,
      variantTitle,
      productTitle: title,
      quantity,
      currentCompareToPrice: originalTotal/quantity
    })
  })
}