import React from 'react';
import { MobileProductDetails } from '../../product-details/ProductDetails';
import { useProductViewProvider } from '../../../../../../components/ProductViewProvider';
import './ProductDetailsView.scss';

export const ProductDetailsView = () => {

	const {
		productViewData: {
			product,
		},
		isProductLoading,
		isPresentationEnabled
	} = useProductViewProvider();

	return (
		<>
			{isProductLoading && !isPresentationEnabled ? (
				<div className='mobile-product-details-skeleton-preview'>
					<div className='mobile-skeleton-main-image-container animated-loader-background'>
					</div>
					<div className='mobile-skeleton-thumbnails-container'>
						{Array.from({ length: 4 }, (el, index) => <div key={index} className='mobile-skeleton-thumbnail animated-loader-background'></div>)}
					</div>
					<div className='mobile-skeleton-description-container'>
						<div className='mobile-skeleton-product-name animated-loader-background'></div>
						<div className='mobile-skeleton-product-price animated-loader-background'></div>
					</div>
					<div className='mobile-skeleton-submin-button-container animated-loader-background'></div>
				</div>
			) : product ? (
				<MobileProductDetails />
			) : (
				<p>Uh oh... no product here</p>
			)}
		</>
	);
};
