import React from 'react'
import { OutlinedButton, FilledButton } from '../boutiq-buttons/BoutiqButton';
import './PageFooter.scss';

export const PageFooter = ({
    shadow = true,
    primaryButton = true,
    primaryButtonLabel,
    primaryOutlineButtonLabel,
    secondaryButtonLabel = null,
    onSubmitPrimary,
    onSubmitSecondary,
    isHidden = false,
    isDisabled = false,
    isLoading = false,
    onSubmitOutlinePrimary,
}) => {
    return (
        <div className={`page-footer-content${shadow ? ' footer-container-shadow' : ''}`}>
            <div className={`actions-container${isHidden ? ' hidden' : ''}`}>
                <div className='primary-buttons-wrapper'>
                    <div className='primary-buttons-container'>
                        {primaryOutlineButtonLabel && <OutlinedButton onClick={onSubmitOutlinePrimary} >
                            {primaryOutlineButtonLabel}
                        </OutlinedButton>}
                        {primaryButton && <FilledButton
                            onClick={onSubmitPrimary}
                            isLoading={isLoading}
                            disabled={isDisabled}
                            className={`${isDisabled ? ' disabled' : ''}`} >
                            {primaryButtonLabel}
                        </FilledButton>}
                    </div>
                </div>
                {secondaryButtonLabel && <span className={`secondary-action${secondaryButtonLabel === null ? ' empty' : ''}`} onClick={onSubmitSecondary}>
                    {secondaryButtonLabel}
                </span>}
            </div>
        </div>
    )
}
