import React, { useState, useRef, useEffect } from 'react';
import { Loader } from '../../../../../components';
import './MainImage.scss';
import '../../animations/Animations.scss';

export const MainImage = ({ src, customSize, viewItem, isRounded, animateAreaWhileLoading }) => {
    const imageRef = useRef(null);
    const imageWrapperRef = useRef(null);
    const [isVertivcal, setIsVertivcal] = useState(false);
    const [imageSize, setImageSize] = useState('100%');
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (imageWrapperRef.current) {
            setImageSize(`${imageWrapperRef.current.offsetHeight}px`)
        }
    }, [imageWrapperRef.current])

    const handleImageAspectRatio = () => {
        setIsLoaded(true);
        const ratio = imageRef.current.naturalHeight / imageRef.current.naturalWidth;
        if (ratio > 1) {
            setIsVertivcal(true);
        }
    }
    return (
        <div 
            ref={imageWrapperRef} 
            onClick={viewItem} 
            className={
                `mobile-main-image-container-wrapper
                ${src ? ' mobile-image-available' : ''}
                ${isVertivcal ? ' mobile-vertical' : ''}
                ${isRounded ? ' mobile-rounded' : ''}
                ${animateAreaWhileLoading ? ' mobile-animated-loader-background' : ''}
                ${isLoaded ? ' mobile-loaded' : ''}`}>
            <div className='mobile-main-image-content-wrapper' style={{ paddingTop: customSize ? customSize : imageSize }}>
                <div className='mobile-main-image-content'>
                    {src &&
                        <>
                            <img ref={imageRef} src={src} onLoad={handleImageAspectRatio} className='mobile-main-image' />
                            {(!isLoaded && !animateAreaWhileLoading) && <Loader />}
                        </>
                    }
                </div>
            </div>
        </div>
    )
}
