import React from 'react';
import { FormattedMessage } from 'react-intl';
import './index.scss';
import CloseButton from '../closeButton/closeButton';
import { FullScreenProductDescription } from "../DescriptionContainer/descriptionContainer";
import { updateDesktopVideoViews } from "../../../../../../../utils/ui";
import { parseIdString } from "../../../../../../../utils";
import { useCallContextProvider } from '../../../../../../../components/CallContextProvider';
import { sidebarMessages } from '../../../../../intl/sidebar-messages/messages';

export default function FullScreenProductView({
  product = null,
  variant = null,
  currentImages = [],
  selectedImage = null,
  setSelectedImage,
  sessionSnapshots,
  addToSession,
  isProductViewVisible,
  hideFullDetailsView,
  isPresentationEnabled = false,
  presentedProductId = null,
}) {
  const { callData } = useCallContextProvider();
  const hostName = callData?.host?.displayName ?? '';

  const renderDesktopViews = () => {
    if (isProductViewVisible) {
      updateDesktopVideoViews();
    }

    return (
      <FullScreenProductDescription
        product={product}
        variant={variant}
        className={isProductViewVisible ? ' visible' : ''}
        selectedImage={selectedImage}
        sessionSnapshots={sessionSnapshots}
        addToSession={addToSession}
        setSelectedImage={setSelectedImage}
        isPresentationEnabled={isPresentationEnabled}
        currentImages={currentImages} />
    );
  }

  return (
    <>
      {
        ((isPresentationEnabled && product && presentedProductId === parseIdString(product.productId)) || !isPresentationEnabled) && isProductViewVisible &&
        <div
          className={`full-screen-product-view-container
            ${isProductViewVisible ? ' visible' : ''}`}>
          {
            !isPresentationEnabled &&
            <CloseButton classNam='full-screen-product-view-container_close-btn' onClick={hideFullDetailsView} />
          }
          {isPresentationEnabled &&
            <div className='presenting-text-mobile'>
              <FormattedMessage {...sidebarMessages.host_is_presenting} values={{ name: hostName }} />
            </div>
          }
          {
            isProductViewVisible &&
            renderDesktopViews()
          }
        </div>
      }
    </>
  );
}
