import React, { useMemo } from 'react';
import { useClientAuthProvider } from '../../../../../../../../../components/ClientAuthProvider';
import { buildUserEntity } from '../../../../../../../../../modules/chat/utils/auth';
import { ChatActions } from '../../../../../chat-components/chat-actions/ChatActions';
import { ChatContent } from '../../../../../chat-components/chat-conent/ChatContent';
import { useCustomerChatData } from '../../../../../../../../../modules/chat/hooks/useCustomerChatData';
import { ROLES } from '../../../../../../../../../modules/chat/constants/participantRoles';
import { Loader } from '../../../../../../../../../components';
import './ChatTab.scss';

export const ChatTab = () => {
  const { shopId, user } = useClientAuthProvider();
  const userEntity = useMemo(() => buildUserEntity({ ...user, role: ROLES.PARTICIPANT }), [user])
  const { activeRoom } = useCustomerChatData(userEntity, shopId);

  return (
      activeRoom && activeRoom.id ?
        <div className='mobile-chat-tab-container'>
            <ChatContent />
            <ChatActions />
        </div> : <Loader />
    )
}
