import React from 'react';
import CloseIcon from '../../../../../../../assets/icons/Close';
import './index.scss';


export default function CloseButton ({onClick}) {
    return (
        <div className='close-button-container'>
            <button className='close-button' onClick={onClick}>
                <CloseIcon />
            </button>
        </div>
    );
}
