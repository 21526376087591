import React from 'react';
import { FilledButton } from '../../../../../components';
import './EmptyTab.scss';

export const EmptyTab = ({ icon, message, onSubmit, buttonLabel }) => {
	return (
		<div className='mobile-empty-tab-container'>
			{icon}
			<p>
				{message}
			</p>
			{buttonLabel && <FilledButton onClick={onSubmit}>
				{buttonLabel}
			</FilledButton>}
		</div>
	);
};
