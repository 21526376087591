import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import firebase from '../Firebase'
import {getDatabase, ref, off, onValue, onChildAdded, onChildChanged, onChildRemoved, set, get, update} from 'firebase/database';
import { logger } from '../logging'

import { buildAddSyncCartRequest, buildUpdateSyncCartRequest, buildDeleteSyncCartRequest, buildDiscountSyncCartRequest } from '../utils/shopify'
import { useInterWindowMessageProvider, REQ_HEADER_TYPES } from '../components/InterWindowMessageProvider';
import { useCallContextAPI } from '../hooks/useCallContextAPI';

import { CURRENT_SESSION } from '../constants/sessionActionTypes';
import { caazamCartAttribute } from '../utils/shopify';


export const useSessionCartManager = (shopId, contextId, callId, callData, callEvents) => {
  const db = getDatabase(firebase);
  const dispatch = useDispatch();
  const { postMessage } = useInterWindowMessageProvider();

  const syncItemListenerToShopify = useRef(false);
  const syncDiscountListenerToShopify = useRef(false);
  const prevCart = useRef({});
  const prevDiscount = useRef({});

  const { activeCartId, shopifyCartData, shopifyCartLoading, disableCartSync } = useSelector(state => state.currentSession);
  const { contextCallLoadProducts }  = useCallContextAPI(contextId)

  const [initialCartLoaded, setInitialCartLoaded] = useState(false);
  const [shopifyCartId, setShopifyCartId] = useState(null);
  const [shopifyCheckoutUrl, setShopifyCheckoutUrl] = useState(null);
  const [isCallConnected, setCallConnected] = useState(false);

  const cartAttribute = `${callId}:${activeCartId}`;

  useEffect(() => {
    let activeCartListener;
    if (callId) {
      activeCartListener = ref(db, `session/${callId}/cart/activeCartId`);
      onValue(activeCartListener, snap => {
        dispatch({ type: CURRENT_SESSION.SET_ACTIVE_CART_ID, activeCartId: snap.val() })
      });
    }

    return () => {
      activeCartListener && off(activeCartListener);
      setInitialCartLoaded(false);
      setShopifyCartId(null);
      setShopifyCheckoutUrl(null);
      dispatch({ type: CURRENT_SESSION.SET_ACTIVE_CART_ID, activeCartId: null });
    }
  }, [callId]);

  useEffect(()=>{

    const callback = () => setCallConnected(true);
    callEvents.once('connected',callback);
    return () => {
      callEvents.off('connected',callback);
    }

  },[callId])

  useEffect(() => {
    // once for call, load the pre-call cart data from the Shopify cart
    if (!shopifyCartLoading && callId && activeCartId && !initialCartLoaded) {
      logger.info('starting cart sync process for call', { callId, activeCartId, disableCartSync });
      if (shopifyCartData?.cartId) {
        setShopifyCartId(shopifyCartData.cartId);
      }
      if (shopifyCartData?.checkoutUrl) {
        setShopifyCheckoutUrl(shopifyCartData.checkoutUrl);
      }

      loadInitialCart(shopifyCartData)
        .then(() => {
          logger.info('initial cart loaded', { cartId: shopifyCartData?.cartId, activeCartId, disableCartSync });
        }).catch(error => {
          logger.warn('Failed to load shopify cart products to call', error);
        }).finally(() => {
          setInitialCartLoaded(true);
        })
    }
  }, [callId, activeCartId, shopifyCartLoading, shopifyCartData, disableCartSync]);

  useEffect(() => {
    if (initialCartLoaded && activeCartId && !disableCartSync) {
      logger.info(`starting cart sync listeners`, { activeCartId });
      const cartItemsRef = ref(db,`session/${callId}/cart/${activeCartId}/products`);
      function shopifyCartCallback(payload) {
        setShopifyCartId(prev => payload?.cartId ?? prev);
        setShopifyCheckoutUrl(prev => payload?.checkoutUrl ?? prev);
      }
      onChildAdded(cartItemsRef, (snap) => {
        if (syncItemListenerToShopify.current) {
          const payload = buildAddSyncCartRequest(snap.val(), cartAttribute);
          postMessage(REQ_HEADER_TYPES.ADD_TO_CART, payload, shopifyCartCallback);
        }
        prevCart.current[snap.key] = snap.val();
      });
      onChildChanged(cartItemsRef, (snap) => {
        const changedData = snap.val();
        if (syncItemListenerToShopify.current) {
          const payload = buildUpdateSyncCartRequest(changedData, prevCart.current[snap.key]?.variantId, cartAttribute);
          postMessage(REQ_HEADER_TYPES.UPDATE_CART_PRODUCT, payload, shopifyCartCallback);
        }
        prevCart.current[snap.key] = snap.val();
      });
      onChildRemoved(cartItemsRef, (snap) => {
        delete prevCart.current[snap.key];
        if (syncItemListenerToShopify.current) {
          const isCartEmpty = Object.keys(prevCart.current).length === 0;
          const payload = buildDeleteSyncCartRequest(snap.val().variantId, isCartEmpty ? null : cartAttribute);
          postMessage(REQ_HEADER_TYPES.REMOVE_CART_PRODUCT, payload, shopifyCartCallback);
        }
      });           
      onValue(cartItemsRef, () => {
        syncItemListenerToShopify.current = true;
      });
      return () => {
        off(cartItemsRef);
        syncItemListenerToShopify.current = false;
      }
    }

  }, [initialCartLoaded, activeCartId, disableCartSync]);

  useEffect(() => {
    if (initialCartLoaded && activeCartId && !disableCartSync) {
      const cartDiscountRef = ref(db,`session/${callId}/cart/${activeCartId}/discount/code`);      
      onValue(cartDiscountRef, (snap) => {     
        const newDiscount = snap.val();   
        if (syncDiscountListenerToShopify.current) {
          const payload = buildDiscountSyncCartRequest(newDiscount, prevDiscount.current);
          postMessage(REQ_HEADER_TYPES.UPDATE_CART_DISCOUNT, payload, () => {});
        }
        syncDiscountListenerToShopify.current = true;
        prevDiscount.current = snap.val();
      });
      return () => {
        off(cartDiscountRef);
        syncDiscountListenerToShopify.current = false;
      }
    }

  }, [initialCartLoaded, activeCartId, disableCartSync]);

  useEffect(() => {
    if (shopifyCartId) {
      (async function() {
        await update(ref(db, `session/${callId}/cart/${activeCartId}`), { shopifyCartId, shopifyCheckoutUrl })
      })();
    }
  }, [shopifyCartId, shopifyCheckoutUrl]);


  useEffect(() => {
    if (isCallConnected && initialCartLoaded) {
      get(ref(db, `session/${callId}/cart/${activeCartId}/products`))
        .then(snap => {
          if (snap.size > 0) {
            logger.info(`Call connected with preloaded cart with ${snap.size} items`);
            postMessage(REQ_HEADER_TYPES.UPDATE_CART_ATTRIBUTE, { attributes: [caazamCartAttribute(cartAttribute)] });
          }
        })
    }

  }, [isCallConnected, initialCartLoaded, shopifyCartData ,callId, activeCartId]);

  
  const loadInitialCart = async (data) => {

    let cartData = null;
    if (data) {
      let { attributes } = data;
      if (attributes && attributes.boutiqapp) {
        let [prevCallId, prevCartId] = attributes.boutiqapp.split(':');
        if (prevCallId === callId && prevCartId === activeCartId) {
          logger.info(`pre-call cart sync skipped`);   // can happen when rejoing a call that already has been synced
          return;
        }
      }
      cartData = {
        ...data,
        items: data.items.map(item => ({
          id: item.id,
          quantity: item.quantity,
          variantId: item.variant_id,
          price: item.final_price,
          discounts: item.discounts ?? null,
          productId: item.product_id,
          productTitle: item.product_title,
        }))
      }

    }

    try {
      const { loadedCartCount, loadedShowroomCount } = await contextCallLoadProducts(callId, cartData, activeCartId);
      logger.info(`Pre-call showroom loaded with ${loadedShowroomCount} products`);
      logger.info(`Pre-call cart loaded with ${loadedCartCount} products & discount code ${data?.discountCodes?.[0]}`);
    } catch (error) {
      logger.error(`Pre-call cart failed contextCallLoadProducts`, error);
    }
  }
}
