import React, { useRef, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { FilledButton } from '../../../../../components';
import { useCallContextProvider } from '../../../../../components/CallContextProvider';
import { useProductViewProvider } from '../../../../../components/ProductViewProvider';
import { useSessionCart, useSessionShowroom } from '../../../../../hooks';
import { parseIdString } from '../../../../../utils';
import { InfoModal } from '../modals/info';
import ProductCurrentPrice from '../../../shared/ProductCurrentPrice/productCurrentPrice';
import { MainImage } from '../main-image/MainImage';
import { ProductGallery } from './components/product-gallery/ProductGallery';
import { ProductOptions } from './components/product-options/ProductOptions';
import { useProductDetailsProvider } from '../../../../../components/ProductDetailsProvider';
import { useModalViewProvider } from '../../../../../components/mobile-providers/ModalViewProvider';
import { FullScreenProductView } from '../modals/full-screen-product-view/FullScreenProductView';
import { ProductDetailsSubmitActions } from './components/product-details-submit-actions/ProductDetailsSubmitActions';
import { sidebarMessages } from '../../../intl/sidebar-messages/messages';
import { innerLinkHandler } from '../../../shared/innerLinkHandler';
import './ProductDetails.scss';

export const MobileProductDetails = () => {
	const intl = useIntl();
	const { showView, isModalViewAnimationDone } = useModalViewProvider();

	const showFullSizedImageView = () => {
		if (!selectedImage || !isModalViewAnimationDone) {
			return;
		}
		const modal = {
			dismissAlloved: true,
			viewPort: true,
			type: 'full-sized-image',
			component: <FullScreenProductView />
		}
		showView(modal);
	};

	const { callId, activeCartId } = useCallContextProvider();
	const { cartSnapshotLoading } = useSessionCart(callId, activeCartId);
	const { sessionSnapshotLoading } = useSessionShowroom(callId);
	const { productViewData: { product, cartKey: viewedCartKey }, clearSelectedProduct } = useProductViewProvider();

	const {
		currentImages,
		selectedImage,
		setSelectedImage,
		selectedProductVariant,
		availableOptions,
		addFullProductToCart,
		handleAddToShowroom,
		showroomItemKey,
		selectedOptions,
		updateSelectedOptions,
		cartItemKey,
		hideModalView,
		openModalView,
		emptyOptions,
		variantNotFound,
		variantNotInIventory,
		updateVariant,
		cartItem
	} = useProductDetailsProvider();

	const { dismissView } = useModalViewProvider()
	const hasOnlyDefaultVariant = product && product.hasOnlyDefaultVariant;
	const hasSingleVariant = product && product.variants.length === 1;
	const imagesRefs = useRef(null);
	const producDescriptionRef = useRef(null);
	const [isScroll, setIsScroll] = useState(false);
	const [startSwipePos, setStartSwipePos] = useState(null)
	const noMainImage = !currentImages || !currentImages.length

	const renderImages = () => {
		if (noMainImage) {
			return null;
		}
		return (
			<MainImage
				animateAreaWhileLoading={true}
				src={selectedImage ? selectedImage.url : ''}
				customSize={isScroll ? '72px' : null}
				viewItem={showFullSizedImageView}
			/>
		);
	};

	const handleTouchStart = (e) => {
		if (!noMainImage) {
			const start = e.changedTouches[0];
			setStartSwipePos(start.clientY)
		}
	}

	const handleTouchEnd = (e) => {
		if (!noMainImage) {
			let end = e.changedTouches[0];
			if (producDescriptionRef.current.scrollTop <= 0) {
				if (Math.abs((end.clientY - startSwipePos)) > 50) {
					setIsScroll(end.clientY - startSwipePos < 0)
				}
			}
		}
	}

	const addToCart = async (e) => {
		if (await addFullProductToCart(e)) {
			dismissView();
			clearSelectedProduct();
		}
	}

	const onUpdateVariant = async (e) => {
		if (updateVariant(e)) {
			dismissView();
			clearSelectedProduct();
		}
	}

	const renderActions = () => {

		// don;t redner actions until we've loaded showroom and cart
		if (cartSnapshotLoading || sessionSnapshotLoading) return null;

		// if source of view was cart
		if (viewedCartKey || showroomItemKey) {
			return (
				<form className='mobile-products-actual-options'>
					{availableOptions && !hasOnlyDefaultVariant
						? <ProductOptions
							selectedOptions={selectedOptions}
							updateSelectedOptions={updateSelectedOptions}
							availableOptions={availableOptions}
							product={product} />
						: null}
					<ProductDetailsSubmitActions
						isUpdateBtnVisible={viewedCartKey && cartItemKey && !hasSingleVariant}
						isUpdateBtnDisabled={updateBtnDisableStatus()}
						isSoldOutBtnVisible={soldOutBtnVisibilityStatus()}
						isAddToCartBtnVisible={!viewedCartKey || !cartItemKey}
						updateVariant={(e) => onUpdateVariant(e)}
						addFullProductToCart={addToCart} />
					<InfoModal
						title={renderErrorMessage()}
						buttonLabel={intl.formatMessage(sidebarMessages.cta_ok)}
						hide={hideModalView}
						isOpen={openModalView}
					/>
				</form>)
		} else {
			return (
				<FilledButton
					style={{
						width: '100%',
						padding: 0,
						padding: '12px 0',
						height: 'auto',
						borderRadius: 4
					}}
					onClick={handleAddToShowroom}>
					<FormattedMessage {...sidebarMessages.showroom} />
				</FilledButton>
			)
		}
	}

	const updateBtnDisableStatus = () => {
		return selectedProductVariant && cartItem && parseIdString(selectedProductVariant.id) === cartItem.variantId;
	}
	const soldOutBtnVisibilityStatus = () => {
		return selectedProductVariant && selectedProductVariant.inventoryQuantity <= 0 && selectedProductVariant.inventoryPolicy !== 'CONTINUE';
	}

	const renderErrorMessage = () => {
		return (
			<p className='mobile-error-text'>
				{emptyOptions.length
					? <FormattedMessage {...sidebarMessages.product_empty_options} values={{ product_options: emptyOptions.join(', ') }} />
					: variantNotFound
						? <FormattedMessage {...sidebarMessages.cart_add_no_variant} />
						: variantNotInIventory
							? <FormattedMessage {...sidebarMessages.cart_add_no_inventory} />
							: ''}
			</p>
		);
	};

	return (
		<div
			onTouchStart={handleTouchStart}
			onTouchEnd={handleTouchEnd}
			className={`mobile-parent-details-container${isScroll ? ' mobile-small' : ''}${noMainImage ? ' mobile-no-main-image' : ''}`}>
			<div className='mobile-product-details-description-wrapper' >
				<div className='mobile-main-image-wrapper' >
					{renderImages()}
				</div>
			</div>
			<ProductGallery
				currentImages={currentImages}
				imagesRefs={imagesRefs}
				setSelectedImage={setSelectedImage}
			/>
			<div className='mobile-product-details'>
				<p className='mobile-product-title'>
					{product.title}
				</p>
				<ProductCurrentPrice
					selectedProductVariant={selectedProductVariant}
					product={product} />
			</div>
			<div ref={producDescriptionRef} className='mobile-product-description-wrapper'>
				<div className='mobile-product-description-container'>
					{renderActions()}
					<div className='mobile-product-description-content'>
						<div className='mobile-product-description'>
							<div className='mobile-product-description-html-content' onClick={(e) => innerLinkHandler(e, product.onlineStoreUrl)} dangerouslySetInnerHTML={{ __html: product.descriptionHtml }} />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
