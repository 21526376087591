const MSG_TYPES = {
  TEXT: 'text',
  FILE: 'file',
  IMAGE: 'image',
  META: 'meta',
  CALL_INVITATION: 'video_call_invitation',
}

const MSG_STATUS = {
  SENT: 'sent',
  DELIVERED: 'delivered',
  SEEN: 'seen',
};

const TEMP_MESSAGE = {
  IMAGE: 'temp_image_message',
  START_VIDEO_CALL: 'start_video_call',
}

const SYSTEM = {
  SYSTEM_SENDER_ID: 'system',
}

const SYSTEM_MESSAGES = { // TODO: move to i18n
  helloMessage: (name) => `Hi ${name}, how can we help?`,
}

const TYPING_TIME = 1000;
const LIMIT_CHAT_MESSAGES = 10;

export {
  MSG_TYPES,
  MSG_STATUS,
  SYSTEM,
  SYSTEM_MESSAGES,
  TYPING_TIME,
  TEMP_MESSAGE,
  LIMIT_CHAT_MESSAGES,
}
