import {useDispatch} from 'react-redux';
import {setDoc, doc, Timestamp, collection} from 'firebase/firestore';
import {firestore} from '../../firebaseInitialization';
import {MSG_STATUS, MSG_TYPES, SYSTEM} from "../../constants/messaging";
import {FIRESTORE_COLLECTIONS} from "../../constants/firestore";
import {
  sendMessageFailure,
  sendMessageRequest,
  sendMessageSuccess,
} from "../../actions/messages";
import metadataRequests from "../metadata/requests";

export default function useSystemRequests({user}) {
  const dispatch = useDispatch();
  const {updateLastMessage} = metadataRequests({user});
  const fs = firestore();

  const sendSystemMessage = async ({msg, room, user = {}}) => {
    if (!room.id) {
      return;
    }

    if (!room.exists) {
      dispatch(sendMessageFailure('Room doesn\'t exist'))
    }

    dispatch(sendMessageRequest());
    const roomRef = doc(fs, FIRESTORE_COLLECTIONS.CHAT_ROOMS, room.id);
    const messageRef = doc(collection(fs, roomRef.path, FIRESTORE_COLLECTIONS.CHAT_MESSAGES));
    const timestamp = Timestamp.fromDate(new Date());
    const message = {
      id: messageRef.id,
      owner: {
        uuid: user && user.uuid ? user.uuid : null,
        system: !user || !user.uuid,
      },
      room: {
        id: room.id,
      },
      type: msg.type || MSG_TYPES.TEXT,
      text: msg.text || null,
      metadata: msg.metadata ? msg.metadata : null,
      timestamp,
      status: {
        [SYSTEM.SYSTEM_SENDER_ID]: MSG_STATUS.SENT,
      }
    };

    try {
      await setDoc(messageRef, message);
      dispatch(sendMessageSuccess());
      if (!msg.metadata || !msg.metadata.system) {
        await updateLastMessage({message, room});
      }
      dispatch(sendMessageSuccess())
    }  catch (e) {
      dispatch(sendMessageFailure(e.message))
      console.error('sendSystemMessage', e);
    }

    dispatch(sendMessageRequest());
  }

  return {
    sendSystemMessage,
  };
}
