import React, { useRef, useCallback, cloneElement } from "react";

export const InfiniteScroll = ({
    isLoading,
    results,
    hasMore,
    loadMore,
    item,
    loader = "Loading..."
}) => {
    const observer = useRef();

    const lastElementRef = useCallback(
        (node) => {
            if (isLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    loadMore();
                }
            });
            if (node) observer.current.observe(node);
        },
        [isLoading, hasMore, loadMore ]
    );

    return (
        <>
            {results && results.map((result, i) => {
                if (results.length === i + 1) {
                    return (
                        <div
                            className="infinite-scroll-item"
                            key={i}
                            ref={lastElementRef}>
                            {cloneElement(item, { product: result })}
                        </div>
                    );
                } else {
                    return <div
                        className="infinite-scroll-item"
                        key={i}>
                        {cloneElement(item, { product: result })}
                    </div>
                }
            })}
            <div>{isLoading && loader}</div>
        </>
    );
}
