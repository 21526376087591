import { defineMessages } from 'react-intl';

export const formMessages = defineMessages({
  welcome_message: {
    id: 'messages.welcome_message'
  },
  welcome_message_content: {
    id: 'messages_welcome_message_content',
  },
  name: {
    id: 'nouns_name'
  },
  email: {
    id: 'nouns.email'
  },
  optional: {
    id: 'adverb_optional'
  },
  powered_by_caazam: {
    id: 'messages.powered_by_caazam',
  },
});
