import * as React from "react"

const ExpandLess = ({size = 'default', color = 'default', className = '', onClick= null}) => (
  <svg
    className={`caazam-icon ${className} color-${color} size-${size}`}
    viewBox="0 0 24 24"
    aria-hidden="true"
    onClick={onClick}
  >
    <path d="m12 8-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
  </svg>
)

export default ExpandLess
