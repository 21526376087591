import React from 'react';
import Trash from '../../../../../../assets/icons/Trash';
import { CounterInput } from '../../../../../../components/counter/counter';
import './item-controls.scss';

export const ItemControls = ({ product, snapshot, updateCartProduct, removeCartProduct, withoutStepper }) => {

  const snapshotKey = snapshot.key;

  const {
    variantTitle,
    quantity,
    inventoryPolicy,
    inventoryQuantity,
  } = product;

  const addOne = (snapshotKey, quantity) => {
    updateCartProduct(snapshotKey, { quantity: quantity + 1 });
  };

  const removeOne = (snapshotKey, quantity) => {
    updateCartProduct(snapshotKey, { quantity: Math.max(quantity - 1, 1) });
  };

  return (
    <div className='product-params-wrapper'>
      <div className='product-params'>
        <CounterInput
          quantity={quantity}
          withoutStepper={withoutStepper}
          inventoryPolicy={inventoryPolicy}
          inventoryQuantity={inventoryQuantity}
          onDecrease={() => removeOne(snapshotKey, quantity)}
          onIncrease={() => addOne(snapshotKey, quantity)} />
        <span className='selected-option'>{variantTitle}</span>
        {removeCartProduct && (
          <div
            id='product-card-trash'
            className='product-card-trash'
            onClick={() => removeCartProduct(snapshot.key, product)}>
            <Trash />
          </div>
        )}
      </div>
    </div>
  );
};
