import {buildUserEntity} from "./auth";

const calcChatNotifications = (rooms = {}, userId) => {
  const pendingBadges = rooms.pendingRooms ? rooms.pendingRooms.length : 0;
  let activeBadges = 0;
  if (rooms.activeRooms && rooms.activeRooms.length > 0 && userId) {
    rooms.activeRooms.forEach(r => {
      activeBadges += r.metadata && r.metadata.badges && r.metadata.badges[userId] && r.metadata.badges[userId] > 0 ? 1 : 0;
    });
  }

  return pendingBadges + activeBadges;
}

const calcVideoRoomChatNotifications = (userId, activeRoom = null) => {
  let activeBadges = 0;
  if (activeRoom && activeRoom.metadata && activeRoom.metadata.badges && activeRoom.metadata.badges[userId] && userId) {
      activeBadges = activeRoom.metadata.badges[userId];
  }
  return activeBadges;
};

const getNumberOfUnreadMessages = (item, user) => {
  if (!!item && !!item.metadata && !!item.metadata.badges) {
    return item.metadata.badges[buildUserEntity(user).uuid];
  }
  return 0;
};

export {
  calcChatNotifications,
  calcVideoRoomChatNotifications,
  getNumberOfUnreadMessages,
}
