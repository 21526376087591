import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FilledButton, StyledInput } from '..';
import './InputWithCopy.scss';

const InputWithCopy = ({ inputText, strings = { copy: 'copy', copied: 'copied'} }) => {
  const [isTextCopied, setTextCopied] = useState(false);

  return (
    <div className='copy-link-container'>
      <StyledInput value={inputText} readOnly></StyledInput>
      <CopyToClipboard text={inputText} onCopy={() => setTextCopied(true)}>
        <FilledButton
          style={{
            height: '34px',
            width: '80px',
            fontSize: '14px',
            position: 'absolute',
            right: '0',
            backgroundColor: 'var(--main-color)',
          }}
          className='button'
          id='copy-client-link-btn'>
          {isTextCopied ? strings.copied : strings.copy}
        </FilledButton>
      </CopyToClipboard>

    </div>
  );
};

export default InputWithCopy;
