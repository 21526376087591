import React from 'react';
import EmptyImage from '../../../../../../components/emptyImage';
import { ProductDetails } from './product-details';
import './cart-items-list.scss';

export const CartItemslist = ({ isMobile, cartSnapshots = [], viewCartItem, removeCartProduct, updateCartProduct }) => {
  return (
    <div className='product-grid-wrapper'>
      <div className={`product-grid ${isMobile ? 'mobile' : ''}`}>
        {cartSnapshots.map((snapshot) => {
          const snapshotKey = snapshot.key;
          const product = snapshot.val();
          const {
            image,
            productTitle,
          } = product;

          return (
            <div className={`product-card ${isMobile ? 'mobile' : ''}`} key={snapshotKey}>
              <div className='product-card-image'>
                {
                  image
                    ? <img
                      src={image}
                      onClick={() => viewCartItem( snapshot.key, product)}
                      alt={productTitle}
                      className='card-image'
                    />
                    : <EmptyImage
                      isMobile={isMobile}
                      product={product}
                      viewItem={() => viewCartItem( snapshot.key, product)}
                      size='m'
                      label={product.productTitle}
                    />
                }
              </div>
              <ProductDetails
                snapshot={snapshot}
                product={product}
                isMobile={isMobile}
                viewItem={() => viewCartItem(snapshot.key, product)}
                updateCartProduct={updateCartProduct}
                removeCartProduct={removeCartProduct}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
