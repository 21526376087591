import React from 'react';
import EmptyImage from '../../../../../../components/emptyImage';
import { MainImage } from '../../main-image/MainImage';
import { currencyFormatter } from '../../../../../../utils';
import { MobileCounter } from '../../../../../../components/counter/mobile/MobileCounter';
import './draft-order-items-list.scss';

export const DraftOrderItemsList = ({products}) => {
  return (
    <div className='mobile-product-grid'>
      {products.map((product) => {
        const {
          image,
          productTitle,
          currencyCode,
          currentPrice,
          currentCompareToPrice,
          variantTitle,
          quantity,
          inventoryPolicy,
          inventoryQuantity,
          variantId
        } = product;

        return (
          <div className='mobile-product-card' key={variantId}>
            <div className='mobile-product-card-image'>
              {
                image
                  ? <MainImage
                    src={image}
                    customSize={'100%'}
                    viewItem={() => {}} />
                  : <EmptyImage
                    isMobile={true}
                    product={product}
                    viewItem={() => {}}
                    size='m'
                    label={product.productTitle}
                  />
              }
            </div>
            <div className='mobile-product-card-details'>
              <div className='mobile-description-container'>
                <p onClick={() => {}} className='mobile-product-title'>{productTitle}</p>
                <div className='mobile-variant-options-container'>
                  <div className='mobile-price-container'>
                    <p className='mobile-actual-price'>{currencyFormatter(currentPrice, currencyCode)}</p>
                    {currentPrice !== currentCompareToPrice && currentCompareToPrice ? (
                      <p className='mobile-old-price'>
                        {currencyFormatter(currentCompareToPrice, currencyCode)}
                      </p>
                    ) : null}
                  </div>
                  {variantTitle && <div className='mobile-options-container'>
                    <p className='mobile-product-variant-data'>
                      {variantTitle}
                    </p>
                  </div>}
                </div>
              </div>
              <div className='mobile-product-params'>
                <MobileCounter
                  quantity={quantity}
                  inventoryPolicy={inventoryPolicy}
                  inventoryQuantity={inventoryQuantity}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
