import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl';
import { scheduleScreenMessages } from '../../../messages';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CheckCircleIcon from '../../../../../assets/icons/CheckCircle'; // need refacor
import './CopyCallLink.scss';

export const CopyCallLink = ({callLink}) => {
    const [linkCopied, setLinkCopied] = useState(false);

    return (
        <CopyToClipboard text={callLink} onCopy={() => setLinkCopied(true)}>
            <span className='copy-to-clipboard is-clickable'>
                <FormattedMessage {...scheduleScreenMessages.cta_copy_call_link} />
                <span className={`copy-link-icon ${linkCopied ? ' copied' : ''}`}><CheckCircleIcon color='main' /></span>
            </span>
        </CopyToClipboard>
    )
}
