import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {collection, onSnapshot, doc} from 'firebase/firestore';
import {firestore} from '../../firebaseInitialization';
import { FIRESTORE_COLLECTIONS } from "../../constants/firestore";

export function useRoomIsTyping(roomId = null) {
  const { id: activeRoomId } = useSelector(state => state.activeRoom);
  const [roomTyping, setRoomTyping] = useState({});
  const idOfChat = roomId ? roomId : activeRoomId;

  const fs = firestore();

  const listenRoomTyping = () => {
    const roomRef = doc(fs, FIRESTORE_COLLECTIONS.CHAT_ROOMS, idOfChat, FIRESTORE_COLLECTIONS.ROOM_FEATURES, FIRESTORE_COLLECTIONS.TYPING_DOC)
    return onSnapshot(roomRef, (doc) => {
      setRoomTyping(doc.data() || {})
    }, (listenError) => {
      console.error('listenRoomTyping', listenError);
    }, null);
  }

  useEffect(() => {
    if (idOfChat) {
      const listener = listenRoomTyping();
      return () => {
        listener();
      }
    }
  }, [idOfChat]);

  return { roomTyping };
}
