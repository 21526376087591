/* eslint-disable no-useless-escape*/
import {
  isMobileOnly,
  isMobileSafari,
} from 'react-device-detect';

export const checkMobileOnly = () => {
  return isMobileOnly;
};

export const isMobileSafariOnly = () => {
  return isMobileSafari;
};

export const parseIdString = (idString) => {
  if (typeof idString === 'number') {
    return idString.toString();
  }

  const idStringArray = idString.split('/');
  return idStringArray[idStringArray.length - 1];
};

export const currencyFormatter = (value, currencyCode) => {
  currencyCode = currencyCode ? currencyCode : 'USD';
  return new Intl.NumberFormat(window.navigator.language, {
    style: 'currency',
    currency: currencyCode,
  }).format(value);
};

export const isEmail = (string) => {
    if (!string)
        return false;

    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(string);
}

export const isPhone = (string) => {
  if (!string)
      return false;

  return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\,\)\(/0-9]*$/.test(string);
}

export const getRandom = (arr, n) => {
  var result = new Array(n),
      len = arr.length,
      taken = new Array(len);
  if (n > len)
      return arr;
  while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}

export const isEmptyString = (toTest) => {
  if (!toTest) {
    return true;
  }

  var regex = /([^\s])/;
  return !regex.test(toTest);
};
