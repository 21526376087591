import * as React from "react"

const ArrowForwardIos = ({size = 'default', color = 'default', className = '', onClick= null}) => (
  <svg
    className={`caazam-icon ${className} color-${color} size-${size}`}
    viewBox="0 0 24 24"
    aria-hidden="true"
    onClick={onClick}
  >
    <path d="M5.88 4.12 13.76 12l-7.88 7.88L8 22l10-10L8 2z" />
  </svg>
)

export default ArrowForwardIos
