import { defineMessages } from 'react-intl';

export const callScreenMessages = defineMessages({
  showroom: {
    id: 'nouns.showroom'
  },
  cart: {
    id: 'nouns.cart'
  },
  custom_cart: {
    id: 'nouns_custom_cart'
  },
  host_is_presenting: {
    id: 'messages_host_is_presenting'
  },
});
