import React, { useContext, useEffect } from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import { useClientConfigProvider } from '../../../components/ClientConfigProvider';
import { FormattedMessage, useIntl } from 'react-intl';
import { scheduleScreenMessages } from '../messages';
import { SchedulerHeaderTabs } from './components/scheduler-header-tabs/SchedulerHeaderTabs';
import { SchedulerSlotList } from './components/scheduler-slot-list/SchedulerSlotList';
import { AppHeaderContext } from '../../../components/AppHeaderProvider';
import { SCHEDULING_PAGES } from '../schedule-screen';
import { SelectedHostInfoRow } from '../components/selected-host-info-row/SelectedHostInfoRow';
import { LeaveMessageEvent } from '../components/leave-message-event/LeaveMessageEvent';
import { useSchdeuleConfigProvider } from '../../../components/ScheduleConfigProvider';
import { PageHeader } from '../../../components/page-header/PageHeader';
import { PageFooter } from '../../../components/page-footer/PageFooter';
import { PageScrollContainer } from '../../../components/page-scroll-container/PageScrollContainer';
import { EventTypeDurationRow } from '../components/eventTypeDurationRow/EventTypeDurationRow';
import './SelectSlot.scss';
import {PAGES} from "../../../constants/routes";

export const SelectSlot = ({navigateToNextScheduleScreen, navigateToSelectHost}) => {
	const { hostSelectionConfig } = useClientConfigProvider();
	const {
		selectedSlot,
		selectSlot,
		currentDay,
	} = useSchdeuleConfigProvider();
	const history = useHistory();
	const { path } = useRouteMatch();
	const { backFuncRef } = useContext(AppHeaderContext);

	useEffect(() => {
		backFuncRef(() => {
			selectSlot(null);
			history.goBack();
		})
	}, [])

	function onSubmit() {
		navigateToNextScheduleScreen(path);
	}

	return (
		<>
			<PageHeader
				title={<FormattedMessage {...scheduleScreenMessages.slot_select_text} />}
				subTitle={
					<>
						<EventTypeDurationRow />
						{hostSelectionConfig.enabled && <SelectedHostInfoRow navigateToSelectHost={navigateToSelectHost} />}
					</>}
				children={<SchedulerHeaderTabs />}
			/>
			<PageScrollContainer
				key={currentDay}
				children={<SchedulerSlotList />}
			/>
			<PageFooter
				primaryButtonLabel={<FormattedMessage {...scheduleScreenMessages.cta_next} />}
				onSubmitPrimary={onSubmit}
				secondaryButtonLabel={<LeaveMessageEvent />}
				onSubmitSecondary={() => history.push({
					pathname: PAGES.CONTACT,
					isBackAllowed: true,
					preventSchedule: true
				})}
				isDisabled={!selectedSlot}
			/>
		</>
	);
};
