export const FontsLoader = (fontFamilyConfig, root, overridePropName = '--font-family') => {
	if (!fontFamilyConfig) return;
	const link = document.createElement('link');
	link.rel = 'stylesheet';
	if (typeof fontFamilyConfig === 'object') {
		link.href = fontFamilyConfig.src;
		document.head.appendChild(link);
		root.style.setProperty(overridePropName, `'${fontFamilyConfig.name}', Poppins, sans-serif`);
	} else {
		link.href = `https://fonts.googleapis.com/css2?family=${fontFamilyConfig.replace(' ', '+')}:wght@400;500;600;700`;
		document.head.appendChild(link);
		root.style.setProperty(overridePropName, `'${fontFamilyConfig}', Poppins, sans-serif`);
	}
}
