import React, { useEffect, useRef, useMemo, useState } from 'react';
import { useChatData } from '../../../../../../hooks/useChatData';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import useGetMessages from '../../../../../../modules/chat/api/messages/api';
import { buildUserEntity } from '../../../../../../modules/chat/utils/auth';
import { useRoomIsTyping } from '../../../../../../modules/chat/api/features/listeners';
import { showStartVideoCallMessage } from '../../../../../../modules/chat/actions/messages';
import { chatScreenMessages } from '../../../../../chat-page/messages';
import MessageItem from '../../../../../../components/messageItem';
import MessageItemWrapper from '../../../../../../components/messageItem/wrapper';
import { Loader } from '../../../../../../components';
import { TEMP_MESSAGE } from '../../../../../../modules/chat/constants/messaging';
import { useModalViewProvider } from '../../../../../../components/mobile-providers/ModalViewProvider';
import { FullScreenView } from '../../modals/full-screen-view/FullScreenView';
import { useActiveRoomData } from '../../../../../../modules/chat/hooks/useActiveRoomData';
import './ChatContent.scss';
import { useCallScreenProvider } from '../../../../../../components/CallScreenProvider';
import { state, tabLabels } from '../../../../../../constants/menuDrawer';
import {isMobileSafariOnly} from "../../../../../../utils";

export const ChatContent = () => {
  const IS_MOBILE_SAFARI_BROWSER = isMobileSafariOnly();

  const { showView } = useModalViewProvider()
  const {
    user,
    seenMessages,
    roomMessagesDisplayed,
    scrollPosition,
    setScrollPosition
  } = useChatData();
  const { activeTab, drawerSize } = useCallScreenProvider()
  const videoInitiated = true;
  const setFullSizeChatImage = async (e) => {
    const modal = {
      viewPort: true,
      type: 'chat-full-sized-image',
      dismissAlloved: true,
      component: <FullScreenView src={e} />
    }
    showView(modal);
  }

  const scrollableDivRef = useRef(null);
  const infiniteScrollRef = useRef(null);
  const lastMessageRef = useRef(null);
  const isScrolled = useRef(false);
  const initialScrollPosition = useRef(null);
  const dispatch = useDispatch();
  const {messages, tempImageMessageId, isShowStartVideoCallMessage, isMessagesLoading} = useSelector(state => state.chatMessages);
  const room = useSelector(state => state.activeRoom);
  useActiveRoomData(user, room, null, true);
  const { loadMessages } = useGetMessages(room.id, user?.uid);
  const lastMessage = useRef(null);
  const [visibleNewMessagesSeparator, setVisibleNewMessagesSeparator] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [messagesList, setMessagesList] = useState([]);
  const [items, setItems] = useState(messages);
  const currentUser = buildUserEntity(user);
  const [roomSeenStatus, setRoomSeenStatus] = useState(false);

  useEffect(() => {
    if (IS_MOBILE_SAFARI_BROWSER) {
      scrollableDivRef.current.addEventListener('scroll', function(e) {
        isScrolled.current = true;
        const {isLastMessageSeen, elementPosition} = getScrollableListParams();
        handleScroll(isLastMessageSeen, elementPosition);
      });
      initialScrollPosition.current = infiniteScrollRef.current.getBoundingClientRect().bottom;

      return () => {
        scrollableDivRef.current.removeEventListener("scroll", handleScroll);
      }
    }
  }, [])


  useEffect(() => {
    setItems(messages);
  }, [messages.length]);

  useEffect(() => {
    lastMessage.current = null;
    setScrollPosition(0);
    setHasMoreData(true);
    async function fetchFirstMessages() {
      const newLastMessage = await loadMessages(null, room.metadata && room.metadata.badges ? room.metadata.badges[user.id] : 0);
      lastMessage.current = newLastMessage;
    }

    fetchFirstMessages();
  }, [room.id]);


  useEffect(() => {
    const timer = setTimeout(() => {
      setRoomSeenStatus(!roomSeenStatus);
    }, 1000);
    return () => clearTimeout(timer);
  }, [room?.metadata?.seenStatus]);

  const fetchOldMessages = () => {
    setHasMoreData(true);
    loadMessages(lastMessage.current)
      .then((newLastMessage) => {
        setHasMoreData(newLastMessage ? lastMessage.current?.id !== newLastMessage?.id : false);
        if (newLastMessage) {
          lastMessage.current = newLastMessage;
        }
        if (scrollableDivRef.current) {
          scrollableDivRef.current.scrollBy(0, 1)
        }
      });
  };

  const isImageLoaded = useMemo(() => {
    if (tempImageMessageId === null) {
      return false;
    }
    const messageIndex = messages.findIndex(m => m.id === tempImageMessageId);
    return messageIndex !== -1;
  }, [messages.length, tempImageMessageId]);

  const [loadedImages, updateLoadedImages] = useState(0);

  const { roomTyping } = useRoomIsTyping();
  const remoteTyping = roomTyping && Object.keys(roomTyping).filter(id => id !== user.uid).find(id => !!roomTyping[id]);
  const userForTyping = remoteTyping && room && room.participants ? room.participants[remoteTyping] : null;

  const incrementLoadedImages = () => {
    updateLoadedImages(loadedImages + 1);
  }

  const hideStartVideoCallMessage = () => {
    dispatch(showStartVideoCallMessage(false));
  }

  useEffect(() => {
    if (
      activeTab === tabLabels.CHAT &&
      drawerSize === state.FULL_EXPANDED &&
      visibleNewMessagesSeparator &&
      scrollPosition === 0 &&
      roomMessagesDisplayed) {
      const timer = setTimeout(() => {
        seenMessages();
        setVisibleNewMessagesSeparator(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [visibleNewMessagesSeparator, scrollPosition, roomMessagesDisplayed, activeTab]);

  useEffect(() => {
    if (messages.length > 0) {
      let list = [...messages];
      const lastSeen = room.metadata && room.metadata.seenStatus && room.metadata.seenStatus[currentUser.uid]
        ? new Date(room.metadata.seenStatus[currentUser.uid]).valueOf()
        : 0

      let lastNewMessagesIndex = JSON.parse(JSON.stringify(messages)).reverse().findIndex((m, i) => {
        return new Date(new Date(m.timestamp).toUTCString()).valueOf() > lastSeen
          && ( m.owner.uuid !== currentUser.uid && !m.owner.system)
      });

      if (lastNewMessagesIndex > 0) {
        const separatorIndex = messages.length - lastNewMessagesIndex;
        setVisibleNewMessagesSeparator(true);
        list = messages.slice(0, separatorIndex)
          .concat([{id: `${new Date().valueOf()}_new`, timestamp: lastSeen}])
          .concat(messages.slice(separatorIndex, messages.length + 1))
      }
      setMessagesList(list);
    }
  }, [messages.length, roomSeenStatus, visibleNewMessagesSeparator]);

  const getScrollableListParams = () => {
    const elementPosition = scrollableDivRef.current.scrollTop;
    const isLastMessageSeen = initialScrollPosition.current - infiniteScrollRef.current.getBoundingClientRect().bottom > -50;
    return {isLastMessageSeen, elementPosition}
  }

  const handleScroll = (isLastMessageSeen, elementPosition) => {
    if (elementPosition === 0) {
      fetchOldMessages();
    }
    setScrollPosition(isLastMessageSeen ? 0 : elementPosition);
  };

  const handleInfiniteScroll = (event) => {
    const position = event.target.scrollTop;
    if (position > -15 && scrollPosition !== 0) {
      setScrollPosition(0);
    } else if (position < -15 && scrollPosition === 0) {
      setScrollPosition(position);
    }
  };

  const setLastMessageRef = (ref, index) => {
    if (index === 0 && IS_MOBILE_SAFARI_BROWSER) {
      if (!lastMessageRef?.current) {
        ref.scrollIntoView();
      }

      if (ref) {
        lastMessageRef.current = ref;
        const {isLastMessageSeen} = getScrollableListParams();
        if (isLastMessageSeen || (!isLastMessageSeen && !isScrolled.current)) {
          ref.scrollIntoView();
          isScrolled.current = false;
        }
      }
    }
  }

  const renderMessages = () => {
    if (messagesList.length > 0) {
      return messagesList.map((msg, index) => {
        if (msg.id.indexOf('_new') !== -1) {
          return (
            <div className='mobile-new-message-item mobile-new-message-item_newmessage' key={`${msg.id}_new`} ref={setLastMessageRef}>
              <div className='mobile-new-message-item_newmessage_delimiter' />
              <FormattedMessage {...chatScreenMessages.newMessages} />
              <div className='mobile-new-message-item_newmessage_delimiter' />
            </div>
          );
        }
        return (
          <MessageItemWrapper
            setLastMessageRef={setLastMessageRef}
            index={index}
            key={msg.id}
            children={<MessageItem
              msg={msg}
              user={user}
              room={room}
              setFullSizeChatImage={setFullSizeChatImage}
              incrementLoadedImages={incrementLoadedImages}
              videoInitiated={videoInitiated}
              navigateToLobby={() => {}}
            />}
          />
        )
      });
    }
    return null;
  };

  const renderTypingMessage = () => {
    if (userForTyping && room) {
      return (
        <MessageItemWrapper
          key='typing'
          setLastMessageRef={setLastMessageRef}
          children={<MessageItem typing={true} user={userForTyping}/>}
        />
      )
    }
  }

  const renderStartVideoMessage = () => {
    if (isShowStartVideoCallMessage && !videoInitiated) {
      return (
        <MessageItemWrapper
          setLastMessageRef={setLastMessageRef}
          key={TEMP_MESSAGE.START_VIDEO_CALL}
          children={<MessageItem
            msg={{ key: TEMP_MESSAGE.START_VIDEO_CALL }}
            user={user}
            hideStartVideoCallMessage={hideStartVideoCallMessage}
            navigateToLobby={() => {}}
            room={room} />}
        />
      )
    }
  }

  const renderTempImageMessage = () => {
    if (tempImageMessageId !== null && !isImageLoaded) {
      return (
        <MessageItemWrapper
          setLastMessageRef={setLastMessageRef}
          key={TEMP_MESSAGE.IMAGE}
          children={<MessageItem
            msg={{ key: TEMP_MESSAGE.IMAGE }}
            user={user}
            room={room}
            incrementLoadedImages={incrementLoadedImages} />}
        />
      )
    }
  }

  const renderChatContent = () => {
    if (IS_MOBILE_SAFARI_BROWSER) {
      return (
        <div className='mobile-chat-content' ref={scrollableDivRef}>
          {
            isMessagesLoading && hasMoreData &&
            <Loader height={'2rem'} width={'2rem'} />
          }
          <div className='mobile-infinite-scroll' ref={infiniteScrollRef}>
            {renderTypingMessage()}
            {renderStartVideoMessage()}
            {renderTempImageMessage()}
            {renderMessages()}
          </div>
        </div>
      );
    }

    return (
      <div className='mobile-chat-content'>
        <div
          id='scrollableDiv'
          style={{
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column-reverse',
          }}
        >
          <InfiniteScroll
            dataLength={items.length}
            next={fetchOldMessages}
            className='mobile-infinite-scroll'
            inverse={true}
            hasMore={hasMoreData}
            loader={<Loader height={'2rem'} width={'2rem'} />}
            scrollableTarget='scrollableDiv'
            onScroll={handleInfiniteScroll}
            initialScrollY={0}
            key={room.id}
          >
            {renderTypingMessage()}
            {renderStartVideoMessage()}
            {renderTempImageMessage()}
            {renderMessages()}
          </InfiniteScroll>
        </div>
      </div>
    )
  }

  return renderChatContent();
}
