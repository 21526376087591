import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AVsettings } from '../../../../assets/icons/AVsettings';
import { PAGES } from '../../../../constants/routes';
import { useAppHeaderProvider } from '../../../AppHeaderProvider';
import { VIDEO_CALL_PAGES } from '../../../VideoCall';
import './AVSettings.scss';

export const AVSettings = () => {
    const { AVSettingVisible, setAVSettingVisible } = useAppHeaderProvider();
    const [avSettingsAvailable, setAvSettingsAvailable] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setAvSettingsAvailable(AVSettingAvailabilityRoutes(history.location.pathname))
    }, [history.location.pathname])


    const AVSettingAvailabilityRoutes = (currentLocation) => {
        return [
            `${PAGES.VIDEO_CALL}`,
            `${PAGES.VIDEO_CALL}/${VIDEO_CALL_PAGES.VIDEO_LOBBY}`,
            `${PAGES.VIDEO_CALL}/${VIDEO_CALL_PAGES.WAITING_ROOM}`
        ].includes(currentLocation);
    }

    return (
        <div className={`boutiq-audio-video-settings${avSettingsAvailable ? '' : ' disabled'}`}>
            <button onClick={() => setAVSettingVisible(!AVSettingVisible)} className='boutiq-audio-video-button'>
                <AVsettings />
            </button>
        </div>)
}
