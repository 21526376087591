import * as React from "react"

const ArrowForwardIosOutlined = ({size = 'default', color = 'default', className = '', onClick= null}) => (
  <svg
    className={`caazam-icon ${className} color-${color} size-${size}`}
    viewBox="0 0 24 24"
    aria-hidden="true"
    onClick={onClick}
  >
    <path d="m6.49 20.13 1.77 1.77 9.9-9.9-9.9-9.9-1.77 1.77L14.62 12l-8.13 8.13z" />
  </svg>
)

export default ArrowForwardIosOutlined
